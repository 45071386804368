import React, { useEffect, useState , useContext} from 'react'

import { getAllUsers, validateOtp } from '../../../api/login'
import OTPInput from '../../../shell/OTPInput'
import { encryptToken, mobileFormat } from '../../../Utils/utils'
import ForgetPasswordOTPTimer from './ForgetPasswordOTPTimer'
import {PhrasesContext} from '../../../context/PhrasesContext'
import { langContext } from "../../../context/langContext";


function ForgetPasswordOTP({loginUserData, setForgetPasswordViews}) {
    const [apiValidation, setApiValidation] = useState("")

    const [userData, setUserData] = useState({
        loading: true,
        data: {},
        error: false
    })
    const [OTPData, setOTPData] = useState({
        userName: loginUserData.name,
        OTP: ""
    })
    const [isSubmitted, setIsSubmitError] = useState(false)
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);


    //- get the OTP validation
    useEffect(() => {
        if(OTPData.OTP.length === 4) {
            validateOtp(OTPData.userName, +OTPData.OTP)
                .then(response => {
                    setIsSubmitError(false)
                    localStorage.setItem('user', JSON.stringify({
                        token: encryptToken(response.data.data.token),
                        allowLogin: false
                    }))
                    setForgetPasswordViews({
                        isForgetPassword: false,
                        isForgetPasswordOTP: false,
                        isForgetPasswordResend: true
                    })
                    
                })
                .catch(error => {
                    console.log(error)
                    setIsSubmitError(true)
                    setApiValidation(error?.response?.data?.key || 'lblSomethingWrong')
                })
        }
        
    }, [OTPData, lang])

    const handleForgetPassViews = () => {
        setForgetPasswordViews({
            isForgetPassword: true,
            isForgetPasswordOTP: false,
            isForgetPasswordResend: false
        })
    }

    return (
        <>
            <div className="login-card-head">
                <strong className="title">{phrases?.lblVerification?.[lang]}</strong>
                <p>{phrases?.lblPleaseEnter4DigitsOTP?.[lang]}</p>
                <span>
                    {`(${loginUserData.mob1}) ***** ${loginUserData.mob2}`}
                </span>

            </div>
            <div className="login-card-body">
                <div className="login-form-wrapper">
                    <form className="request-form" id="login-create">
                        <div className="form-row otp-form-row">
                            <OTPInput setData={setOTPData} name="OTP" isSubmitted={isSubmitted} setIsSubmitError={setIsSubmitError} />
                        </div>
                        {
                            isSubmitted && 
                            <div className="form-row">
                                <div className="form-col invalid-col">
                                    <p className="error-msg" style={{textAlign: 'center'}}>
                                        {
                                            phrases[apiValidation]
                                            ?   phrases[apiValidation][lang]
                                            :   phrases[`lblSomethingWrong`][lang]
                                        }
                                    </p>
                                </div>
                            </div>
                        }

                        <ForgetPasswordOTPTimer userName={loginUserData.name} setIsSubmitError={setIsSubmitError} />

                        <div className="form-row" style={{marginTop: '32px'}}>
                            <div className="form-col invalid-col back-wrap">
                                <p className="success-msg login-back-btn" onClick={handleForgetPassViews}>{phrases?.lblBacktoForgetPassword?.[lang]}</p>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}

export default ForgetPasswordOTP
