import React, { useEffect, useRef, useState } from 'react'
import downArrow from "../../assets/icons/down-arrow-green.svg";
import { DubaiDialCodes } from '../../Utils/utils';
import PhoneHandleCountryCodeList from './PhoneHandleCountryCodeList';
import $ from 'jquery'

function PhoneHandleCountryCode({setData, cCode, disabled}) {
    const selectRef = useRef(null)
    const requestBodyRef = useRef(null)
    const textRef = useRef(null)


    //- handle dropdown toggle
    const handleReqeustSelect = () => {
        if(disabled) {
            return
        }
        $(requestBodyRef.current).toggle()
    }

    
    //- handle dropdown on window click
    useEffect(() => {
        function hideSelectOutSide(event) {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                $(requestBodyRef.current).hide();
            }
        }
        document.addEventListener("click", hideSelectOutSide);
        return () => {
            document.removeEventListener("click", hideSelectOutSide);
        }
    }, [])



    return (
        <div className={`country-code`}>
            <div className="input-wrap">
                <div className="request-select" ref={selectRef}>
                    <div className={`request-select-head ${disabled ? 'disabled' : ''}`} onClick={handleReqeustSelect}>
                        <div className="request-select-text">
                            <span ref={textRef}>{cCode}</span>
                        </div>
                        <img src={downArrow} width="11" alt="icon" />
                    </div>
                    <div className="request-select-body" ref={requestBodyRef}>
                        <ul>
                            {
                                DubaiDialCodes.map(item => {
                                    return (
                                        <PhoneHandleCountryCodeList 
                                            key={item.id} 
                                            data={item}
                                            setData={setData}
                                            refBody={requestBodyRef} 
                                            refText={textRef}
                                        />
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PhoneHandleCountryCode
