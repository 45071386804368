import React, { useState, useContext } from 'react'
import { useHistory } from "react-router-dom";

import PasswordInput from '../../../shell/PasswordInput'
import CloseBlack from "../../../assets/icons/icons-close.svg";
import closeRed from "../../../assets/icons/close-red.svg";
import $ from 'jquery'
import { useEffect } from 'react';
import { userContext } from '../../../context/userContext';
import { modalContext } from '../../../context/modalContext';
import { langContext } from '../../../context/langContext';
import { PhrasesContext } from '../../../context/PhrasesContext';
import { ChangePassword } from '../../../api/profile';
import axios from 'axios'
import { decryptToken } from '../../../Utils/utils';
//- Validation
function validateForm(relatedForm) {
    var amountInput = $(relatedForm).find('.requiredData');
    var filledInputs = [];
    amountInput.each(function() {
        filledInputs.push(this);
    });
    
    
    var noValFilter = filledInputs.filter(function (item) {
        return $(item).val() === '';
    })

    $(noValFilter).each(function () {
        $(this).closest('.form-col').addClass('invalid-col');
        $(this).addClass('invalid-input');
        $(this).closest('.form-col').find('.error-msg').show()
    });


    if(noValFilter.length === 0) {
        return true
    } 

}

function ProfilePasswordUpdate() {
    const history = useHistory();
    const {userContextData, userContextDispatch} = useContext(userContext)
    const {setModalState} = useContext(modalContext)
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);


    const [customError, setCustomError] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isSumbitError, setIsSubmitError] = useState(false)
    //- password data
    const [passwordData, setPasswordData] = useState({
        CurrentPassword: "",
        NewPassword: "",
        ConfirmPassword: ""
    })


    useEffect(() => {
        console.log("Password Data ==>", passwordData)
        setCustomError(false)
    }, [passwordData])

    //- password validation data
    const [apiValidation, setApiValidation] = useState("")


    // custom error handling
    const closeErrorSubmit = () => {
        setIsSubmitError(false)
    }


    // - custom function for password validation
    let isValidateToSubmit = true;
    
    const passwordValidationFunc = (relatedClass, validMsg) => {
        $(`.${relatedClass}`).addClass("invalid-input");
        setApiValidation(validMsg)
        setCustomError(true)
        isValidateToSubmit = false;
    }

    //- handle data submit
    const handleFormSubmit = e => {
        let elem = $(e.target);
        let isPasswordPatternValid = true;
        isValidateToSubmit = true;
        
        var passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;




        // if (!passwordData.CurrentPassword.match(passwordPattern) && passwordData.CurrentPassword.length !== 0) {
        //     passwordValidationFunc('CurrentPassword', phrases?.lblPasswordValidation?.[lang])
        // }

        // if (!passwordData.NewPassword.match(passwordPattern) && passwordData.NewPassword.length !== 0) {
        //     passwordValidationFunc('NewPassword', phrases?.lblPasswordValidation?.[lang])
        //     isPasswordPatternValid = false

        // }

        // if (!passwordData.ConfirmPassword.match(passwordPattern) && passwordData.ConfirmPassword.length !== 0) {
        //     passwordValidationFunc('ConfirmPassword', phrases?.lblPasswordValidation?.[lang])
        //     isPasswordPatternValid = false

        // }

        // if (
        //     passwordData.NewPassword !== passwordData.ConfirmPassword && isPasswordPatternValid
        //     && passwordData.CurrentPassword.length !== 0 && passwordData.NewPassword.length !== 0 && passwordData.ConfirmPassword.length !== 0
        // ) {
        //     passwordValidationFunc('ConfirmPassword', phrases?.lblNewPasswordReEnterMustMatch?.[lang])
        // }




        if (passwordData.NewPassword.length < 8 || passwordData.ConfirmPassword.length < 8 || passwordData.CurrentPassword.length < 8) {
            isValidateToSubmit = false;
        } 



        if (!validateForm(elem)) {
            isValidateToSubmit = false;
        }
        if(isValidateToSubmit) {
            console.log("Validate Status ===> SUCCESS")
            setIsSubmitted(true)
            let submittedData = {
                currentPassword: passwordData.CurrentPassword, 
                newPassword: passwordData.NewPassword, 
                confirmPassword: passwordData.ConfirmPassword
            }

                ChangePassword(submittedData).then(response => {
                        
                        console.log('SUCESS')
                        setIsSubmitted(false)
                        setModalState('showPasswordSuccess')
                        setIsSubmitError(false)
                        setPasswordData({
                            CurrentPassword: "",
                            NewPassword: "",
                            ConfirmPassword: ""
                        })
                        
                    })
                    .catch(error => {
                        console.log(error)
                        console.log('Failed')
                        setIsSubmitted(false)
                        setIsSubmitError(true)
                        setApiValidation(error?.response?.data?.key || 'lblSomethingWrong')

                    })
                
        }

        e.preventDefault();
    }

    


    return (
        <div className="profile-card">
            <div className="profile-card-head">
                <div className="user-title-wrap">
                    <strong>{phrases?.lblChangeYourPassword?.[lang]}</strong>
                </div>
                
            </div>
            {
                isSumbitError
                ?   <div className="note error">
                        <div className="note-text">
                            <img src={closeRed} alt="" />
                            <span>
                                {
                                    phrases[apiValidation]
                                    ?   phrases[apiValidation][lang]
                                    :   phrases[`lblSomethingWrong`][lang]
                                }
                            </span>
                        </div>
                        <img src={CloseBlack} className="closeNote" onClick={closeErrorSubmit} />
                    </div>
                :   null
            }
            <div className="profile-card-body">
                <form className="request-form" id="update-password" onSubmit={handleFormSubmit}>
                    <div className="form-row">
                        <div className="form-col">
                            <strong>{phrases?.lblCurrentPassword?.[lang]}</strong>
                            <PasswordInput 
                                data={passwordData} 
                                setData={setPasswordData} 
                                name="CurrentPassword"
                                placeHolder={phrases?.lblPleaseENterYourCurrentPassword?.[lang]}
                                setIsSubmitError={setIsSubmitError}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col">
                            <strong>{phrases?.lblNewPassword?.[lang]}</strong>
                            <PasswordInput 
                                data={passwordData} 
                                setData={setPasswordData} 
                                name="NewPassword"
                                placeHolder={phrases?.lblPleaseEnterYourNewPassword?.[lang]}
                                setIsSubmitError={setIsSubmitError}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col">
                            <strong>{phrases?.lblReEnterPassword?.[lang]}</strong>
                            <PasswordInput 
                                data={passwordData} 
                                setData={setPasswordData} 
                                name="ConfirmPassword"
                                placeHolder={phrases?.lblPleaseReEnterYourNewPassword?.[lang]}
                                setIsSubmitError={setIsSubmitError}
                            />
                        </div>
                    </div>


                    <div className="request-form-action">
                        <div className="form-aciton">
                            <button form="update-password" type="submit" className="site-btn full" disabled={`${isSubmitted  ? "disabled" : ""}`}>
                                <span>{phrases?.lblChangeYourPassword?.[lang]}</span>
                                {
                                    isSubmitted
                                    ?   <div className="spinner"></div>
                                    :   null
                                }
                                
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ProfilePasswordUpdate
