import React, {useContext} from 'react'
import shareICON from "../../../../assets/icons/share-btn-icon.png";
import { modalContext } from '../../../../context/modalContext';
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';
import { RequestListContext } from '../../../../context/RequestListContext';

function ShareBtn({}) {
    const [report, setReport, filter, setFilter] = useContext(RequestListContext)
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)

    const {setModalState} = useContext(modalContext)
    const showShare = () => {
        if(report.data.length === 0) {
            return
        }
        setModalState('showShareSend')
    }
    return (
        <li className={`site-btn white ${report.data.length === 0 ? 'disabled' : ''}`} onClick={showShare}>
            <img src={shareICON} alt="icon" />
            <span>{phrases?.btnShare?.[lang]}</span>
        </li>
    )
}

export default ShareBtn
