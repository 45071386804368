import React from 'react'
import ReportsCompareIndex from '../Components/Reports/ReportsCompare/ReportsCompareIndex'

function ReportComparePage(props) {
    const {firstId, secondId} = props.match.params;

    return (
        <>
            <div className="data-container">
                <div className="data-wrapper report-details-wrapper">
                    <div className="request-report-wrap">
                        <ReportsCompareIndex firstId={firstId} secondId={secondId} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportComparePage
