import React, { useEffect, useContext } from "react";
import { GoogleMap, Marker, KmlLayer, Polygon } from "@react-google-maps/api";
import CustomMarker from "../CustomMarker";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import { notificationContext } from "../../context/addNotificationContext";
import { ListOnMapContext } from "../../context/listOnMap";

const KmlLayerOptions = {
  suppressInfoWindows: true,
  preserveViewport: true,
};

const trianglePolygon = [
  { lat: 25.774, lng: -80.19 },
  { lat: 18.466, lng: -66.118 },
  { lat: 32.321, lng: -64.757 },
];

const pubnub = new PubNub({
  publishKey: "pub-c-bf1cafb0-020e-43eb-b564-72cfb47b2c84",
  subscribeKey: "sub-c-49010a26-3a18-11eb-b6eb-96faa39b9528",
  uuid: "PubNubdriver",
  secretKey: "sec-c-MWQ1ZjlkNmYtYzllZC00ZWQ5LWI1YjMtNDIxMzE1NWMyZjY5",
  restore: true,
  listenToBrowserNetworkEvents: false,
});

const WrappedMap = props => {
  useEffect(() => {
      if (props.isMapLoaded && props?.pickedLocation?.latitude !== "") {
        
            props.panTo({
              lat: props?.pickedLocation?.latitude || "",
              lng: props?.pickedLocation?.longitude || "",
            });
      }
    
  }, [props?.pickedLocation?.latitude]);

  const [requestsList, renderList, updateRequestsList, listWithoutUpdate] =
    useContext(ListOnMapContext);

  return (
    <GoogleMap
      id="map"
      mapContainerStyle={props.mapContainerStyle}
      zoom={props.currentZoom}
      center={props.center}
      options={props.options}
      // onClick={props.onMapClick}
      onLoad={props.onMapLoad}
    >
      <div
        className={`custom-controls-wrap ${
          props.requestStatus.showList ||
          props.requestStatus.showDetailed ||
          props.requestStatus.showAddRequest ||
          props.requestStatus.showEditRequest
            ? "move"
            : ""
        }`}
      >
        <ul>
          <li onClick={props.mapTypeToggle}>
            <img
              src={props.isRoadMap ? props.GlogbeIcon : props.GreenGlogbeIcon}
            />
          </li>

          <li onClick={() => props.handleZoom("inc")}>
            <img src={props.AddIcon} />
          </li>
          <li onClick={() => props.handleZoom("dec")}>
            <img src={props.MinusIcon} />
          </li>
          <li
            onClick={
              !props.isFullScreen ? props.showFullScreen : props.hideFullScreen
            }
          >
            <img
              src={
                !props.isFullScreen
                  ? props.FullScreenIcon
                  : props.CloseFullScreen
              }
              alt="icon"
            />
          </li>
        </ul>
      </div>
      {props.requestsList?.map(request => {
        return props.renderDynamicOverlay({
          key: request?.ccExternalId,
          lat: request?.pickUpLatitude,
          lng: request?.pickUpLongtitude,
          iconClass: request?.statusNameEn,
          numberOfVehicles: request?.ccNumberOfVehicles,
          fn: () => {
            props.showDetailedRequest(request?.requestId);
            let filteredArray = props.requestsList.filter(
              item => item?.requestId == request?.requestId
            );
            props.updateRequestsList(filteredArray);
          },
        });
      })}

      {props.mapControl.isVehiclesRendered &&
        props.concentrationPoints?.map(point => {
          return (
            <div key={point?.latitude}>
              <Marker
                position={{ lat: point?.latitude, lng: point?.longitude }}
                icon={{
                  url: `/IconGreenCircle.svg`,
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(62.5, 62.5),
                  scaledSize: new window.google.maps.Size(130, 130),
                }}
              />
            </div>
          );
        })}
      <>
        {/** Pin Marker  */}
        {props.showPin && (
          <Marker
            position={{
              lat: props.pickedLocation.latitude,
              lng: props.pickedLocation.longitude,
            }}
            draggable
            onDragEnd={props.onPinDrag}
            icon={{
              url: `/pin.svg`,
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(75, 75),
              scaledSize: new window.google.maps.Size(150, 150),
            }}
          />
        )}
      </>
      <PubNubProvider client={pubnub}>
        <CustomMarker
          onIconDblClick={props.onIconDblClick}
          activeRequests={props.activeRequests}
          requestsList={listWithoutUpdate}
          AllFiltredRequestsList={requestsList}
          google={props.google}
          directionService={props.directionService}
          updateRequestsList={updateRequestsList}
          map={props.map}
        ></CustomMarker>
      </PubNubProvider>
      <KmlLayer
        options={KmlLayerOptions}
        onClick={props.onMapClick}
        url="https://cdn.emiratesauction.com/CCPCDN/MapSupportedRegions.kml"
      />
    </GoogleMap>
  );
};

export default WrappedMap;
