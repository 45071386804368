import React, {useContext, useState, useEffect} from 'react'
import { chartsType } from '../../../../../../api/requests_reports'
import { langContext } from '../../../../../../context/langContext'
import { PhrasesContext } from '../../../../../../context/PhrasesContext'
import RequestsTypeChart from '../../../../../RequestsTypeChart'
import ReportsCompareChartsType from './ReportsCompareChartsType'
import ReportsCompareChartsTypeHead from './ReportsCompareChartsTypeHead'

function ReportsCompareChartsTypeIndex({chartView, firstId, secondId, reportDataFirst, reportDataSecond}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)


    //- charts type data
    const [chartsTypeDataFirst, setChartsTypeDataFirst] = useState({loading: true, data: []})
    const [chartsTypeDataSecond, setChartsTypeDataSecond] = useState({loading: true, data: []})

    const [totalTypeDataFirst, setTotalTypeDataFirst] = useState({total: 0, impound: 0, offset: 0})
    const [totalTypeDataSecond, setTotalTypeDataSecond] = useState({total: 0, impound: 0, offset: 0})
    
    
    useEffect(() => {

        //- fetch the first
        chartsType(firstId)
            .then(response => {

                setChartsTypeDataFirst({loading: false, data: response.data.data !== null ? response.data.data.requestsbyTypeList : []})
                
                if(response.data.data === null) {
                    setTotalTypeDataFirst({total: 0, impound: 0, offset: 0})
                } else {
                    setTotalTypeDataFirst({
                        total: response.data.data.requestsbyTypeList.reduce((accumulator, current) => accumulator + current.total, 0),
                        impound: response.data.data.requestsbyTypeList.reduce((accumulator, current) => accumulator + current.impound, 0),
                        offset: response.data.data.requestsbyTypeList.reduce((accumulator, current) => accumulator + current.offset, 0)
                    })
                }
            })
            .catch(error => {
                console.log(error)
                setChartsTypeDataFirst({loading: false, data: []})
            })
        
        //- fetch the second 
        chartsType(secondId)
            .then(response => {
                setChartsTypeDataSecond({loading: false, data: response.data.data !== null ? response.data.data.requestsbyTypeList : []})

                if(response.data.data === null) {
                    setTotalTypeDataSecond({total: 0, impound: 0, offset: 0})
                } else {
                    setTotalTypeDataSecond({
                        total: response.data.data.requestsbyTypeList.reduce((accumulator, current) => accumulator + current.total, 0),
                        impound: response.data.data.requestsbyTypeList.reduce((accumulator, current) => accumulator + current.impound, 0),
                        offset: response.data.data.requestsbyTypeList.reduce((accumulator, current) => accumulator + current.offset, 0)
                    })
                }
            })
            .catch(error => {
                console.log(error)
                setChartsTypeDataSecond({loading: false, data: []})
            })
    }, [])



    return (
        <>
            <ReportsCompareChartsTypeHead 
                reportDataFirst={reportDataFirst} 
                reportDataSecond={reportDataSecond}
                totalTypeDataFirst={totalTypeDataFirst}
                totalTypeDataSecond={totalTypeDataSecond}
            />
            
            {
                chartsTypeDataFirst.loading || chartsTypeDataSecond.loading
                ?   <div className="card-loader data" style={{ width: "100%", height: "180px", marginBottom: "16px" }} ></div>
                :   chartView === 'merged'
                    ?   <ReportsCompareChartsType firstChartData={chartsTypeDataFirst.data} secondChartData={chartsTypeDataSecond.data} />

                    :   <div className="map-status-compare">
                            <div className="double-map-wrapper">
                                <div className="double-map-item status-chart">
                                    <RequestsTypeChart isBig chartData={chartsTypeDataFirst.data} />
                                    
                                </div>
                                <div className="double-map-item status-chart">
                                    <RequestsTypeChart isBig chartData={chartsTypeDataSecond.data} />
                                    
                                </div>
                            </div>
                            
                        </div>   
                
                
            }
            
        </>
    )
}

export default ReportsCompareChartsTypeIndex
