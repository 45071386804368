import React, { useContext, useEffect, useState } from "react";
import { langContext } from "../../context/langContext";
import { PhrasesContext } from "../../context/PhrasesContext";
import { requestContext } from "../../context/reqeustContext";
import { itemIdContext } from "../../context/itemIdContext";
import { PinContext } from "../../context/pinContext";
import { PickedLocationContext } from "../../context/pickedLocationContext";
import { ListOnMapContext } from "../../context/listOnMap";

import { AddNewRequest } from "../../api/requests";
import RequestType from "./RequestType";
import InjuredCounter from "./InjuredCounter";
import FormIcon from "../../assets/icons/form-icon-head.svg";
import MousePin from "../../assets/icons/mousepin.svg";
import mapPin from "../../assets/icons/icons-map-pin.svg";
import closeRed from "../../assets/icons/close-red.svg";
import CloseBlack from "../../assets/icons/icons-close.svg";

import $ from "jquery";
import { profileContext } from "../../context/profileContext";
import AutoCompleteInputs from "./AutoCompleteInputs";



//- dynamic valdations
//- Validation
function validateForm(relatedForm) {
    var amountInput = $(relatedForm).find(".requiredData");
    var filledInputs = [];
    amountInput.each(function () {
        filledInputs.push(this);
    });

    var noValFilter = filledInputs.filter(function (item) {
        return $(item).val() === "";
    });

    $(noValFilter).each(function () {
        $(this).closest(".form-col").addClass("invalid-col");
        $(this).addClass("invalid-input");
        $(this).closest(".form-col").find(".error-msg").show();
    });

    if (noValFilter.length === 0) {
        return true;
    }
}
function RequestAddIndex() {
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const { setRequestStatus } = useContext(requestContext);
    const { setItemId } = useContext(itemIdContext);
    const [showPin, changePinStatus] = useContext(PinContext);
    const [requestsList, renderList, updateRequestsList] = useContext(ListOnMapContext);


    


    //- Request state
    const [newRequestData, setNewRequestData] = useState({
        pickUpLocation: {
            longitude: 0,
            latitude: 0,
            title: "",
            address: "",
        },
        policemanName: "",
        policemanPhone: "",
        numberOfVehicles: 1,
        requestTypeId: 1,
        militaryNumber: "",
        policemanId: 0,
        userId: 0
    });
    
    //- Log data once changed
    useEffect(() => {
        console.log("Request Data ===> ", newRequestData)        
    }, [newRequestData])

    //- Request location update
    const [location] = useContext(PickedLocationContext);
    useEffect(() => {
        setNewRequestData({ ...newRequestData, pickUpLocation: location });
    }, [location]);



    



    //- handle submit preloading
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSumbitError, setIsSubmitError] = useState(false);
    const [apiValidation, setApiValidation] = useState('');
    const closeErrorSubmit = () => {
        setIsSubmitError(false);
    };


    //- show request list
    const showRequestList = () => {
        if (!isSubmitted) {
            setRequestStatus("showList");
            setItemId(null);
            changePinStatus(false);
        }
    };


    //- handle form submit
    const hanldeSubmit = e => {
        let elem = $(e.target);
        let isValidateToSubmit = true;

        if(
            newRequestData.pickUpLocation.address === "" ||  newRequestData.policemanName.length < 3 ||
            newRequestData.policemanPhone.length < 10 ||
            newRequestData.militaryNumber.length < 2
        ) {
            isValidateToSubmit = false
        }

        if (!validateForm(elem)) {
            isValidateToSubmit = false;
        }

        if(isValidateToSubmit) {
            console.log("Validate Status ===> SUCCESS")


            setIsSubmitted(true)
            let handleNewReuqestData = {
                pickUpLocation: newRequestData.pickUpLocation,
                policemanName: newRequestData.policemanName,
                policemanPhone: newRequestData.policemanPhone,
                numberOfVehicles: newRequestData.numberOfVehicles,
                requestTypeId: newRequestData.requestTypeId,
                militaryNumber: +newRequestData.militaryNumber,
                policemanId: newRequestData.policemanId,
                userId: newRequestData.userId
        
            };

            
            AddNewRequest(handleNewReuqestData)
                .then(response => {
                    setIsSubmitted(false);
                    setItemId(response.data.data.requestId);
                    setRequestStatus("showDetailed");
                    changePinStatus(false);
                    renderList();
                })
                .catch(error => {
                    console.log(error)
                    setIsSubmitted(false)
                    setIsSubmitError(true)
                    setApiValidation(error?.response?.data?.key || 'lblSomethingWrong')

                })
        }

        e.preventDefault();
    }

    
    
    return (
        <>
            <div className="request-head">
                <div className="request-title noMargin">
                    <strong>{phrases?.lblCreateNew?.[lang]}</strong>
                </div>
            </div>
            
            {
                isSumbitError 
                ?   <div className="note error">
                        <div className="note-text">
                            <img src={closeRed} alt="" />
                            <span> 
                                {
                                    phrases[apiValidation]
                                    ?   phrases[apiValidation][lang]
                                    :   phrases[`lblSomethingWrong`][lang]
                                }
                            </span>
                        </div>
                        <img className="closeNote" onClick={closeErrorSubmit} src={CloseBlack} />
                    </div>
                :   null
            }
            
            <div className="request-body">
                <div className="request-form-contain">
                    <div className="request-form-wrap">
                        <div className="request-form-head">
                            <div className="request-form-title">
                                <img src={FormIcon} alt="icon" />
                                <strong>{phrases?.lblProvideMainData?.[lang]}</strong>
                            </div>
                        </div>
                        <div className="request-form-body">
                            <form className="request-form" id="request-add-form" onSubmit={hanldeSubmit}>
                                <div className="form-row">
                                    <div className="form-col">
                                        <strong>{phrases?.lblRequestType?.[lang]}</strong>
                                    </div>
                                    <RequestType data={newRequestData} setData={setNewRequestData} selectedTab={newRequestData.requestTypeId} />
                                </div>
                                
                                <div className="form-row">
                                    <div className="form-col">
                                        <strong>
                                            {phrases?.lblNumberofRecoveriesNeeded?.[lang]}

                                            <span>{phrases?.lblOptional?.[lang]}</span>
                                        </strong>
                                        <InjuredCounter
                                            data={newRequestData}
                                            setData={setNewRequestData}
                                        />
                                    </div>
                                </div>
                                
                                <AutoCompleteInputs 
                                    data={newRequestData} 
                                    setData={setNewRequestData} 
                                    setIsSubmitError={setIsSubmitError} 
                                />
                                
                                
                                <div className="form-row">
                                    <div className="form-col">
                                        <strong>{phrases?.lblAccidentLocation?.[lang]}</strong>
                                        <div className="request-location">
                                            <div className="request-location-item">
                                                <img src={mapPin} alt="icon" />
                                                <p className="location-txt">{location.address}</p>
                                            </div>
                                            <div className="request-location-item">
                                                <img src={MousePin} alt="icon" />
                                                <p className="location-note">
                                                    {phrases?.lblMoveThePinOverMapOrSearch?.[lang]}{" "}
                                                </p>
                                            </div>
                                        </div>
                                        <p className="error-msg">
                                            {phrases?.lblInvalidAddress?.[lang]}
                                        </p>
                                    </div>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
                <div className="request-form-action">
                    <div className="form-aciton">
                        <button
                            form="request-add-form"
                            type="submit"
                            className="site-btn"
                            disabled={`${isSubmitted ? "disabled" : ""}`}
                        >
                            <span>{phrases?.btnSubmitRequest?.[lang]}</span>
                            {isSubmitted ? <div className="spinner"></div> : null}
                        </button>
                        <p onClick={showRequestList} className={`${isSubmitted ? "disabled" : ""}`}>
                            {phrases?.lblCancelAndExploreRequestsList?.[lang]}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RequestAddIndex
