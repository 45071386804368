import React, {useEffect, useState} from 'react'
import { mapHeatPoints } from '../../../../../../api/requests_reports'
import ReportsCompareChartsMap from './ReportsCompareChartsMap'

function ReportsCompareChartsMapIndex({firstId, secondId, reportDataFirst, reportDataSecond}) {
    //- map heat points
    const [mapHeatPointsDataFirst, setMapHeatPointsDataFirst] = useState({loading: true, data: []})
    const [mapHeatPointsDataSecond, setMapHeatPointsDataSecond] = useState({loading: true, data: []})
    
    //- fetch map points
    useEffect(() => {
        //- fetch first points
        mapHeatPoints(firstId)
            .then(response => {
                setMapHeatPointsDataFirst({loading: false, data: response.data.data !== null ? response.data.data : []})
            })
            .catch(error => {
                console.log(error)
                setMapHeatPointsDataFirst({loading: false, data: []})
            })
        
        //- fetch second points
        mapHeatPoints(secondId)
            .then(response => {

                setMapHeatPointsDataSecond({loading: false, data: response.data.data !== null ? response.data.data : []})
            })
            .catch(error => {
                console.log(error)
                setMapHeatPointsDataSecond({loading: false, data: []})
            })
    }, [])



    return (
        <>
            {
                mapHeatPointsDataFirst.loading || mapHeatPointsDataSecond.loading
                ?   <div className="card-loader data" style={{ width: "100%", height: "180px", marginBottom: "16px" }} ></div>
                :   <ReportsCompareChartsMap 
                        filterValueFirst={mapHeatPointsDataFirst.data} 
                        filterValueSecond={mapHeatPointsDataSecond.data}
                        reportDataFirst={reportDataFirst}
                        reportDataSecond={reportDataSecond}

                    />
            }
        </>
    )
}

export default ReportsCompareChartsMapIndex
