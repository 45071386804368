import React from 'react';



export const addRequestDataContext = React.createContext();
export const addRequestDataInitialState = ""


export const addRequestDataReducer = (state = addRequestDataInitialState, action) => {
    return state = action
}