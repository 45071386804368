import React, { useContext, useState } from 'react'
import CloseIocn from '../../../assets/icons/icon-close-white.svg';
import { modalContext } from '../../../context/modalContext';
import { langContext } from '../../../context/langContext';
import {PhrasesContext} from '../../../context/PhrasesContext';
import { deleteReport, getReportList } from '../../../api/requests_reports';
import { useHistory } from "react-router-dom";


function ReportDetailsDeleteModal({isList, itemId, report, setReport, deletedReport}) {
    const history = useHistory();

    const {setModalState} = useContext(modalContext)
    const {lang} = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const [isSubmitted, setIsSubmitted] = useState(false)
    const hideModal = () => {
        setModalState('hideModal')
    }
    const stopProb = (e) => {
        e.stopPropagation()
    }

    const handleDeleteRequest = () => {
        if(isSubmitted) {
            return
        }
        setIsSubmitted(true)
        if(isList) {
            deleteReport(deletedReport)
            .then(response => {
                const submittedData = {
                    reportName: "",
                    pageIndex: 0
                }
                setReport({
                    ...report,
                    loading: true
                })
                setIsSubmitted(false)
                setModalState('hideModal')

                getReportList(submittedData)
                    .then(response => {
                        const reportDataArray = [];
                        if(response.data.data.reports.length) {
                            response.data.data.reports.map(item => reportDataArray.push({...item, selected: false}))
                        }
                        
                        setReport({
                            loading: false,
                            data: reportDataArray,
                            totalCount: response.data.data.totalCount,
                            error: false
                        })
                    })
                    .catch(error => {
                        setReport({
                            loading: false,
                            data: [],
                            totalCount: "",
                            error: true
                        })
                        console.log("Error ==>", error)
                    })
            })
            .catch(error => {
                console.log(error)
                setModalState('hideModal')

            })
        }
        if(!isList) {
            deleteReport([+itemId])
            .then(res => {
                console.log('===> Deleted Success <===')
                setIsSubmitted(false)
                setModalState('hideModal')
                history.push('/report')
            })
            .catch(error => {
                console.log(error)
                setModalState('hideModal')
            })
        }
        
    }
    

    return (
        <div className="modal-data" onClick={stopProb}>
            <div className="close-modal" onClick={hideModal}>
                <img src={CloseIocn} alt="icon" />
            </div>
            <div className="modal-data-head">
                <strong>{phrases?.lblDeleteReportConfirmation?.[lang]}</strong>
                
                {
                    !isList
                    ?   <>
                            <p>{phrases?.lblAreYouSureDeleteReport?.[lang]}</p>
                            <div className="request-id">{phrases?.lblReportID?.[lang]}: {itemId}</div>
                        </>
                    :   <p>{phrases?.lblAreYouSureDeleteSelected?.[lang]}</p>
                }
                
                
                
            </div>
            <div className="modal-data-foot">
                <div className={`site-btn ${isSubmitted ? 'disabled' : ''}`}  onClick={handleDeleteRequest}>
                    <span>{phrases?.lblDeleteReport?.[lang]}</span>
                    {
                        isSubmitted
                        ?   <div className="spinner"></div>
                        :   null
                    }
                </div>
            </div>

        </div>
    )
}

export default ReportDetailsDeleteModal
