import React, { useReducer, useEffect, useContext } from "react";
import AppLayout from "./AppLayout";
import DefaultLayout from "./DefaultLayout";
import {
  langContext,
  langInitalState,
  langReducer,
} from "../context/langContext";
import { PhrasesProvider } from "../context/PhrasesContext";
import {
  userContext,
  userInitalState,
  userReducer,
} from "../context/userContext";
import ServerDownPage from "../Pages/ServerDownPage";
import {
  serverDownContext,
  serverDownReducer,
  serverDownState,
} from "../context/serverDownContext";

function MergedLayout() {
  const [langStates, setLangStates] = useReducer(langReducer, langInitalState);
  const [userStates, setUserStates] = useReducer(userReducer, userInitalState);
  const [serverDown, setServerDown] = useReducer(
    serverDownReducer,
    serverDownState
  );

  useEffect(() => {
    if (langStates === "en") {
      document.getElementById("rootHTML").dir = "ltr";
    } else {
      document.getElementById("rootHTML").dir = "rtl";
    }
  }, [langStates]);
  return (
    <>
      <serverDownContext.Provider
        value={{ serverDown: serverDown, setServerDown: setServerDown }}
      >
        <langContext.Provider
          value={{ lang: langStates, setLang: setLangStates }}
        >
          <PhrasesProvider>
            <userContext.Provider
              value={{
                userContextDispatch: setUserStates,
                userContextData: userStates,
              }}
            >
              {serverDown ? (
                <ServerDownPage lang={langStates} />
              ) : userStates.isLogin ? (
                <AppLayout />
              ) : (
                <DefaultLayout />
              )}
            </userContext.Provider>
          </PhrasesProvider>
        </langContext.Provider>
      </serverDownContext.Provider>
    </>
  );
}

export default MergedLayout;
