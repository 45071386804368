import React, {useState, useEffect} from 'react'
import { chartsStatus } from '../../../../../../api/requests_reports'
import RequestsStatusChart from '../../../../../RequestsStatusChart'
import ReportsCompareChartsStatus from './ReportsCompareChartsStatus'

function ReportsCompareChartsStatusIndex({chartView, firstId, secondId}) {
    //- charts status data
    const [chartsStatusDataFirst, setChartsStatusDataFirst] = useState({loading: true, data: []})
    const [chartsStatusDataSecond, setChartsStatusDataSecond] = useState({loading: true, data: []})
    
    //- fetch data
    useEffect(() => {

        //- fetch first data
        chartsStatus(firstId)
            .then(response => {
                setChartsStatusDataFirst({loading: false, data: response.data.data !== null ? response.data.data.requestsbyStatusList : []})
            })
            .catch(error => {
                setChartsStatusDataFirst({loading: false, data: []})
                console.log(error)
            })

        //- fetch second data
        chartsStatus(secondId)
            .then(response => {
                setChartsStatusDataSecond({loading: false, data: response.data.data !== null ? response.data.data.requestsbyStatusList : []})
            })
            .catch(error => {
                setChartsStatusDataFirst({loading: false, data: []})
                console.log(error)
            })
    }, [])



    return (
        <>
            {
                chartsStatusDataFirst.loading || chartsStatusDataSecond.loading
                ?   <div className="card-loader data" style={{ width: "100%", height: "180px", marginBottom: "16px" }} ></div>
                :   chartView === 'merged'
                    ?   <ReportsCompareChartsStatus firstChartData={chartsStatusDataFirst.data} secondChartData={chartsStatusDataSecond.data} />
                    :   <div className="map-status-compare">
                            <div className="double-map-wrapper">
                                <div className="double-map-item status-chart">
                                    <RequestsStatusChart isBig chartData={chartsStatusDataFirst.data} />
                                    
                                </div>
                                <div className="double-map-item status-chart">
                                    <RequestsStatusChart isBig chartData={chartsStatusDataSecond.data} />
                                    
                                </div>
                            </div>
                            
                        </div>
                        
                    
            }
        </>
    )
}
export default ReportsCompareChartsStatusIndex
