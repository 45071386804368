import React, {useState, useContext, useEffect} from 'react'
import lockIcon from '../assets/icons/lock.svg';
import Eye from "../assets/icons/eye.svg";
import EyeClosed from "../assets/icons/hide.svg";
import $ from "jquery";
import {PhrasesContext} from '../context/PhrasesContext';
import {langContext} from '../context/langContext';

function PasswordInput({data, setData, name, placeHolder, setIsSubmitError}) {
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const [isPasswordView, setIsPasswordView] = useState(true)
    const [validationMsg, setValidationMsg] = useState('')
    
    useEffect(() => {
        if (data[name].toString().length === 0) {
            setValidationMsg(phrases?.lblPasswordIsRequired?.[lang])
        }
        if (data[name].toString().length < 8 && data[name].toString().length !== 0) {
            setValidationMsg(phrases?.lblPasswordShouldBe8Chars?.[lang])
        }
    }, [phrases, lang])
    
    //- hanlde value change
    const handleValueChange = e => {
        var startWithSpace = /^\s/;
        if (e.target.value.match(startWithSpace)) {
            return;
        }
        

        setData({ ...data, [name]: e.target.value });
        $(e.target).removeClass("invalid-input");
        $(e.target).closest(".form-col").removeClass("invalid-col");
        setIsSubmitError(false)
    }
    
    //- toggle password view
    const hanldePasswordView = () => {
        setIsPasswordView(prevState => !prevState)
    }

    // handle input foucs
    const hanldeFocusOut = e => {

        if (data[name].toString().length === 0) {
            $(`.${name}`).closest(".form-col").addClass("invalid-col");
            $(`.${name}`).addClass("invalid-input");
            setValidationMsg(phrases?.lblPasswordIsRequired?.[lang])


        } else if (data[name].toString().length < 8) {
            $(`.${name}`).closest(".form-col").addClass("invalid-col");
            $(`.${name}`).addClass("invalid-input");
            setValidationMsg(phrases?.lblPasswordShouldBe8Chars?.[lang])

        } else {
            $(`.${name}`).closest(".form-col").removeClass("invalid-col");
            $(`.${name}`).removeClass("invalid-input");
            console.log("===> Passwrod Validate <===");
        }
    }
    
    return (
        <>
            <div className="input-wrap has-input-icon has-eye-icon">
                <img src={lockIcon} alt="icon" className="input-icon" />
                <input 
                    className={`site-input requiredData ${name}`} 
                    type={isPasswordView ? 'password' : 'text'}
                    value={data[name]}
                    name={name}
                    placeholder={placeHolder}
                    onChange={handleValueChange}
                    onBlur={hanldeFocusOut}
                />
                <img src={isPasswordView ? Eye : EyeClosed} alt="icon" className="remove-text" onClick={hanldePasswordView} />
            </div>
            <p className="error-msg">{validationMsg}</p>
        </>
    )
}

export default PasswordInput
