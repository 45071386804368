import React from 'react';


export const profileContext = React.createContext();
export const profileInitialState = {};
export const isProfileStatusChanged = false;

export const profileReducer = (state = profileInitialState, action) => {
    return state = action
}

export const isProfileStatusChangedReducer = (state = isProfileStatusChanged, action) => {
    return state = action
}