import React from "react";

export default function IconInfo(props) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
      >
        <g fill="none" fill-rule="evenodd">
          <g fill="#FFF" fill-rule="nonzero">
            <g>
              <g>
                <path
                  d="M11 22C4.9 22 0 17.1 0 11S4.9 0 11 0s11 4.9 11 11-4.9 11-11 11zm0-20c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm1 9V7c0-.6-.4-1-1-1s-1 .4-1 1v4c0 .6.4 1 1 1s1-.4 1-1zm-.3 4.7c.1-.1.2-.2.2-.3 0-.1.1-.3.1-.4 0-.1 0-.3-.1-.4-.1-.1-.1-.2-.2-.3 0 0-.1-.1-.2-.1s-.1-.1-.2-.1c-.4-.2-.8-.1-1.1.2-.1.1-.2.2-.2.3 0 .1-.1.2-.1.4 0 .3.1.5.3.7.2.2.4.3.7.3.4 0 .6-.1.8-.3z"
                  transform="translate(-609 -1044) translate(598 1030) translate(11 14)"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
}
