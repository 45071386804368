import React, { useContext, useEffect, useReducer } from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    useHistory,
} from "react-router-dom";
import $ from "jquery";

import Header from "../Components/Header";
import SideBar from "../Components/SideBar";
import {
    modalContext,
    modalInitialState,
    modalReducer,
} from "../context/modalContext";
import ModalWrapper from "../Modals/ModalContainer/ModalWrapper";

import {
    reqeustInitalState,
    requestContext,
    requestReducer,
} from "../context/reqeustContext";
import {
    mapControlContext,
    mapControlsInitialState,
    mapControlReducer,
} from "../context/mapControlContext";
import { PickedLocationProvider } from "../context/pickedLocationContext";
import { NotificationBarProvider } from "../context/notificationBarContext";
import { PinProvider } from "../context/pinContext";
import { ListOnMapProvider } from "../context/listOnMap";

import { ActiveOnMapProvider } from "../context/activeOnMapContext";
import {
    itemIdContext,
    itemIdInitialState,
    itemIdReducer,
} from "../context/itemIdContext";
import ScrollReverse from "../Components/scrollReverse";
import RequestTrackPage from "../Pages/RequestTrackPage";
import DashboardPage from "../Pages/DashboardPage/DashboardPage";
import RequestBoardPage from "../Pages/RequestsBoardPage/RequestBoardPage";
import ReportsPage from "../Pages/ReportsPage";

import {
    sideBarContext,
    sideBarInitalState,
    sideBarReducer,
} from "../context/sideBarContext";
import {
    addRequestDataInitialState,
    addRequestDataReducer,
    addRequestDataContext,
} from "../context/addRequestDataContext";
import {
    notificationContext,
    notificationInitialState,
    notificationReducer,
} from "../context/addNotificationContext";
import UserProfile from "../Pages/UserProfile";
import ProfilePassword from "../Pages/ProfilePassword";
import ManagePortal from "../Pages/ManagePortal";
import NewRequestBoardPage from "../Pages/NewRequestBoardPage";

import {
    addSubRequestInitialState,
    addSubRequestReducer,
    addSubRequestContext,
} from "../context/addSubRequestContext";
import {
    userListInitialState,
    userListReducer,
    userListContext,
    isUserStatusChangedReducer,
    isUserStatusChangedState,
} from "../context/userListContext";
import WithAxios from "../axios/withAxios";
import {
    isProfileStatusChanged,
    isProfileStatusChangedReducer,
    profileContext,
    profileInitialState,
    profileReducer,
} from "../context/profileContext";
import { getProfileData } from "../api/profile";
import { userContext } from "../context/userContext";
import {
    activeSubRequestInitialState,
    activeSubRequestReducer,
    activeSubRequestContext,
} from "../context/activeSubRequestContext";
import ReportDetails from "../Pages/ReportDetails";
import { RequestListPorvider } from "../context/RequestListContext";
import { CCExternalIdProvider } from "../context/ccExternalID";
import ReportComparePage from "../Pages/ReportComparePage";
import { addCarDetailsContext, addCarDetailsInitialState, addCarDetailsReducer } from "../context/carDetailsContext";

function AppLayout() {
    const history = useHistory();
    const [requestListStates, setRequestListStates] = useReducer(
        requestReducer,
        reqeustInitalState
    );
    const [mapControl, setMapControl] = useReducer(
        mapControlReducer,
        mapControlsInitialState
    );
    const [itemId, setItemId] = useReducer(itemIdReducer, itemIdInitialState);
    const [addRequestData, setAddRequestData] = useReducer(
        addRequestDataReducer,
        addRequestDataInitialState
    );

    const [sideBarStatus, setSideBarStatus] = useReducer(
        sideBarReducer,
        sideBarInitalState
    );
    const [notification, setNotification] = useReducer(
        notificationReducer,
        notificationInitialState
    );
    const [modalState, setModalState] = useReducer(
        modalReducer,
        modalInitialState
    );
    const [activeSubRequest, setActiveSubRequest] = useReducer(
        activeSubRequestReducer,
        activeSubRequestInitialState
    );

    const [subRequest, setSubRequest] = useReducer(
        addSubRequestReducer,
        addSubRequestInitialState
    );


    const [carDetails, addCarDetails] = useReducer(
        addCarDetailsReducer,
        addCarDetailsInitialState
    );
    const [userListData, setUserListData] = useReducer(
        userListReducer,
        userListInitialState
    );
    const [userStatus, setUserStatus] = useReducer(
        isUserStatusChangedReducer,
        isUserStatusChangedState
    );
    const [profileData, setProfileData] = useReducer(
        profileReducer,
        profileInitialState
    );
    const [profileStatus, setProfileStatus] = useReducer(
        isProfileStatusChangedReducer,
        isProfileStatusChanged
    );
    const { userContextDispatch } = useContext(userContext);

    let hideControls = false;
    if (
        requestListStates.showDetailed ||
        requestListStates.showAddRequest ||
        requestListStates.showEditRequest
    ) {
        hideControls = true;
    }

    useEffect(() => {
        getProfileData()
            .then(response => {
                setProfileData(response.data.data.data);
            })
            .catch(error => {
                console.log(error);
                setProfileData({});
            });
    }, [profileStatus]);

    useEffect(() => {
        var hasTrueKeys = Object.keys(modalState).some(k => modalState[k]);
        if (hasTrueKeys) {
            document.getElementsByTagName("body")[0].classList.add("hideScroll");
        } else {
            document.getElementsByTagName("body")[0].classList.remove("hideScroll");
        }
    }, [modalState]);


    return (
        <>
            <CCExternalIdProvider>
                <RequestListPorvider>
                    <profileContext.Provider
                        value={{
                            profileContextDispatch: setProfileData,
                            profileContextData: profileData,
                            profileStatus: profileStatus,
                            setProfileStatus: setProfileStatus,
                        }}
                    >
                        <userListContext.Provider
                            value={{
                                userListData: userListData,
                                setUserListData: setUserListData,
                                userStatus: userStatus,
                                setUserStatus: setUserStatus,
                            }}
                        >
                            <activeSubRequestContext.Provider
                                value={{
                                    activeSubRequest: activeSubRequest,
                                    setActiveSubRequest: setActiveSubRequest,
                                }}
                            >
                                <addCarDetailsContext.Provider value={{ carDetails: carDetails, addCarDetails: addCarDetails }}>
                                    <addSubRequestContext.Provider
                                        value={{ subRequest: subRequest, setSubRequest: setSubRequest }}
                                    >
                                        <notificationContext.Provider
                                            value={{
                                                notification: notification,
                                                setNotification: setNotification,
                                            }}
                                        >
                                            <sideBarContext.Provider
                                                value={{
                                                    sideBarStatus: sideBarStatus,
                                                    setSideBarStatus: setSideBarStatus,
                                                }}
                                            >
                                                <itemIdContext.Provider
                                                    value={{ itemId: itemId, setItemId: setItemId }}
                                                >
                                                    <addRequestDataContext.Provider
                                                        value={{ addRequestData, setAddRequestData }}
                                                    >
                                                        <ActiveOnMapProvider>
                                                            <PickedLocationProvider>
                                                                <PinProvider>
                                                                    <WithAxios>
                                                                        <ListOnMapProvider>
                                                                            <modalContext.Provider
                                                                                value={{
                                                                                    modalState: modalState,
                                                                                    setModalState: setModalState,
                                                                                }}
                                                                            >
                                                                                <NotificationBarProvider>
                                                                                    <mapControlContext.Provider
                                                                                        value={{
                                                                                            mapControl: mapControl,
                                                                                            setMapControl: setMapControl,
                                                                                        }}
                                                                                    >
                                                                                        <requestContext.Provider
                                                                                            value={{
                                                                                                requestStatus: requestListStates,
                                                                                                setRequestStatus:
                                                                                                    setRequestListStates,
                                                                                            }}
                                                                                        >
                                                                                            <Router>
                                                                                                <ScrollReverse />
                                                                                                {!hideControls ? (
                                                                                                    <>
                                                                                                        <Header isHide={false} />
                                                                                                        <SideBar />
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <Header isHide={true} />
                                                                                                )}

                                                                                                <Switch>
                                                                                                    <Route exact path="/">
                                                                                                        <RequestTrackPage
                                                                                                            hideControls={hideControls}
                                                                                                        ></RequestTrackPage>
                                                                                                    </Route>
                                                                                                    <Route
                                                                                                        exact
                                                                                                        path="/dashboard"
                                                                                                        component={DashboardPage}
                                                                                                    />
                                                                                                    <Route
                                                                                                        exact
                                                                                                        path="/requestboard"
                                                                                                        component={NewRequestBoardPage}
                                                                                                    />

                                                                                                    <Route
                                                                                                        exact
                                                                                                        path="/report"
                                                                                                        component={ReportsPage}
                                                                                                    />
                                                                                                    <Route
                                                                                                        exact
                                                                                                        path="/report/details/:id"
                                                                                                        component={ReportDetails}
                                                                                                    />
                                                                                                    <Route
                                                                                                        exact
                                                                                                        path="/report/compare/:firstId/:secondId"
                                                                                                        component={ReportComparePage}
                                                                                                    />
                                                                                                    <Route
                                                                                                        exact
                                                                                                        path="/profile"
                                                                                                        component={UserProfile}
                                                                                                    />
                                                                                                    <Route
                                                                                                        exact
                                                                                                        path="/password"
                                                                                                        component={ProfilePassword}
                                                                                                    />
                                                                                                    <Route
                                                                                                        exact
                                                                                                        path="/manageportal"
                                                                                                        component={ManagePortal}
                                                                                                    />
                                                                                                </Switch>
                                                                                                <ModalWrapper />
                                                                                            </Router>
                                                                                        </requestContext.Provider>
                                                                                    </mapControlContext.Provider>
                                                                                </NotificationBarProvider>
                                                                            </modalContext.Provider>
                                                                        </ListOnMapProvider>
                                                                    </WithAxios>
                                                                </PinProvider>
                                                            </PickedLocationProvider>
                                                        </ActiveOnMapProvider>
                                                    </addRequestDataContext.Provider>
                                                </itemIdContext.Provider>
                                            </sideBarContext.Provider>
                                        </notificationContext.Provider>
                                    </addSubRequestContext.Provider>
                                </addCarDetailsContext.Provider>
                            </activeSubRequestContext.Provider>
                        </userListContext.Provider>
                    </profileContext.Provider>
                </RequestListPorvider>
            </CCExternalIdProvider>
        </>
    );
}
export default AppLayout;
