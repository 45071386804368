import React, { useEffect, useReducer } from "react";
import NewLoginPage from "../Pages/NewLoginPage";

function DefaultLayout() {
 return (
    <>
        <NewLoginPage />
    </>
  );
}
export default DefaultLayout;
