import React, { useContext, useState } from 'react'
import SettingIcon from "../../assets/icons/icons-settings.svg";
import Close from "../../assets/icons/icons-close.svg";
import MapSettingData from './MapSettingData';
import { sideBarContext } from '../../context/sideBarContext';
import {langContext} from '../../context/langContext';
import {PhrasesContext} from '../../context/PhrasesContext';

function MapSettings() {
    const [showMap, setShowMap] = useState(false)
    const {sideBarStatus} = useContext(sideBarContext)
    const {lang} = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const handleMapAppear = (event) => {
        event.stopPropagation()
        setShowMap(state => !state)
    }

    return (
        <div className={`map-setting-container ${showMap ? 'open' : ''} ${sideBarStatus.mainSideBar ? 'side-bar-open' : ''}`} onClick={handleMapAppear}>
            {
                showMap
                ?   <MapSettingData />
                :   null
            }
            
            <div className="map-setting-key" onClick={handleMapAppear}>
                {
                    showMap
                    ?   <>
                            <div className="map-setting-icon">
                                <img src={Close} width="14" alt="icon" />
                            </div>
                            <span className="white">{phrases?.lblCloseMapSettings?.[lang]}</span>
                        </>
                    :   <>
                            <div className="map-setting-icon">
                                <img src={SettingIcon} width="20" alt="icon" />
                            </div>
                            <span>{phrases?.lblMapSettings?.[lang]}</span>
                        </>
                }
            </div>
        </div>
    )
}

export default MapSettings
