import React from 'react';



export const mapControlContext = React.createContext();
export const mapControlsInitialState = {
    isVehiclesRendered: true,
    isCompletedRequestsRendered: false,
    isCanceledRequestsRendered: false,
    isInitialRequestsRendered: false,
    isRequestDeleted: false,
}


export const mapControlReducer = (state = mapControlsInitialState, action) => {
    switch(action) {
        case 'showVehicles':
            return {
                ...state,
                isVehiclesRendered: true
            }

        case 'hideVehicles':
            return {
                ...state,
                isVehiclesRendered: false
            }


        case 'showComplete':
            return {
                ...state,
                isCompletedRequestsRendered: true
            }
        case 'hideComplete':
            return {
                ...state,
                isCompletedRequestsRendered: false
            }


        case 'showCancelled':
            return {
                ...state,
                isCanceledRequestsRendered: true
            }
        case 'hideCancelled':
            return {
                ...state,
                isCanceledRequestsRendered: false
            }


        case 'showInitial':
            return {
                ...state,
                isInitialRequestsRendered: true
            }
        case 'hideInitial':
            return {
                ...state,
                isInitialRequestsRendered: false
            }

        case 'refreshRequestList':
            return {
                ...state,
                isRequestDeleted: !state.isRequestDeleted
            }

     


            
        default:
            return state
    }
}