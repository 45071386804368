import React, {useState, useContext} from 'react'
import Files from "../../../assets/icons/files.svg";
import { langContext } from '../../../context/langContext';
import { PhrasesContext } from '../../../context/PhrasesContext';
import { RequestListContext } from '../../../context/RequestListContext';
import ActionsList from './ActionsList/ActionsList';
import AdvancedSearchWrap from './AdvancedSearch/AdvancedSearchWrap';
import FilterWrap from './FilterWrap/FilterWrap';

function RequestReportHead({data, setData, coulmns}) {
    const [phrases] = useContext(PhrasesContext);
    const { lang } = useContext(langContext);
    const [report, setReport, filter, setFilter] = useContext(RequestListContext)
  
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)
    return (
        <div className="request-report-head">
            <div className="head-level-1">
                <div className="head-title">
                    <img src={Files} alt="icon" />
                    <p>
                        <span>{phrases?.lblShowingRequests?.[lang]}</span>
                        {
                            report.data.length === 0
                            ?   <span>({phrases?.lblNoDataFound?.[lang]})</span>
                            :   <span>
                                    ({report.mainRequestsCount} {phrases?.lblRequestsLoc?.[lang]} {phrases?.lblAnd?.[lang]} {report.totalCount} {phrases?.lblServices?.[lang]} {phrases?.lblFoundReport?.[lang]})
                                </span>
                        }
                        
                    </p>
                </div> 
                <ActionsList coulmns={coulmns} data={data}  />
            </div>
            <div className="head-level-2">
                <FilterWrap 
                    data={data} 
                    setData={setData} 
                    showAdvancedSearch={showAdvancedSearch} 
                    setShowAdvancedSearch={setShowAdvancedSearch}
                />
                {
                    showAdvancedSearch
                    ?   <AdvancedSearchWrap data={data} setData={setData} />
                    :   null
                }
                
            </div>
        </div>
    )
}

export default RequestReportHead
