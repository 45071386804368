import React, { useEffect, useState, useContext } from 'react'
import closeGreen from "../../../../assets/icons/close-green.png";
import Arrow from "../../../../assets/icons/down-arrow-green.svg";


import uuid from 'react-uuid'
import $ from "jquery";
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';


function ShareModalEmail({data, setData}) {
    const {lang} = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);

    const [emailText, setEmailText] = useState('')
    const [validate, setValidate] = useState(phrases?.lblProvideEmailToShare?.[lang])
    
    const hanldeChange = e => {
        var startWithSpace = /^\s/;
        var arabic = /[\u0621-\u064A]+/;
        if (e.target.value.match(startWithSpace) || e.target.value.match(arabic)) {
            return;
        }
        setEmailText(e.target.value)
        $(e.target).closest(".form-col").removeClass("invalid-col");
        $(`.email-head-input`).removeClass("invalid-input");

        if(e.target.value.length >= 1) {
            setValidate(phrases?.lblEmailValidation?.[lang])
        } else {
            setValidate(phrases?.lblProvideEmailToShare?.[lang])
        }

    }
    
    //- add new email
    const hanldeAddEmail = e => {
        if(e.key === 'Enter' || e.keyCode === 13) {
            e.persist();
            var emailValid = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            
            if(!e.target.value.match(emailValid)) {
                if(e.target.value.length >= 1) {
                    $(e.target).closest(".form-col").addClass("invalid-col");
                }

                return
            }

            for (var i = 0; i < data.emailList.length; i++) {
                if (data.emailList[i].email === e.target.value) {
                    console.log("This email is exist.")
                    return
                }
            }
            setData(prevState => ({
                ...data,
                emailList: [{id: uuid(), email: e?.target?.value},...prevState.emailList]
            }))

            setEmailText("")
        }
        
    }

    const hanldeAddEmailOnClick = e => {
        var emailValid = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        
        if(!emailText.match(emailValid)) {
            if(emailText.length >= 1) {
                $(e.target).closest(".form-col").addClass("invalid-col");
            }

            return
        }

        for (var i = 0; i < data.emailList.length; i++) {
            if (data.emailList[i].email === emailText) {
                console.log("This email is exist.")
                return
            }
        }

        setData(prevState => ({
            ...data,
            emailList: [{id: uuid(), email: emailText},...prevState.emailList]
        }))

        setEmailText("")
    }
    //- remove the email
    const removeLabel = (e, id) => {
        
        e.stopPropagation()
        setData({
            ...data,
            emailList: data.emailList.filter(item => item.id !== id)
        })
    }

    useEffect(() => {
        if(data.emailList.length === 0) {
            setValidate(phrases?.lblProvideEmailToShare?.[lang])
        }
    }, [data])
    
    return (
        <div className="multi-search-wrap">
            <div className="multi-search-head email-head-input site-input">
                {
                    data.emailList.length >= 1
                    ?   <div className="multi-search-labels share-labels">
                            {
                                data.emailList.map(item => {
                                    return (
                                        <p key={item.id}>
                                            <img src={closeGreen} onClick={(e) => removeLabel(e, item.id)} alt="icon" />
                                            <span>{item.email}</span>
                                        </p>
                                    )
                                })
                            }
                        </div>
                    :   null
                }
                
                <div className="multi-search-input">
                    <input 
                        type="text"
                        placeholder={phrases?.lblEnterMailsShare?.[lang]}
                        value={emailText}
                        onChange={hanldeChange}
                        onKeyDown={hanldeAddEmail}
                    />
                    <img onClick={hanldeAddEmailOnClick} className="add-item-btn" src={Arrow} alt="icon" width="16" />
                </div>
            </div>
            <p className="error-msg">{validate}</p>
        </div>
    )
}

export default ShareModalEmail
