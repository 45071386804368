import React from "react";

export default function IconClose(props) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
      >
        <g fill="none" fillRule="evenodd" opacity=".6">
          <g fill="#222B45" fillRule="nonzero">
            <g>
              <g>
                <g>
                  <path
                    d="M9.677.323c.43.43.424 1.135-.002 1.561L6.559 5l3.116 3.116c.431.431.435 1.127.002 1.56-.43.431-1.135.425-1.561-.001L5 6.559 1.884 9.675c-.431.431-1.127.435-1.56.002-.431-.43-.425-1.135.001-1.561L3.441 5 .325 1.884C-.106 1.453-.11.757.323.324c.43-.431 1.135-.425 1.561.001L5 3.441 8.116.325c.431-.431 1.127-.435 1.56-.002z"
                    transform="translate(-1600 -150) translate(1334) translate(40 130) translate(226 20)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
}
