import React, { useState, useContext } from 'react'
import CloseBlack from "../../../assets/icons/icons-close.svg";
import closeRed from "../../../assets/icons/close-red.svg";
import axios from 'axios';
import $ from 'jquery'
import PasswordInput from '../../../shell/PasswordInput';
import { decryptToken } from '../../../Utils/utils';
import {PhrasesContext} from '../../../context/PhrasesContext';
import {langContext} from '../../../context/langContext';
import { resetPassword } from '../../../api/login';
 

//- Validation
function validateForm(relatedForm) {
    var amountInput = $(relatedForm).find('.requiredData');
    var filledInputs = [];
    amountInput.each(function() {
        filledInputs.push(this);
    });
   
    
    
    var noValFilter = filledInputs.filter(function (item) {
        return $(item).val() === '';
    })

    $(noValFilter).each(function () {
        $(this).closest('.form-col').addClass('invalid-col');
        $(this).addClass('invalid-input');
        $(this).closest('.form-col').find('.error-msg').show()
    });


    if(noValFilter.length === 0) {
        return true
    } 

}
function ForgetPasswordResend({setShowResetSuccessModal}) {
    const [apiValidation, setApiValidation] = useState("")
    const [isSumbitError, setIsSubmitError] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    
    //- password data
    const [passwordData, setPasswordData] = useState({
        newPassword: "",
        confirmPassword: ""
    })

    // - custom function for password validation
    let isValidateToSubmit = true;
    
    const passwordValidationFunc = (relatedClass, validMsg) => {
        $(`.${relatedClass}`).addClass("invalid-input");
        setApiValidation(validMsg)
        setIsSubmitError(true)
        isValidateToSubmit = false;
    }

    //- handle submit
    const hanldeSubmit = e => {
        let elem = $(e.target);
        let isPasswordPatternValid = true;

        var passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;


        // if (!passwordData.newPassword.match(passwordPattern) && passwordData.newPassword.length !== 0) {
        //     passwordValidationFunc('newPassword', phrases?.lblPasswordValidation?.[lang])
        //     isPasswordPatternValid = false

        // }

        // if (!passwordData.confirmPassword.match(passwordPattern) && passwordData.confirmPassword.length !== 0) {
        //     passwordValidationFunc('confirmPassword', phrases?.lblPasswordValidation?.[lang])
        //     isPasswordPatternValid = false

        // }

        // if (
        //     passwordData.newPassword !== passwordData.confirmPassword && isPasswordPatternValid
        //     && passwordData.newPassword.length !== 0 && passwordData.confirmPassword.length !== 0
        // ) {
        //     passwordValidationFunc('confirmPassword', phrases?.lblPasswordDontMatch?.[lang])
        // }

        if (passwordData.newPassword.length < 8 || passwordData.confirmPassword.length < 8) {
            isValidateToSubmit = false;
        }

        


        if (!validateForm(elem)) {
            isValidateToSubmit = false;
        }



        if(isValidateToSubmit) {
            resetPassword(passwordData)
            setIsSubmitted(true)
            axios
                .post(`${process.env.REACT_APP_API_BASE_URL}Account/ResetPassword`, passwordData, {
                    headers: {
                        Authorization: `Bearer ${decryptToken(JSON.parse(localStorage.getItem('user')).token)}`
                    }
                })
                .then(response => {
                    setIsSubmitted(false)
                    setShowResetSuccessModal(true)
                   
                })  
                .catch(error => {
                    console.log(error)
                    setIsSubmitted(false)
                    setIsSubmitError(true)
                    setApiValidation(error?.response?.data?.key || 'lblSomethingWrong')
                })
        }

        e.preventDefault();
    }

    
    // custom error handling
    const closeErrorSubmit = () => {
        setIsSubmitError(false)
    }
    
    return (
        <>
            <div className="login-card-head">
                <strong className="title">{phrases?.lblResetPassword?.[lang]}</strong>
                <strong>{phrases?.lblEnterYourNewPassword?.[lang]}</strong>
            </div>
            {
                isSumbitError
                ?   <div className="note error" style={{marginTop: '32px'}}>
                        <div className="note-text">
                            <img src={closeRed} alt="" />
                            <span>
                                {
                                    phrases[apiValidation]
                                    ?   phrases[apiValidation][lang]
                                    :   phrases[`lblSomethingWrong`][lang]
                                }
                            </span>
                        </div>
                        <img src={CloseBlack} className="closeNote" onClick={closeErrorSubmit} />
                    </div>
                :   null
            }
            <div className="login-card-body">
                <div className="login-form-wrapper">
                    <form className="request-form" id="login-create" onSubmit={hanldeSubmit}>
                        <div className="form-row">
                            <div className="form-col">
                                <strong>{phrases?.lblNewPassword?.[lang]}</strong>
                                <PasswordInput 
                                    data={passwordData} 
                                    setData={setPasswordData} 
                                    name="newPassword"
                                    placeHolder={phrases?.lblPleaseEnterYourNewPassword?.[lang]}
                                    setIsSubmitError={setIsSubmitError}
                                />
                            </div>
                        </div>
                        
                        <div className="form-row">
                            <div className="form-col">
                            <strong>{phrases?.lblReEnterPassword?.[lang]}</strong>

                                <PasswordInput 
                                    data={passwordData} 
                                    setData={setPasswordData} 
                                    name="confirmPassword"
                                    placeHolder={phrases?.lblPleaseReEnterYourNewPassword?.[lang]}
                                    setIsSubmitError={setIsSubmitError}
                                />
                            </div>
                        </div>
                        
                        

                        <div className="request-form-action">
                            <div className="form-aciton">
                                <button form="login-create" type="submit" className="site-btn full" disabled={`${isSubmitted  ? "disabled" : ""}`}>
                                    <span>{phrases?.lblResetPassword?.[lang]}</span>
                                    {
                                        isSubmitted
                                        ?   <div className="spinner"></div>
                                        :   null
                                    }
                                    
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ForgetPasswordResend
