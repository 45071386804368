import React, { useState , useContext } from 'react'
import CCBackgroundimage from '../assets/images/cc-background.png'
import CCIcon from '../assets/images/cc-icon.png'
import IconEaLogo from "../assets/icons/IconEaLogo.js";
import IconDubaiPolice from "../assets/icons/IconDubaiPolice.js";
import LoginWrap from '../Components/NewLogin/Login/LoginWrap';
import ForgetPasswordWrap from '../Components/NewLogin/ForgetPassword/ForgetPasswordWrap';
import {PhrasesContext} from '../context/PhrasesContext';
import {langContext} from '../context/langContext';

function NewLoginPage() {
    const [loginView, setLoginView] = useState(true)
    const { lang, setLang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);

    const handleLang = (langText) => {
        setLang(langText)
        localStorage.setItem('lang', langText)
    }
    return (
        <div className="login-contain">
            <div className="login-wrap">
                <div className="login-data-contain">
                    <div className="login-data-wrap">
                        <div className="login-data-head">
                            <ul>
                                <li>
                                    <IconEaLogo />
                                </li>
                                <li>
                                    <IconDubaiPolice />
                                </li>
                                <li>
                                <strong>{phrases?.lblCommandCenter?.[lang]}</strong>
                                </li>
                            </ul>
                            <div className="login-lang-wrap">
                            {
                                lang === 'en'
                                ?   <div className="login-lang-item" onClick={() => handleLang('ar')}>
                                        <div className="flag">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="27" viewBox="0 0 12 6">
                                                <rect width="12" height="2" fill="#009E49" id="green" />
                                                <rect width="12" height="2" y="2" fill="#fff" />
                                                <rect width="12" height="2" y="4" />
                                                <rect width="3" height="6" fill="#CE1126" id="red" />
                                            </svg>
                                            <div className="flag-text ar">
                                                <span>اللغه العربية</span>
                                            </div>
                                        </div>
                                    </div>
                                :   <div className="login-lang-item" onClick={() => handleLang('en')}>
                                        <div className="flag">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="27" viewBox="0 0 40 29">
                                                <g fill="none" fill-rule="evenodd">
                                                    <g fill-rule="nonzero">
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path fill="#F0F0F0" d="M0 0.644H40V27.644H0z" transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356)"/>
                                                                    <path fill="#D80027" d="M22.5 0.644L17.5 0.644 17.5 11.613 0 11.613 0 16.676 17.5 16.676 17.5 27.644 22.5 27.644 22.5 16.676 40 16.676 40 11.613 22.5 11.613z" transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356)"/>
                                                                    <g fill="#0052B4">
                                                                        <path d="M6.59.029L16 5.306 16 .029zM.052.029L16 8.973 16 6.444 4.562.029zM11.752 8.973L.052 2.411.052 8.973z" transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356) translate(24 18.644)"/>
                                                                    </g>
                                                                    <path fill="#F0F0F0" d="M24 18.644L40 27.644 40 25.099 28.525 18.644z" transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356)"/>
                                                                    <path fill="#D80027" d="M24 18.644L40 27.644 40 25.099 28.525 18.644z" transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356)"/>
                                                                    <g fill="#0052B4">
                                                                        <path d="M7.191.029L0 4.062 0 .029zM15.948 1.166L15.948 8.973 2.029 8.973z" transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356) translate(0 18.644)"/>
                                                                    </g>
                                                                    <path fill="#D80027" d="M11.475 18.644L0 25.099 0 27.644 0 27.644 16 18.644z" transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356)"/>
                                                                    <g fill="#0052B4">
                                                                        <path d="M9.41 8.971L0 3.693 0 8.971zM15.948 8.971L0 .027 0 2.556 11.438 8.971zM4.248.027L15.948 6.589 15.948.027z" transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356) translate(0 .644)"/>
                                                                    </g>
                                                                    <path fill="#F0F0F0" d="M16 9.644L0 0.644 0 3.189 11.475 9.644z" transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356)"/>
                                                                    <path fill="#D80027" d="M16 9.644L0 0.644 0 3.189 11.475 9.644z" transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356)"/>
                                                                    <g fill="#0052B4">
                                                                        <path d="M8.809 8.971L16 4.938 16 8.971zM.052 7.833L.052.027 13.971.027z" transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356) translate(24 .644)"/>
                                                                    </g>
                                                                    <path fill="#D80027" d="M28.525 9.644L40 3.189 40 0.644 40 0.644 24 9.644z" transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356)"/>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            <div className="flag-text en">
                                                <span>English</span>
                                            </div>
                                        </div>
                                    </div>
                            }
                            </div>
                        </div>
                        <div className="login-data-body">
                            {
                                loginView
                                ?   <LoginWrap setLoginView={setLoginView} /> 
                                :   <ForgetPasswordWrap setLoginView={setLoginView} />
                            }
                            
                        </div>
                        <div className="login-data-foot">
                            <p>{phrases?.lblAllRightsReserved?.[lang]}</p>
  
                            <p>{phrases?.lblTermsOfServicesPrivacyPolicy?.[lang]}</p>
                        </div>
                    </div>
                </div>


                <div className="login-img-contain" style={{backgroundImage: `url(${CCBackgroundimage})`}}>
                    <img className="login-img-icon" src={CCIcon} alt="cc-icon" />
                    <div className="login-img-text">
                        {/* <strong>Login to start</strong> */}
                        <strong>{phrases?.lblLoginToBeginTracking?.[lang]}</strong>
                        <p>{phrases?.lblManageYourRequestsEasily?.[lang]}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewLoginPage
