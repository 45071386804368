import React, { useState, useContext, useEffect } from "react";
import IconPointer from "../../assets/icons/IconPointer";
import ClearIcon from "../../assets/icons/IconClose.js";
import IconInfo from "../../assets/icons/IconInfo.js";
import { NotificationBarContext } from "../../context/notificationBarContext";
import { langContext } from "../../context/langContext";
import { PhrasesContext } from "../../context/PhrasesContext";

export default function NotificationBar({ isGreen, related, msgText }) {
  const [showMessage, setShowMessage] = useState(true);
  const { lang } = useContext(langContext);
  const [phrases] = useContext(PhrasesContext);
  const [barNonfiction, changeBarStatus] = useContext(NotificationBarContext);
  const text = phrases?.lblTapAnywhere?.[lang] || "test";

  const handleCloseNotification = () => {
    setShowMessage(false);
  };
  useEffect(() => {
    changeBarStatus({
      text: phrases?.lblTapAnywhere?.[lang],
      isOpened: false,
      isGreen: true,
      isVehicleBtn: false,
    });
   
  }, [lang, phrases]);

  return (
    <>
      {showMessage && (
        <div className={`notification-wrapper ${related === 'RadiusMap' ? 'radius-map' : ''}`}>
          <div
            className={`notification-parent ${
              barNonfiction.isGreen ? "green" : "yellow"
            }`}
          >
            <div className="notification-icon">
              {barNonfiction.isGreen ? <IconPointer /> : <IconInfo />}
            </div>
            <div className="notification-text">
              {
                msgText
                ? <p>{msgText}</p>
                : <p>{barNonfiction.text}</p>
              } 
              
              <div
                className="close-notification"
                onClick={handleCloseNotification}
              >
                <ClearIcon />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
