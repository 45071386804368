import React from 'react'


export const userContext = React.createContext();
export const userInitalState = {
    isLogin: JSON.parse(localStorage.getItem('user'))?.allowLogin ? true : false,
    userData: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {}
}



export const userReducer = (state = userInitalState, action) => {
    switch(action) {
        case 'login':
            return {
                isLogin: true,
                userData: JSON.parse(localStorage.getItem('user'))
            }
        
        case 'logout':
            return {
                isLogin: false,
                userData: {}
            }
        default:
            return state
    }
}