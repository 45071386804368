import React, { useContext } from "react";
import { langContext } from "../../context/langContext";
import { PhrasesContext } from "../../context/PhrasesContext";
import IconMapPin from "../../assets/icons/icons-map-pin.svg";
import IconClockGreen from "../../assets/icons/icons-clock-green.svg";
import IconPhone from "../../assets/icons/icons-phone.svg";
import IconRequest from "../../assets/icons/icons-request-green.svg";
import Iconshield from "../../assets/icons/icons-shield.svg";
import IconTowing from "../../assets/icons/icons-towing.svg";
import Copy from "../../assets/icons/copy.png";
import militaryIcon from "../../assets/icons/military-icon.svg";
import $ from "jquery";


function RequestMainData({mainData}) {
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const updateClipboard = (newClip) => {
        navigator.clipboard.writeText(newClip).then(
            () => {
                $('.copied-text').css('opacity', '1')
                setTimeout(function() {
                    $('.copied-text').css('opacity', '0')
                }, 800)
            },
            () => {
              console.log("Copy Failed.")
            }
        );
    }
    const handleCopyAddress = (address) => {
        navigator.permissions
            .query({ name: "clipboard-write" })
            .then((result) => {
            if (result.state === "granted" || result.state === "prompt") {
                updateClipboard(address);
            }
        });
        
        
    }
    return (
        <div className="request-data-contain">
            <div className="request-data-wrap">
                <div className="request-data-head">
                    <strong>{phrases?.lblRequestMainData?.[lang]}</strong>
                </div>
                <div className="request-data-body">
                    <ul className="request-data-list">
                        <li className="full address">
                            {
                                mainData.pickupURLEn
                                ?   <div className="copyLink" onClick={() => handleCopyAddress(mainData.pickupURLEn)}>
                                        <span className="copied-text">{phrases?.lblURLCopied?.[lang]}</span>
                                        <img src={Copy} alt="copyLink" />
                                    </div>
                                :   null
                            }
                            
                            <div className="request-data-list-img">
                                <img src={IconMapPin} alt="icon" />
                            </div>
                            <div className="request-data-list-text">
                                <strong>{phrases?.lblRequestLocation?.[lang]}</strong>
                                <p>{mainData.pickUpAddress}</p>
                            </div>
                        </li>

                        <li>
                            <div className="request-data-list-img">
                                <img src={IconClockGreen} alt="icon" />
                            </div>
                            <div className="request-data-list-text">
                                <strong>{phrases?.lblRequestCreationDate?.[lang]}</strong>
                                <p>
                                    {
                                        new Date(mainData.creationTime).toLocaleString(`${lang === 'en' ? 'en-EG' : 'ar-EG'}`, {
                                            month: "short",
                                            day: "numeric",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                        })
                                    }
                                </p>
                                
                            </div>
                        </li>

                        <li>
                            <div className="request-data-list-img">
                                <img src={IconTowing} alt="icon" />
                            </div>
                            <div className="request-data-list-text">
                                <strong>{phrases?.lblNumberofRecoveriesNeeded?.[lang]}</strong>
                                <p>
                                    <span className="number">{mainData.ccNumberOfVehicles}</span>
                                    {phrases?.lblVehicleCC?.[lang]}
                                </p>
                            </div>
                        </li>

                        <li>
                            <div className="request-data-list-img">
                                <img src={IconRequest} alt="icon" />
                            </div>
                            <div className="request-data-list-text">
                                <strong>{phrases?.lblRequestType?.[lang]}</strong>
                                <p>
                                    {
                                        lang === 'en'
                                        ?   mainData.requestTypeNameEn
                                        :   mainData.requestTypeNameAr
                                    }
                                </p>
                            </div>
                        </li>


                        <li>
                            <div className="request-data-list-img">
                                <img src={militaryIcon} alt="icon" />
                            </div>
                            <div className="request-data-list-text">
                                <strong>{phrases?.lblMilitaryNumber?.[lang]}</strong>
                                <p>
                                    {mainData.militaryNumber}
                                </p>
                            </div>
                        </li>


                        <li>
                            <div className="request-data-list-img">
                                <img src={Iconshield} alt="icon" />
                            </div>
                            <div className="request-data-list-text">
                                <strong>{phrases?.lblPolicemanName?.[lang]}</strong>
                                <p>
                                    {mainData.ccPolicemanName}
                                </p>
                            </div>
                        </li>

                        <li>
                            <div className="request-data-list-img">
                                <img src={IconPhone} alt="icon" />
                            </div>
                            <div className="request-data-list-text">
                                <strong>{phrases?.lblPolicemanPhone?.[lang]}</strong>
                                <p className="phoneNumber">
                                    ({mainData.ccPolicemanPhone.substring(0, 3)}) &nbsp;
                                    {mainData.ccPolicemanPhone.substring(3, 13)}
                                </p>
                            </div>
                        </li>


                    </ul>
                </div>
            </div>
        </div>
    )
}

export default RequestMainData
