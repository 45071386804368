import React, { useEffect, useState, useContext } from 'react'
import { chartsStatus, chartsType, mapHeatPoints } from '../../../../../api/requests_reports'
import { langContext } from '../../../../../context/langContext'
import { PhrasesContext } from '../../../../../context/PhrasesContext'
import StatMap from '../../../../StatMap'
import ReportDetailsChartStatus from './ReportDetailsChartStatus'
import ReportDetailsChartType from './ReportDetailsChartType'

function ReportDetailsChartIndex({reportData, itemId}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)

    //- charts status data
    const [chartsStatusData, setChartsStatusData] = useState({loading: true, data: []})
    useEffect(() => {
        chartsStatus(itemId)
            .then(response => {
                // console.log('response.data.data.requestsbyStatusList', response.data.data.requestsbyStatusList)
                setChartsStatusData({loading: false, data: response.data.data !== null ? response.data.data.requestsbyStatusList : []})
            })
            .catch(error => {
                setChartsStatusData({loading: false, data: []})
                console.log(error)
            })
    }, [])



    //- charts type data
    const [chartsTypeData, setChartsTypeData] = useState({loading: true, data: []})
    const [totalTypeData, setTotalTypeData] = useState({total: 0, impound: 0, offset: 0})
    useEffect(() => {
        chartsType(itemId)
            .then(response => {
                setChartsTypeData({loading: false, data: response.data.data !== null ? response.data.data.requestsbyTypeList : []})

                if(response.data.data === null) {
                    setTotalTypeData({total: 0, impound: 0, offset: 0})
                } else {
                    setTotalTypeData({
                        total: response.data.data.requestsbyTypeList.reduce((accumulator, current) => accumulator + current.total, 0),
                        impound: response.data.data.requestsbyTypeList.reduce((accumulator, current) => accumulator + current.impound, 0),
                        offset: response.data.data.requestsbyTypeList.reduce((accumulator, current) => accumulator + current.offset, 0)
                    })
                }
                
            })
            .catch(error => {
                console.log(error)
                setChartsTypeData({loading: false, data: []})
            })
    }, [])
    
    //- map heat points
    const [mapHeatPointsData, setMapHeatPointsData] = useState({loading: true, data: []})
    useEffect(() => {
        mapHeatPoints(itemId)
            .then(response => {
                
                setMapHeatPointsData({loading: false, data: response.data.data !== null ? response.data.data : []})
            })
            .catch(error => {
                console.log(error)
                setMapHeatPointsData({loading: false, data: []})
            })
    }, [])

    
    return (
        <>
            <div className="report-tabs-chart-view" style={{marginTop: '32px'}}>
                <div className="body">
                    <div className="chart-cards">
                        {
                            chartsStatusData.loading
                            ?   <div className="card-loader data" style={{ width: "100%", height: "180px", marginBottom: "32px" }} ></div>
                            :   <ReportDetailsChartStatus chartData={chartsStatusData.data} />
                        }
                        {
                            chartsTypeData.loading
                            ?   <div className="card-loader data" style={{ width: "100%", height: "180px", marginBottom: "32px" }} ></div>
                            :   <>

                                    <ReportDetailsChartType totalTypeData={totalTypeData} reportData={reportData} chartData={chartsTypeData.data} />
                                </>
                        }
                        {
                            mapHeatPointsData.loading
                            ?   <div className="card-loader data" style={{ width: "100%", height: "180px", marginBottom: "32px" }} ></div>
                            :   <StatMap isReport filterValue={mapHeatPointsData.data} phrases={phrases} lang={lang}/>
                        }
                    </div>
                </div>
                
                
            </div>
        </>
    )
}

export default ReportDetailsChartIndex
