import React, { useContext, useEffect, useState } from 'react'
import RequestWrapper from '../../Containers/RequestContainer/RequestWrapper'
import { modalContext } from '../../context/modalContext'
import CloseIocn from '../../assets/icons/icons-close.svg';
import NotificationCard from '../../Components/NotifcationCard';
import { langContext } from '../../context/langContext';
import { PhrasesContext } from '../../context/PhrasesContext';
import { notificationContext } from '../../context/addNotificationContext';
import { getNotifications } from '../../api/notifictions';
import dobuleUserIcon from "../../assets/icons/double-user-icon-white.svg";

function NotificationModal() {
    const { modalState, setModalState } = useContext(modalContext)
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const { notification } = useContext(notificationContext);

    const [items, setitems] = useState({
        length: 0,
        loading: true,
        data: [],
        error: false
    });

    const hideNotification = () => {
        setModalState('hideModal')
    }

    useEffect(() => {
        if (notification?.MainRequestId) {
            console.log(notification)
            if (modalState.showNotification) {
                items.data.unshift({
                    id: 0,
                    textAr: notification.MainRequestStatusAr,
                    textEn: notification.MainRequestStatusEn,
                    objId: notification.MainRequestExternalId,
                    statusId: notification.MainRequestStatusId,
                    type: "Request",
                    createdAt: 1.1061367
                });
                setitems({ ...items });
            }
        }

    }, [notification])


    //- call notificatins api
    useEffect(() => {
        getNotifications()
            .then(response => {
                console.log('Notifications ==> ', response.data);
                setitems({
                    length: response.data.data.length,
                    loading: false,
                    data: response.data.data,
                    error: false,
                });
            })
            .catch(error => {
                console.log("ERROR ==> ", error.response);
                setitems({
                    length: 0,
                    loading: false,
                    data: [],
                    error: true,
                });
            })
    }, [])


    return (
        <>
            <RequestWrapper floatingRight={true}>
                <div className="request-head notification-head">
                    <div className="request-title between">
                        <strong>{phrases?.lblNotirifcations?.[lang]}</strong>
                        <img onClick={hideNotification} width={16} style={{ cursor: 'pointer' }} src={CloseIocn} alt="icon" />
                    </div>
                </div>
                <div className="request-body notification-body">
                    <div className="notification-items-wrapper">

                        {
                            items.loading
                                ?   <>
                                        <div className="card-loader data" style={{ width: '100%', height: '108px' }}></div>
                                        <div className="card-loader data" style={{ width: '100%', height: '108px' }}></div>
                                        <div className="card-loader data" style={{ width: '100%', height: '108px' }}></div>
                                    </>
                                :   items.error
                                    ?   <p>Opps!</p>
                                    :   items.length >= 1
                                        ?   items.data.map((item) => {
                                                return <NotificationCard data={item} key={item.id} />
                                            })
                                                                                  
                                        
                                        :   
                                        
                                            <>
                                                <div className="no-active-request sub table" style={{marginBottom: '96px'}}>
                                                    <div className="no-active-request-icon">
                                                        <img src={dobuleUserIcon} alt="icon" />
                                                    </div>
                                                    <div className="no-active-request-body">
                                                        <strong>{phrases?.lblNoDataFound?.[lang]}</strong>
                                                    </div>
                                                </div>
                                            </>  
                                        
                        }

                    </div>
                </div>
            </RequestWrapper>
        </>
    )
}

export default NotificationModal
