import React, { useContext } from "react";
import { PhrasesContext } from "../../context/PhrasesContext";
import { langContext } from "../../context/langContext";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
import { structureStatusChartData } from "../../Pages/DashboardPage/helpers";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

function Canvas({ chartData = [], isBig }) {
  const { chartLabels: labels, data } = structureStatusChartData(chartData);
  const { totalRequests, completedRequests, cancelledRequests } = data || {};
  const { lang } = useContext(langContext);
  const [phrases] = useContext(PhrasesContext);

  let ctx = null;
  const useCanvas = callback => {
    const canvasRef = React.useRef(null);

    React.useEffect(() => {
      const canvas = canvasRef.current;
      
      ctx = canvas.getContext("2d");
      callback([canvas, ctx]);
    }, [callback]);

    return canvasRef;
  };

  const Canvas = () => {
    const canvasRef = useCanvas(ctx => {
      const data = {
        labels,
        datasets: [
          {
            data: completedRequests,
            borderColor: "#2b7391",
            fill: true,
            label: phrases?.lblCompletedRequests?.[lang],
            cubicInterpolationMode: "monotone",
            tension: 0.5,
            backgroundColor: "rgba(92, 165, 195,0.3)",
          },
          {
            data: cancelledRequests,
            borderColor: "#fb8888",
            cubicInterpolationMode: "monotone",
            label: phrases?.lblCancelledRequests?.[lang],
            fill: true,
            backgroundColor: "rgba(251, 136, 136,0.4)",
            tension: 0.5,
          },
          {
            data: totalRequests,
            borderColor: "#63d588",
            cubicInterpolationMode: "monotone",
            fill: true,
            label: phrases?.lblTotalRequests?.[lang],
            tension: 0.5,
            backgroundColor: "rgba(130, 255, 204,0.5)",
          },
        ],
        datasets: [
          {
            data: completedRequests,
            borderColor: "#2b7391",
            fill: true,
            label: phrases?.lblCompletedRequests?.[lang],
            cubicInterpolationMode: "monotone",
            tension: 0.5,
            backgroundColor: "rgba(92, 165, 195,0.3)",
          },
          {
            data: cancelledRequests,
            borderColor: "#fb8888",
            cubicInterpolationMode: "monotone",
            label: phrases?.lblCancelledRequests?.[lang],
            fill: true,
            backgroundColor: "rgba(251, 136, 136,0.4)",
            tension: 0.5,
          },
          {
            data: totalRequests,
            borderColor: "#63d588",
            cubicInterpolationMode: "monotone",
            fill: true,
            label: phrases?.lblTotalRequests?.[lang],
            tension: 0.5,
            backgroundColor: "rgba(130, 255, 204,0.5)",
          },
        ]
      };
      new Chart(ctx, {
        type: "line",
        data: data,
        options: {
          responsive: true,
          aspectRatio: isBig ? 2.5 : 5,
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
          },
          label: {
            display: false,
          },
          scales: {
            x: {
              grid: { display: false },
              title: {
                display: true,
                text: phrases?.lblRequestTime?.[lang],
                color: "#2ea1bb",
                fontSize: "17px",
                textAlign: "start",
                fontFamily: "Roboto",
                fontWight: "500",
              },
            },
            y: {
              display: true,
              ticks: {
                stepSize: 1,
              },
              title: {
                display: true,
                text: phrases?.lblRequestsNumber?.[lang],
                color: "#2ea1bb",
                fontSize: "17px",
                textAlign: "start",
                fontFamily: "Roboto",
                fontWight: "500",
              },
              suggestedMax: Math.max(...totalRequests),
            },
          },
        },
      });
    });
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <canvas ref={canvasRef} />
      </div>
    );
  };
  return (
    <div className="total-card remove-shadow">
      <Canvas />
    </div>
  );
}
export default React.memo(Canvas);
