import React from 'react'


export const sideBarContext = React.createContext();
export const sideBarInitalState = {
    mainSideBar: false,
    subMainSideBar: false,
};


export const sideBarReducer = (state = sideBarInitalState, action) => {
    switch(action) {
        case 'openMainSideBar':
            return {
                ...state,
                mainSideBar: true,
            }

        case 'hideMainSideBar':
            return {
                ...state,
                mainSideBar: false,
            }
        case 'openSubMainSideBar':
            return {
                ...state,
                subMainSideBar: true,
            }
        case 'hideSubMainSideBar':
            return {
                ...state,
                subMainSideBar: false,
            }
        default:
            return state
    }
}


