import React from "react";

export default function IconEaLogo(props) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        width="70"
        height="70"
        viewBox="0 0 70 70"
      >
        <defs>
          <filter
            id="jzb3pqkzma"
            width="164%"
            height="164%"
            x="-32%"
            y="-28%"
            filterUnits="objectBoundingBox"
          >
            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
              stdDeviation="5"
            />
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              in="shadowBlurOuter1"
              values="0 0 0 0 0.522107111 0 0 0 0 0.522107111 0 0 0 0 0.522107111 0 0 0 0.22 0"
            />
          </filter>
          <circle id="h81ud3p6jb" cx="25" cy="25" r="25" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g>
                <g>
                  <g transform="translate(-135 -15) translate(105) translate(40 22) translate(0 1)">
                    <use
                      fill="#000"
                      filter="url(#jzb3pqkzma)"
                      href="#h81ud3p6jb"
                    />
                    <circle
                      cx="25"
                      cy="25"
                      r="24"
                      fill="#F5F5F5"
                      stroke="#FFF"
                      strokeLinejoin="square"
                      strokeWidth="2"
                    />
                  </g>
                  <g fillRule="nonzero">
                    <path
                      fill="#162035"
                      d="M20.992 0c-.287.804-.582 1.706-.86 2.513-.795 2.384-1.398 4.828-1.804 7.307-.082.422-.14.849-.173 1.278l1.052-2.638c.11.026.156.108.216.168 1.983 1.984 3.964 3.97 5.944 5.957.401.344.632.842.633 1.367 0 .525-.228 1.024-.628 1.37L15.165 27.557c-.05.06-.096.126-.135.194l-.096-.042v-3.073l-.098-.01c-.96 4.113-2.107 8.185-2.739 12.373-.115-2.092-.12-4.182-.056-6.272.047-1.522.154-3.04.303-4.554.187-1.887.454-3.762.8-5.628.667-3.61 1.655-7.156 2.952-10.595.077-.204.147-.41.22-.614.043-.094.022-.056.06-.154.063-.133.1-.248.15-.373.178-.376.337-.761.475-1.154.524-1.14 1.03-2.29 1.6-3.407.73-1.424 1.536-2.891 2.386-4.247-.008.03-.009.018.005-.002z"
                      transform="translate(-135 -15) translate(105) translate(40 22) translate(0 1) translate(12 7)"
                    />
                    <path
                      fill="#2EBB55"
                      d="M12.717 3.871C14.047 2.763 15.483 1.801 17 1c-.967.935-1.858 1.9-2.716 2.9-.857 1-1.677 2.038-2.459 3.106S10.29 9.182 9.576 10.31c-.709 1.12-1.384 2.262-2.024 3.425-.646 1.17-1.243 2.365-1.792 3.588-.547 1.211-1.06 2.44-1.567 3.678-.355-.364-.709-.716-1.051-1.081-.17-.18-.449-.33-.464-.56-.014-.211.181-.438.287-.656.606-1.244 1.271-2.451 1.946-3.654.022-.038.042-.076.065-.113.028-.043.067-.101.02-.14-.068-.057-.1.027-.134.069-.392.482-.785.963-1.171 1.45-.6.757-1.164 1.543-1.677 2.284-.546-.543-1.12-1.05-1.62-1.627-.58-.732-.512-1.811.155-2.456 2.005-2.131 4.01-4.262 6.018-6.391l4.882-5.19c.461-.54 1.188-.732 1.838-.486.164.055.214.11.094.279-.25.355-.481.725-.72 1.089l.056.054z"
                      transform="translate(-135 -15) translate(105) translate(40 22) translate(0 1) translate(12 7)"
                    />
                    <path
                      fill="#162035"
                      d="M9.17 26c-.095-.007-.13-.08-.179-.129-.624-.644-1.244-1.291-1.87-1.934-.107-.082-.15-.229-.102-.358.552-1.822 1.16-3.623 1.824-5.404.062-.166.125-.201.284-.158.558.151 1.116.3 1.68.425.19.042.223.097.17.289-.525 1.906-.99 3.83-1.454 5.753-.122.504-.235 1.011-.353 1.516zM11.643 17.984c-.52-.14-1.031-.28-1.543-.417-.09-.024-.123-.068-.083-.182.507-1.433 1.033-2.857 1.595-4.262.013-.047.041-.086.08-.107.038-.02.081-.021.12-.002.36.105.718.217 1.08.307.131.032.12.094.086.202-.438 1.425-.833 2.867-1.2 4.32-.019.072-.017.203-.135.141zM19 2.037c-1.434 1.807-2.7 3.711-3.785 5.693-.136.25-.272.327-.563.23-.16-.047-.325-.081-.491-.103-.147-.024-.214-.052-.112-.2 1.288-1.94 2.823-3.748 4.576-5.39.103-.093.217-.178.326-.267l.049.037zM12.777 12.843L12 12.62c.185-.388.362-.77.55-1.143.392-.774.793-1.545 1.184-2.323.067-.133.124-.184.28-.135.323.102.652.172.986.257l-.4 1.003-.17.419c-.209.488-.41.98-.572 1.488-.1.255-.136.59-.315.74-.2.17-.5.013-.766-.082z"
                      transform="translate(-135 -15) translate(105) translate(40 22) translate(0 1) translate(12 7)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
}
