import React, { useEffect, useState, useContext } from 'react'
import { getGoogleSuggestions } from '../../../../api/requests_reports'
import IconMapPin from "../../../../assets/icons/icons-map-pin.svg";

import uuid from 'react-uuid'
import { langContext } from '../../../../context/langContext'
import { PhrasesContext } from '../../../../context/PhrasesContext'

function LocationSearchInput({searchText, setSearchText, setShowSuggested, setGoogleSuggestions, data}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)

    const hanldeInputChange = e => {
        if(e.target.value.length >= 50) {
            return;
        }
        setSearchText(e.target.value)
    }

    useEffect(() => {
        
        if (searchText !== '') {
            setShowSuggested(true)
            
            //- get google suggestions
            setGoogleSuggestions({
                loading: true,
                data: [],
                error: false
            })
            getGoogleSuggestions(searchText)
                .then(response => {
                    console.log("GOOGLE SUGGESTIONS ===>", response.data.data.map(item => ({...item, id: uuid()})))
                    setGoogleSuggestions({
                        loading: false,
                        data: response.data.data.map(item => ({...item, id: uuid()})),
                        error: false
                    })
                })
                .catch(error => {
                    console.log("GOOGLE SUGGESTIONS ERROR ===>", error)
                    setGoogleSuggestions({
                        loading: false,
                        data: [],
                        error: true
                    })
                })
            
        } else {
            setShowSuggested(false)
        }
    }, [searchText])

    useEffect(() => {
        if(data.locations.length === 0) {
            setSearchText('')
        }
    }, [data])

    return (
        <div className="multi-search-input">
            <img src={IconMapPin} alt="" />
            <input 
                type="text"
                placeholder={phrases?.lblSearchToAddLocations?.[lang]} 
                value={searchText}
                onChange={hanldeInputChange}
            />
        </div>
    )
}

export default LocationSearchInput
