import React, { useContext } from 'react'
import EditIcon from "../../../../assets/icons/icons-edit.svg";
import DeleteIcon from "../../../../assets/icons/icons-trash.svg";
import { modalContext } from '../../../../context/modalContext';
import { userListContext } from '../../../../context/userListContext';

function PortalPolicemanViewData({data}) {
    const { setModalState } = useContext(modalContext) 
    const {setUserListData} = useContext(userListContext)
    const showPolicemanEdit = (singleItem) => {
        setModalState('showPolicemanEdit')
        setUserListData(singleItem)
    }
    const showPolicemanDelete = (singleItem) => {
        setModalState('showPolicemanDelete')
        setUserListData(singleItem)
    }
    
    return (
        <tr>
            <td><span>{data.name}</span></td>
            <td><span>{data.militryNumber}</span></td>
            <td className="number-text">
                ({data.phone.substring(0, 3)}) &nbsp;
                {data.phone.substring(3, 13)}
            </td>
            <td className="table-actions">
                <div className="action edit-action" onClick={() => showPolicemanEdit(data)}>
                    <img src={EditIcon} alt="icon" />
                </div>
                <div className="action delete-action" onClick={() => showPolicemanDelete(data)} >
                    <img src={DeleteIcon} alt="icon" />
                </div>
            </td>
        </tr>
    )
}

export default PortalPolicemanViewData
