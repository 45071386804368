import React from 'react';



export const addSubRequestContext = React.createContext();
export const addSubRequestInitialState = {

}


export const addSubRequestReducer = (state = addSubRequestInitialState, action) => {
    return state = action
}