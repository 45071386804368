import React, { useContext, useState } from 'react'
import downArrow from "../../../../assets/icons/down-arrow-green.svg";
import useOnclickOutside from "react-cool-onclickoutside";
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';

function ReportColumnsDrodown({dynamicList, setDynamicList}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)

    const [showDropDown, setShowDropDown] = useState(false)
    
    //- hanlde dropdown
    const handleDrowDown = () => {
        if(showDropDown) {
            setShowDropDown(false)   
        } else {
            setShowDropDown(true)   
        }
        
    }

    //- hanlde outside click
    const dropDownKey = useOnclickOutside(() => {
        setShowDropDown(false)
    }, { ignoreClass: "ignore-this" });
    
    

    


    return (
        <div className="columns-dropdown-contain">
            <div className="columns-dropdown-wrap">
                <div className="columns-dropdown">
                    <div className="columns-dropdown-head ignore-this" onClick={handleDrowDown}>
                        <span>{phrases?.lblColumns?.[lang]}</span>
                        <img src={downArrow} width="11" alt="icon" />
                    </div>
                    {
                        showDropDown
                        ?   <div className="columns-dropdown-body ignore-this" ref={dropDownKey}>
                                <div className="columns-dropdown-static-list">
                                    <ul>
                                        {
                                            dynamicList.map(item => {
                                                return (
                                                    item.mainColumn
                                                    ?   <li key={item.id}>
                                                            <label className="custom-checkbox-label">
                                                                <span className="custom-checkbox-text">{item.name}</span>
                                                                <input type="checkbox" disabled onChange={(e) => {e.preventDefault()}} defaultChecked="checked" />
                                                                <span className="custom-checkbox-checkmark"></span>
                                                            </label>
                                                        </li>
                                                    :   null
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="columns-dropdown-dynamic-list">
                                    <ul>
                                        {
                                            dynamicList.map(item => {
                                                return (
                                                    !item.mainColumn
                                                    ?   
                                                        <DynamicListItem 
                                                            key={item.id} 
                                                            item={item}
                                                            dynamicList={dynamicList}
                                                            setDynamicList={setDynamicList}
                                                        />
                                                    :   null
                                                )
                                            })
                                        }
                                        
                                    </ul>
                                </div>
                            </div>
                        :   null
                    }
                    
                </div>
            </div>
        </div>
    )
}


//- dynamic list item  functional component
function DynamicListItem({item, dynamicList, setDynamicList}) {

    const handleChange = (e) => {
        let checked = e.target.checked;
        if(checked) {
            setDynamicList(dynamicList.map(x => x.id === item.id ? {...x, selected: true} : {...x}))
        } else {
            setDynamicList(dynamicList.map(x => x.id === item.id ? {...x, selected: false} : {...x}))
        }
    }

    return (
        <>
            <li>
                <label className="custom-checkbox-label">
                    <span className="custom-checkbox-text">{item.name}</span>
                    <input type="checkbox" onChange={handleChange} defaultChecked={item.selected} />
                    <span className="custom-checkbox-checkmark"></span>
                </label>
            </li>
        </>
    )
}

export default ReportColumnsDrodown
