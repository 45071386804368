import React, { useEffect, useRef, useState , useContext} from 'react'
import { resendOtp } from '../../../api/login'
import {PhrasesContext} from '../../../context/PhrasesContext'
import { langContext } from "../../../context/langContext";

function ForgetPasswordOTPTimer({userName, setIsSubmitError}) {
    const timeRef = useRef(null)
    const [isTimeDone, setTimeDone] = useState(false)
    const [startCount, setStartCount] = useState(true)
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const [counter, setCounter] = useState(0)

    const hanldeTimeEnd = () => {
        resendOtp(userName)
        .then(response => {
            console.log(response)
        })
        .catch(error => {
            console.log(error)
        })
        setCounter(prevState => prevState + 1)
        setTimeDone(false)
        setStartCount(true)
        setIsSubmitError(false)
    }
    

    useEffect(() => {
        if(startCount) {
            if(counter < 3) {
                var timeLeft = 59;
                var timerId = setInterval(countdown, 1000);
                function countdown() {
                    if (timeLeft === -1) {
                        clearTimeout(timerId);
                        setTimeDone(true)
                        setStartCount(false)
                       
                    } else {
                        timeRef.current.innerHTML = timeLeft > 9 ? timeLeft : `0${timeLeft }`
                        timeLeft--;
                    }
                }
            }
            
        }

        return () => {
            clearTimeout(timerId)
        }
        

    }, [startCount])

    return (
        <div className="form-row">
            <div className="form-col invalid-col back-wrap">
                

                {
                    counter === 3
                    ?   <p className="error-msg">{phrases?.lblOTPTrialsExceeded?.[lang]}</p>
                    :   isTimeDone
                        ?   <p className="send-msg" style={{textAlign: 'center'}} onClick={hanldeTimeEnd}>
                                {phrases?.btnResendOTP?.[lang]}
                            </p>
                        :   <p className="success-msg" style={{textAlign: 'center'}}>
                                00:
                                <span ref={timeRef}>59</span>
                            </p>
                }
                
            </div>
        </div>
    )
}

export default ForgetPasswordOTPTimer
