import React, { useContext } from 'react'
import ModalContainer from '.'
import PortalUserViewDataAdd from '../../Components/Profile/ProfilePortal/ProfilePortalUser/PortalUserViewDataAdd'
import PortalUserViewDataEdit from '../../Components/Profile/ProfilePortal/ProfilePortalUser/PortalUserViewDataEdit'
import PortalPolicemanViewDataAdd from '../../Components/Profile/ProfilePortal/ProfilePortalPoliceman/PortalPolicemanViewDataAdd'
import PortalPolicemanViewDataEdit from '../../Components/Profile/ProfilePortal/ProfilePortalPoliceman/PortalPolicemanViewDataEdit'
import PortalPolicemanViewDataDelete from '../../Components/Profile/ProfilePortal/ProfilePortalPoliceman/PortalPolicemanViewDataDelete'
import { modalContext } from '../../context/modalContext'
import CancelRequest from '../CancelRequest'
import NotificationModal from '../NotificationModal'
import ResetPasswordModal from '../../Components/NewLogin/ForgetPassword/ResetPasswordModal'
import ProfilePasswordSuccessModal from '../../Components/Profile/ProfilePassword/ProfilePasswordSuccessModal'
import ShareModal from '../../Components/MyRequestReport/components/ShareModal'

function ModalWrapper() {
    const {modalState} = useContext(modalContext)
    return (
        <>
            {
                modalState.showNotification &&
                    <ModalContainer>
                        <NotificationModal />
                    </ModalContainer>
            }

            {
                modalState.showCancel &&
                    <ModalContainer>
                        <CancelRequest />
                    </ModalContainer>
            }

            {
                modalState.showUserAdd &&
                    <ModalContainer>
                        <PortalUserViewDataAdd />
                    </ModalContainer>
            }

            {
                modalState.showPolicemanAdd &&
                    <ModalContainer>
                        <PortalPolicemanViewDataAdd />
                    </ModalContainer>
            }

            {
                modalState.showUserEdit &&
                    <ModalContainer>
                        <PortalUserViewDataEdit />
                    </ModalContainer>
            }

            {
                modalState.showPolicemanEdit &&
                    <ModalContainer>
                        <PortalPolicemanViewDataEdit />
                    </ModalContainer>
            }

            {
                modalState.showPolicemanDelete &&
                    <ModalContainer>
                        <PortalPolicemanViewDataDelete />
                    </ModalContainer>
            }

            {
                modalState.showPasswordSuccess &&
                    <ModalContainer>
                        <ProfilePasswordSuccessModal />
                    </ModalContainer>
            }

            

            
        </>
    )
}

export default ModalWrapper
