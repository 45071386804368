import React, {useContext, useEffect, useState} from 'react'
import { modalContext } from '../../../context/modalContext'
import shareIcon from "../../../assets/icons/share-icon-white.png";
import CloseIocn from '../../../assets/icons/icon-close-white.svg';
import CloseBlack from "../../../assets/icons/icons-close.svg";
import closeRed from "../../../assets/icons/close-red.svg";
import tickGreen from "../../../assets/icons/tick-green-small.svg";
import ReportFile from "../../../assets/icons/report-file.png";


import ShareModalEmail from './ShareModalComponents/ShareModalEmail';
import $ from "jquery";
import { langContext } from '../../../context/langContext';
import { PhrasesContext } from '../../../context/PhrasesContext';
import { profileContext } from '../../../context/profileContext';
import { shareWithEmail, shareWithEmailBase64 } from '../../../api/requests_reports';

import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";


function ShareModal({filter, coulmns, isReport, portalView}) {
    const{profileContextData} = useContext(profileContext)
    const {lang} = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);

    const {setModalState} = useContext(modalContext)
    const hideModal = () => {
        setModalState('hideModal')
    }
    const stopProb = (e) => {
        e.stopPropagation()
    }

    //- validations and submitting
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isSumbitError, setIsSubmitError] = useState(false)
    const [isSumbitSuccess, setisSumbitSuccess] = useState(false)

    const [apiValidation, setApiValidation] = useState('')

    //- Submitted form object
    const [formData, setFormData] = useState({
        emailList: [],
        message: ''
    })

    //- handle message value change
    const hanldeChange = e => {
        
        if(e.target.value.length > 250) {
            return
        }
        
        setFormData({
            ...formData,
            message: e.target.value
        })

       
        
        
    }
    
    //- log form data
    useEffect(() => {
        console.log("Sending Report ===>", formData)
    }, [formData])
    
    //- Hanlde form submit
    const handleSubmit = e => {
        let isValidateToSubmit = true;
        if(formData.emailList.length === 0) {
            $(`.email-head-input`).closest(".form-col").addClass("invalid-col");
            $(`.email-head-input`).addClass("invalid-input");
            isValidateToSubmit = false

        }


        if(isValidateToSubmit) {
            setIsSubmitted(true)
            let selectedEmails = [];
            let selectedCoulmns = [];
            coulmns.map(item => item.selected ? selectedCoulmns.push({name: item.relatedColumnName, title: item.name}) : item)
            formData.emailList.map(item => selectedEmails.push(item.email))

            const submittedData = {
                reportRequestWithLocation: {...filter, requestId: +filter.requestId, pageIndex: 0,},
                userName: profileContextData.userName,
                body: formData.message,
                emailReceivers: selectedEmails,
                columns: isReport ? coulmns : selectedCoulmns
            }
            console.log('submittedData', submittedData)

            

            if(isReport) {
                if(portalView === 'chart') {
                    const chart = document.getElementById("downloaded-view");
                    // document.getElementById('hide-on-print').style.display = 'none'
                    // document.getElementById('hide-on-print-two').style.display = 'none'
                    $('.remove-shadow').addClass('removed')
                    $('.hide-on-print-item').hide()
                    html2canvas(chart, { useCORS: true }).then((canvas) => {
                        var dataUrl = canvas.toDataURL("image/png");
                        var doc = new jsPDF(null, null, null, true);
                        var width = doc.internal.pageSize.getWidth();
                        var height = doc.internal.pageSize.getHeight();
                        doc.addImage(dataUrl, "jpeg", 0, 0, width, height);
                        let output = doc.output('datauristring');
                        let base64Only = output.split("base64,")[1];
                        // document.getElementById('hide-on-print').style.display = 'flex'
                        // document.getElementById('hide-on-print-two').style.display = 'flex'
                        $('.remove-shadow').removeClass('removed')
                        $('.hide-on-print-item').css('display', 'flex')
                        const base64SubmittedData = {
                            userName: profileContextData.userName,
                            reportName: "",
                            body: formData.message,
                            emailReceivers: selectedEmails,
                            base64File: base64Only
                        }

                        shareWithEmailBase64(base64SubmittedData)
                            .then(resp => {
                                setIsSubmitted(false)
                                setisSumbitSuccess(true)
                                setTimeout(() => {
                                    setModalState('hideModal')

                                }, 3000)

                            })
                            .catch(error => {
                                console.log('ERROR =>', error)
                                setIsSubmitted(false)
                                setIsSubmitError(true)
                                setApiValidation(error?.response?.data?.key || 'lblSomethingWrong')
                            })

                    });
                    
    
                }
                if(portalView === 'table') {
                    shareWithEmail(submittedData)
                        .then(resp => {
                            setIsSubmitted(false)
                            setisSumbitSuccess(true)
                            setTimeout(() => {
                                setModalState('hideModal')

                            }, 3000)

                        })
                        .catch(error => {
                            console.log('ERROR =>', error)
                            setIsSubmitted(false)
                            setIsSubmitError(true)
                            setApiValidation(error?.response?.data?.key || 'lblSomethingWrong')
                        })
                }
    
            } else {
                shareWithEmail(submittedData)
                    .then(resp => {
                        setIsSubmitted(false)
                        setisSumbitSuccess(true)
                        setTimeout(() => {
                            setModalState('hideModal')

                        }, 3000)

                    })
                    .catch(error => {
                        console.log('ERROR =>', error)
                        setIsSubmitted(false)
                        setIsSubmitError(true)
                        setApiValidation(error?.response?.data?.key || 'lblSomethingWrong')
                    })
            }
            
        }


        e.preventDefault()
    }
    
    //- handle enter key
    const handleEnterKey = e => {
        if(e.key === 'Enter' || e.keyCode === 13) {
            e.preventDefault()
        }
    }

    // custom error handling
    const closeErrorSubmit = () => {
        setIsSubmitError(false)
    }

    return (
        <div className="modal-data protal-modal share-modal" onClick={stopProb}>
            <div className="close-modal" onClick={hideModal}>
                <img src={CloseIocn} alt="icon" />
            </div>

            <div className="profile-card">
                <div className="profile-card-head">
                    <div className="user-title-wrap">
                        <div className="user-icon">
                            <img src={shareIcon} alt="icon" style={{width: '24px'}} />
                        </div>
                        <strong>{phrases?.lblShareReport?.[lang]}</strong>
                    </div>
                </div>
                {
                    isSumbitSuccess
                    ?   <div className="note success">
                            <div className="note-text">
                                <img src={tickGreen} alt="" />
                                <span>
                                    {phrases?.lblReportShareSuccessful?.[lang]}
                                </span>
                            </div>
                            <img src={CloseBlack} className="closeNote" onClick={closeErrorSubmit} />
                        </div>
                    :   null
                }
                {
                    isSumbitError
                    ?   <div className="note error">
                            <div className="note-text">
                                <img src={closeRed} alt="" />
                                <span>
                                    {
                                        phrases[apiValidation]
                                        ?   phrases[apiValidation][lang]
                                        :   phrases[`lblSomethingWrong`][lang]
                                    }
                                </span>
                            </div>
                            <img src={CloseBlack} className="closeNote" onClick={closeErrorSubmit} />
                        </div>
                    :   null
                }
                <div className={`profile-card-body`}>
                    <form className="share-form request-form" onSubmit={handleSubmit} onKeyDown={handleEnterKey}>
                        <div className="share-form-content">
                            <div className="form-row mb-30">
                                <div className="form-col">
                                    <strong>{phrases?.lblShareWith?.[lang]}</strong>
                                    <ShareModalEmail
                                        data={formData}
                                        setData={setFormData}
                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-col">
                                    <strong>{phrases?.lblMessage?.[lang]}</strong>
                                    <div className="input-wrap">
                                        <textarea 
                                            className="site-input" 
                                            rows="3" 
                                            placeholder={phrases?.lblEnterMessage?.[lang]} 
                                            value={formData.message} 
                                            onChange={hanldeChange} ></textarea>
                                    </div>
                                    <p className="length-msg">{formData.message.length} / 250 {phrases?.lblCharactersRemaining?.[lang]}</p>
                                </div>
                            </div>
                        </div>
                        <div className="share-form-report">
                            <div className="share-form-report-icon">
                                <img src={ReportFile}  alt="icon" />
                            </div>
                            <p>{phrases?.lblReportAttachedAuto?.[lang]}</p>
                        </div>

                        <div className="request-form-action">
                            <div className="form-aciton">
                                <button  type="submit" className="site-btn" disabled={`${isSubmitted  ? "disabled" : ""}`}>
                                    <span>{phrases?.lblShareReport?.[lang]}</span>
                                    {
                                        isSubmitted
                                        ?   <div className="spinner"></div>
                                        :   null
                                    }
                                    
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ShareModal
