import React, { useState, useEffect, useContext } from 'react'
import { langContext } from '../../../../../context/langContext'
import { PhrasesContext } from '../../../../../context/PhrasesContext'
import ReportDetailsTable from './ReportDetailsTable'

function ReportDetailsListIndex({reportData, setReportData, itemId}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)

    //- columns
    const [columns, setColumns] = useState([])
    //- coming columns
    const [comingColumns, setComingColumns] = useState([])

    //- matching columns
    const [matchingColumns, setMatchingColumns] = useState([])

    useEffect(() => {
        setColumns([
            {
                id: 1,
                relatedColumnName: "ccExternalId",
                name: lang === 'en' ? phrases?.lblRequestID?.['en'] : phrases?.lblRequestID?.['ar'],
                mainColumn: true,
                selected: true,
            },
            {
                id: 2,
                relatedColumnName: "requestId",
                name: lang === 'en' ? phrases?.lblRequestID?.['en'] : phrases?.lblRequestID?.['ar'],
                mainColumn: true,
                selected: true,
            },
            {
                id: 3,
                relatedColumnName: "jobId",
                name: lang === 'en' ? phrases?.lblJobOrderID?.['en'] : phrases?.lblJobOrderID?.['ar'],
                mainColumn: true,
                selected: true,
            },
            {
                id: 4,
                relatedColumnName: "creationTime",
                name: lang === 'en' ? phrases?.lblRequestDate?.['en'] : phrases?.lblRequestDate?.['ar'],
                mainColumn: true,
                selected: true,
            },
            {
                id: 5,
                relatedColumnName: "pickUpAddress",
                name: lang === 'en' ? phrases?.lblPickupLocationCCPAR?.['en'] : phrases?.lblPickupLocationCCPAR?.['ar'],
                mainColumn: true,
                selected: true,
            },
            {
                id: 6,
                relatedColumnName: "statusNameEn",
                name: lang === 'en' ? phrases?.lblStatus?.['en'] : phrases?.lblStatus?.['ar'],
                mainColumn: true,
                selected: true,
            },
            {
                id: 7,
                relatedColumnName: "statusNameAr",
                name: lang === 'en' ? phrases?.lblStatus?.['en'] : phrases?.lblStatus?.['ar'],
                mainColumn: true,
                selected: true,
            },
            {
                id: 8,
                relatedColumnName:  "requestTypeNameEn",
                name: lang === 'en' ? phrases?.lblServiceType?.['en'] : phrases?.lblServiceType?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 9,
                relatedColumnName:  "requestTypeNameAr",
                name: lang === 'en' ? phrases?.lblServiceType?.['en'] : phrases?.lblServiceType?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 10,
                relatedColumnName: "dropOffAddress",
                name: lang === 'en' ? phrases?.lblDropOffLocation?.['en'] : phrases?.lblDropOffLocation?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 11,
                relatedColumnName: "driverName",
                name: lang === 'en' ? phrases?.lblMovedBy?.['en'] : phrases?.lblMovedBy?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 12,
                relatedColumnName: "vehicleVin",
                name: lang === 'en' ? phrases?.lblVINNo?.['en'] : phrases?.lblVINNo?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 13,
                relatedColumnName: "vehiclePlateNo",
                name: lang === 'en' ? phrases?.lblPlateNo?.['en'] : phrases?.lblPlateNo?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 14,
                relatedColumnName: "vehicleMake",
                name: lang === 'en' ? phrases?.lblVehicleMake?.['en'] : phrases?.lblVehicleMake?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 15,
                relatedColumnName: "vehicleModel",
                name: lang === 'en' ? phrases?.lblVehicleModel?.['en'] : phrases?.lblVehicleModel?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 16,
                relatedColumnName: "vehicleColor",
                name: lang === 'en' ? phrases?.lblVehicleColor?.['en'] : phrases?.lblVehicleColor?.['ar'],
                mainColumn: false,
                selected: false,
            },
        ])
    }, [lang, phrases])

    useEffect(() => {
        setComingColumns(reportData.report.columns)
    }, [reportData])

    //- merge both columns
    useEffect(() => {
        const matchingColumnsNested = [];

        if(columns  && comingColumns) {
            if(columns.length !== 0 && comingColumns.length !== 0) {
                for (var i = 0; i <= comingColumns.length; i++) {
                    if (comingColumns[i]) {
                        columns.find(item => {
                            if(item.relatedColumnName === comingColumns[i].name) {
                                matchingColumnsNested.push(item)
                            }
                        })
                    }
                }
            }
        }
        setMatchingColumns(matchingColumnsNested)
    }, [columns, comingColumns])


    return (
        <>
            <div className="report-tabs-list-view" style={{marginTop: '32px'}}>
                <ReportDetailsTable matchingColumns={matchingColumns} reportData={reportData} setReportData={setReportData} itemId={itemId} />
            </div>
        </>
    )
}

export default ReportDetailsListIndex
