import React, { useContext, useEffect, useState } from "react";
import { langContext } from '../../context/langContext';
import { PhrasesContext } from '../../context/PhrasesContext';
import MilitaryNumberInput from "../../shell/MilitaryNumberInput";
import FullNameInput from "../../shell/FullNameInput";
import PhoneHandleWrap from "../../shell/PhoneHandleWrap/PhoneHandleWrap";
import { GetPoliceMan } from "../../api/profile";
import { getAllUsers } from "../../api/login";
import { profileContext } from "../../context/profileContext";

import $ from "jquery";

function AutoCompleteInputs({data, setData, setIsSubmitError, isEdit, isDataLoad}) {
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const{ profileContextData } = useContext(profileContext)

    //- Militray number state
    const [userList, setUserList] = useState({loading: true, data: [], error: false})
    const [policeMen, setPoliceMen] = useState({loading: true, data: [], error: false})

    const [isMilitaryFound, setIsMilitaryFound] = useState(false)
    const [disable, hanldeDisable] = useState(false)

    //- Get military number data 
    useEffect(() => {
        GetPoliceMan()
            .then(response => {
                setPoliceMen({
                    loading: false,
                    data: response.data.data,
                    error: false
                })
            }) 
            .catch(error => {
                console.log('Police Man Error ==> ', error)
            })
        getAllUsers()
            .then(response => {
                setUserList({
                    loading: false,
                    data: response.data.data,
                    error: false
                })
            }) 
            .catch(error => {
                console.log('Users Error ==> ', error)
            })
        

    }, [])

    //- Update military number data
    useEffect(() => {
        if(data.militaryNumber.length >= 2) {
            let exactDataUser = userList.data.find((item) => item.militaryNumber.toString() == data.militaryNumber.toString())
            let exactDataPolice = policeMen.data.find((item) => item.militryNumber.toString() == data.militaryNumber.toString())
            
            if (exactDataUser) {
                setData(prevState => ({
                    ...prevState,
                    policemanName: exactDataUser.name,
                    policemanPhone: exactDataUser.phoneNum,
                    userId: exactDataUser.id,
                    policemanId: 0
                }))
                setIsMilitaryFound(state => !state)
                if  (profileContextData.isAdmin) {
                    hanldeDisable(false)
                } else {
                    hanldeDisable(true)
                }
                
                $('.policemanName, .policemanPhone').removeClass("invalid-input");
                $('.policemanName, .policemanPhone').closest(".form-col").removeClass("invalid-col");
            }

            if (exactDataPolice) {
                setData(prevState => ({
                    ...prevState,
                    policemanName: exactDataPolice.name,
                    policemanPhone: exactDataPolice.phone,
                    policemanId: exactDataPolice.id,
                    userId: 0
                }))
                setIsMilitaryFound(state => !state)
                if  (profileContextData.isAdmin) {
                    hanldeDisable(false)
                } else {
                    hanldeDisable(true)
                }

                
                $('.policemanName, .policemanPhone').removeClass("invalid-input");
                $('.policemanName, .policemanPhone').closest(".form-col").removeClass("invalid-col");
            }

            if(!exactDataUser && !exactDataPolice) {
                setData(prevState => ({
                    ...prevState,
                    policemanName: "",
                    policemanPhone: "",
                    policemanId: 0,
                    userId: 0
                }))
                setIsMilitaryFound(state => !state)
                hanldeDisable(false)
                $('.policemanName, .policemanPhone').removeClass("invalid-input");
                $('.policemanName, .policemanPhone').closest(".form-col").removeClass("invalid-col");
            }

        } else {
            setData(prevState => ({
                ...prevState,
                policemanName: "",
                policemanPhone: "",
                policemanId: 0,
                userId: 0
            }))
            setIsMilitaryFound(state => !state)
            hanldeDisable(true)
            $('.policemanName, .policemanPhone').removeClass("invalid-input");
            $('.policemanName, .policemanPhone').closest(".form-col").removeClass("invalid-col");
            
        }
    }, [data.militaryNumber])
    
    //- update military number once loaded

    useEffect(() => {
        if(isEdit) {
            if(!isDataLoad && !userList.loading && !policeMen.loading) {
                let exactDataUser = userList.data.find((item) => item.militaryNumber.toString() == data.militaryNumber.toString())
                let exactDataPolice = policeMen.data.find((item) => item.militryNumber.toString() == data.militaryNumber.toString())
    
                if (exactDataUser) {
                    setData(prevState => ({
                        ...prevState,
                        policemanName: exactDataUser.name,
                        policemanPhone: exactDataUser.phoneNum,
                        userId: exactDataUser.id,
                        policemanId: 0
                    }))
                    setIsMilitaryFound(state => !state)
                    if  (profileContextData.isAdmin) {
                        hanldeDisable(false)
                    } else {
                        hanldeDisable(true)
                    }
                    $('.policemanName, .policemanPhone').removeClass("invalid-input");
                    $('.policemanName, .policemanPhone').closest(".form-col").removeClass("invalid-col");
                }
        
                if (exactDataPolice) {
                    setData(prevState => ({
                        ...prevState,
                        policemanName: exactDataPolice.name,
                        policemanPhone: exactDataPolice.phone,
                        policemanId: exactDataPolice.id,
                        userId: 0
                    }))
                    setIsMilitaryFound(state => !state)
                    if  (profileContextData.isAdmin) {
                        hanldeDisable(false)
                    } else {
                        hanldeDisable(true)
                    }
                    $('.policemanName, .policemanPhone').removeClass("invalid-input");
                    $('.policemanName, .policemanPhone').closest(".form-col").removeClass("invalid-col");
                }
        
            
            }
        }
        
        
    }, [isDataLoad, userList, policeMen])
    return (
        <>
            {
                isEdit && userList.loading && policeMen.loading
                ?   <>
                        <div className="card-loader data" style={{ width: '100%', height: '40px', marginBottom: '16px' }}></div>
                        <div className="card-loader data" style={{ width: '100%', height: '40px', marginBottom: '16px' }}></div>
                        <div className="card-loader data" style={{ width: '100%', height: '40px', marginBottom: '16px' }}></div>
                    </>
                :   <>
                    <div className="form-row">
                        <div className="form-col">
                            <strong>{phrases?.lblMilitaryNumber?.[lang]}</strong>
                            <MilitaryNumberInput 
                                data={data} 
                                setData={setData} 
                                name="militaryNumber" 
                                placeHolder={phrases?.lblEnterMilitaryNumber?.[lang]}
                                setIsSubmitError={setIsSubmitError}
                            />
                        </div>
                    </div>
                    
                    <div className="form-row">
                        <div className="form-col">
                            <strong>{phrases?.lblName?.[lang]}</strong>
                            <FullNameInput 
                                data={data} 
                                setData={setData} 
                                name="policemanName"
                                placeHolder={phrases?.lblEnterFullName?.[lang]}
                                setIsSubmitError={setIsSubmitError}
                                disabled={disable}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col">
                            <strong>{phrases?.lblPhone?.[lang]}</strong>
                            <PhoneHandleWrap
                                data={data}
                                setData={setData}
                                name="policemanPhone"
                                placeHolder={phrases?.lblEnterYourPhoneNumber?.[lang]}
                                setIsSubmitError={setIsSubmitError}
                                dataChanged={isMilitaryFound}
                                disabled={disable}
                            />
                        </div>
                    </div>

                    </>
                    
            }
            
        </>
    )
}

export default AutoCompleteInputs
