import React, { useEffect, useState, useContext } from 'react'
import CloseIocn from '../../../../assets/icons/icon-close-white.svg';
import compareFilesIcon from '../../../../assets/icons/share-files.png';
import deleteReportIcon from '../../../../assets/icons/deleteReportIcon.png';
import { PhrasesContext } from '../../../../context/PhrasesContext';
import { langContext } from '../../../../context/langContext';
import { modalContext } from '../../../../context/modalContext';
import ModalContainer from '../../../../Modals/ModalContainer';
import ReportDetailsDeleteModal from '../../ReportsDetails/ReportDetailsDeleteModal';
import { useHistory } from "react-router-dom";

function ReportListFixedFoot({report, setReport}) {
    const [phrases] = useContext(PhrasesContext);
    const { lang } = useContext(langContext);
    const {modalState, setModalState} = useContext(modalContext)

    const history = useHistory();


    //- deleted report 
    const [deletedReport, setDeletedReport] = useState([])

    //- hanlde deleted report
    const handleDeleteReport = () => {
        if(deletedReport.length === 0 ) {
            return
        }
        setModalState('showDeleteReport')
        
        
    }

    //- handle compare
    const handleCompare = () => {
        if(deletedReport.length !== 2) {
            return
        }

        history.push(`/report/compare/${deletedReport[0]}/${deletedReport[1]}`)
    }

    //- handle unselect
    const handleUnSelectAll = () => {
        if(deletedReport.length === 0) {
            return
        }
        let dataArray = [];
        report.data.map(item => dataArray.push({...item, selected: false}))
        setReport({
            ...report,
            data: dataArray
        })
        
    }

    //- update selected report indexing and data
    useEffect(() => {
        let selectedArray = []
        report.data.map(x => {
            if(x.selected === true) {
                selectedArray.push(x.id)
                setDeletedReport(selectedArray)
            } else {
                selectedArray.filter(item => item.id !== x.id)
                setDeletedReport(selectedArray)
            }
        })

    }, [report])




    return (
        <>
            <div className="report-fixed-foot">
                <div className="report-fixed-foot-data">
                    <div className={`reports-selected-wrap ${deletedReport.length === 0 ? 'disabled' : ''}`}>
                        <span>{deletedReport.length} {phrases?.lblReportsSelected?.[lang]}</span>
                        <img src={CloseIocn} alt="" onClick={handleUnSelectAll} />
                    </div>
                    <div className="site-actions-wrap">
                        <ul>
                            <li>
                                <div className={`site-btn ${deletedReport.length !== 2 ? 'disabled' : ''}`} onClick={handleCompare}>
                                    <img src={compareFilesIcon} alt="icon" />
                                    <span>{phrases?.lblCompare?.[lang]}</span>
                                </div>
                            </li>
                            <li>
                                <div className={`site-btn red ${deletedReport.length === 0 ? 'disabled' : ''}`} onClick={handleDeleteReport}>
                                    <img src={deleteReportIcon} alt="icon" />
                                    <span>{phrases?.btnDelete?.[lang]}</span>
                                    
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {
                modalState.showDeleteReport
                ?   <ModalContainer>
                        <ReportDetailsDeleteModal isList itemId="0" report={report} setReport={setReport} deletedReport={deletedReport} />
                    </ModalContainer>
                :   null
                
            }
        </>
        
    )
}

export default ReportListFixedFoot
