import React, {useContext} from 'react'
import IconPhone from '../../../assets/icons/icons-phone.svg';
import militaryIcon from '../../../assets/icons/military-icon.svg';
import userIconGreen from '../../../assets/icons/user-icon-green.svg';
import emailIconGreen from '../../../assets/icons/email-icon-green.svg';
import { PhrasesContext } from '../../../context/PhrasesContext';
import { langContext } from '../../../context/langContext';



function ProfileInformationView({data}) {
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
  
    return (
        <div className="profile-card-body">
            <div className="profile-card-information">
                <ul>
                    <li>
                        <img src={userIconGreen} alt="icon" />
                        <div className="profile-card-information-meta">
                            <span>{phrases?.lblName?.[lang]}</span>
                            <p>{data.name}</p>
                        </div>
                    </li>

                    <li>
                        <img src={emailIconGreen} alt="icon" />
                        <div className="profile-card-information-meta">
                            <span>{phrases?.lblEmailAddress?.[lang]}</span>
                            <p>{data.email}</p>
                        </div>
                    </li>

                    <li>
                        <img src={militaryIcon} alt="icon" />
                        <div className="profile-card-information-meta">
                            <span>{phrases?.lblMilitaryNumber?.[lang]}</span>
                            <p>{data.militaryNumber}</p>
                        </div>
                    </li>

                    <li>
                        <img src={IconPhone} alt="icon" />
                        <div className="profile-card-information-meta">
                            <span>{phrases?.lblPhone?.[lang]}</span>
                            <p style={{direction:'ltr'}}>
                                ({data.phoneNum.substring(0, 3)}) &nbsp;
                                {data.phoneNum.substring(3, 13)}
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default ProfileInformationView
