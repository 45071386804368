/**
 * @author   Abdoulrhman Salah
 */
import React, { useContext, useEffect, useState, useRef } from "react";
import { useLoadScript, Marker, OverlayView } from "@react-google-maps/api";

import RequestOnMapIcon from "../../Components/RequestOnMapIcon";
import { PickedLocationContext } from "../../context/pickedLocationContext";
import GlogbeIcon from "../../assets/icons/icons-globe.svg";
import GreenGlogbeIcon from "../../assets/icons/green-globe.svg";
import AddIcon from "../../assets/icons/adding.svg";
import MinusIcon from "../../assets/icons/minus.svg";
import FullScreenIcon from "../../assets/icons/icons-full-screen.svg";
import CloseFullScreen from "../../assets/icons/closeFull.svg";
import "@reach/combobox/styles.css";
import mapStyles from "../../mapStyles";
import SearchComponent from "../../Components/SearchComponent";
import NotificationBar from "../../Components/NotificationBar";
import { langContext } from "../../context/langContext";
import { PinContext } from "../../context/pinContext";
import { mapControlContext } from "../../context/mapControlContext";
import { ListOnMapContext } from "../../context/listOnMap";
import { GetConcentrationPoints } from "../../api/painpointedCars";
import { requestContext } from "../../context/reqeustContext";
import { itemIdContext } from "../../context/itemIdContext";
import axios from "axios";
import WrappedMap from "../../Components/wrappedMap";
import { notificationContext } from "../../context/addNotificationContext";

// Array of all the used APIs with Google Maps
const libraries = ["places", "geometry"];
let google = window.google;

const mapContainerStyle = {
  height: "100vh",
  width: "100vw",
  position: "absolute",
  top: 0,
  right: 0,
  left: 0,
  zIndex: 999,
};
// Map Dimensions

// Map Starting Center Point
const center = { lat: 25.161732389637713, lng: 55.292450271155516 };

const MainMap = () => {
  // Add map configurations
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    libraries,
  });

  const { lang } = useContext(langContext);
  const [isRoadMap, setIsRoadMap] = useState(false);
  const [currentZoom, setCurrentZoom] = useState(15);
  // Map Options
  const options = {
    styles: mapStyles,
    disableDefaultUI: false,
    zoomControl: false,
    label: true,
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    clickableIcons: false,
    scaleControl: false,
    keyboardShortcuts: false,
    mapTypeId: isRoadMap ? "roadmap" : "hybrid",
    maxZoom: 20,
    minZoom: 5,
    // restriction: {
    //   latLngBounds: {
    //     north: { lat: 25.165606933291297, lng: 55.27988680412976 },
    //     south: { lat: 25.165995351931635, lng: 55.294005952384154 },
    //     east: -180,
    //     west: 180,
    //   },
    //   strictBounds: true,
    // },
  };

  // toggle full screen
  const [isFullScreen, setFullScreen] = useState(false);
  var elem = document.documentElement;
  const showFullScreen = () => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
    setFullScreen(true);
  };
  const hideFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    setFullScreen(false);
  };

  // Component State
  const { mapControl } = useContext(mapControlContext);
  const [showPin, changePinStatus] = useContext(PinContext);
  const [refreshList, setRefreshList] = useState(false);
  let [directionService, setDirectionService] = useState(false);

  const { requestStatus, setRequestStatus } = useContext(requestContext);
  const [concentrationPoints, setConcentrationPoints] = useState([]);
  const [pickedLocation, setPickedLocation] = useContext(PickedLocationContext);
  const [requestsList, renderList, updateRequestsList] =
    useContext(ListOnMapContext);
  const { setItemId, itemId } = useContext(itemIdContext);
  const { notification } = useContext(notificationContext);

  const showDetailedRequest = id => {
    setItemId(id);
    setRequestStatus("showDetailed");
    changePinStatus(false);
  };

  useEffect(() => {
    if (!requestsList.loading) {
      if (notification?.MainRequestStatusEn) {
        renderList();
      }
    }
  }, [notification]);

  useEffect(() => {
    renderList();
  }, [mapControl.isRequestDeleted]);

  useEffect(() => {
    GetConcentrationPoints()
      .then(res => {
        const results = res?.data?.data;
        setConcentrationPoints(results);
      })
      .catch(err => {
        console.log("Error::", err.message);
      });
  }, []);

  const showAddRequest = () => {
    setRequestStatus("showAddRequest");
  };

  // Adding the markers on the map with the spotted time
  const onMapClick = React.useCallback(
    e => {
      if (requestStatus.showDetailed || requestStatus.showEditRequest) {
        changePinStatus(false);
        return;
      }
      changePinStatus(true);
      showAddRequest();
      getAddress(e);
    },
    [requestStatus]
  );

  const onPinDrag = React.useCallback(e => {
    getAddress(e);
  });

  const getAddress = async e => {
    try {
      const results = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${e.latLng.lat()},${e.latLng.lng()}&key=${
          process.env.REACT_APP_GOOGLE_PLACES_API_KEY
        }&language=${localStorage.getItem("lang")}`
      );

      const selectedLocation = {
        longitude: e.latLng.lng(),
        latitude: e.latLng.lat(),
        title: results?.data?.results[0]?.formatted_address,
        address: results?.data?.results[0]?.formatted_address,
      };
      setPickedLocation(selectedLocation);
    } catch {
      console.log("Error");
    }
  };

  // map reference
  const mapRef = React.useRef();

  // Store the map to the ref before it mounted
  const onMapLoad = React.useCallback(map => {
    mapRef.current = map;
    google = window.google;

    let service = new google.maps.DirectionsService();
    setDirectionService(service);
  }, []);

  const mapTypeToggle = () => {
    setIsRoadMap(!isRoadMap);
  };

  const handleZoom = type => {
    if (currentZoom == 5 && type == "dec") {
      setCurrentZoom(currentZoom);
    } else {
      type == "inc"
        ? setCurrentZoom(currentZoom + 1)
        : type == "search"
        ? setCurrentZoom(currentZoom + 3)
        : setCurrentZoom(currentZoom - 1);
      mapRef && mapRef.current.setZoom(currentZoom);
    }
  };

  const renderDynamicMarker = markerObject => {
    return (
      <Marker
        key={markerObject.key && markerObject.key}
        position={{ lat: markerObject.lat, lng: markerObject.lng }}
        label={{ text: `${markerObject.label}`, color: "white" }}
        icon={{
          url: `/${markerObject.iconName}.svg`,
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(25, 25),
          scaledSize: new window.google.maps.Size(50, 50),
        }}
      />
    );
  };

  // applying the panTo function of google maps

  const panTo = React.useCallback(({ lat, lng }) => {
    if(!isLoaded) {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(currentZoom);
      changePinStatus(false);
  
    }
  }, []);

  const panToWithoutZoom = React.useCallback(({ lat, lng }) => {
    
    if(!isLoaded) {
      if(mapRef.current) {
        mapRef.current.panTo({ lat, lng });

      }

    }

  }, []);


  const renderDynamicOverlay = markerObject => {
    const { key, lat, lng, fn, iconClass, numberOfVehicles } = markerObject;
    return (
      <OverlayView
        key={key && key}
        position={{ lat: lat, lng: lng }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={(width, height) => ({
          x: -(width / 2),
          y: -(height / 2),
        })}
      >
        <div
          ref={ref => ref && google.maps.OverlayView.preventMapHitsFrom(ref)}
          onClick={fn}
        >
          <RequestOnMapIcon
            iconClass={iconClass}
            iconId={key && key}
            numberOfVehicles={numberOfVehicles}
          />
        </div>
      </OverlayView>
    );
  };

  // Check the map error
  if (loadError) return "Error";
  // handle the pre render
  if (!isLoaded) return "Loading...";

  return (
    <>
      {requestStatus.showDetailed ? null : (
        <SearchComponent panTo={panTo} isReportSearch={false} />
      )}

      <NotificationBar />
      <WrappedMap
        mapContainerStyle={mapContainerStyle}
        currentZoom={currentZoom}
        center={center}
        options={options}
        onMapClick={onMapClick}
        onMapLoad={onMapLoad}
        requestStatus={requestStatus}
        mapTypeToggle={mapTypeToggle}
        isRoadMap={isRoadMap}
        GreenGlogbeIcon={GreenGlogbeIcon}
        GlogbeIcon={GlogbeIcon}
        handleZoom={handleZoom}
        AddIcon={AddIcon}
        MinusIcon={MinusIcon}
        isFullScreen={isFullScreen}
        showFullScreen={showFullScreen}
        hideFullScreen={hideFullScreen}
        FullScreenIcon={FullScreenIcon}
        CloseFullScreen={CloseFullScreen}
        requestsList={requestsList}
        renderDynamicOverlay={renderDynamicOverlay}
        showDetailedRequest={showDetailedRequest}
        updateRequestsList={updateRequestsList}
        mapControl={mapControl}
        concentrationPoints={concentrationPoints}
        showPin={showPin}
        pickedLocation={pickedLocation}
        onPinDrag={onPinDrag}
        google={google}
        requestsList={requestsList}
        directionService={directionService}
        panTo={panToWithoutZoom}
        map={mapRef.current}
        isMapLoaded={isLoaded}
      ></WrappedMap>
    </>
  );
};

export default MainMap;
