import React from 'react'
import ProfilePasswordUpdate from '../Components/Profile/ProfilePassword/ProfilePasswordUpdate';
import ProfileSideBar from '../Components/Profile/ProfileSideBar/ProfileSideBar'

function ProfilePassword() {

    return (
        <div className="data-container profile">
            <div className="data-wrapper">
                <ProfileSideBar />
                <div className="profile-information-contain">
                    <div className="profile-information-wrap">
                        <div className="profile-information-data">
                            <ProfilePasswordUpdate />
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default ProfilePassword
