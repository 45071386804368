import React, { useContext, useEffect, useRef } from "react";
import ArrowGray from "../../assets/icons/icons-arrow-gray.svg";
import closeIcon from "../../assets/icons/icons-close.svg";

import { requestContext } from "../../context/reqeustContext";
import { PinContext } from "../../context/pinContext";
import { ListOnMapContext } from "../../context/listOnMap";
import { itemIdContext } from "../../context/itemIdContext";


function RequestWrapper({ children, floatingRight, closeIconType, hideType , reloading, width, scroll}) {
  const { setRequestStatus } = useContext(requestContext);
  const [, changePinStatus] = useContext(PinContext);
    const [requestsList, renderList, updateRequestsList] = useContext(ListOnMapContext);
    const { setItemId } = useContext(itemIdContext);


  const requestContainerRef = useRef(null);
  useEffect(() => {
    // setTimeout(() => {
    //   requestContainerRef.current.classList.add("open");
    // }, 1);
    requestContainerRef.current.classList.add("open");
  }, []);

  const handleRequestStatus = related => {
    requestContainerRef.current.classList.remove("open");
    setTimeout(() => {
        setRequestStatus(related);
        if (related == "hideAllRequest") {
            setItemId(null);
        }
      changePinStatus(false);
      if(reloading){
        renderList()
      }
    }, 300);
  };
  const stopNestedClicks = e => {
    e.stopPropagation();
  };
  return (
    <div
      className={`request-container ${floatingRight && "floatingRight"} ${width} ${scroll ? 'scroll-container' : ''}`}
      ref={requestContainerRef}
      onClick={stopNestedClicks}
    >
      {closeIconType === "closeX" ? (
        <div
          className="close-request"
          onClick={() => handleRequestStatus(hideType)}
        >
          <img src={closeIcon} alt="icon" />
        </div>
      ) : (
        <div
          className="close-request"
          onClick={() => handleRequestStatus(hideType)}
        >
          <img src={ArrowGray} alt="icon" />
        </div>
      )}
      <div className="request-wrapper">{children}</div>
    </div>
  );
}

export default RequestWrapper;
