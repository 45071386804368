import React, { useContext, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import DashBoardIcon from "../../assets/icons/icons-dashboard.svg";
import TrackIcon from "../../assets/icons/icons-track.svg";
import IconRequest from "../../assets/icons/icons-requests.svg";
import IconReports from "../../assets/icons/icons-reports.svg";


import UserIconWhite from "../../assets/icons/user-icon-white.svg";
import NotificationIcon from "../../assets/icons/notification-icon.svg";
import { modalContext } from "../../context/modalContext.js";
import { langContext } from "../../context/langContext";
import { sideBarContext } from "../../context/sideBarContext";
import { PhrasesContext } from "../../context/PhrasesContext";
import { notificationContext } from "../../context/addNotificationContext";

function SideBar() {
  let { pathname } = useLocation();
  const { modalState, setModalState } = useContext(modalContext);
  const { lang, setLang } = useContext(langContext);
  const [phrases] = useContext(PhrasesContext);
  const { notification } = useContext(notificationContext);
  const [notificationstatus, setnotification] = useState("");

  const { sideBarStatus, setSideBarStatus } = useContext(sideBarContext);
  const showNotifications = () => {
    setModalState("showNotification");
  };
  const handleLang = langText => {
    setLang(langText);
    localStorage.setItem("lang", langText);
  };
  const hanldeSideBarOpen = () => {
    setSideBarStatus("openSubMainSideBar");
  };

  React.useEffect(() => {
    if (notification?.MainRequestId) {
      if (!modalState.showNotification) {
        setnotification("active");
      }
    }
  }, [notification]);

  return (
    <div
      className={`sidebar-contain ignoreOuside-click ${
        sideBarStatus.mainSideBar ? "open" : ""
      }`}
    >
      <div className="sidebar-wrap">
        <div className="sidebar-top">
          <ul>
            <li className={`sidebar-item ${pathname === "/" ? "active" : ""}`}>
              <Link to="/">
                <div className="icon">
                  <img
                    src={TrackIcon}
                    alt="icon"
                    title={phrases?.lblTrackYourRequest?.[lang]}
                  />
                </div>
                <div className="text">
                  <span>{phrases?.lblTrackYourRequest?.[lang]}</span>
                </div>
              </Link>
            </li>
            <li
              className={`sidebar-item ${
                pathname === "/dashboard" ? "active" : ""
              }`}
            >
              <Link to="/dashboard">
                <div className="icon">
                  <img
                    src={DashBoardIcon}
                    alt="icon"
                    title={phrases?.lblDashboard?.[lang]}
                  />
                </div>
                <div className="text">
                  <span>{phrases?.lblDashboard?.[lang]}</span>
                </div>
              </Link>
            </li>

            <li className={`sidebar-item ${pathname === '/requestboard' ? 'active' : ''}`}>
                <Link to="/requestboard">
                    <div className="icon">
                        <img src={IconRequest} alt="icon" title="Requests Board" />
                    </div>
                    <div className="text">
                        <span>{phrases?.lblMyRequestsCC?.[lang]}</span>
                    </div>
                </Link>

            </li>
            <li className={`sidebar-item ${pathname.split('/').includes('report') ? 'active' : ''}`}>
                <Link to="/report">
                    <div className="icon">
                        <img src={IconReports} alt="icon" title="Reports" />
                    </div>
                    <div className="text">
                        <span>{phrases?.lblMyReports?.[lang]}</span>
                    </div>
                </Link>

            </li>
          </ul>
        </div>

        <div className="sidebar-bot">
          <ul>
            <li
              className={`sidebar-item ${
                pathname === "/profile" ||
                pathname === "/password" ||
                pathname === "/manageportal"
                  ? "active"
                  : ""
              }`}
              onClick={hanldeSideBarOpen}
            >
              <Link to="/profile">
                <div className="icon user-icon-wrap">
                  <div className="user-icon">
                    <img src={UserIconWhite} alt="icon" title="User Profile" />
                  </div>
                </div>
                <div className="text">
                  <span>{phrases?.lblUserProfile?.[lang]}</span>
                </div>
              </Link>
            </li>
            <li className="sidebar-item" onClick={showNotifications}>
              <div className="icon">
                <div className={"notification-icon " + notificationstatus}>
                  <img
                    src={NotificationIcon}
                    alt="icon"
                    title={phrases?.lblNotirifcations?.[lang]}
                  />
                </div>
              </div>
              <div className="text">
                <span>{phrases?.lblNotirifcations?.[lang]}</span>
              </div>
            </li>
            {lang === "en" ? (
              <li className="sidebar-item" onClick={() => handleLang("ar")}>
                <div className="icon flag">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="27"
                    viewBox="0 0 12 6"
                  >
                    <rect width="12" height="2" fill="#009E49" id="green" />
                    <rect width="12" height="2" y="2" fill="#fff" />
                    <rect width="12" height="2" y="4" />
                    <rect width="3" height="6" fill="#CE1126" id="red" />
                  </svg>
                  {!sideBarStatus.mainSideBar && (
                    <div className="flag-text">
                      <span>اللغه العربية</span>
                    </div>
                  )}
                </div>
                <div className="text arabic">
                  <span>اللغه العربية</span>
                </div>
              </li>
            ) : (
              <li className="sidebar-item" onClick={() => handleLang("en")}>
                <div className="icon flag">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="27"
                    viewBox="0 0 40 29"
                  >
                    <g fill="none" fill-rule="evenodd">
                      <g fill-rule="nonzero">
                        <g>
                          <g>
                            <g>
                              <path
                                fill="#F0F0F0"
                                d="M0 0.644H40V27.644H0z"
                                transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356)"
                              />
                              <path
                                fill="#D80027"
                                d="M22.5 0.644L17.5 0.644 17.5 11.613 0 11.613 0 16.676 17.5 16.676 17.5 27.644 22.5 27.644 22.5 16.676 40 16.676 40 11.613 22.5 11.613z"
                                transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356)"
                              />
                              <g fill="#0052B4">
                                <path
                                  d="M6.59.029L16 5.306 16 .029zM.052.029L16 8.973 16 6.444 4.562.029zM11.752 8.973L.052 2.411.052 8.973z"
                                  transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356) translate(24 18.644)"
                                />
                              </g>
                              <path
                                fill="#F0F0F0"
                                d="M24 18.644L40 27.644 40 25.099 28.525 18.644z"
                                transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356)"
                              />
                              <path
                                fill="#D80027"
                                d="M24 18.644L40 27.644 40 25.099 28.525 18.644z"
                                transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356)"
                              />
                              <g fill="#0052B4">
                                <path
                                  d="M7.191.029L0 4.062 0 .029zM15.948 1.166L15.948 8.973 2.029 8.973z"
                                  transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356) translate(0 18.644)"
                                />
                              </g>
                              <path
                                fill="#D80027"
                                d="M11.475 18.644L0 25.099 0 27.644 0 27.644 16 18.644z"
                                transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356)"
                              />
                              <g fill="#0052B4">
                                <path
                                  d="M9.41 8.971L0 3.693 0 8.971zM15.948 8.971L0 .027 0 2.556 11.438 8.971zM4.248.027L15.948 6.589 15.948.027z"
                                  transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356) translate(0 .644)"
                                />
                              </g>
                              <path
                                fill="#F0F0F0"
                                d="M16 9.644L0 0.644 0 3.189 11.475 9.644z"
                                transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356)"
                              />
                              <path
                                fill="#D80027"
                                d="M16 9.644L0 0.644 0 3.189 11.475 9.644z"
                                transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356)"
                              />
                              <g fill="#0052B4">
                                <path
                                  d="M8.809 8.971L16 4.938 16 8.971zM.052 7.833L.052.027 13.971.027z"
                                  transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356) translate(24 .644)"
                                />
                              </g>
                              <path
                                fill="#D80027"
                                d="M28.525 9.644L40 3.189 40 0.644 40 0.644 24 9.644z"
                                transform="translate(-1847 -998) translate(1814) translate(0 800) translate(33 198.356)"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>

                  {!sideBarStatus.mainSideBar && (
                    <div className="flag-text">
                      <span>English</span>
                    </div>
                  )}
                </div>
                <div className="text arabic">
                  <span>English</span>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
