import React, { useContext } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import $ from "jquery";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import Search from "../../assets/icons/search.svg";
import IconPin from "../../assets/icons/icons-map-pin.svg";
import Close from "../../assets/icons/icons-close.svg";


import LocationPin from "../../assets/icons/icons-map-pin.svg";
import { requestContext } from "../../context/reqeustContext";
import { PickedLocationContext } from "../../context/pickedLocationContext";
import { sideBarContext } from "../../context/sideBarContext";
import {PhrasesContext} from '../../context/PhrasesContext';
import { langContext } from '../../context/langContext';

const SearchComponent = ({ panTo, isRequestForm , isReportSearch }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 25.2048493, lng: () => 55.2707828 },
      radius: 100 * 1000,
    },
  });

  const [options, setOptions] = React.useState([]);
  const [pickedLocation, setPickedLocation] = useContext(PickedLocationContext);
  const {sideBarStatus} = useContext(sideBarContext)
  const {lang} = useContext(langContext)
  const [phrases] = useContext(PhrasesContext);

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest

  const handleInput = e => {
    console.log("Event Data::" ,e.target.value );
    setValue(e.target.value);
    $(e.target).removeClass("invalid-input");
    $(e.target).closest(".form-col").removeClass("invalid-col");
    setPickedLocation({...pickedLocation , reportLocation:  e.target.value })
  };

  const clearInput =(e)=>{
    e.stopPropagation()
    setValue("");
    clearSuggestions();
  }



  const handleSelect = async address => {
    console.log("Add::", address);
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      if (!isReportSearch) {
        panTo({ lat, lng });
      }
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };

  const { requestStatus } = useContext(requestContext);

  return (
    <>
      <div className={`${isReportSearch ? "search-reports" : "search-contain"}  ${isRequestForm ? "add-request-form" : "map-seardch-form"}  ${requestStatus.showAddRequest || requestStatus.showEditRequest || requestStatus.showDetailed ? 'noSideBar' : ""} ${sideBarStatus.mainSideBar ? 'side-bar-open' : ''}`}>
        <Combobox onSelect={handleSelect}>
          <div>
            <span className={isReportSearch ? "pick-location-icon" : "search-icon"}>
              {isRequestForm ? (
                <img className="input-icon" src={LocationPin} />
              ) : (
                <img className="input-icon" src={isReportSearch ? IconPin :Search } />
              )}
            </span>
            <ComboboxInput
              value={value}
              className="requiredData"
              onChange={handleInput}
              disabled={!ready}
              placeholder={phrases?.lblSearchGoogleMaps?.[lang]}
              
            ></ComboboxInput>
           
             
           
          </div>
          {value == "" ? null : (<span className="clear-icon" onClick={clearInput}>
          
          <img className="input-icon" src={Close} />
          
         </span>)}

          <ComboboxPopover className="search-results">
            <ComboboxList>
              {status === "OK" &&
                data.map(({ id, description }, index) => (
                  <>
                    <ComboboxOption key={index} value={description} />
                  </>
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    </>
  );
};

export default SearchComponent;
