import React, {useContext} from 'react'
import { langContext } from '../../../../../context/langContext'
import { PhrasesContext } from '../../../../../context/PhrasesContext'
import shareICON from "../../../../../assets/icons/share-btn-icon.png";
import { modalContext } from '../../../../../context/modalContext';

function ReportShareBtn() {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    const {setModalState} = useContext(modalContext)
    const showShare = () => {
        setModalState('showReportShareSend')
    }
    return (
        <li className={`site-btn white`} onClick={showShare}>
            <img src={shareICON} alt="icon" />
            <span>{phrases?.btnShare?.[lang]}</span>
        </li>
    )
}

export default ReportShareBtn
