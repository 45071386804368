import React, {useState} from 'react'
import LocationSearchInput from './LocationSearchInput'
import useOnclickOutside from "react-cool-onclickoutside";
import closeGreen from "../../../../assets/icons/close-green.png";
import closeWhite from "../../../../assets/icons/close-white.png";
import IconMapPin from "../../../../assets/icons/icons-map-pin.svg";


function LocationSearch({data, setData}) {
    

    const [googleSuggestions, setGoogleSuggestions] = useState({loading: true, data: [], error: false})
    const [showSuggested, setShowSuggested] = useState(false)
    const [searchText, setSearchText] = useState("")

    //- add new location
    const addLocation = (item) => {
        for (var i = 0; i < data.locations.length; i++) {
            if (data.locations[i].address === item.address) {
                console.log("This location is exist.")
                return
            }
        }
        if(data.locations.length < 3) {
            setData(prevState => ({
                ...data,
                locations: [{...item, radius: 500, selected: false}, ...prevState.locations]
            }))
        }
        setShowSuggested(false)
        setSearchText("")
        
    }


    //- handle selected location
    const handleSelected = (id) => {
        setData({
            ...data,
            locations: data.locations.map(item => item.id === id ? {...item, selected: true} : {...item, selected: false})
        })
       
    }

    //- remove the location
    const removeLabel = (e, id) => {
        
        e.stopPropagation()
        setData({
            ...data,
            locations: data.locations.filter(item => item.id !== id)
        })
    }


    //- hanlde outside click
    const suggestedRef = useOnclickOutside(() => {
        setShowSuggested(false)
    });



    return (
        <div className="multi-search-wrap">
            <div className="multi-search-head">
                <div className="multi-search-labels">
                    {
                        data.locations.length
                        ?   data.locations.map(item => (
                                <p key={item.id} className={`${item.selected ? 'selected' : ''}`} onClick={() => handleSelected(item.id)}>
                                    <img src={item.selected ? closeWhite : closeGreen} onClick={(e) => removeLabel(e, item.id)} alt="icon" />
                                    <span>{item.address.substring(0 , 8)}..</span>
                                    <span>&nbsp; +{item.radius}</span>
                                </p>
                            ))
                        :   null
                    }
                </div>
                <LocationSearchInput searchText={searchText} setSearchText={setSearchText}  setShowSuggested={setShowSuggested} setGoogleSuggestions={setGoogleSuggestions} data={data} />
            </div>
            {
                showSuggested
                ?   <div className="multi-search-body" ref={suggestedRef}>
                        
                        <ul>
                            {
                                googleSuggestions.loading
                                ?   <>
                                        <div className="card-loader data"></div>
                                        <div className="card-loader data"></div>
                                        <div className="card-loader data"></div>
                                    </>
                                :   googleSuggestions.error
                                    ?   <li>Opps! Something wrong!</li>
                                    :   googleSuggestions.data.map((item) => (
                                            <li key={item.id} onClick={() => addLocation(item)}> 
                                                <img src={IconMapPin} alt="" />
                                                <span>{item.address} </span>
                                            </li>
                                        )) 
                            }
                        </ul>
                    </div>
                :   null
            }
            
        </div>
    )
}

export default LocationSearch
