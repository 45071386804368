import React, {useState,  useEffect} from 'react'
import { getReportDetails } from '../../../api/requests_reports';
import ReportsCompareChartsIndex from './components/ReportsCompareCharts/ReportsCompareChartsIndex';
import ReportsCompareHead from './components/ReportsCompareHead';
import ReportsCompareHeadData from './components/ReportsCompareHeadData';

function ReportsCompareIndex({firstId, secondId}) {
    //- report details data
    const [reportDataFirst, setReportDataFirst] = useState({
        totalCount: "",
        report: {},
        loading: true,
        error: false,
    })

    const [reportDataSecond, setReportDataSecond] = useState({
        totalCount: "",
        report: {},
        loading: true,
        error: false,
    })

    useEffect(() => {
        //- fetch first data
        getReportDetails(firstId, 0)
            .then(response => {
                setReportDataFirst({
                    totalCount: response.data.data.totalCount,
                    report: response.data.data.report,
                    mainRequestsCount: response.data.data.mainRequestsCount,
                    loading: false,
                    error: false,
                })
            })
            .catch(error => {
                console.log(error)
                setReportDataFirst({
                    totalCount: "",
                    report: {},
                    mainRequestsCount: "",
                    loading: false,
                    error: true,
                })
            })
        

        //- fetch second data
        getReportDetails(secondId, 0)
            .then(response => {
                setReportDataSecond({
                    totalCount: response.data.data.totalCount,
                    report: response.data.data.report,
                    mainRequestsCount: response.data.data.mainRequestsCount,
                    loading: false,
                    error: false,
                })
            })
            .catch(error => {
                console.log(error)
                setReportDataSecond({
                    totalCount: "",
                    report: {},
                    mainRequestsCount: "",
                    loading: false,
                    error: true,
                })
            })


    }, [])


    //- log the report details
    useEffect(() => {
        if(!reportDataFirst.loading && !reportDataSecond.loading) {
            console.log("Report Details FIRST ==>", reportDataFirst)
            console.log("Report Details Second ==>", reportDataSecond)
        }
    }, [reportDataFirst, reportDataSecond])


    //- separate charts view
    const [chartView, setChartView] = useState('merged')


    return (
        <div className="request-report-data">
            <div className="request-report-head">
                {
                    reportDataFirst.loading || reportDataSecond.loading
                    ?   <div className="card-loader data" style={{ width: "100%", height: "60px", marginBottom: "16px" }} ></div>
                    :   <ReportsCompareHead reportDataFirst={reportDataFirst} reportDataSecond={reportDataSecond} chartView={chartView} setChartView={setChartView} />
                }
            </div>

            <div id="downloaded-view">
                <div className="request-report-body">
                    {
                        reportDataFirst.loading || reportDataSecond.loading
                        ?   <div className="card-loader data" style={{ width: "100%", height: "180px", marginBottom: "16px" }} ></div>
                        :   <ReportsCompareHeadData reportDataFirst={reportDataFirst} reportDataSecond={reportDataSecond} />
                    }
                </div>

                
                <div className="request-report-body" style={{marginTop: '0px'}}>
                    {
                        reportDataFirst.loading || reportDataSecond.loading
                        ?   <div className="card-loader data" style={{ width: "100%", height: "180px", marginBottom: "16px" }} ></div>
                        :   <ReportsCompareChartsIndex chartView={chartView} reportDataFirst={reportDataFirst} reportDataSecond={reportDataSecond} firstId={firstId} secondId={secondId} />
                    }
                </div>
            </div>
        </div>
    )
}

export default ReportsCompareIndex
