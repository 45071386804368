
import React, { Component } from 'react'

export class CustomIfiniteScroll extends Component {
    constructor(props) {
        super(props)
        this.scrolLTargetRef = React.createRef()
        this.state = {
            OneTimeFetch: true,
        }
    }
    
    handleScroll = () => {
        var myElement = this.scrolLTargetRef.current;
        var bounding = myElement.getBoundingClientRect();
        var myElementHeight = myElement.offsetHeight;
        var myElementWidth = myElement.offsetWidth;
        if(this.props.hasMore) {
            
            if (bounding.top >= -myElementHeight 
                && bounding.left >= -myElementWidth
                && bounding.right <= (window.innerWidth || document.documentElement.clientWidth) + myElementWidth
                && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + myElementHeight) 
            {
                if(this.state.OneTimeFetch) {
                    this.props.next()
                }
                this.setState({
                    OneTimeFetch: false
                })
            }
            
        }
    }

    componentDidMount() {
        if(this.props.parentScroll) {
            
            document.getElementById('content-scroll-func').addEventListener('scroll', this.handleScroll)
        } else {
            window.addEventListener('scroll', this.handleScroll)

        }
    }

    componentWillUnmount() {
        if(this.props.parentScroll) {
            document.getElementById('content-scroll-func').removeEventListener('scroll', this.handleScroll)
        } else {
            window.removeEventListener('scroll', this.handleScroll)

        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.dataLength !== this.props.dataLength) {
            this.setState({
                OneTimeFetch: true
            })
        }
    }


    render() {
        return (
            <div className="scroll-content-wrap">
                {this.props.children}
                <div className="scroll-target-element" ref={this.scrolLTargetRef}></div>
            </div>
        )
    }
}


export default CustomIfiniteScroll
