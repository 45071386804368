import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";

import { modalContext } from '../../../../context/modalContext';
import PortalPolicemanViewData from './PortalPolicemanViewData';
import userIconAdd from "../../../../assets/icons/user-create-white.svg";
import dobuleUserIcon from "../../../../assets/icons/double-user-icon-white.svg";
import { useEffect } from 'react';
import { GetPoliceMan } from '../../../../api/profile';
import { userListContext } from '../../../../context/userListContext';
import SearchInput from '../../../../shell/SearchInput';
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';

import ModalContainer from '../../../../Modals/ModalContainer';
import DataSuccessModal from '../../../../Modals/DataSuccessModal';
import { userContext } from '../../../../context/userContext';

function PortalPolicemanView() {
    const history = useHistory();

    const {lang} = useContext(langContext)
    const {userContextDispatch} = useContext(userContext)
    const [phrases] = useContext(PhrasesContext)
    const { setModalState, modalState } = useContext(modalContext);
    const {userStatus} = useContext(userListContext)
    const [clearSearch, setClearSearch] = useState(false);
    const [policeInfoList, setPoliceInfoList] = useState({ loading: true, data: [], error: false, length: 0 });
    const [isPoliceManInfoEmpty, setPoliceManInfoEmpty] = useState(false)
    
    const showAddPoliceman = () => {
        setModalState('showPolicemanAdd')
    }

    //- get all policemen
    useEffect(() => {
        setPoliceInfoList(prevState => ({
            ...prevState,
            loading: true
        }))
        GetPoliceMan()
            .then(response => {
                console.log('Policeman List ==> ', response.data.data)
                setPoliceInfoList({
                    loading: false,
                    data: response.data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
                    error: false,
                    length: response.data.data.length
                })
                setPoliceManInfoEmpty(response.data.data.length === 0 ? true : false)
            }) 
            .catch(error => {
                console.log('Police Man Error ==> ', error)
                setPoliceInfoList({
                    loading: false,
                    data: [],
                    error: true,
                    length: 0
                })
            })
    }, [userStatus])


    //- hanlde clear search
    const hanldeClearSearch = () => {
        setClearSearch(true)
    }

    return (
        <>
       
            {
                isPoliceManInfoEmpty &&
                <div className="general-card-contain">
                    <div className="general-card-wrap">
                        <div className="general-card">
                            <div className="general-card-icon">
                                <img src={dobuleUserIcon} alt="icon" />
                            </div>
                            <div className="general-card-body">
                                <div className="site-btn" onClick={showAddPoliceman}>
                                    <img src={userIconAdd} alt="icon" />
                                    <span>{phrases?.btnAddAPoliceman?.[lang]}</span>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            }

            {
                !isPoliceManInfoEmpty &&
                    <div className="portal-table-contain">
                        <div className="portal-table-wrap">
                            <div className="portal-table-head">
                                <div className="portal-table-head-searchAction">
                                    <div className="portal-title">
                                        {
                                            policeInfoList.loading
                                            ?   <div className="card-loader data" style={{ width: '100%', height: '33px', marginBottom: '0px' }}></div>
                                            :   policeInfoList.error
                                                ?   <p>Opps! something went wrong.</p>
                                                :   <>
                                                        <strong>{phrases?.lblAllPolicemen?.[lang]}</strong>
                                                        <span>
                                                            {`(${policeInfoList.length} ${phrases?.lblPoliceman?.[lang]})`}
                                                        </span>
                                                    </>
                                        }
                                    </div>
                                    <div className="portal-search">
                                        <SearchInput 
                                            setData={setPoliceInfoList}
                                            userType='police'
                                            clearSearch={clearSearch}
                                            setClearSearch={setClearSearch}
                                            placeHolder={phrases?.lblSearchInPolicemen?.[lang]} 
                                       
                                        />
                                    </div>
                                </div>
                                <div className="portal-table-head-addAction">
                                    {
                                        policeInfoList.loading
                                        ?   <div className="card-loader data" style={{ width: '185px', height: '50px', marginBottom: '0px' }}></div>
                                        :   policeInfoList.error
                                            ?   <p>Opps! something went wrong.</p>
                                            :   <>
                                                    <div className="site-btn" onClick={showAddPoliceman}>
                                                        <img src={userIconAdd} alt="icon" />
                                                        <span>{phrases?.btnAddAPoliceman?.[lang]}</span>
                                                    </div>
                                                </>
                                    }
                                    
                                </div>
                            </div>
                            
                            <div className="portal-table-body">
                                <div className="portal-table">
                                    {
                                        policeInfoList.loading
                                        ?   <>
                                                <div className="card-loader data" style={{ width: '100%', height: '60px' }}></div>
                                                <div className="card-loader data" style={{ width: '100%', height: '60px' }}></div>
                                                <div className="card-loader data" style={{ width: '100%', height: '60px' }}></div>
                                                <div className="card-loader data" style={{ width: '100%', height: '60px' }}></div>
                                            </>
                                        :   policeInfoList.error
                                            ?   <p>Opps! something went wrong.</p>
                                            :   policeInfoList.length === 0
                                                ?   <>
                                                        <div className="no-active-request sub table" style={{marginBottom: '96px'}}>
                                                            <div className="no-active-request-icon">
                                                                <img src={dobuleUserIcon} alt="icon" />
                                                            </div>
                                                            <div className="no-active-request-body">
                                                                <strong>{phrases?.lblNoMatchingPolicemen?.[lang]}</strong>
                                                                <p>{phrases?.lblThereWereNoMatchingPolicemen?.[lang]}</p>
                                                                <p className="clear-search-text" onClick={hanldeClearSearch}>{phrases?.lblClearSearchResultsAndDisplayAllPolicemen?.[lang]}</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                :
                                                    <>
                                                        <table className="policeman-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>{phrases?.lblName?.[lang]}</th>
                                                                    <th>{phrases?.lblMilitaryNumber?.[lang]}</th>
                                                                    <th>{phrases?.lblPhoneNo?.[lang]}</th>
                                                                    <th>{phrases?.lblAction?.[lang]}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    policeInfoList.data.map((item) => {return (
                                                                        <PortalPolicemanViewData data={item} key={item.id} />
                                                                    )})
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </>
                                    }
                                    
                                </div>
                            </div>
                    
                        </div>
                    </div>
            }
            {
                modalState.policeManAddedSucces &&
                    <ModalContainer>
                        <DataSuccessModal msg={phrases?.lblPolicemanAddedSuccessfully?.[lang]} />
                    </ModalContainer>
            }

            {
                modalState.policeManUpdatedSucces &&
                    <ModalContainer>
                        <DataSuccessModal msg={phrases?.lblPolicemanUpdatedSuccessfully?.[lang]} />
                    </ModalContainer>
            }
            
        </>
    )
}

export default PortalPolicemanView
