import React, { useContext, useState } from 'react'
import CloseIocn from '../../assets/icons/icon-close-white.svg';
import { itemIdContext } from '../../context/itemIdContext';
import { modalContext } from '../../context/modalContext';
import axios from 'axios'
import { requestContext } from '../../context/reqeustContext';
import { mapControlContext } from "../../context/mapControlContext";
import { langContext } from '../../context/langContext';
import {PhrasesContext} from '../../context/PhrasesContext';
import {CancelRequestById} from '../../api/requests'
import { CCExternalIdContext } from '../../context/ccExternalID';

function CancelRequest() {
    const [ccItemId, setCcItemId] = useContext(CCExternalIdContext)
    const {setModalState} = useContext(modalContext)
    const { setItemId, itemId } = useContext(itemIdContext);
    const {setRequestStatus} = useContext(requestContext)
    const { mapControl , setMapControl } = useContext(mapControlContext);
    const {lang} = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const [isSubmitted, setIsSubmitted] = useState(false)
    const hideModal = () => {
        setModalState('hideModal')
    }
    const stopProb = (e) => {
        e.stopPropagation()
    }

    const handleDeleteRequest = () => {
        if(isSubmitted) {
            return
        }
        setIsSubmitted(true)
        CancelRequestById(itemId).then(res => {
            console.log('===> Deleted Success <===')
            setIsSubmitted(false)
            setModalState('hideModal')
            setRequestStatus('showList')
            setItemId(null);
            setMapControl('refreshRequestList')
        })
        .catch(error => {
            console.log('===> Deleted Failed <===')
            setIsSubmitted(false)
            console.log(error)
            setModalState('hideModal')
            setRequestStatus('showList')
            setItemId(null);
        })
    }
    return (
        <div className="modal-data" onClick={stopProb}>
            <div className="close-modal" onClick={hideModal}>
                <img src={CloseIocn} alt="icon" />
            </div>
            <div className="modal-data-head">
                <strong>{phrases?.lblDeleteRequestConfirmationTitle?.[lang]}</strong>
                <p>{phrases?.lblAreYouSureYouWantToDeleteTheFollowingRequest?.[lang]}</p>
                <div className="request-id">{`${phrases?.lblRequestID?.[lang]} ${ccItemId}`}</div>
            </div>
            <div className="modal-data-foot">
                <div className={`site-btn ${isSubmitted ? 'disabled' : ''}`}  onClick={handleDeleteRequest}>
                    <span>{phrases?.btnDeleteRequest?.[lang]}</span>
                    {
                        isSubmitted
                        ?   <div className="spinner"></div>
                        :   null
                    }
                </div>
            </div>

        </div>
    )
}

export default CancelRequest
