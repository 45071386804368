import React, {useContext, useEffect, useState} from 'react';
import { langContext } from '../../../../../../context/langContext';
import { PhrasesContext } from '../../../../../../context/PhrasesContext';
import { structureTypeChartData } from "../../../../../../Pages/DashboardPage/helpers";

import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
} from "chart.js";


Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
);
  


//- global variables for the chart
const DISPLAY = true;
const BORDER = true;
const CHART_AREA = false;
const TICKS = false;

function ReportsCompareChartsType({ firstChartData= [], secondChartData= [] }) {
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const [portalView, setPortalView] = useState("offset");
    
    // charts data collecting in objects
    const [labelsString, setLabelsString] = useState([])
    const [labelsStringSecond, setLabelsStringSecond] = useState([])
    const [finalLabel, setFinalLabel] = useState([])

    const [impoundData, setImpoundData] = useState([])
    const [offsetData, setOffsetData] = useState([])
    const [dataToShow, setDataToShow] = useState({labels: [], datasets: []})

    const convertDataToLocale = (orignalData) => {
        let { chartLabels: labels, data: { offsetRequests, impoundRequests } = {}, } = structureTypeChartData(orignalData);
        return {
            labels: labels,
            data: { offsetRequests, impoundRequests }
        }
    }


    //- get and organize the data at the first time
    useEffect(() => {
        setLabelsString(convertDataToLocale(firstChartData).labels)
        setLabelsStringSecond(convertDataToLocale(secondChartData).labels)


        //- set impound data
        setImpoundData([
            {
                label: phrases?.lblImpound?.[lang],
                data: convertDataToLocale(firstChartData).data.impoundRequests,
                backgroundColor: "#55bddd",
                borderWidth: 1,
                borderRadius: 13.5,
                barThickness: 27,
                borderSkipped: false,

            },
            {
                label: phrases?.lblImpound?.[lang],
                data: convertDataToLocale(secondChartData).data.impoundRequests,
                backgroundColor: "#65d097",
                borderWidth: 1,
                borderRadius: 13.5,
                barThickness: 27,
                borderSkipped: false,

            },
        ])   

        //- set offset data
        setOffsetData([
            {
                label: phrases?.lblOffset?.[lang],
                data: convertDataToLocale(firstChartData).data.offsetRequests,
                backgroundColor: "#55bddd",
                borderWidth: 1,
                borderRadius: 13.5,
                barThickness: 27,
                borderSkipped: false,

            },
            {
                label: phrases?.lblOffset?.[lang],
                data: convertDataToLocale(secondChartData).data.offsetRequests,
                backgroundColor: "#65d097",
                borderWidth: 1,
                borderRadius: 13.5,
                barThickness: 27,
                borderSkipped: false,

            },
        ])   

    }, [])
    

    useEffect(() => {

        function timePairs(labelsString) {
            const result = []
            for (let i = 0; i < labelsString.length; i++) {
                result.push([labelsString[i]])
            }
            return result
        }

        const result = timePairs(labelsString).map((pair, index) => {
            return [ ...pair, labelsStringSecond[index]].join(' | ')
        });

        setFinalLabel(result)
        if(result.length) {
            setFinalLabel(result)

        } else {
            if(labelsString.length) {
                setFinalLabel(labelsString)
            }
            if(labelsStringSecond.length) {
                setFinalLabel(labelsStringSecond)
            }
        }
    }, [labelsString, labelsStringSecond])

    //- set data show and toggle between impound and offset
    useEffect(() => {
        if(finalLabel.length >= 1 && impoundData.length >= 1 && offsetData.length >= 1) {
            if(portalView === 'offset') {
                setDataToShow({labels: finalLabel, datasets: offsetData})
            } else {
                setDataToShow({labels: finalLabel, datasets: impoundData})
            }
        }
    }, [finalLabel, impoundData, offsetData, portalView])



    //- get canvas ref
    let ctx = null;
    const useCanvas = callback => {
        const canvasRef = React.useRef(null);
        React.useEffect(() => {
            const canvas = canvasRef.current;
            ctx = canvas.getContext("2d");
            callback([canvas, ctx]);
        }, [callback]);

        return canvasRef;
    };


    //- draw canvas
    const Canvas = () => {
        const canvasRef = useCanvas(ctx => {
            new Chart(ctx, 
                {
                    type: "bar",
                    data: dataToShow,
                    options: {
                        responsive: true,
                        aspectRatio: 5,
                        plugins: {
                            title: {
                                display: false,
                            },
                            legend: {
                            display: false,
                        },
                    },
                    scales: {
                        x: {
                            grid: { display: false },
                            display: DISPLAY,
                            drawTicks: TICKS,
                            drawBorder: BORDER,
                            drawOnChartArea: CHART_AREA,
                        },
                        y: {
                            ticks: {
                                stepSize: 1,
                            },
                            beginAtZero: true,
                        },
                    },
                },
            });
        });
    
        return (
            <div style={{ width: "100%" }}>
                <canvas ref={canvasRef} />
            </div>
        );
    };

    return (
        <div className="card compare-chart-card remove-shadow EN_IMP">
            <div className="chart-header AR_IMP hide-on-print-item" id="hide-on-print-two">
                <span>{phrases?.lblServicesChart?.[lang]}</span>
                <div className="site-tabs">
                    <ul>
                        <li className={`${ portalView === "impound" ? "active" : "not-active" } user`} onClick={() => { setPortalView("impound"); }} >
                            {phrases?.lblImpound?.[lang]}
                        </li>
                        <li className={`${ portalView === "offset" ? "active" : "not-active" } policeman`} onClick={() => { setPortalView("offset"); }} >
                            {phrases?.lblOffset?.[lang]}
                        </li>
                    </ul>
                </div>
            </div>
            <Canvas />
        </div>
    )
}

export default React.memo(ReportsCompareChartsType);

