import React, {useState , useEffect} from "react";
import { GetMainRequests } from "../api/requests";


const ListOnMapContext = React.createContext([() => {}]);

const ListOnMapProvider = ({ children }) => {
  const [list, setList] = useState([]);
  const [listWithoutUpdate , setListWithoutUpdate] = useState([]);


  useEffect(() => {
    getAllRequests();
  }, [])

  const getAllRequests = () => {
    GetMainRequests()
        .then(res => {
            const results = res?.data?.data;
            setList(results);
            setListWithoutUpdate(results);
        })
        .catch(err => {
            console.log("Error::", err.message);
        });
}

const updateRequestsList = (result)=>{
    setList(result)
}
  return (
    <ListOnMapContext.Provider value={[list, getAllRequests, updateRequestsList ,listWithoutUpdate]}>
      {children}
    </ListOnMapContext.Provider>
  );
};

export { ListOnMapProvider, ListOnMapContext };
