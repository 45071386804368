import React, { useEffect, useState, useContext } from 'react'
import ReportICON from "../../../../assets/icons/report-btn-icon-white.svg";
import reportIconGray from "../../../../assets/icons/report-btn-icon-gray.svg";
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';
import { modalContext } from '../../../../context/modalContext';
import { RequestListContext } from '../../../../context/RequestListContext';

function CreateReport({}) {
    const [report, setReport, filter, setFilter] = useContext(RequestListContext)
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    const {setModalState} = useContext(modalContext)
    const showReport = () => {
        if(report.data.length === 0) {
            return
        }
        setModalState('showCreateReportRequest')
    }

    const [isResponsve, setResponsive] = useState(false)

    useEffect(() => {
        const dynamicResponsive = (media) => {
            if(media.matches) {
                setResponsive(true)
            }
        }
        let activeMedia = window.matchMedia("(max-width: 1281px)");
        dynamicResponsive(activeMedia)

    }, [])

    return (
        <li className={`site-btn ${report.data.length === 0 ? 'disabled' : ''} ${isResponsve ? 'white' : ''}`} onClick={showReport}>
            <img src={isResponsve ? reportIconGray : ReportICON} alt="icon" />
            <span>{phrases?.btnCreateReport?.[lang]}</span>
        </li>
    )
}

export default CreateReport
