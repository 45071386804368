import React from 'react'
import MapSettings from '../Components/MapSettings'
import MainMap from '../Containers/MainMap'
import RequestContainer from '../Containers/RequestContainer'







function RequestTrackPage({ hideControls }) {
    return (
        <>
            <RequestContainer />

                           
            <MainMap />

            {
                !hideControls &&
                <MapSettings />
            }

        </>
    )
}

export default RequestTrackPage
