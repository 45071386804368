import axiosInstance from '../axios/axiosInstance';

// /Http GET All Main Requests ()
export const GetMainRequests = () => axiosInstance.get(`api/Request/GetMainRequests`);


// /Http GET Request Details
export const GetRequestDetails = (id) => axiosInstance.get(`api/Request/GetRequestDetails?requestId=${id}`);

export const CancelRequestById = (itemId) => axiosInstance.post(`api/request/CancelRequest?requestId=${itemId}`);



// /Http POST Add New Request
export const AddNewRequest = (newRequest) => axiosInstance.post(`api/Request/AddNewRequest`, newRequest);

// http POST edit new request
export const EditNewRequest = (newRequest) => axiosInstance.post(`api/Request/UpdateRequest`, newRequest);


// /Http GET All Requests types ()
export const GetRequestTypes = () => axiosInstance.get(`Lookup/GetRequestTypes`);





// http search for request
export const SearchForRequest = (searchData) => axiosInstance.post(`api/Request/Search`, searchData);



