import React, { useState, useContext } from "react";

const NotificationBarContext = React.createContext([() => {}]);

const NotificationBarProvider = ({ children }) => {
  const [barNonfiction, setBarNonfiction] = useState({
    text: "",
    isOpened: false,
    isGreen: true,
    isVehicleBtn: false,
  });

  const changeBarStatus = newObject => {
    setBarNonfiction(newObject);
  };
  return (
    <NotificationBarContext.Provider value={[barNonfiction, changeBarStatus]}>
      {children}
    </NotificationBarContext.Provider>
  );
};

export { NotificationBarProvider, NotificationBarContext };
