import React, { useState, useContext, useCallback, useEffect } from 'react'
import _get from 'lodash/get';
import fileDownload from 'js-file-download';

import DownLoadIcon from "../../../../../assets/icons/download-icon.png";
import PdfIcon from "../../../../../assets/icons/PDF.svg";
import XlsIcon from "../../../../../assets/icons/XLS.svg";
import CsvIcon from "../../../../../assets/icons/CSV.svg";
import useOnclickOutside from "react-cool-onclickoutside";
import { langContext } from '../../../../../context/langContext';
import { PhrasesContext } from '../../../../../context/PhrasesContext';
import { downloadCsv, downloadExcel, downloadPdf } from '../../../../../api/requests_reports';
import ButtonSpinner from '../../../../ButtonSpinner/ButtonSpinner';

function ReportDownloadBtn({coulmns, data, reportData}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    const [isDropdownOn, setDropDownStatus] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [error, setError] = useState(null);

    const dropDownRef = useOnclickOutside(() => {
        setDropDownStatus(false);
    });

    const showReportsDropdown = () => {
        setDropDownStatus(prevState => !prevState);
    };

    //- columns
    const [columnsLocale, setColumnsLocale] = useState([])
    //- matching columns
    const [matchingColumns, setMatchingColumns] = useState([])

    useEffect(() => {
        setColumnsLocale([
            {
                id: 1,
                relatedColumnName: "ccExternalId",
                name: lang === 'en' ? phrases?.lblRequestID?.['en'] : phrases?.lblRequestID?.['ar'],
                mainColumn: true,
                selected: true,
            },
            {
                id: 2,
                relatedColumnName: "requestId",
                name: lang === 'en' ? phrases?.lblRequestID?.['en'] : phrases?.lblRequestID?.['ar'],
                mainColumn: true,
                selected: true,
            },
            {
                id: 3,
                relatedColumnName: "jobId",
                name: lang === 'en' ? phrases?.lblJobOrderID?.['en'] : phrases?.lblJobOrderID?.['ar'],
                mainColumn: true,
                selected: true,
            },
            {
                id: 4,
                relatedColumnName: "creationTime",
                name: lang === 'en' ? phrases?.lblRequestDate?.['en'] : phrases?.lblRequestDate?.['ar'],
                mainColumn: true,
                selected: true,
            },
            {
                id: 5,
                relatedColumnName: "pickUpAddress",
                name: lang === 'en' ? phrases?.lblPickupLocationCCPAR?.['en'] : phrases?.lblPickupLocationCCPAR?.['ar'],
                mainColumn: true,
                selected: true,
            },
            {
                id: 6,
                relatedColumnName: "statusNameEn",
                name: lang === 'en' ? phrases?.lblStatus?.['en'] : phrases?.lblStatus?.['ar'],
                mainColumn: true,
                selected: true,
            },
            {
                id: 7,
                relatedColumnName: "statusNameAr",
                name: lang === 'en' ? phrases?.lblStatus?.['en'] : phrases?.lblStatus?.['ar'],
                mainColumn: true,
                selected: true,
            },
            {
                id: 8,
                relatedColumnName:  "requestTypeNameEn",
                name: lang === 'en' ? phrases?.lblServiceType?.['en'] : phrases?.lblServiceType?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 9,
                relatedColumnName:  "requestTypeNameAr",
                name: lang === 'en' ? phrases?.lblServiceType?.['en'] : phrases?.lblServiceType?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 10,
                relatedColumnName: "dropOffAddress",
                name: lang === 'en' ? phrases?.lblDropOffLocation?.['en'] : phrases?.lblDropOffLocation?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 11,
                relatedColumnName: "driverName",
                name: lang === 'en' ? phrases?.lblMovedBy?.['en'] : phrases?.lblMovedBy?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 12,
                relatedColumnName: "vehicleVin",
                name: lang === 'en' ? phrases?.lblVINNo?.['en'] : phrases?.lblVINNo?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 13,
                relatedColumnName: "vehiclePlateNo",
                name: lang === 'en' ? phrases?.lblPlateNo?.['en'] : phrases?.lblPlateNo?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 14,
                relatedColumnName: "vehicleMake",
                name: lang === 'en' ? phrases?.lblVehicleMake?.['en'] : phrases?.lblVehicleMake?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 15,
                relatedColumnName: "vehicleModel",
                name: lang === 'en' ? phrases?.lblVehicleModel?.['en'] : phrases?.lblVehicleModel?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 16,
                relatedColumnName: "vehicleColor",
                name: lang === 'en' ? phrases?.lblVehicleColor?.['en'] : phrases?.lblVehicleColor?.['ar'],
                mainColumn: false,
                selected: false,
            },
        ])
    }, [lang, phrases])

    //- merge both columns
    useEffect(() => {
        const matchingColumnsNested = [];

        if(columnsLocale  && coulmns) {
            if(columnsLocale.length !== 0 && coulmns.length !== 0) {
                for (var i = 0; i <= coulmns.length; i++) {
                    if (coulmns[i]) {
                        columnsLocale.find(item => {
                            if(item.relatedColumnName === coulmns[i].name) {
                                matchingColumnsNested.push({name: item.relatedColumnName, title: item.name})
                            }
                        })
                    }
                }
            }
        }
        setMatchingColumns(matchingColumnsNested)
    }, [columnsLocale, coulmns])

    

    const handleDownloadFile = (fileType) => {
        setDownloading(true);
        const fileDownloaders = {
            xls: downloadExcel,
            pdf: downloadPdf,
            csv: downloadCsv,
        }
        const subittedData = {
            requestId: +data.requestId,
            vehiclePlateNo: data.vehiclePlateNo,
            vehicleVin: data.vehicleVin,
            from: data.from,
            to: data.to,
            pageIndex: data.pageIndex,
            locations: data.locations
        }
        fileDownloaders[fileType]({ ...subittedData, columns: matchingColumns })
            .then(fileBlobResponse => {
                const fileBlob = _get(fileBlobResponse, 'data', '');
            
                fileDownload(fileBlob, `${reportData.report.reportName} - ${new Date().toLocaleDateString()}.${fileType}`);
    
                setDownloading(false);
            })
            .catch(error => {
                setError(error);
                setDownloading(false);
            })
        
    }

    return (
        <li className={`site-btn white`} onClick={showReportsDropdown}>
            {downloading ? (<ButtonSpinner />) : (
               <>
                    <img src={DownLoadIcon} alt="icon" />
                    <span>{phrases?.btnDownload?.[lang]}</span>
               </>
           )}
            {
                isDropdownOn
                ?   <div className="reports-dropdown" ref={dropDownRef}>
                        <ul>
                            <li onClick={() => handleDownloadFile('xls')}>
                                <img src={XlsIcon} alt="icon" />
                                <span>Excel</span>
                            </li>
                            <li onClick={() => handleDownloadFile('pdf')}>
                                <img src={PdfIcon} alt="icon" />
                                <span>PDF</span>
                            </li>
                            <li onClick={() => handleDownloadFile('csv')}>
                                <img src={CsvIcon} alt="icon" />
                                <span>CSV</span>
                            </li>
                        </ul>
                    </div>
                :   null
            }
        </li>
    )
}

export default ReportDownloadBtn
