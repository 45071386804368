import React, { useContext, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { modalContext } from '../../context/modalContext'

function ModalContainer({ closeAction, children}) {
    const {setModalState} = useContext(modalContext)
    const modalRef = useRef(null)
    const hideModal = () => {
        setModalState('hideModal')
    }

    useEffect(() => {
        modalRef.current.classList.add('active')
    }, [])

    return ReactDOM.createPortal(
        <div className="modal-container" onClick={closeAction || hideModal}>
            <div className="modal-wrapper" ref={modalRef}>
                {children}
            </div>
        </div>,
        document.getElementById('modal_root')
    )
}

export default ModalContainer
