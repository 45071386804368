import React from "react";
export const requestContext = React.createContext();
export const reqeustInitalState = {
  showList: true,
  showDetailed: false,
  showAddRequest: false,
  showEditRequest: false,
};

export const requestReducer = (state = reqeustInitalState, action) => {
  switch (action) {
    case "showList":
      return {
        showList: true,
        showDetailed: false,
        showAddRequest: false,
        showEditRequest: false,
      };

    case "showDetailed":
      return {
        showList: false,
        showDetailed: true,
        showAddRequest: false,
        showEditRequest: false,
      };

    case "showAddRequest":
      return {
        showList: false,
        showDetailed: false,
        showAddRequest: true,
        showEditRequest: false,
      };

    case "showEditRequest":
      return {
        showList: false,
        showDetailed: false,
        showAddRequest: false,
        showEditRequest: true,
      };

    case "hideAllRequest":
      return {
        showList: false,
        showDetailed: false,
        showAddRequest: false,
        showEditRequest: false,
      };

    default:
      return state;
  }
};
