import React, {useContext, useEffect} from 'react'
import { langContext } from '../../../../../context/langContext'
import { PhrasesContext } from '../../../../../context/PhrasesContext'
import RequestsTypeChart from '../../../../RequestsTypeChart'

function ReportDetailsChartType({reportData, chartData, totalTypeData}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)


    return (
        <>
            <div className="header reportDetailsChartTypeHeader" style={{minHeight: "150px"}}>
                <div className="header-left">
                    {
                        reportData.loading
                        ?   <>
                            </>
                        :   <>
                                <div className="status-div">
                                    <div className="Oval gray">
                                        <div className="Oval-in">{totalTypeData.total}</div>
                                    </div>
                                    <div className="request-labels">
                                        <span>{phrases?.lblTotalServices?.[lang]}</span>
                                        <label className="total">
                                            {phrases?.lblTotalDailyRequests?.[lang]}
                                        </label>
                                        <label className="total">{phrases?.lblAcrossDubai?.[lang]}</label>
                                    </div>
                                </div>
                                <div className="status-div">
                                    <div className="small-Oval">
                                        <div className="Oval-in">{totalTypeData.impound}</div>
                                    </div>

                                    <div className="request-labels">
                                        <span>{phrases?.lblImpoundServices?.[lang]}</span>

                                        <div className="rectangle">
                                            <div className="complete-rectangle" style={{ width: ((totalTypeData.impound / totalTypeData.total) * 100 || 0) + '%' }} />
                                        </div>
                                        <label>
                                            {Math.round((totalTypeData.impound / totalTypeData.total) * 100) || 0}
                                            {phrases?.lblRequestsPercentage?.[lang]}
                                        </label>
                                    </div>
                                </div>
                                <div className="status-div cancel">
                                    <div className="small-Oval green">
                                        <div className="Oval-in">{totalTypeData.offset}</div>
                                    </div>

                                    <div className="request-labels">
                                        <span>{phrases?.lblOffsetServices?.[lang]}</span>

                                        <div className="rectangle">
                                            <div className="complete-rectangle green" style={{ width: ((totalTypeData.offset / totalTypeData.total) * 100) || 0 + '%' }}  />
                                        </div>

                                        <label>
                                            {Math.round((totalTypeData.offset / totalTypeData.total) * 100) || 0}
                                            {phrases?.lblRequestsPercentage?.[lang]}
                                        </label>
                                    </div>
                                </div>
                        
                            </>
                    }
                    
                </div>
                
            </div>
            <RequestsTypeChart chartData={chartData}  />
        </>
    )
}

export default ReportDetailsChartType
