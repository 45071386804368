import React, { useContext, useState } from 'react'
import { useHistory } from "react-router-dom";

import CloseIocn from '../../../../assets/icons/icon-close-white.svg';
import { modalContext } from '../../../../context/modalContext';
import DeleteIcon from "../../../../assets/icons/user-delete-gray.svg";
import CloseBlack from "../../../../assets/icons/icons-close.svg";
import closeRed from "../../../../assets/icons/close-red.svg";

import { userListContext } from '../../../../context/userListContext';
import { DeletePoliceMan } from '../../../../api/profile';
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';
import { userContext } from '../../../../context/userContext';

function PortalPolicemanViewDataDelete() {
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const history = useHistory();
    const {userContextDispatch} = useContext(userContext)

  
    const {setModalState} = useContext(modalContext)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isSumbitError, setIsSubmitError] = useState(false)
    const {userListData, setUserStatus, userStatus} = useContext(userListContext)
    const [apiValidation, setApiValidation] = useState('')

    const hideModal = () => {
        setModalState('hideModal')
    }
    const stopProb = (e) => {
        e.stopPropagation()
    }
    // custom error handling
    const closeErrorSubmit = () => {
        setIsSubmitError(false)
    }

    const deletePoliceMan = () => {
        setIsSubmitted(true)
        DeletePoliceMan({id: userListData.id})
            .then(response => {
                setUserStatus(!userStatus)
                setIsSubmitted(false)
                setModalState('hideModal')
            })
            .catch(error => {
                console.log(error)
                setIsSubmitted(false)
                setIsSubmitError(true)
                setApiValidation(error?.response?.data?.key || 'lblSomethingWrong')

            })
    }
    return (
        <div className="modal-data" onClick={stopProb} style={{paddingTop: '48px', paddingBottom: '48px'}}>
            <div className="close-modal" onClick={hideModal}>
                <img src={CloseIocn} alt="icon" />
            </div>
            {
                isSumbitError
                ?   <div className="note error" style={{marginBottom: '16px'}}>
                        <div className="note-text">
                            <img src={closeRed} alt="" />
                            <span>
                                {
                                    phrases[apiValidation]
                                    ?   phrases[apiValidation][lang]
                                    :   phrases[`lblSomethingWrong`][lang]
                                }
                            </span>
                        </div>
                        <img src={CloseBlack} className="closeNote" onClick={closeErrorSubmit} />
                    </div>
                :   null
            }
            <div className="modal-data-head">
                <img src={DeleteIcon} alt="icon" />
                <strong style={{maxWidth: '317px'}}>{phrases?.lblAreYouSureDeletePoliceman?.[lang]} {userListData.name}</strong>
            </div>
            <div className="modal-data-foot">
                <div className={`site-btn ${isSubmitted ? 'disabled' : ''}`} onClick={deletePoliceMan}>
                    <span>{phrases?.btnDelete?.[lang]}</span>
                    {
                        isSubmitted
                        ?   <div className="spinner"></div>
                        :   null
                    }
                </div>
            </div>
        </div>
    )
}

export default PortalPolicemanViewDataDelete
