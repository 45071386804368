import React from 'react'

import CloseIocn from '../../assets/icons/icon-close-white.svg';

function ModalInfoWrapper({ title, description, icon, closeAction, children }) {
    return (
        <div className="modal-data"  style={{paddingTop: '48px', paddingBottom: '48px'}}>
            <div className="close-modal" onClick={closeAction}>
                <img src={CloseIocn} alt="icon" />
            </div>
        
            <div className="modal-data-head">
                <img src={icon} alt="icon" className="modal-info-wrapper-icon" />

                <strong style={{ maxWidth: '317px' }}>{title}</strong>
                <p>{description}</p>
            </div>

            {children}
        
        </div>
    )
}

export default ModalInfoWrapper
