import React, {useContext} from 'react'
import { langContext } from '../../../../../../context/langContext'
import { PhrasesContext } from '../../../../../../context/PhrasesContext'

function ReportsCompareChartsTypeHead({totalTypeDataFirst, totalTypeDataSecond, reportDataFirst, reportDataSecond}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)

    return (
        <div className="header reportDetailsChartTypeHeader compare-header" style={{minHeight: "150px"}}>
            <div className="compare-header-left">
                <div className="header-left">
                    <div className="status-div full-width">
                        <div className="request-labels">
                            <span>{reportDataFirst.report.reportName}</span>
                            <label className="total">
                                {phrases?.lblNumberofServices?.[lang]} 
                            </label>
                        </div>

                    </div>
                    <div className="status-div">
                        <div className="small-Oval">
                            <div className="Oval-in">{totalTypeDataFirst.impound}</div>
                        </div>

                        <div className="request-labels">
                            <span>{phrases?.lblImpoundServices?.[lang]}</span>

                            <div className="rectangle">
                                <div className="complete-rectangle blue" style={{ width: ((totalTypeDataFirst.impound / totalTypeDataFirst.total) * 100 || 0) + '%' }} />
                            </div>
                            <label>
                                {Math.round((totalTypeDataFirst.impound / totalTypeDataFirst.total) * 100) || 0}
                                {phrases?.lblRequestsPercentage?.[lang]}
                            </label>
                        </div>
                    </div>
                    <div className="status-div cancel">
                        <div className="small-Oval">
                            <div className="Oval-in">{totalTypeDataFirst.offset}</div>
                        </div>

                        <div className="request-labels">
                            <span>{phrases?.lblOffsetServices?.[lang]}</span>

                            <div className="rectangle">
                                <div className="complete-rectangle blue" style={{ width: ((totalTypeDataFirst.offset / totalTypeDataFirst.total) * 100) || 0 + '%' }}  />
                            </div>

                            <label>
                                {Math.round((totalTypeDataFirst.offset / totalTypeDataFirst.total) * 100) || 0}
                                {phrases?.lblRequestsPercentage?.[lang]}
                            </label>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className="compare-header-right">
                <div className="header-left">
                    <div className="status-div full-width">
                        <div className="request-labels">
                            <span>{reportDataSecond.report.reportName}</span>
                            <label className="total">
                                {phrases?.lblNumberofServices?.[lang]}
                            </label>
                        </div>

                    </div>
                    <div className="status-div">
                        <div className="small-Oval">
                            <div className="Oval-in">{totalTypeDataSecond.impound}</div>
                        </div>

                        <div className="request-labels">
                            <span>{phrases?.lblImpoundServices?.[lang]}</span>

                            <div className="rectangle">
                                <div className="complete-rectangle green" style={{ width: ((totalTypeDataSecond.impound / totalTypeDataSecond.total) * 100 || 0) + '%' }} />
                            </div>
                            <label>
                                {Math.round((totalTypeDataSecond.impound / totalTypeDataSecond.total) * 100) || 0}
                                {phrases?.lblRequestsPercentage?.[lang]}
                            </label>
                        </div>
                    </div>
                    <div className="status-div cancel">
                        <div className="small-Oval">
                            <div className="Oval-in">{totalTypeDataSecond.offset}</div>
                        </div>

                        <div className="request-labels">
                            <span>{phrases?.lblOffsetServices?.[lang]}</span>

                            <div className="rectangle">
                                <div className="complete-rectangle green" style={{ width: ((totalTypeDataSecond.offset / totalTypeDataSecond.total) * 100) || 0 + '%' }}  />
                            </div>

                            <label>
                                {Math.round((totalTypeDataSecond.offset / totalTypeDataSecond.total) * 100) || 0}
                                {phrases?.lblRequestsPercentage?.[lang]}
                            </label>
                        </div>
                    </div>
                    
                </div>
            </div>
            
        </div>
    )
}

export default ReportsCompareChartsTypeHead
