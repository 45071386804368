import React, { useContext, useEffect, useRef, useState } from "react";
import SettingIcon from "../../../assets/icons/icons-settings.svg";
import AssignedVehcile from "../../../assets/icons/assigned-vehicle.svg";
import NotAssignedVehcile from "../../../assets/icons/idle-ea-vehicle.svg";
import CompletedSign from "../../../assets/icons/completed-sign.svg";
import ActiveSign from "../../../assets/icons/active-sign.svg";
import cancelledSign from "../../../assets/icons/cancelled-sign.svg";
import InitSign from "../../../assets/icons/intiated-sign.svg";
import centenredPoints from "../../../assets/icons/centenredPoints.svg";




import { mapControlContext } from "../../../context/mapControlContext";
import { NotificationBarContext } from "../../../context/notificationBarContext";
import {langContext} from '../../../context/langContext';
import {PhrasesContext} from '../../../context/PhrasesContext';


function MapSettingData() {
  const { mapControl, setMapControl } = useContext(mapControlContext);
  const [toggleSwitch, setToggleSwitch] = useState(
    mapControl.isVehiclesRendered
  );
  const {lang} = useContext(langContext);
  const [phrases] = useContext(PhrasesContext);
  const [barNonfiction, changeBarStatus] = useContext(NotificationBarContext);
  const handleToggleSwitch = () => {
    setToggleSwitch(state => !state);
    changeBarStatus({
      text: phrases?.lblDisplayCenterPoints?.[lang],
      isGreen: false,
      isVehicle: true,
    });
  };

  useEffect(() => {
    if (toggleSwitch) {
      setMapControl("showVehicles");
    } else {
      setMapControl("hideVehicles");
    }
  }, [toggleSwitch]);

  const wrapperRef = useRef(null);
  const stopClick = event => {
    event.stopPropagation();
  };
  useEffect(() => {
    setTimeout(function () {
      wrapperRef.current.classList.add("open");
    }, 50);
  }, []);

  return (
    <div className="map-setting-wrapper" onClick={stopClick} ref={wrapperRef}>
      <div className="map-setting-head">
        <img src={SettingIcon} alt="icon" />
        <strong>{phrases?.lblMapSettingsLegend?.[lang]}</strong>
      </div>
      <div className="map-setting-body">
        
        <div className="map-setting-item-container">
          <div className="map-setting-item-wrapper">

            <div className="map-setting-item-body">
            <div className="map-setting-item">
                <div className="map-setting-item-icon">
                  <img src={centenredPoints} alt="icon" />
                </div>
                <div className="map-setting-item-text">
                  <div className="hide-ea-vehicles">
                    <strong>{phrases?.lblCenterPoints?.[lang]}</strong>
                    <div
                      className={`custom-switch-wrap ${toggleSwitch ? "active" : ""}`}
                      onClick={handleToggleSwitch}
                    >
                      <div className="custom-switch-circle"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="map-setting-item">
                <div className="map-setting-item-icon">
                  <img src={AssignedVehcile} alt="icon" />
                </div>
                <div className="map-setting-item-text">
                  <strong>{phrases?.lblAssigned?.[lang]}</strong>
                </div>
              </div>
              <div className="map-setting-item">
                <div className="map-setting-item-icon">
                  <img src={InitSign} alt="icon" />
                </div>
                <div className="map-setting-item-text">
                <strong>{phrases?.lblInitiatedRequests?.[lang]}</strong>
                </div>
              </div>
              <div className="map-setting-item">
                <div className="map-setting-item-icon">
                  <img src={ActiveSign} alt="icon" />
                </div>
                <div className="map-setting-item-text">
                  <strong>{phrases?.lblActiveRequests?.[lang]}</strong>
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </div>
    </div>
  );
}

export default MapSettingData;
