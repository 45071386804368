import React from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: "#2ebb55",
    },
    secondary: {
      main: "#222b45",
    },
  },
});

const Theme = props => {
  const { children } = props;
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export const withTheme = Component => {
  return props => {
    return (
      <Theme>
        <Component {...props} />
      </Theme>
    );
  };
};
