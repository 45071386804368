import React, { useEffect, useState, useContext } from 'react'
import RequestReportBody from './components/RequestReportBody'
import RequestReportHead from './components/RequestReportHead'
import { getReports } from '../../api/requests_reports';
import { langContext } from '../../context/langContext';
import { PhrasesContext } from '../../context/PhrasesContext';
import { modalContext } from '../../context/modalContext';
import ModalContainer from '../../Modals/ModalContainer';
import ShareModal from './components/ShareModal';
import CreateReportRequestModa from './components/CreateReportRequestModa';
import { RequestListContext } from '../../context/RequestListContext';
import moment from "moment";

function RequestReportPageWrapper() {
    const {modalState} = useContext(modalContext)
    const [report, setReport, filter, setFilter] = useContext(RequestListContext)
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)

    

    useEffect(() => {
        // - reformating object to be send to API
        const subittedData = {
            requestId: +filter.requestId,
            vehiclePlateNo: filter.vehiclePlateNo,
            vehicleVin: filter.vehicleVin,
            from: filter.from,
            to: filter.to,
            pageIndex: 0,
            locations: filter.locations
        }


        //- API handle
        getReports(subittedData)
            .then(response => {
                console.log("FIRST TIME ONLY IN IT =>", response.data)
                setReport({
                    loading: false,
                    data: response.data.data.requests,
                    totalCount: response.data.data.totalCount,
                    subRequestsCount: response.data.data.subRequestsCount,
                    mainRequestsCount: response.data.data.mainRequestsCount,
                    error: false
                })
            })
            .catch(error => {
                console.log("Request Report Error ===>", error)
            })
            
        return () => {
            setFilter({
                requestId: "",
                vehiclePlateNo: "",
                vehicleVin: "",
                locations: [],
                pageIndex: 1,
                from: moment(moment().subtract(1, "days")).startOf("day").format(),
                to: moment(moment().subtract(1, "days")).endOf("day").format(),
            })
        }
    }, [])

    //- coulmns
    const [coulmns, setCoulmns] = useState([])
    useEffect(() => {
        setCoulmns([
            {
                id: 1,
                relatedColumnName: "ccExternalId",
                name: lang === 'en' ? phrases?.lblRequestID?.['en'] : phrases?.lblRequestID?.['ar'],
                mainColumn: true,
                selected: true,
            },
            {
                id: 2,
                relatedColumnName: "jobId",
                name: lang === 'en' ? phrases?.lblJobOrderID?.['en'] : phrases?.lblJobOrderID?.['ar'],
                mainColumn: true,
                selected: true,
            },
            {
                id: 3,
                relatedColumnName: "creationTime",
                name: lang === 'en' ? phrases?.lblRequestDate?.['en'] : phrases?.lblRequestDate?.['ar'],
                mainColumn: true,
                selected: true,
            },
            {
                id: 4,
                relatedColumnName: "pickUpAddress",
                name: lang === 'en' ? phrases?.lblPickupLocationCCPAR?.['en'] : phrases?.lblPickupLocationCCPAR?.['ar'],
                mainColumn: true,
                selected: true,
            },
            {
                id: 5,
                relatedColumnName: lang === 'en' ? "statusNameEn" : "statusNameAr",
                name: lang === 'en' ? phrases?.lblStatus?.['en'] : phrases?.lblStatus?.['ar'],
                mainColumn: true,
                selected: true,
            },
            {
                id: 6,
                relatedColumnName:  lang === 'en' ? "requestTypeNameEn" : "requestTypeNameAr",
                name: lang === 'en' ? phrases?.lblServiceType?.['en'] : phrases?.lblServiceType?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 7,
                relatedColumnName: "dropOffAddress",
                name: lang === 'en' ? phrases?.lblDropOffLocation?.['en'] : phrases?.lblDropOffLocation?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 8,
                relatedColumnName: "driverName",
                name: lang === 'en' ? phrases?.lblMovedBy?.['en'] : phrases?.lblMovedBy?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 9,
                relatedColumnName: "vehicleVin",
                name: lang === 'en' ? phrases?.lblVINNo?.['en'] : phrases?.lblVINNo?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 10,
                relatedColumnName: "vehiclePlateNo",
                name: lang === 'en' ? phrases?.lblPlateNo?.['en'] : phrases?.lblPlateNo?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 11,
                relatedColumnName: "vehicleMake",
                name: lang === 'en' ? phrases?.lblVehicleMake?.['en'] : phrases?.lblVehicleMake?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 12,
                relatedColumnName: "vehicleModel",
                name: lang === 'en' ? phrases?.lblVehicleModel?.['en'] : phrases?.lblVehicleModel?.['ar'],
                mainColumn: false,
                selected: false,
            },
            {
                id: 13,
                relatedColumnName: "vehicleColor",
                name: lang === 'en' ? phrases?.lblVehicleColor?.['en'] : phrases?.lblVehicleColor?.['ar'],
                mainColumn: false,
                selected: false,
            },
        ])
    }, [lang, phrases])

    
    return (
        <div className="request-report-wrap">
            <div className="request-report-data">
                <RequestReportHead data={filter} setData={setFilter} coulmns={coulmns} />

                <RequestReportBody data={filter} setData={setFilter}  coulmns={coulmns} setCoulmns={setCoulmns} />
            </div>
            
            {
                modalState.showShareSend &&
                    <ModalContainer>
                        <ShareModal filter={filter} coulmns={coulmns} />
                    </ModalContainer>
            }

            {
                modalState.showCreateReportRequest &&
                    <ModalContainer>
                        <CreateReportRequestModa filter={filter} coulmns={coulmns} setCoulmns={setCoulmns} />
                    </ModalContainer>
            }
            
        </div>
    )
}
export default RequestReportPageWrapper
