import React, { useState, useContext } from 'react'
import PortalPolicemanView from '../Components/Profile/ProfilePortal/ProfilePortalPoliceman/PortalPolicemanView'
import PortalUserView from '../Components/Profile/ProfilePortal/ProfilePortalUser/PortalUserView'
import ProfileSideBar from '../Components/Profile/ProfileSideBar/ProfileSideBar'
import { langContext } from '../context/langContext';
import { PhrasesContext } from '../context/PhrasesContext';

function ManagePortal() {
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const [portalView, setPortalView] = useState('user')
    const handleProtalView = (view) => {
        setPortalView(view)
    }

    return (
        <div className="data-container profile">
            <div className="data-wrapper">
                <ProfileSideBar />
                <div className="portal-contain">
                    <div className="portal-wrap">
                        <div className="portal-head">
                            <div className="site-tabs">
                                <ul>
                                    <li 
                                        className={`${portalView === 'user' ? 'active' : 'not-active'} user`} 
                                        onClick={() => {handleProtalView('user')}} 
                                    >
                                        {phrases?.lblUser?.[lang]}
                                    </li>
                                    <li 
                                        className={`${portalView === 'policeman' ? 'active' : 'not-active'} policeman`} 
                                        onClick={() => {handleProtalView('policeman')}} 
                                    >
                                        {phrases?.lblPoliceman?.[lang]}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="portal-body">
                            {
                                portalView === 'user'
                                ?   <PortalUserView />
                                :   <PortalPolicemanView />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManagePortal

