import React, {useContext, useState} from 'react'
import { langContext } from '../../../context/langContext';
import { PhrasesContext } from '../../../context/PhrasesContext';
import ButtonSpinner from '../../ButtonSpinner/ButtonSpinner';
import DownLoadIcon from "../../../assets/icons/download-icon.png";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import $ from "jquery";

function ReportDownloadShell({reportData}) {

    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    const [printing, setPrinting] = useState(false);

    const handleDownload = () => {
        setPrinting(true)

        // document.getElementById('hide-on-print').style.display = 'none'
        // document.getElementById('hide-on-print-two').style.display = 'none'
        $('.remove-shadow').addClass('removed')
        $('.hide-on-print-item').hide()
        const chart = document.getElementById("downloaded-view");
        
        
        html2canvas(chart, { useCORS: true }).then((canvas) => {
            var dataUrl = canvas.toDataURL("image/png");

            var doc = new jsPDF("p", "mm", "a4");

            var width = doc.internal.pageSize.getWidth();
            var height = doc.internal.pageSize.getHeight();

            doc.addImage(dataUrl, "png", 0, 0, width, height);
            // doc.save("chart.pdf");
            doc.save(`${reportData.report.reportName} - ${new Date().toLocaleDateString()}.pdf`)
            
            setPrinting(false)
            // document.getElementById('hide-on-print').style.display = 'flex'
            // document.getElementById('hide-on-print-two').style.display = 'flex'
            $('.remove-shadow').removeClass('removed')
            $('.hide-on-print-item').css('display', 'flex')



        });
    }
    return (
        <li className={`site-btn white`} onClick={handleDownload}>
            {printing ? (<ButtonSpinner />) : (
                <>
                    <img src={DownLoadIcon} alt="icon" />
                    <span>{phrases?.btnDownload?.[lang]}</span>
                </>
            )}
        </li>
    )
}

export default ReportDownloadShell
