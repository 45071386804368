import React, { useState , useContext} from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import "../../assets/style/modules/calendarPicker/index.scss";
import {PhrasesContext} from '../../context/PhrasesContext';
import {langContext} from '../../context/langContext';

function NewCalendarPicker({dateValue, setDateValue, setDateSelected, setViewCalendar}) {
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div 
                role="tabpanel" 
                hidden={value !== index} 
                id={`full-width-tabpanel-${index}`} 
                aria-labelledby={`full-width-tab-${index}`} 
                {...other} 
            >
                {
                    value === index && (
                        <Typography>{children}</Typography>
                    )
                }
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`,
        };
    };

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    const useStyles = makeStyles(() => ({
        tab: {
            minWidth: 100,
            outline: 0,
            border: 'none',
            fontSize: '16px',
            fontFamily: 'Roboto',
            color: '#4f566b',
            fontWeight: 'normal',
            '&.Mui-selected': {
                outline: 'none',
                fontWeight: 'bold',
                color: '#4f566b',
            }

        },
    }));

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const theme = useTheme();
    const classes = useStyles();
    const [value, setValue] = useState(0);


    

    return (<div className={`tabs-requests-container`}>
        <div className="tabs">
            <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" >
                <Tab classes={{ root: classes.tab }} label={phrases?.lblDays?.[lang]} {...a11yProps(0)} />
                <Tab classes={{ root: classes.tab }} label={phrases?.lblMonths?.[lang]} {...a11yProps(1)} />
                <Tab classes={{ root: classes.tab }} label={phrases?.lblYears?.[lang]}  {...a11yProps(2)} />
            </Tabs>
        </div>
        <SwipeableViews  axis={lang === 'ar' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex} >
            <TabPanel value={value} index={0} dir={lang == "en" ? "ltr" :"rtl"}>
                <Calendar
                    className="class1"
                    value={dateValue}
                    onChange={(e) => { setDateValue(e); setDateSelected(true); setViewCalendar(false)}}
                    selectRange={true}
                    view="month"
                    calendarType= {lang == 'ar' ? "Arabic" : "US"}
                    locale= {lang == 'ar' ? "ar" : "en"}
                />
            </TabPanel>
            <TabPanel value={value} index={1} dir={lang == "en" ? "ltr" :"rtl"}>
                <Calendar
                    className="class1"
                    onChange={(e) => { setDateValue(e); setDateSelected(true); setViewCalendar(false) }}
                    value={dateValue}
                    maxDetail="year"
                    selectRange={true}
                    calendarType= {lang == 'ar' ? "Arabic" : "US"}
                    locale= {lang == 'ar' ? "ar" : "en"}
                />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
                <Calendar
                    className="class1"
                    value={dateValue}
                    onChange={(e) => { setDateValue(e); setDateSelected(true); setViewCalendar(false)  }}
                    selectRange={true}
                    maxDetail="decade"
                    calendarType= {lang == 'ar' ? "Arabic" : "US"}
                    locale= {lang == 'ar' ? "ar" : "en"}
                    
                />
            </TabPanel>
        </SwipeableViews>
    </div>)
}

export default NewCalendarPicker
