import React, { useEffect, useState } from 'react';
import { GetLocalization } from '../api/phrases';
import { normalize } from '../Utils/utils';


const PhrasesContext = React.createContext([{}, () => {}]);

const PhrasesProvider = ({ children }) => {
	const [phrases, setPhrases] = useState({});

	useEffect(() => {
		GetLocalization().then((res) => {
		
			if (res?.data?.data?.length > 0) {
				setPhrases( normalize(res?.data?.data , 'key'));
			}
		});
	}, []);

	return (
		<PhrasesContext.Provider value={[phrases, setPhrases]}>
		
			{children}
		</PhrasesContext.Provider>
	);
};

export { PhrasesProvider, PhrasesContext };
