import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";

import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';
import { userListContext } from '../../../../context/userListContext';
import { modalContext } from '../../../../context/modalContext';
import RoleSelect from './RoleSelect';
import MilitaryNumberInput from '../../../../shell/MilitaryNumberInput';
import FullNameInput from '../../../../shell/FullNameInput';
import EmailInput from '../../../../shell/EmailInput';
import CloseIocn from '../../../../assets/icons/icon-close-white.svg';
import userIconAdd from "../../../../assets/icons/user-create-white.svg";
import CloseBlack from "../../../../assets/icons/icons-close.svg";
import closeRed from "../../../../assets/icons/close-red.svg";
import $ from "jquery";
import { AddNewUser } from '../../../../api/profile';
import PhoneHandleWrap from '../../../../shell/PhoneHandleWrap/PhoneHandleWrap';
import UsernameInput from '../../../../shell/UsernameInput';
import { userContext } from '../../../../context/userContext';


//- Validation
function validateForm(relatedForm) {
    var amountInput = $(relatedForm).find(".requiredData");
    var filledInputs = [];
        amountInput.each(function () {
        filledInputs.push(this);
    });

    var noValFilter = filledInputs.filter(function (item) {
        return $(item).val() === "";
    });

    $(noValFilter).each(function () {
        $(this).closest(".form-col").addClass("invalid-col");
        $(this).addClass("invalid-input");
        $(this).closest(".form-col").find(".error-msg").show();
    });

    if (noValFilter.length === 0) {
        return true;
    }
}

function PortalUserViewDataAdd() {
    const history = useHistory();
    const {userContextDispatch} = useContext(userContext)

    const {lang} = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const {setModalState} = useContext(modalContext)
    const {setUserStatus, userStatus} = useContext(userListContext)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isSumbitError, setIsSubmitError] = useState(false)
    const [toggleSwitch, setToggleSwitch] = useState(true);


    // User object
    const [userData, setUserData] = useState({
        name: "",
        userName: "",
        email: "",
        phone: "",
        militaryNumber: "",
        isAdmin: false,
        isActive: true,
    })

    useEffect(() => {
        console.log('User Data ===>', userData)
    }, [userData])

    // User validation
    const [apiValidation, setApiValidation] = useState('')
        

    const handleToggleSwitch = (activeStatus) => {
        setToggleSwitch(state => !state);
        setUserData({...userData, isActive: activeStatus})
    };

    const hideModal = () => {
        setModalState('hideModal')
    }
    const stopProb = (e) => {
        e.stopPropagation()
    }


    // custom error handling
    const closeErrorSubmit = () => {
        setIsSubmitError(false)
    }
    // handle submit
    const handleFormSubmit = (e) => {
        let elem = $(e.target);
        let isValidateToSubmit = true;
        var emailValid = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        
        // custom validation when submit
        if(
            userData.name.length < 3 || userData.name.length > 300 ||
            userData.phone.length < 10 ||
            userData.militaryNumber.length < 2 ||
            !userData.email.match(emailValid) || userData.userName.length < 3 || userData.userName.length > 30
        ) {
            isValidateToSubmit = false
        }

        if (!validateForm(elem)) {
            isValidateToSubmit = false;
        }

        if(isValidateToSubmit) {
            console.log("Validate Status ===> SUCCESS")
            setIsSubmitted(true)
            let sendingObj = {
                name: userData.name,
                userName: userData.userName,
                email: userData.email,
                phone: userData.phone,
                militaryNumber: +userData.militaryNumber,
                isAdmin: userData.isAdmin,
                isActive: userData.isActive,
            }
            
            AddNewUser(sendingObj)
                .then(response => {
                    setUserStatus(!userStatus)
                    setIsSubmitted(false)
                    setModalState('hideModal')
                    setModalState('showUserAddedSucces')
                })
                .catch(error => {
                    setIsSubmitted(false)
                    setIsSubmitError(true)
                    setApiValidation(error?.response?.data?.key || 'lblSomethingWrong')
                })
        }

        // custom validation when submit
        e.preventDefault();
    }

    return (
        <div className="modal-data protal-modal" onClick={stopProb}>
            <div className="close-modal" onClick={hideModal}>
                <img src={CloseIocn} alt="icon" />
            </div>

            <div className="profile-card">
                <div className="profile-card-head">
                    <div className="user-title-wrap">
                        <div className="user-icon">
                            <img src={userIconAdd} alt="icon" style={{width: '24px'}}/>
                        </div>
                        <strong>{phrases?.lblCreateANewUser?.[lang]}</strong>
                    </div>
                </div>
                {
                    isSumbitError
                    ?   <div className="note error">
                            <div className="note-text">
                                <img src={closeRed} alt="" />
                                <span>
                                    {
                                        phrases[apiValidation]
                                        ?   phrases[apiValidation][lang]
                                        :   phrases[`lblSomethingWrong`][lang]
                                    }
                                </span>
                            </div>
                            <img src={CloseBlack} className="closeNote" onClick={closeErrorSubmit} />
                        </div>
                    :   null
                }
                
                
                <div className={`profile-card-body ${isSumbitError  ? 'has-error' : ''}`}>
                    <form className="request-form" id="user-create" onSubmit={handleFormSubmit}>
                        <div className="form-row">
                            <div className="form-col">
                                <strong>{phrases?.lblName?.[lang]}</strong>
                                <FullNameInput 
                                    data={userData} 
                                    setData={setUserData} 
                                    name="name"
                                    placeHolder={phrases?.lblEnterFullName?.[lang]}
                                    setIsSubmitError={setIsSubmitError}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                               <strong>{phrases?.lblUsername?.[lang]}</strong>
                               
                                <UsernameInput 
                                    data={userData} 
                                    setData={setUserData} 
                                    name="userName"
                                    placeHolder={phrases?.lblEnterYourUsername?.[lang]}
                                    setIsSubmitError={setIsSubmitError}
                                
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                <strong>{phrases?.lblEmailAddress?.[lang]}</strong>
                                <EmailInput 
                                    data={userData} 
                                    setData={setUserData} 
                                    name="email"
                                    placeHolder={phrases?.lblEnterYourEmail?.[lang]}
                                    setIsSubmitError={setIsSubmitError}
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                <strong>{phrases?.lblMilitaryNumber?.[lang]}</strong>
                                <MilitaryNumberInput 
                                    data={userData} 
                                    setData={setUserData} 
                                    name="militaryNumber" 
                                    placeHolder={phrases?.lblEnterMilitaryNumber?.[lang]}
                                    setIsSubmitError={setIsSubmitError}

                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                <strong>{phrases?.lblPhone?.[lang]}</strong>
                                <PhoneHandleWrap
                                    data={userData}
                                    setData={setUserData}
                                    name="phone"
                                    placeHolder={phrases?.lblEnterYourPhoneNumber?.[lang]}
                                    setIsSubmitError={setIsSubmitError}
                                />
                                
                            </div>
                        </div>
                        
                        
                        <div className="form-row">
                            <div className="form-col">
                                <strong>{phrases?.lblRole?.[lang]}</strong>
                            </div>

                            <RoleSelect data={userData} setData={setUserData} />


                        </div>

                        <div className="form-row">
                            <div className="form-col">
                                <strong>{phrases?.lblStatus?.[lang]}</strong>
                                {
                                    toggleSwitch
                                    ?   <div className={`table-status active user-form-status`}>
                                            <span>{phrases?.lblActive?.[lang]}</span>
                                            <div className={`custom-switch-wrap active`} onClick={() => handleToggleSwitch(false)}>
                                                <div className="custom-switch-circle"></div>
                                            </div>
                                        </div>
                                    :   <div className={`table-status deactive user-form-status`}>
                                            <span>{phrases?.lblDeactivated?.[lang]}</span>
                                            <div className={`custom-switch-wrap deactive`} onClick={() => handleToggleSwitch(true)}>
                                                <div className="custom-switch-circle"></div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>

                        <div className="request-form-action">
                            <div className="form-aciton">
                                <button form="user-create" type="submit" className="site-btn" disabled={`${isSubmitted  ? "disabled" : ""}`}>
                                    <span>{phrases?.btnCreate?.[lang]}</span>
                                    {
                                        isSubmitted
                                        ?   <div className="spinner"></div>
                                        :   null
                                    }
                                    
                                </button>
                                <p onClick={hideModal}>{phrases?.lblCancelAndGoBackToUsersList?.[lang]}</p>
                            </div>
                        </div>
                        
                    </form>
                </div>
            </div>  
        </div>
    )
}

export default PortalUserViewDataAdd
