import React from 'react'

function PhoneHandleCountryCodeList({data, setData, refBody, refText }) {
    const handleClick = (value) => {
        refBody.current.style.display = 'none'
        refText.current.innerHTML = value
        setData(value)
    }
    return (
        <>
            <li onClick={() => handleClick(data.value)}>
                <span>{data.value}</span>
            </li>
        </>
    )
}

export default PhoneHandleCountryCodeList
