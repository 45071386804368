import React, {useContext} from 'react'
import CloseIocn from '../../assets/icons/icon-close-white.svg';
import Tick from "../../assets/icons/tick-white.svg";
import { modalContext } from '../../context/modalContext';


function DataSuccessModal({msg}) {
    const {setModalState} = useContext(modalContext)
    const stopProb = (e) => {
        e.stopPropagation()
    }

    const hideModal = () => {
        setModalState('hideModal')
    }
    

    return (
        <div className="modal-data" onClick={stopProb} style={{paddingTop: '48px', paddingBottom: '48px'}}>
            <div className="close-modal" onClick={hideModal}>
                <img src={CloseIocn} alt="icon" />
            </div>
        
            <div className="modal-data-head">
                <img src={Tick} alt="icon" />
                <strong style={{maxWidth: '317px'}}>{msg}</strong>
            </div>
        
        </div>
    )
}

export default DataSuccessModal
