
import * as moment from 'moment';

const checkDataAndExecute = (data, fn) => {
    data = data || [];

    return fn(data);
}

const approximateHour = d => {
    const date = moment(d);
    const minutes = date.minutes();
    const hour = date.hour();

    if (minutes >= 30) return date.hour(hour + 1).minute(0);
    return date.minute(0);
}

const getDifferenceBetweenDates = ds => {
    const first = moment(ds[0]).hour(0);
    const last = moment(ds[ds.length - 1]).hour(24);
    const difference = last.diff(first, 'days');

    return difference;
}

const formateDate = d => type => {
    const formats = {
        within_day: 'HH:mm',
        within_week: 'HH:mm ddd',
        within_six_months: 'DD/MMM',
        general: 'MMM/YYYY'
    }

    return moment(d).format(formats[type] || formats.general);
}

const dataIsInSingleDay = (data = []) => {
    if (!data || (data && !data.length)) return;

    const sampleDay = moment(data[0].date).day();

    return data.every(({ date }) => moment(date).day() === sampleDay)
}

const dataIsInSameWeek = (data = []) => {
    if (!data || (data && !data.length)) return;
   
    return getDifferenceBetweenDates(data.map(({ date }) => date)) <= 7;
}

const dataIsInSixMonthsRange= (data = []) => {
    if (!data || (data && !data.length)) return;

    return getDifferenceBetweenDates(data.map(({ date }) => date)) <= 180;
}

const generateConditionalDates = (data = []) =>
    formate => data.map(({ date }) => formateDate(approximateHour(date))(formate));

const generateChartLabels = (data = []) => {
    if (dataIsInSingleDay(data)) {
        return generateConditionalDates(data)('within_day');
    } else if (dataIsInSameWeek(data)) {
        return generateConditionalDates(data)('within_week');
    } else if (dataIsInSixMonthsRange(data)) {
        return generateConditionalDates(data)('within_six_months');
    } else {
        return generateConditionalDates(data)('general');
    }
}


const structureStatisticsBarData = (rawData = []) => checkDataAndExecute(rawData, data => ({
    totalAmount: data.reduce((acc, { total }) => acc + total, 0),
    completedAmount: data.reduce((acc, { completed }) => acc + completed, 0),
    canceledAmount: data.reduce((acc, { canceled }) => acc + canceled, 0),
}))

const structureStatusChartData = (rawData = []) => checkDataAndExecute(rawData, data => ({
    chartLabels: generateChartLabels(data),
    data: {
        totalRequests: data.map(({ total }) => total),
        completedRequests: data.map(({ completed }) => completed),
        cancelledRequests: data.map(({ canceled }) => canceled),
    }
}))

const structureTypeChartData = (rawData = []) => checkDataAndExecute(rawData, data => ({
    chartLabels: generateChartLabels(data),
    data: {
        offsetRequests: data.map(({ offset }) => offset),
        impoundRequests: data.map(({ impound }) => impound),
    }
}))

export {
    dataIsInSingleDay,
    generateChartLabels,
    structureStatisticsBarData,
    structureStatusChartData,
    structureTypeChartData
};