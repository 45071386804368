import React, { useEffect, useReducer, useRef, useState } from "react";
import { GoogleMap, useLoadScript, HeatmapLayer } from "@react-google-maps/api";
import _get from "lodash/get";
import mapStyles from "../../mapStyles";
import { getRequestsHeatMap } from "../../api/dashboard";
// Array of all the used APIs with Google Maps
const libraries = ["places", "visualization"];

let google = window.google;
// Map Dimensions
const mapContainerStyle = {
  height: "573px",
  width: "100%",
  borderRadius: "30px",
};

// Map Options
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
};

const gradient = [
  "rgba(172,215,238,0)",
  "rgba(172,215,238,1)",
  "rgba(161,202,227,1)",
  "rgba(150,190,217,1)",
  "rgba(139,177,206,1)",
  "rgba(129,164,196,1)",
  "rgba(118,151,185,1)",
  "rgba(107,139,174,1)",
  "rgba(96,126,164,1)",
  "rgba(85,113,153,1)",
  "rgba(75,100,143,1)",
  "rgba(64,88,132,1)",
  "rgba(53,75,122,1)",
  "rgba(42,62,111,1)",
];

const onLoad = heatmapLayer => {
  console.log("HeatmapLayer onLoad heatmapLayer: ", heatmapLayer);
  console.log("HeatmapLayer DATA: ", heatmapLayer.get("gradient"));
};

const onUnmount = heatmapLayer => {
  console.log("HeatmapLayer onUnmount heatmapLayer: ", heatmapLayer);
};

// Map Starting Center Point
const center = { lat: 25.163625978982154, lng: 55.283261023070494 };

function PlainMap({ filterValue, isReport }) {
  // Add map configurations
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    libraries,
  });

  const [{ heatMapList, loading, error }, dispatch] = useReducer(
    (base, next) => ({ ...base, ...next }),
    {
      heatMapList: null,
      loading: false,
      error: null,
    }
  );
    
  useEffect(() => {
    if(!isReport) {
      (async function getHeatMapList() {
        dispatch({ loading: true });
  
        try {
          const heatMapResponse = await getRequestsHeatMap({
            from: filterValue[0],
            to: filterValue[1],
          });
  
          const heatMapResponseList = _get(heatMapResponse, "data.data", null);
  
          dispatch({
            loading: false,
            heatMapList: heatMapResponseList,
          });
        } catch (err) {
          dispatch({ loading: false, error: err });
        }
      })();
      
    }
    if(isReport) {
      dispatch({
        loading: false,
        heatMapList: filterValue,
      });
    }
    
  }, [filterValue]);

  // map reference
  const mapRef = React.useRef();
  // Store the map to the ref before it mounted
  const onMapLoad = React.useCallback(map => {
    mapRef.current = map;
    google = window.google;
  }, []);

  // Check the map error
  if (loadError || error) return "Error";
  // handle the pre render
  if (!isLoaded || loading) return "Loading...";

  const mapData =
    heatMapList &&
    heatMapList.length > 1 &&
    heatMapList.map(
      item =>
        new window.google.maps.LatLng({
          lat: item.pickUpLatitude,
          lng: item.pickUpLongtitude,
        })
    );
  return (
    <GoogleMap
      id="map"
      mapContainerStyle={mapContainerStyle}
      zoom={11}
      center={center}
      options={options}
      onMapLoad={onMapLoad}
    >
      {console.log("MAP DATA::", mapData)}
      {mapData && mapData.length > 0 && (
        <HeatmapLayer
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{ gradient, radius: 10, opacity: 1 }}
          data={mapData}
        />
      )}
    </GoogleMap>
  );
}

export default PlainMap;
