import React, { useEffect, useRef, useState } from 'react'

function OTPInput({setData, name, isSubmitted, setIsSubmitError}) {
    const firstRef = useRef(null)
    const secondRef = useRef(null)
    const thirdRef = useRef(null)
    const fourthRef = useRef(null)
    
    const [first, setFirst] = useState("")
    const [second, setSecond] = useState("")
    const [third, setThird] = useState("")
    const [fourth, setFourth] = useState("")
    const [otp, setOTP] = useState("")

    const hanldeInputChange = e => {
        let name = e.target.name;
        let value = e.target.value;

        var numbers = /^[0-9]+$/;
        if (!value.match(numbers) && value !== '') {
            return;
        }
        
        if (value.length > 1) {
            return;
        }


        if(name === 'first') {
            setFirst(value)
            if(value !== '') {
                secondRef.current.focus()
            }
            
        }

        if(name === 'second') {
            setSecond(value)
            if(value !== '') {
                thirdRef.current.focus()
            }
        }

        if(name === 'third') {
            setThird(value)
            if(value !== '') {
                fourthRef.current.focus()
            }
        }

        if(name === 'fourth') {
            setFourth(value)
        }

        setIsSubmitError(false)

    }

    const handleKeyDown = e => {
        var key = e.keyCode || e.charCode;
        let name = e.target.name;
        if(key === 8) {
            if(name === 'second') {
                firstRef.current.focus()
            }

            if(name === 'third') {
                secondRef.current.focus()
            }

            if(name === 'fourth') {
                thirdRef.current.focus()
            }
            
        }
    }

    //- clear inputs when resend click
    useEffect(() => {
        if(!isSubmitted) {
            setFirst("")
            setSecond("")
            setThird("")
            setFourth("")
            firstRef.current.focus()

        }
    }, [isSubmitted])
    
    //- concat the four inputs
    useEffect(() => {
        setOTP(first+second+third+fourth)
    }, [first, second, third, fourth])


    //- log the four inputs value
    useEffect(() => {
        if(otp.length >= 4) {
            setData(prevState => ({
                ...prevState,
                [name]: otp
            }))
        }
    }, [otp])


    //- get pasted 
    useEffect(() => {
        function pasteFunc(e) {
            setIsSubmitError(false)
            e.stopPropagation();
            e.preventDefault();
            var numbers = /^[0-9]+$/;
            let clipboardData = e.clipboardData || window.clipboardData;
            let pastedData = clipboardData.getData('Text');

            if (!pastedData.match(numbers) && pastedData !== '') {
                return;
            }
            setFirst(pastedData[0] || '')
            setSecond(pastedData[1] || '')
            setThird(pastedData[2] || '')
            setFourth(pastedData[3] || '')
            
        }
        document.addEventListener("paste", pasteFunc);
        return () => {
            document.removeEventListener("paste", pasteFunc);
        }
    }, [])
    return (
        <>
            <div className="form-col">
                <div className="input-wrap">
                    <input 
                        className={`site-input requiredData ${name} ${isSubmitted && 'invalid-input'}`}
                        type="text"
                        name="first"
                        value={first}
                        onChange={hanldeInputChange}
                        onKeyUp={handleKeyDown}
                        ref={firstRef}
                        inputMode="numeric"
                        pattern="\d*"
                    />
                </div>
            </div>

            <div className="form-col">
                <div className="input-wrap">
                    <input 
                        className={`site-input requiredData ${name} ${isSubmitted && 'invalid-input'}`} 
                        type="text"
                        name="second"
                        value={second}
                        onChange={hanldeInputChange}
                        onKeyUp={handleKeyDown}
                        ref={secondRef}
                        inputMode="numeric"
                        pattern="\d*"
                    />
                </div>
            </div>

            <div className="form-col">
                <div className="input-wrap">
                    <input 
                        className={`site-input requiredData ${name} ${isSubmitted && 'invalid-input'}`} 
                        type="text"
                        name="third"
                        value={third}
                        onChange={hanldeInputChange}
                        onKeyUp={handleKeyDown}
                        ref={thirdRef}
                        inputMode="numeric"
                        pattern="\d*"
                    />
                </div>
            </div>

            <div className="form-col">
                <div className="input-wrap">
                    <input 
                        className={`site-input requiredData ${name} ${isSubmitted && 'invalid-input'}`} 
                        type="text" 
                        name="fourth"
                        value={fourth}
                        onChange={hanldeInputChange}
                        onKeyUp={handleKeyDown}
                        ref={fourthRef}
                        inputMode="numeric"
                        pattern="\d*"
                    />
                </div>
            </div>
            
        </>
        
    )
}

export default OTPInput
