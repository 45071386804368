import React from 'react';

import './ButtonSpinner.scss';

const ButtonSpinner = () => (
    <div className="btn-spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
    </div>
);

export default ButtonSpinner;