import React, {useContext, useEffect, useState} from 'react'
import { langContext } from '../../../context/langContext';
import { PhrasesContext } from '../../../context/PhrasesContext';
import CloseIocn from '../../../assets/icons/icon-close-white.svg';
import shareIcon from "../../../assets/icons/share-icon-white.png";
import { modalContext } from '../../../context/modalContext';
import { getReportList } from '../../../api/requests_reports';
import ReportListTable from '../ReportsListing/components/ReportListTable';
import { useHistory } from "react-router-dom";

function ReportsDetailsCompareModal({itemId}) {
    const history = useHistory();

    const {lang} = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const {setModalState} = useContext(modalContext)


    const hideModal = () => {
        setModalState('hideModal')
    }
    const stopProb = (e) => {
        e.stopPropagation()
    }

    //- define report list
    const [reportList, setReportList] = useState({ loading: true, data: [], error: false, totalCount: "" });
    const [filter, setFilter] = useState({reportName: "", pageIndex: 1})

    //- fetch report list
    useEffect(() => {
        const submittedData = {
            reportName: filter.reportName,
            pageIndex: 0
        }
        getReportList(submittedData)
            .then(response => {
                const reportDataArray = [];
                if(response.data.data.reports.length) {
                    response.data.data.reports.map(item => reportDataArray.push({...item, selected: false}))
                }
                
                setReportList({
                    loading: false,
                    data: reportDataArray,
                    totalCount: response.data.data.totalCount,
                    error: false
                })
            })
            .catch(error => {
                setReportList({
                    loading: false,
                    data: [],
                    totalCount: "",
                    error: true
                })
                console.log("Error ==>", error)
            })
    }, [])

    let selectedSecondId = reportList.data.find(item => item.selected === true);
    const goToComparePage = () => {
        // let selectedSecondId = reportList.data.find(item => item.selected === true)
        if(selectedSecondId) {
            setModalState('hideModal')
            history.push(`/report/compare/${itemId}/${selectedSecondId.id}`)

        }
    }

    return (
        <div className="modal-data protal-modal share-modal" onClick={stopProb}>
            <div className="close-modal" onClick={hideModal}>
                <img src={CloseIocn} alt="icon" />
            </div>

            <div className="profile-card">
                <div className="profile-card-head">
                    <div className="user-title-wrap">
                        <div className="user-icon">
                            <img src={shareIcon} alt="icon" style={{width: '24px'}} />
                        </div>
                        <strong>{phrases?.lblCompareYourReport?.[lang]}</strong>
                    </div>
                </div>
                
                <div className={`profile-card-body`}>
                    <div className="share-form request-form" >
                        
                        <div className="share-form-content" id="content-scroll-func" style={{height: '450px', overflowY: 'auto'}}>
                            <strong className="compare-report-modal-title">{phrases?.lblSelectCompareReport?.[lang]}</strong>
                            <ReportListTable report={reportList} setReport={setReportList} filter={filter} setFilter={setFilter} parentScroll itemId={itemId} />
                        </div>
                       

                        <div className="request-form-action">
                            <div className="form-aciton">
                                <button  type="submit" className={`site-btn ${!selectedSecondId ? 'disabled': ''}`} onClick={goToComparePage}>
                                    <span>{phrases?.btnCompareReports?.[lang]}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportsDetailsCompareModal
