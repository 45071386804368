import React, { useEffect } from 'react'
import ReportDetailsIndex from '../Components/Reports/ReportsDetails/ReportDetailsIndex';

function ReportDetails(props) {

    const {id} = props.match.params;
    
    return (
        <div className="data-container">
            <div className="data-wrapper report-details-wrapper">
                <div className="request-report-wrap">
                    <ReportDetailsIndex itemId={id} />
                </div>
            </div>
        </div>
    )
}

export default ReportDetails
