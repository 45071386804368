import React from "react";

export default function IconPointer(props) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        width="22"
        height="25"
        viewBox="0 0 22 25"
      >
        <defs>
          <path
            id="k5b8nupqxa"
            d="M1.152 12.557C.423 13.142-.005 14.074 0 15.062v.162c.007 1.24.683 2.357 1.724 2.849 3.305 1.651 7.033 6.505 7.066 6.552.182.238.45.376.734.375H20c.526 0 .952-.466.952-1.042v-8.797c-.007-2.095-1.18-3.974-2.962-4.744-1.83-.76-3.704-1.388-5.609-1.88V3.124c0-.92-.37-1.794-1.012-2.388-.643-.594-1.492-.849-2.321-.695C7.653.336 6.65 1.68 6.667 3.234V13.51c-.95-.614-1.965-1.096-3.024-1.432-.853-.283-1.778-.105-2.49.48zm5.796 3.802c.271.3.681.39 1.038.23.355-.163.586-.543.585-.964V3.235c-.023-.54.306-1.023.786-1.152.279-.054.565.03.781.23.219.201.343.5.338.812v6.25c-.009.504.314.943.767 1.042 2.062.484 4.089 1.137 6.062 1.953 1.048.453 1.738 1.559 1.743 2.791v7.756H9.962c-.99-1.24-4.286-5.162-7.448-6.74-.361-.152-.602-.53-.61-.953v-.162c0-.327.142-.636.382-.833.23-.187.529-.245.804-.156 1.432.422 2.753 1.205 3.858 2.286z"
          />
          <path
            id="bdjvgloe0b"
            d="M18.095 5.208c.526 0 .953.467.953 1.042s-.427 1.042-.953 1.042H16.19c-.526 0-.952-.467-.952-1.042s.426-1.042.952-1.042zm-15.238 0c.526 0 .953.467.953 1.042s-.427 1.042-.953 1.042H.952C.426 7.292 0 6.825 0 6.25s.426-1.042.952-1.042zM15.52.308c.376-.374.953-.36 1.313.032s.375 1.024.035 1.436l-.953 1.042c-.179.197-.422.307-.676.307-.384-.002-.73-.256-.876-.644-.147-.388-.066-.834.205-1.132zM2.22.34c.36-.392.938-.406 1.313-.033l.953 1.042c.369.406.369 1.062 0 1.469-.18.197-.423.307-.676.307-.253-.001-.494-.112-.672-.307l-.952-1.042C1.846 1.364 1.86.732 2.22.34z"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <g>
            <g>
              <g transform="translate(-799 -1043) translate(788 1030) translate(11 13)">
                <use fill="#FFF" fillRule="nonzero" href="#k5b8nupqxa" />
                <use fill="#FFF" fillRule="nonzero" href="#bdjvgloe0b" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
}
