import React from 'react';


export const userListContext = React.createContext();
export const userListInitialState = {};
export const isUserStatusChangedState = false;


export const userListReducer = (state = userListInitialState, action) => {
    return state = action
}

export const isUserStatusChangedReducer = (state = isUserStatusChangedState, action) => {
    return state = action
}