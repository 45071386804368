import axiosInstance from '../axios/axiosInstance';

export const loginUser = (userObject) => axiosInstance.post(`/Account/Login`, userObject);

export const resetPassword = (newPassword) => axiosInstance.post(`/Account/ResetPassword`, newPassword);

export const forgetPassword = (username) => axiosInstance.post(`/Account/ForgetPassword?UserName=${username}`);

export const getAllUsers = () => axiosInstance.get(`/Account/GetAllUsers`);

export const resendOtp = (name) => axiosInstance.post(`/Account/ResendOtp?UserName=${name}`);


export const validateOtp = (name, otp) => axiosInstance.post(`/Account/ValidateOTPMessage?UserName=${name}&otp=${otp}`);


