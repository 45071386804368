import React from "react";
import MergedLayout from "./HOC/MergedLayout";
import { LoginProvider } from "./context/loginContext";

import { withTheme } from "./HOC/Theme";

const app = () => {
  return (
    <LoginProvider>
      <MergedLayout />
    </LoginProvider>
  );
};

export default withTheme(app);
