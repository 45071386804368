import React, { useState, useContext, useEffect } from 'react'
import IconButton from "@material-ui/core/IconButton";
import useOnclickOutside from "react-cool-onclickoutside";
import { PhrasesContext } from '../../../../context/PhrasesContext';
import { langContext } from '../../../../context/langContext';
import calendarIcon from "../../../../assets/icons/icons-calendar.svg";
import NewCalendarPicker from '../../../CalendarPicker/NewCalendarPicker';
import moment from "moment";
import { RequestListContext } from '../../../../context/RequestListContext';

function DateSearch({data, setData}) {
    const [report, setReport, filter, setFilter] = useContext(RequestListContext)

    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    const [viewCalendar, setViewCalendar] = useState(false);
    const [dateValue, setDateValue] = useState([filter.from, filter.to])
    const [isDateSelected, setDateSelected] = useState(false)
    
    //- hanlde outside click
    const calndarRef = useOnclickOutside(() => {
        setViewCalendar(false);
    });



    //- log date value
    useEffect(() => {
        let yest = moment(moment().subtract(1, "days")).startOf("day").format();
        setFilter({
            ...filter,
            from: moment(dateValue[0]).format(),
            to: moment(dateValue[1]).format()
        })
        if(filter.from !== yest && filter.to !== yest) {
            setDateSelected(true)

        }
    }, [dateValue])



    

    return (
        <>
            <div className="site-input">
                <span className="date-text">
                    {
                        isDateSelected
                        ?   `${new Date(dateValue[0]).toLocaleDateString()} ~ ${new Date(dateValue[dateValue.length - 1]).toLocaleDateString()}`
                        :   phrases?.lblYesterday?.[lang]
                    }
                </span>
                <div className="circle-BG">
                    <IconButton style={{ outline: "0" }} onClick={() => setViewCalendar(prev => !prev)}>
                        <img src={calendarIcon} alt="calendar" />
                    </IconButton>
                </div>
            </div>

            <div style={{ display: viewCalendar ? "flex" : "none" }} ref={calndarRef}>
                <NewCalendarPicker dateValue={dateValue} setDateValue={setDateValue} setDateSelected={setDateSelected} setViewCalendar={setViewCalendar} closePicker={() => setViewCalendar(false)}  />
            </div>
        </>
    )
}

export default DateSearch
