import React, {useState, useContext, useEffect} from 'react'
import { useHistory } from 'react-router';

import { getReportList } from '../../../../api/requests_reports'
import { langContext } from '../../../../context/langContext'
import { PhrasesContext } from '../../../../context/PhrasesContext'
import CustomIfiniteScroll from '../../../MyRequestReport/components/ReportTable/CustomIfiniteScroll'
import dobuleUserIcon from "../../../../assets/icons/double-user-icon-white.svg";

function ReportListTable({report, setReport, filter, setFilter, parentScroll, itemId}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)

    //- handle checkbox
    const handleChange = (e) => {
        let checked = e.target.checked;
        if(checked) {
            let dataArray = [];
            report.data.map(item => dataArray.push({...item, selected: true}))
            setReport({
                ...report,
                data: dataArray
            })
            
        } else {
            let dataArray = [];
            report.data.map(item => dataArray.push({...item, selected: false}))
            setReport({
                ...report,
                data: dataArray
            })
        }
        
    }


    //- load more data
    const [loading, setLoading] = useState(false)
    const loadMoreData = () => {
        const submittedData = {
            reportName: filter.reportName,
            pageIndex: filter.pageIndex
        }

        setLoading(true)
        

        //- API handle

        getReportList(submittedData)
            
            .then(response => {
                const reportDataArray = [];
                if(response.data.data.reports.length) {
                    response.data.data.reports.map(item => reportDataArray.push({...item, selected: false}))
                }

                setReport(prevState => ({
                    ...prevState,
                    data: prevState.data.concat(reportDataArray)
                }))

                setFilter(prevState => ({
                    ...filter,
                    pageIndex: prevState.pageIndex + 1
                }))
                setLoading(false)

            })
            .catch(error => {
                console.log("Request Report Error ===>", error)
                setLoading(false)
            })
    
    }


    //- handle auto main checkbox selected
    useEffect(() => {
        let selectedArray = []
        report.data.map(x => {
            if(x.selected === true) {
                selectedArray.push(x.id)
            } else {
                selectedArray.filter(item => item.id !== x.id)
            }
        })

        if(selectedArray.length === report.data.length) {
            let isContainsSelected = report.data.some(item => item.selected)
            if(document.getElementById("mainCheckbox")) {
                if(isContainsSelected) {
                    document.getElementById("mainCheckbox").checked = true;
                } else {
                    document.getElementById("mainCheckbox").checked = false;
                }
            }
        } else {
            if(document.getElementById("mainCheckbox")) {
                document.getElementById("mainCheckbox").checked = false;
            }
        }
    }, [report])

    return (
        <>
            {
                report.loading
                ?   <>
                        <div className="card-loader data" style={{ width: "100%", height: "33px", marginBottom: "16px" }} ></div>
                        <div className="card-loader data" style={{ width: "100%", height: "33px", marginBottom: "16px" }} ></div>
                        <div className="card-loader data" style={{ width: "100%", height: "33px", marginBottom: "16px" }} ></div>
                        <div className="card-loader data" style={{ width: "100%", height: "33px", marginBottom: "16px" }} ></div>
                        <div className="card-loader data" style={{ width: "100%", height: "33px", marginBottom: "16px" }} ></div>
                    </>
                :   report.data.length === 0
                    ?   <>
                            <div className="no-active-request sub table" style={{marginBottom: '96px'}}>
                                <div className="no-active-request-icon">
                                    <img src={dobuleUserIcon} alt="icon" />
                                </div>
                                <div className="no-active-request-body">
                                    <strong>{phrases?.lblNoDataFound?.[lang]}</strong>
                                </div>
                            </div>
                        </>
                    :   <>
                            <div className="portal-table report-list-table">
                                <CustomIfiniteScroll parentScroll={parentScroll} dataLength={report.data.length} next={loadMoreData}  hasMore={report.totalCount > report.data.length ? true : false}> 
                                    <table className="policeman-table">
                                        <thead>
                                            <tr>
                                                {
                                                    parentScroll
                                                    ?   <th></th>
                                                    :   <th className="chekcbox-column">
                                                            <label className="custom-checkbox-label border-label">
                                                                <input type="checkbox" onChange={handleChange} id="mainCheckbox" />
                                                                <span className="custom-checkbox-checkmark"></span>
                                                            </label>
                                                        </th>
                                                }
                                                
                                                <th className="requestId">{phrases?.lblReportID?.[lang]}</th>
                                                <th className="militaryRow">{phrases?.lblName?.[lang]}</th>
                                                <th className="militaryRow">{phrases?.lblCreationDate?.[lang]}</th>
                                                <th className="militaryRow">{phrases?.lblReportingArea?.[lang]}</th>
                                                <th className="militaryRow">{phrases?.lblReportingPeriods?.[lang]}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                report.data.map((item) => {
                                                    return (
                                                        <ReportListItem key={item.id} item={item} report={report} setReport={setReport} parentScroll={parentScroll} itemId={itemId} />
                                                        
                                                    )
                                                })
                                            }
                                            
                                        </tbody>
                                    </table>
                                    {
                                        loading
                                        ?   <>
                                                <div className="card-loader data" style={{ width: "100%", height: "33px", marginBottom: "16px" }} ></div>
                                                <div className="card-loader data" style={{ width: "100%", height: "33px", marginBottom: "16px" }} ></div>
                                            </>
                                        :   null
                                    }
                                </CustomIfiniteScroll>
                            </div>
                        </>
            }
            
            
        </>
    )
}


//- report list item
function ReportListItem({item, report, setReport, parentScroll, itemId}) {
    const history = useHistory();

    const handleChange = (e) => {
        let checked = e.target.checked;
        if(checked) {
            setReport(prevState => ({
                ...prevState,
                data: report.data.map(x => x.id === item.id ? {...x, selected: true} : {...x})
            }))
        } else {
            setReport(prevState => ({
                ...prevState,
                data: report.data.map(x => x.id === item.id ? {...x, selected: false} : {...x})
            }))
        }
    }

    const handleChangeRadio = (e) => {
        let checked = e.target.checked;
        if(itemId == item.id) {
            console.log("SAME ID")
            return
        }
        if(checked) {
            setReport(prevState => ({
                ...prevState,
                data: report.data.map(x => x.id === item.id ? {...x, selected: true} : {...x, selected: false})
            }))
        }
    }
    const stopNestedAction = e => {
        e.stopPropagation()

    }
    const goToReportDetails = () => {
        if(parentScroll) {
            return
        }
        history.push(`report/details/${item.id}`)
    }
    

    return (
        <tr onClick={goToReportDetails} style={{cursor: 'pointer'}} className={`${itemId == item.id ? 'disable' : ''}`}>
            <td className="chekcbox-column" onClick={stopNestedAction}>
                {
                    parentScroll
                    ?   <label className={`custom-checkbox-label border-label custom-radio-label ${itemId == item.id ? 'disable' : ''}`}>
                            <input type="radio" onChange={handleChangeRadio} name="report-radio" checked={item.selected} />
                            <span className="custom-checkbox-checkmark"></span>
                        </label>
                    :   <label className="custom-checkbox-label border-label">
                            <input type="checkbox" onChange={handleChange} checked={item.selected} />
                            <span className="custom-checkbox-checkmark"></span>
                        </label>
                }
                
            </td>
            <td className="requestId">
                <span>{item.id}</span>
            </td>
            <td className="militaryRow">
                <span>{item.reportName}</span>
            </td>
            <td className="militaryRow ltr">
                <span>{item.createionDate}</span>
            </td>
            <td className="militaryRow">
                <span>{item.area || '-'}</span>
            </td>
            <td className="militaryRow ltr">
                <span>{item.period}</span>
            </td>
        </tr>
    )
}


export default ReportListTable
