import React, { useState , useEffect } from "react";

const PinContext = React.createContext([() => {}]);

const PinProvider = ({ children }) => {
  const [showPin, setShowPin] = useState(false);

  useEffect(()=>{
   
  }, [showPin])

  const changePinStatus = status => {
    setShowPin(status);
  };
  return (
    <PinContext.Provider value={[showPin, changePinStatus]}>
      {children}
    </PinContext.Provider>
  );
};

export { PinProvider, PinContext };
