import axiosInstance from '../axios/axiosInstance';

// get all policeman
export const GetPoliceMan = () => axiosInstance.get(`api/PoliceMan`);


// add new policeman
export const AddPoliceMan = (newPoliceMan) => axiosInstance.post(`api/PoliceMan/Add`, newPoliceMan);

// update new policeman
export const UpdatePoliceMan = (newPoliceMan) => axiosInstance.post(`api/PoliceMan/Update`, newPoliceMan);

// delete policeman
export const DeletePoliceMan = (id) => axiosInstance.post(`api/PoliceMan/Delete`, id);
// search policeman
export const SearchPoliceMan = (searchText) => axiosInstance.post(`api/PoliceMan/SearchPoliceMan` , { searchText: searchText});












// add new user
export const AddNewUser = (newUser) => axiosInstance.post(`/Account/Register`, newUser);


// update user
export const UpdateUser = (newUser) => axiosInstance.post(`/Account/UpdateUser`, newUser);
// search user
export const SearchUser = (searchText) => axiosInstance.post(`Account/SearchUsers` , { searchText: searchText});
// get all users
export const GetAllUsers = () => axiosInstance.get(`Account/GetAllUsers`);

// get profile data
export const getProfileData = () => axiosInstance.get(`Account/GetProfileData`);

// update Profile


export const UpdateProfile = (userData) => axiosInstance.post(`Account/UpdateProfile`, userData);


//logout
export const Logout = () => axiosInstance.post(`/Account/Logout`);


// change passwrod
export const ChangePassword = (data) => axiosInstance.post('/Account/ChangePassword', data)




