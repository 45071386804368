import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { requestContext } from "../../context/reqeustContext";
import IconRequest from "../../assets/icons/icons-request.svg";
import IconAdd from "../../assets/icons/add.svg";
import SearchIcon from "../../assets/icons/search-white-big.svg";
import SearchRequest from "../SearchRequest";
import ReqeustItem from "../RequestItem";
import { GetMainRequests } from "../../api/requests";
import { langContext } from "../../context/langContext";
import { PhrasesContext } from "../../context/PhrasesContext";
import { notificationContext } from '../../context/addNotificationContext';
import { ListMainStatus } from "../../Utils/utils";
import { userContext } from "../../context/userContext";

function RequestList() {
    const history = useHistory();
    const {userContextDispatch} = useContext(userContext)
    const { requestStatus, setRequestStatus } = useContext(requestContext);
    const { notification } = useContext(notificationContext);
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const [listLength, setListLength] = useState(null)
    const [resetClicked, setResetClicked] = useState(false)

    const showAddRequest = () => {
        setRequestStatus('showAddRequest')
    }


    useEffect(() => {
        if (!requestList.loading) {
            if (notification?.MainRequestId) {
                if (requestStatus.showList && requestList.data.length > 0) {

                    const index = requestList.data.findIndex(r => r.requestId == notification.MainRequestId);
                    if (index != -1) {
                        if (notification.MainRequestStatusId == 7 || notification.MainRequestStatusId == 14) {
                            requestList.data.splice(index, 1)
                        }
                        else {
                            requestList.data[index].statusId = notification.MainRequestStatusId;
                            requestList.data[index].statusNameEn = notification.MainRequestStatusEn;
                            requestList.data[index].statusNameAr = notification.MainRequestStatusAr;
                        }
                        const newRequestList = { ...requestList };
                        setRequestList({ ...newRequestList });
                    }
                }
            }
        }

    }, [notification])


    const [requestList, setRequestList] = useState({ loading: true, data: [], error: false, length: 0 })

    useEffect(() => {
        GetMainRequests()
            .then(response => {
                console.log(`Items ==>: ${response.data}`);
                setRequestList({
                    length: response.data.data.length,
                    loading: false,
                    data: response.data.data,
                    error: false,
                });
                setListLength(response.data.data.length)

            })
            .catch(error => {
                console.log("ERROR ==> ", error.response);
                setRequestList({
                    length: 0,
                    loading: false,
                    data: [],
                    error: true,
                });
                
            });
    }, []);




    const resetFilter = () => {
        setResetClicked(true)
        setRequestList({
            length: 0,
            loading: true,
            data: [],
            error: false,
        });
        GetMainRequests()
            .then(response => {
                console.log(`Items ==>: ${response.data}`);
                setRequestList({
                    length: response.data.data.length,
                    loading: false,
                    data: response.data.data,
                    error: false,
                });

            })
            .catch(error => {
                console.log("ERROR ==> ", error.response);
                setRequestList({
                    length: 0,
                    loading: false,
                    data: [],
                    error: true,
                });
            });
    }

    return (
        <>
            {
                listLength === 0 &&
                <div className="no-active-request main">
                    <div className="no-active-request-icon">
                        <img src={IconRequest} alt="icon" />
                    </div>
                    <div className="no-active-request-body">
                        <strong>{phrases?.lblNoRequestsYet?.[lang]}</strong>

                        <p>{phrases?.lblTapAnywhere?.[lang]}</p>
                        <div className="site-btn" onClick={showAddRequest}>
                            <img src={IconAdd} />

                            <span>{phrases?.lblAddFirstRequest?.[lang]}</span>
                        </div>
                    </div>
                </div>
            }

            {
                listLength !== 0 &&
                <>
                    <div className="request-head request-list-head">

                        <div className="request-title">
                            <strong>
                                {
                                    requestList.loading
                                        ? <div className="card-loader data" style={{ width: '150px', height: '30px', marginBottom: '0px' }}></div>
                                        : phrases?.lblTodaysActiveAndInitiatedRequests?.[lang]
                                }

                            </strong>
                            <span>
                                {requestList.loading ? (
                                    <div className="card-loader data" style={{ width: '100px', height: '30px', marginBottom: '0px' }}></div>
                                ) : requestList.length === undefined ? (
                                    `0 ${phrases?.lblRequestsLoc?.[lang]})`
                                ) : (
                                    `(${requestList?.length} ${phrases?.lblRequestsLoc?.[lang]})`
                                )}
                            </span>
                        </div>
                        <div className="request-search-wrap">
                            <div className="request-search noFilter">
                                <SearchRequest
                                    setRequestList={setRequestList}
                                    searchType={"requestList"}
                                    placeHold={phrases?.lblSearchInRequests?.[lang]}
                                    resetClicked={resetClicked}
                                />
                            </div>

                        </div>
                    </div>
                    <div className="request-body request-list-body">
                        <div className="request-card-wrapper">
                            {requestList.loading ? (
                                <>
                                    <div className="card-loader image"></div>
                                    <div className="card-loader image"></div>
                                </>
                            ) : requestList.error ? (
                                <p>{phrases?.lblSomethingWrong?.[lang]}</p>
                            ) : requestList.length === undefined ? (
                                <div className="no-active-request sub">
                                    <div className="no-active-request-icon">
                                        <img src={SearchIcon} alt="icon" />
                                    </div>
                                    <div className="no-active-request-body">
                                        <strong>{phrases?.lblNoMatchingResultsTxt?.[lang]}</strong>

                                        <p>{phrases?.lblTapAnywhere?.[lang]}</p>
                                        <p className="clear-search-text" onClick={resetFilter}>
                                            {phrases?.lblClearSearchResultsAndDisplayRequestsList?.[lang]}
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {requestList.data.map(item => {
                                        return <ReqeustItem item={item} key={item.requestId} />;
                                    })}
                                </>
                            )}
                        </div>
                    </div>

                </>
            }

        </>
    );
}

export default RequestList;
