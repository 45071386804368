import React, { useContext } from 'react'
import { useLocation, Link } from "react-router-dom";
import ArrowGray from "../../../assets/icons/icons-arrow-gray.svg";
import { langContext } from '../../../context/langContext';
import { PhrasesContext } from '../../../context/PhrasesContext';
import { profileContext } from '../../../context/profileContext';
import { sideBarContext } from '../../../context/sideBarContext';

function ProfileSideBar() {
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
  
    let { pathname } = useLocation();
    const {sideBarStatus, setSideBarStatus} = useContext(sideBarContext);
    const {profileContextData} = useContext(profileContext)

    const closeSideBar = () => {
        setSideBarStatus('hideSubMainSideBar')
    }

    const openSideBar = () => {
        setSideBarStatus('openSubMainSideBar')
    }



    
    return (
        <div className={`sidebar-contain nested ignoreOuside-click ${sideBarStatus.subMainSideBar ? 'open' : ''} ${sideBarStatus.mainSideBar ? 'mainIsOpen' : ''}`}>
            
            {
                sideBarStatus.subMainSideBar
                ?   <div className="sidebar-close" onClick={closeSideBar}>
                        <img src={ArrowGray} alt="icon" />
                    </div>
                :   <div className="sidebar-close" onClick={openSideBar}>
                        <img src={ArrowGray} alt="icon" className="flip" />
                    </div>
            }


            <div className="sidebar-wrap">
                <div className="sidebar-top">
                    <ul>
                        <li className={`sidebar-item ${pathname === '/profile' ? 'active' : ''}`}>
                            <div className="text">
                                <Link to="/profile">{phrases?.lblPersonalInformation?.[lang]}</Link>
                            </div>
                        </li>
                        {
                            profileContextData.isAdmin && 
                                <li className={`sidebar-item ${pathname === '/manageportal' ? 'active' : ''}`}>
                                    <div className="text">
                                        <Link to="/manageportal">{phrases?.lblManagePortal?.[lang]}</Link>
                                    </div>
                                </li>
                        }
                        
                        <li className={`sidebar-item ${pathname === '/password' ? 'active' : ''}`}>
                            <div className="text">
                                <Link to="/password">{phrases?.lblPasswordAndSecurity?.[lang]}</Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ProfileSideBar
