import React from 'react'
import serverDown from '../assets/icons/serverDown.svg'

function ServerDownPage({lang}) {

    return (
        <div className="serverDown-wrap">
            <div className="serverDown-item">
                <div className="serverDown-img">
                    <img src={serverDown} alt="down" />
                </div>
                <div className="serverDown-data">
                    <strong>
                        {
                            lang === 'en' 
                            ?   'Technical Error!' 
                            :   'خطأ تقني'}
                    </strong>
                    <p>
                        {
                            lang === 'en' 
                            ?   'Error has occurred, Please try again later or contact support.' 
                            :   'حدث خطأ تقني، يرجى المحاولة لاحقًا أو التواصل مع الدعم.'
                        }
                    </p>
                    <div className="serverDown-link">
                        <div className="site-btn" onClick={() => window.location.reload()}>
                            {
                                lang === 'en'
                                ?   'Reload'
                                :   'إعادة تحميل'
                            }
                        </div>
                    </div>
                    
                </div>
            </div>
            
        </div>
    )
}

export default ServerDownPage
