import React, { useState, useContext, useCallback } from 'react'
import _get from 'lodash/get';
import fileDownload from 'js-file-download';

import DownLoadIcon from "../../../../assets/icons/download-icon.png";
import PdfIcon from "../../../../assets/icons/PDF.svg";
import XlsIcon from "../../../../assets/icons/XLS.svg";
import CsvIcon from "../../../../assets/icons/CSV.svg";
import useOnclickOutside from "react-cool-onclickoutside";
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';
import { downloadCsv, downloadExcel, downloadPdf } from '../../../../api/requests_reports';
import ButtonSpinner from '../../../ButtonSpinner/ButtonSpinner';
import { RequestListContext } from '../../../../context/RequestListContext';
import { profileContext } from '../../../../context/profileContext';

function DownloadBtn({ coulmns, data }) {
    const{profileContextData} = useContext(profileContext)

    const [report, setReport, filter, setFilter] = useContext(RequestListContext)
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    const [isDropdownOn, setDropDownStatus] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [error, setError] = useState(null);
    const dropDownRef = useOnclickOutside(() => {
        setDropDownStatus(false);
    });
    const showReportsDropdown = () => {
        if(report.data.length === 0) {
            return
        }
        setDropDownStatus(prevState => !prevState);
    };
    const mappedColumns = coulmns
        .filter(({ mainColumn, selected }) => mainColumn || selected)
        .map(({ relatedColumnName, name }) => ({
            name: relatedColumnName,
            title: name
        }));

    const handleDownloadFile = useCallback((fileType) => async () => {
        
        const fileDownloaders = {
            xls: downloadExcel,
            pdf: downloadPdf,
            csv: downloadCsv,
        }
        setDownloading(true);
        const subittedData = {
            requestId: +data.requestId,
            vehiclePlateNo: data.vehiclePlateNo,
            vehicleVin: data.vehicleVin,
            from: data.from,
            to: data.to,
            pageIndex: data.pageIndex,
            locations: data.locations
        }
        try {
            const fileBlobResponse = await fileDownloaders[fileType]({
                ...subittedData, columns: mappedColumns
            });
            const fileBlob = _get(fileBlobResponse, 'data', '');

            fileDownload(fileBlob, `My Requests - ${profileContextData.name} - ${new Date().toLocaleDateString()}.${fileType}`);
        } catch (e) {
            setError(e);
        } finally {
            setDownloading(false);
        }
    }, [coulmns, data]);
   

    return (
        <li className={`site-btn white ${report.data.length === 0 ? 'disabled' : ''}`} onClick={showReportsDropdown}>
           {downloading ? (<ButtonSpinner />) : (
               <>
                    <img src={DownLoadIcon} alt="icon" />
                    <span>{phrases?.btnDownload?.[lang]}</span>
               </>
           )}
            {
                isDropdownOn
                ?   <div className="reports-dropdown" ref={dropDownRef}>
                        <ul>
                            <li onClick={handleDownloadFile('xls')}>
                                <img src={XlsIcon} alt="icon" />
                                <span>Excel</span>
                            </li>
                            <li onClick={handleDownloadFile('pdf')}>
                                <img src={PdfIcon} alt="icon" />
                                <span>PDF</span>
                            </li>
                            <li onClick={handleDownloadFile('csv')}>
                                <img src={CsvIcon} alt="icon" />
                                <span>CSV</span>
                            </li>
                        </ul>
                    </div>
                :   null
            }
        </li>
    )
}

export default DownloadBtn
