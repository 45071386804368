import React, { useContext } from "react";
import WhiteFlag from "../../assets/icons/finish.png";
import { langContext } from "../../context/langContext";
import { PhrasesContext } from "../../context/PhrasesContext";

function RequestOnMapIcon({ iconClass, iconId , numberOfVehicles}) {
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    return (
        <div className="wrapper">

            <div
                className={`icon_contain ${iconClass?.toLowerCase()} `}
            >
                <div className="icon_wrapper">
                    {iconClass?.toLowerCase() == "initiated" ?  (
                    <img
                        src={WhiteFlag}
                        alt="Icon"
                    />) : (<span>{numberOfVehicles}</span>)}
                    
                </div>
            </div>
            <div className="icon_id">
                <p>{`${phrases?.lblRequestID?.[lang]} ${iconId}`}</p>
            </div>
        </div>
    );
}

export default RequestOnMapIcon;
