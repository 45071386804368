import React from 'react'
import ReportListingIndex from '../Components/Reports/ReportsListing/ReportListingIndex';


function ReportsPage() {

    return (
        <>
            <div className="data-container profile">
                <div className="data-wrapper">
                    <div className="portal-table-contain">
                        <ReportListingIndex />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportsPage
