import React, {useState, useContext} from 'react'
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';
import SearchInput from '../../../../shell/SearchInput';

function ReportListHead({report, setReport}) {
    const [phrases] = useContext(PhrasesContext);
    const { lang } = useContext(langContext);

    const [clearSearch, setClearSearch] = useState(false);

    return (
        <div className="portal-table-head-searchAction">
            <div className="portal-title">
                <strong>{phrases?.lblAllReports?.[lang]}</strong>
                <span>
                    {
                        report.data.length === 0
                        ?   <span>({phrases?.lblNoDataFound?.[lang]})</span>
                        :   <span>({report.totalCount} {phrases?.lblReportsAR?.[lang]})</span>

                    }
                </span>
            </div>
            <div className="portal-search">
                <SearchInput 
                    setData={setReport}
                    userType='report'
                    clearSearch={clearSearch}
                    setClearSearch={setClearSearch}
                    placeHolder={phrases?.lblSearchInReports?.[lang]} 
            
                />
            </div>
        </div>
    )
}

export default ReportListHead
