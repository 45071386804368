import React, { useContext } from 'react'
import RequestWrapper from './RequestWrapper'
import { requestContext } from '../../context/reqeustContext';
import RequestList from '../../Components/RequestList';
import RequestSingle from '../../Components/RequestSingle';
import RequestAddIndex from '../../Components/RequestAdd/RequestAddIndex';
import RequestEditIndex from '../../Components/RequestEdit/RequestEditIndex';

function RequestContainer() {
    const {requestStatus} = useContext(requestContext)
    return (
        <>
            {
                requestStatus.showList && 
                    <RequestWrapper hideType="hideAllRequest" width={'normal'}>
                        <RequestList />
                    </RequestWrapper>
            }

            {
                requestStatus.showDetailed && 
                    <RequestWrapper closeIconType="closeX" hideType="hideAllRequest" reloading={true} width={'big'}>
                        <RequestSingle />
                    </RequestWrapper>
            }

            {
                requestStatus.showAddRequest && 
                    <RequestWrapper scroll closeIconType="closeX" hideType="hideAllRequest" reloading={true} width={'big'}>
                        {/* <RequestAdd /> */}
                        <RequestAddIndex />
                    </RequestWrapper>
            }

            {
                requestStatus.showEditRequest && 
                    <RequestWrapper scroll closeIconType="closeX" hideType="showDetailed" reloading={false} width={'big'}>
                        <RequestEditIndex />
                    </RequestWrapper>
            }
            
        </>
    )
}

export default RequestContainer
