import React, {useCallback, useContext, useState} from 'react'
import printJS from 'print-js';
import _get from 'lodash/get';

import ButtonSpinner from '../../../../ButtonSpinner/ButtonSpinner';

import { langContext } from '../../../../../context/langContext';
import { PhrasesContext } from '../../../../../context/PhrasesContext';
import printIcon from "../../../../../assets/icons/printer-icon.png";
import { printPDFFile } from '../../../../../api/requests_reports';

function ReportPrintBtn({ columns = [], data = {} }) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    const [printing, setPrinting] = useState(false);
    const [, setError] = useState(null);
   
    const handlePrint = useCallback(async () => {
        
        setPrinting(true);
        const subittedData = {
            requestId: +data.requestId,
            vehiclePlateNo: data.vehiclePlateNo,
            vehicleVin: data.vehicleVin,
            from: data.from,
            to: data.to,
            pageIndex: data.pageIndex,
            locations: data.locations
        }
        try {
            const fileBase64 = _get(await printPDFFile({...subittedData, columns: columns }), 'data.data', '');

            printJS({ printable: fileBase64, type: 'pdf', base64: true });
        } catch (e) {
            setError(e);
        } finally {
            setPrinting(false);
        }
    }, [columns, data]);
    
    return (
        <li className={`site-btn white print-btn`} onClick={handlePrint}>
            {printing ? (<ButtonSpinner />) : (
                <>
                    <img src={printIcon} alt="icon" />
                    <span>{phrases?.btnPrint?.[lang]}</span>
                </>
            )}
        </li>
    )
}

export default ReportPrintBtn
