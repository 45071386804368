import React, { useEffect, useState, useContext } from "react";
import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import _get from "lodash/get";
import mapStyles from "../../../../mapStyles";
import MapLocationCircle from "./MapLocationCircle";
import axios from 'axios'
import uuid from 'react-uuid'
import NotificationBar from "../../../NotificationBar";
import { langContext } from "../../../../context/langContext";
import { PhrasesContext } from "../../../../context/PhrasesContext";

let google = window.google;
// Map Options
const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    maxZoom: 20,
    minZoom: 5,
};
  
// Array of all the used APIs with Google Maps
const libraries = ["places", "visualization"];

function RadiusMapWrap({data, setData}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
        libraries,
    });

    const [zoom , setZoom ] = useState(14)
    const [center , setCenter ] = useState({ lat: 25.163625978982154, lng: 55.283261023070494 })

    //- update center
    useEffect(() => {
        if(data.locations.length) {
            data.locations.map(item => {
                if(item.selected) {
                    setCenter(item.center)
                    setZoom(14)
                }
            })
        }
    }, [data])

    // map reference
    const mapRef = React.useRef();
    // Store the map to the ref before it mounted
    const onMapLoad = React.useCallback(map => {
        mapRef.current = map;
        google = window.google;
        
    }, []);

    const onMapClick = e => {
        getAddress(e);
    }

    const getAddress = async e => {
        axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${e.latLng.lat()},${e.latLng.lng()}&key=${
              process.env.REACT_APP_GOOGLE_PLACES_API_KEY
            }&language=${localStorage.getItem("lang")}`
        )
            .then(response => {
                const selectedLocation = {
                    center: {lat: e.latLng.lat(), lng: e.latLng.lng()},
                    address: response.data.results[0].formatted_address,
                    radius: 500,
                    selected: false,
                    id: uuid()
                };
                for (var i = 0; i < data.locations.length; i++) {
                    if (data.locations[i].address === selectedLocation.address) {
                        console.log("This location is exist.")
                        return
                    }
                }
                if(data.locations.length < 3) {
                    setData(prevState => ({
                        ...data,
                        locations: [selectedLocation, ...prevState.locations]
                    }))
                }
                
            })
            .catch(error => {
                console.log("ERROR")
            })
        
    };

    // Check the map error
    if (loadError) return "Error";
    // handle the pre render
    if (!isLoaded) return "Loading...";


    return (
        <GoogleMap id="map" zoom={zoom} center={center} options={options} onMapLoad={onMapLoad} onClick={onMapClick} >
            {
                data.locations.map(item => {
                    return (
                        <MapLocationCircle 
                            key={item.id} 
                            data={data} 
                            setData={setData}
                            singleItem={item}
                            setZoom={setZoom} 
                            setCenterPoint={setCenter} 
                        />
                    )
                })
            }
            <NotificationBar related="RadiusMap" msgText={phrases?.lblClickOnMap?.[lang]} />
        </GoogleMap>
    )
}

export default RadiusMapWrap
