import React, { useEffect, useContext, useState } from 'react'
import useOnclickOutside from "react-cool-onclickoutside";
import DropDownArrow from "../../../../../assets/icons/down-arrow-white.png";
import { langContext } from '../../../../../context/langContext';
import { modalContext } from '../../../../../context/modalContext';
import { PhrasesContext } from '../../../../../context/PhrasesContext';
import ModalContainer from '../../../../../Modals/ModalContainer';
import ShareModal from '../../../../MyRequestReport/components/ShareModal';
import ReportDownloadShell from '../../../ReportsShell/ReportDownloadShell';
import ReportPrintShell from '../../../ReportsShell/ReportPrintShell';
import ReportCloneBtn from './ReportCloneBtn';
import ReportCompareBtn from './ReportCompareBtn';
import ReportDeleteBtn from './ReportDeleteBtn';
import ReportDownloadBtn from './ReportDownloadBtn';
import ReportPrintBtn from './ReportPrintBtn';
import ReportShareBtn from './ReportShareBtn';
function ReportActionsListIndex({reportData, itemId, portalView}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    const {modalState} = useContext(modalContext)


    const [actionsListView, setActionsListView] = useState(false)

    const handleActionsDropdown = () => {
        setActionsListView(prevState => !prevState)
    }
    const actionsListRef = useOnclickOutside(() => {
        setActionsListView(false)
    });
    useEffect(() => {
        if(actionsListView) {
            document.getElementById('dropdown-list').style.display = 'block'
        } else {
            document.getElementById('dropdown-list').style.display = 'none'
        }
    }, [actionsListView])

    return (
        <div className="head-actions">
            <div className="mobile-key site-btn" onClick={handleActionsDropdown}>
                <span>{phrases?.lblActions?.[lang]}</span>
                <img src={DropDownArrow} alt="icon" />
            </div>
            <div className="actions-list" id="dropdown-list" ref={actionsListRef}>
                <ul>
                    {
                        portalView === 'table'
                        ?   <>
                                <ReportCompareBtn />
                                <ReportDownloadBtn coulmns={reportData.report.columns} data={reportData.report.reportFilter} reportData={reportData} />
                                <ReportPrintBtn data={reportData.report.reportFilter} columns={reportData.report.columns} reportData={reportData} />
                                <ReportShareBtn />
                                <ReportCloneBtn reportData={reportData} />
                                <ReportDeleteBtn itemId={itemId} />

                            </>
                        :   <>
                                <ReportCompareBtn />
                                <ReportDownloadShell reportData={reportData} />
                                <ReportPrintShell reportData={reportData} />
                                <ReportShareBtn />
                                <ReportCloneBtn reportData={reportData} />
                                <ReportDeleteBtn itemId={itemId} />

                            </>
                    }
                    
                    
                </ul>
            </div>
            
            {
                modalState.showReportShareSend &&
                    <ModalContainer>
                        <ShareModal isReport filter={reportData.report.reportFilter} coulmns={reportData.report.columns} portalView={portalView}  />
                    </ModalContainer>
            }
        </div>
    )
}

export default ReportActionsListIndex
