import React from "react";
import { getRequestsHeatMap } from "../../api/dashboard";
import PlainMap from "../PlainMap";

function StatMap({ filterValue, phrases, lang, isReport }) {
  return (
    <div className="map-stat-card remove-shadow">
      <strong> {phrases?.lblRequestsHeatmap?.[lang]}</strong>

      <div className="map-container">
        <PlainMap filterValue={filterValue } isReport={isReport} />
      </div>
      <div className="map-status-bar-contain">
          <div className="map-status-bar-wrap">
              <div className="map-status-bar-progress"></div>
              <div className="map-status-bar-text">
                  <span>{phrases?.lblLow?.[lang]}</span>
                  <span>{phrases?.lblMid?.[lang]}</span>
                  <span>{phrases?.lblHigh?.[lang]}</span>
              </div>
          </div>
      </div>
    </div>
  );
}

export default StatMap;
