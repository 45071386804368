import React, { useState, useContext } from 'react'
import { useHistory } from "react-router-dom";

import EditIcon from "../../../../assets/icons/icons-edit.svg";
import { modalContext } from '../../../../context/modalContext';
import { userContext } from '../../../../context/userContext';
import { userListContext } from '../../../../context/userListContext';
import axios from 'axios';
import { decryptToken } from '../../../../Utils/utils';
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';
import { profileContext } from '../../../../context/profileContext';

function PortalUserViewData({data}) {
    const history = useHistory();
    const {userContextDispatch} = useContext(userContext)
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const [toggleSwitch, setToggleSwitch] = useState(data.isActive ? true : false);
    const {userContextData} = useContext(userContext)
    const { setModalState } = useContext(modalContext) 
    const {setUserListData} = useContext(userListContext)
    const {profileContextData} = useContext(profileContext)
    const handleToggleSwitch = () => {
        setToggleSwitch(state => !state);
    };
    const showUserEdit = (singleItem) => {
        setModalState('showUserEdit')
        setUserListData(singleItem)

    }

    //- handle activate user
    const handleActiveStatus = (type, id) => {
        axios
            .post(`${process.env.REACT_APP_API_BASE_URL}Account/${type}?userId=${id}`, {}, {
                headers: {
                    Authorization: `Bearer ${decryptToken(userContextData.userData.token)}`
                }
            })
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error)
                
            })
    }

    return (
        <tr>
            <td><span>{data.name}</span></td>
            <td><span>{data.userName}</span></td>
            <td><span>{data.email || '-'}</span></td>
            <td>{data.militaryNumber}</td>
            <td className="number-text">
                
                ({data.phoneNum.substring(0, 3)}) &nbsp;
                {data.phoneNum.substring(3, 13)}
            </td>
            <td>
                {data.isAdmin ? phrases?.lblAdmin?.[lang] : phrases?.lblUser?.[lang] }
            </td>
            {
                profileContextData.id === data.id
                ?   <td>
                        <div className={`table-status active disable`}>
                            <span>{phrases?.lblActivated?.[lang]}</span>
                            <div className={`custom-switch-wrap active`}>
                                <div className="custom-switch-circle"></div>
                            </div>
                        </div>
                    </td>
                :   toggleSwitch
                    ?   <td>
                            <div className={`table-status active`} onClick={() => handleActiveStatus('DeActivateUser', data.id)}>
                                <span>{phrases?.lblActivated?.[lang]}</span>
                                <div className={`custom-switch-wrap active`} onClick={handleToggleSwitch}>
                                    <div className="custom-switch-circle"></div>
                                </div>
                            </div>
                        </td>
                    :   <td>
                            <div className={`table-status deactive`} onClick={() => handleActiveStatus('ActivateUser', data.id)}>
                                <span>{phrases?.lblDeactivated?.[lang]}</span>
                                <div className={`custom-switch-wrap deactive`} onClick={handleToggleSwitch}>
                                    <div className="custom-switch-circle"></div>
                                </div>
                            </div>
                        </td>
            }
            
            <td>
                <div className="table-edit" onClick={() => showUserEdit(data)}>
                    <img src={EditIcon} alt="icon" />
                </div>
            </td>
        </tr>
    )
}

export default PortalUserViewData
