import React, { useContext, useEffect,  useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import useOnclickOutside from "react-cool-onclickoutside";

//- icons
import Arrow from "../../assets/icons/icons-arrow.svg";
import IconMenu from "../../assets/icons/icons-menu.svg";
import IconEaLogo from "../../assets/icons/IconEaLogo.js";
import IconDubaiPolice from "../../assets/icons/IconDubaiPolice.js";
import logOutBlue from "../../assets/icons/log-out-blue.svg";


import { requestContext } from "../../context/reqeustContext";
import { sideBarContext } from "../../context/sideBarContext";
import { langContext } from "../../context/langContext";
import { PhrasesContext } from "../../context/PhrasesContext";
import { PinContext } from "../../context/pinContext";
import { ListOnMapContext } from "../../context/listOnMap";
import { itemIdContext } from "../../context/itemIdContext";
import { userContext } from "../../context/userContext";
import { profileContext } from "../../context/profileContext";
import { logoutUser } from "../../api/logout";

function Header({ openSideBar, isHide }) {
  const history = useHistory();
  let { pathname } = useLocation();
  const {profileContextData} = useContext(profileContext)
  const {userContextDispatch, userContextData} = useContext(userContext)
  const { setRequestStatus } = useContext(requestContext);
  const [showPin, changePinStatus] = useContext(PinContext);
  const { sideBarStatus, setSideBarStatus } = useContext(sideBarContext);
  const [isSideBarOpen, setSideBarOpen] = useState(false);

  const { lang } = useContext(langContext);
  const [phrases] = useContext(PhrasesContext);
  const [,getAllRequests,] = useContext(ListOnMapContext);
  const { setItemId, itemId } = useContext(itemIdContext);
  const showRequestList = () => {
      setRequestStatus("showList");
      setItemId(null);
      changePinStatus(false);
      getAllRequests()
  };

  const handleSideBar = () => {
      setSideBarOpen(state => !state);
  };

  useEffect(() => {
    setSideBarOpen(sideBarStatus.mainSideBar)
  }, [sideBarStatus])

  useEffect(() => {
    if (isSideBarOpen) {
        setSideBarStatus("openMainSideBar");
        
    } else {
        setSideBarStatus("hideMainSideBar");
    }
  }, [isSideBarOpen]);

  const handleLogout = ()=>{
    logoutUser()
      .then(res => {
        userContextDispatch('logout')
        localStorage.removeItem("user");
        history.push('/')
        console.log("LOGOUT::", res);
  
      })
      .catch(error => {
        console.log("LOGOUT::", error);
        userContextDispatch('logout')
        localStorage.removeItem("user");
        history.push('/')
      })
  }
  const sideBarRef = useOnclickOutside(
      () => {
          if(sideBarStatus.mainSideBar) {
              setSideBarStatus("hideMainSideBar");
          }
          if(sideBarStatus.subMainSideBar) {
              setSideBarStatus("hideSubMainSideBar");
          }
      }, { ignoreClass: "ignoreOuside-click" }
  );

  

  
  return (
    <>
      <div className={`header-contain ${pathname === '/' ? 'homepage' : ''}`}>
        <div className="header-wrap">
          {isHide ? (
            <div className="header-actions alone">
              <ul>
                <li>
                  <div className="site-btn" onClick={showRequestList}>
                    <img src={Arrow} />
                    <span>{phrases?.lblTrackRequests?.[lang]}</span>
                  </div>
                </li>
              </ul>
            </div>
          ) : (
            <>
              <div className="header-nav">
                <div
                  className={`sidebar-btn ${sideBarStatus.mainSideBar ? "active" : ""}`}
                  onClick={handleSideBar}
                  ref={sideBarRef}
                >
                  <span>{phrases?.lblCommandCenter?.[lang]}</span>
                  <img src={IconMenu} />
                </div>
                <div className="header-nav-list">
                  <ul>
                    <li className="brand-logo">
                      <IconEaLogo />
                    </li>
                    <li className="brand-logo">
                      <IconDubaiPolice />
                    </li>
                    {
                      pathname === '/profile' || pathname === '/manageportal' || pathname === '/password'
                      ? <li className="header-info">
                          <strong>{phrases?.lblWelcome?.[lang]} {profileContextData.name}</strong>
                          <p>
                          {phrases?.lblHaveANiceDayAtWork?.[lang]}
                          </p>
                        </li>
                      : null
                    }
                    {
                      pathname === '/requestboard'
                      ? <li className="header-info">
                          <strong>{phrases?.lblMyRequestsCC?.[lang]}</strong>
                          <p>
                          {phrases?.lblThisBoardDisplaysYourRequests?.[lang]}
                          </p>
                        </li>
                      : null
                    }
                     {
                      pathname === '/dashboard'
                      ? <li className="header-info">
                          <strong>{phrases?.lblDashboard?.[lang]}</strong>
                          <p>
                          {phrases?.lblThisBoardDisplaysYourRequests?.[lang]}
                          </p>
                        </li>
                      : null
                    }
                    
                    {
                      pathname === '/'
                      ? <li className="header-info">
                          <strong>{phrases?.lblTrackTodaysRequest?.[lang]}</strong>
                          <p>
                            {
                              phrases
                                ?.lblThisBoardDisplaysRequestsOfLastThreeDays?.[
                                lang
                              ]
                            }
                          </p>
                        </li>
                      : null
                    }

                    {
                      pathname.split('/').includes('report')
                      ? <li className="header-info">
                          <strong>{phrases?.lblMyReports?.[lang]}</strong>
                          <p>
                            {phrases?.lblThisPageDisplaysReportDetails?.[lang]}
                          </p>
                        </li>
                      : null
                    }
                  </ul>
                </div>
              </div>
              <div className="header-actions">
                {
                  pathname === '/profile' || pathname === '/manageportal' || pathname === '/password'
                  ?   <div className="header-nested-actions">
                        <div className="site-btn logout" onClick={handleLogout}>
                          <img src={logOutBlue} />
                          <span>{phrases?.btnLogout?.[lang]}</span>
                        </div>
                      </div>
                  :   null
                }

                
                
                  {pathname === "/" ? (
                    <ul>
                      <li>
                        <div
                          className="site-btn request-btn"
                          onClick={showRequestList}
                        >
                          <img src={Arrow} />

                          <span>{phrases?.lblDisplayRequestsList?.[lang]}</span>
                        </div>
                      </li>
                    </ul>
                  ) : null}

                
                  

                  
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Header;
