import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";

import CloseIocn from '../../../../assets/icons/icon-close-white.svg';
import CloseBlack from "../../../../assets/icons/icons-close.svg";
import closeRed from "../../../../assets/icons/close-red.svg";
import userIconEditWhite from '../../../../assets/icons/userIconEditWhite.svg';

import { modalContext } from '../../../../context/modalContext';
import FullNameInput from '../../../../shell/FullNameInput';
import MilitaryNumberInput from '../../../../shell/MilitaryNumberInput';
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';
import $ from "jquery";
import { userListContext } from '../../../../context/userListContext';
import { UpdatePoliceMan } from '../../../../api/profile';
import PhoneHandleWrap from '../../../../shell/PhoneHandleWrap/PhoneHandleWrap';
import { userContext } from '../../../../context/userContext';



//- Validation
function validateForm(relatedForm) {
    var amountInput = $(relatedForm).find(".requiredData");
    var filledInputs = [];
        amountInput.each(function () {
        filledInputs.push(this);
    });

    var noValFilter = filledInputs.filter(function (item) {
        return $(item).val() === "";
    });

    $(noValFilter).each(function () {
        $(this).closest(".form-col").addClass("invalid-col");
        $(this).addClass("invalid-input");
        $(this).closest(".form-col").find(".error-msg").show();
    });

    if (noValFilter.length === 0) {
        return true;
    }
}

function PortalPolicemanViewDataEdit() {
    const history = useHistory();
    const {userContextDispatch} = useContext(userContext)

    const {lang} = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const {setModalState} = useContext(modalContext);
    const {userListData, setUserStatus, userStatus} = useContext(userListContext)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isSumbitError, setIsSubmitError] = useState(false)

    // Police man object
    const [policemanData, setPolicemanData] = useState({
        id: userListData.id,
        name: userListData.name,
        militaryNumber: userListData.militryNumber,
        phone: userListData.phone,
        type: userListData.type
    })

    const [apiValidation, setApiValidation] = useState('')

    
    useEffect(() => {
        console.log("Police Man Data ===> ", policemanData)
    }, [policemanData])
    
   

    const hideModal = () => {
        setModalState('hideModal')
    }
    const stopProb = (e) => {
        e.stopPropagation()
    }
    
    const handleFormSubmit = (e) => {
        let elem = $(e.target);
        let isValidateToSubmit = true;


        // custom validation when submit

        if(
            policemanData.name.length < 3 || policemanData.name.length > 300 ||
            policemanData.phone.length < 10 ||
            policemanData.militaryNumber.length < 2

        ) {
            isValidateToSubmit = false
        }

        if (!validateForm(elem)) {
            isValidateToSubmit = false;
        }

        if(isValidateToSubmit) {
            console.log("Validate Status ===> SUCCESS")
            setIsSubmitted(true)
            let sendingObj = {
                id: policemanData.id,
                name: policemanData.name,
                militryNumber: +policemanData.militaryNumber,
                phone: policemanData.phone,
                type: policemanData.type
            }
            UpdatePoliceMan(sendingObj)
                .then(response => {
                    setUserStatus(!userStatus)
                    setIsSubmitted(false)
                    setModalState('hideModal')
                    setModalState('showPoliceManUpdatedSucces')
                })
                .catch(error => {
                    console.log(error)
                    setIsSubmitted(false)
                    setIsSubmitError(true)
                    setApiValidation(error?.response?.data?.key || 'lblSomethingWrong')


                })
        }

        e.preventDefault();
    }
    

    // custom error handling
    const closeErrorSubmit = () => {
        setIsSubmitError(false)
    }
    
    return (
        <>
            <div className="modal-data protal-modal" onClick={stopProb}>
                <div className="close-modal" onClick={hideModal}>
                    <img src={CloseIocn} alt="icon" />
                </div>

                <div className="profile-card">
                    <div className="profile-card-head">
                        <div className="user-title-wrap">
                            <div className="user-icon">
                                <img src={userIconEditWhite} alt="icon" />
                            </div>
                            <strong>{phrases?.lblEditPoliceman?.[lang]}</strong>
                        </div>
                    </div>
                    
                    {
                        isSumbitError
                        ?   <div className="note error">
                                <div className="note-text">
                                    <img src={closeRed} alt="" />
                                    <span>
                                        {
                                            phrases[apiValidation]
                                            ?   phrases[apiValidation][lang]
                                            :   phrases[`lblSomethingWrong`][lang]
                                        }
                                    </span>
                                </div>
                                <img src={CloseBlack} className="closeNote" onClick={closeErrorSubmit} />
                            </div>
                        :   null
                    }
                    
                    <div className={`profile-card-body ${isSumbitError  ? 'has-error' : ''}`}>
                        <form className="request-form" id="policeman-form" onSubmit={handleFormSubmit}>
                            <div className="form-row">
                                <div className="form-col">
                                    <strong>{phrases?.lblName?.[lang]}</strong>
                                    <FullNameInput 
                                        data={policemanData} 
                                        setData={setPolicemanData} 
                                        name="name"
                                        placeHolder={phrases?.lblEnterFullName?.[lang]}
                                        setIsSubmitError={setIsSubmitError}

                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-col">
                                    <strong>{phrases?.lblMilitaryNumber?.[lang]}</strong>
                                    <MilitaryNumberInput 
                                        data={policemanData} 
                                        setData={setPolicemanData} 
                                        name="militaryNumber" 
                                        placeHolder={phrases?.lblEnterMilitaryNumber?.[lang]}
                                        setIsSubmitError={setIsSubmitError}

                                    />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-col">
                                    <strong>{phrases?.lblPhone?.[lang]}</strong>
                                    <PhoneHandleWrap
                                        data={policemanData}
                                        setData={setPolicemanData}
                                        name="phone"
                                        placeHolder={phrases?.lblEnterYourPhoneNumber?.[lang]}
                                        setIsSubmitError={setIsSubmitError}
                                    />
                                    
                                </div>
                            </div>

                            <div className="request-form-action">
                                <div className="form-aciton">
                                    <button form="policeman-form" type="submit" className="site-btn" disabled={`${isSubmitted  ? "disabled" : ""}`}>
                                        <span>{phrases?.btnUpdate?.[lang]}</span>
                                        {
                                            isSubmitted
                                            ?   <div className="spinner"></div>
                                            :   null
                                        }
                                        
                                    </button>
                                    <p onClick={hideModal} className={`${isSubmitted ? "disabled" : ""}`}>{phrases?.lblCancelAndGoBackToPolicemanList?.[lang]}</p>
                                </div>
                            </div>
                            
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PortalPolicemanViewDataEdit
