import React, {useEffect, useState, useContext} from 'react'
import { GetRequestTypes } from "../../../api/requests";
import { langContext } from "../../../context/langContext";
import { PhrasesContext } from "../../../context/PhrasesContext";
import CheckMark from "../../../assets/icons/icons-check-mark.svg";


function RequestType({data, setData, selectedTab}) {
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const [reqeustTypeValue, setRequestTypeValue] = useState({});

    //- Request type state and fetching data
    const [requestType, setRequestType] = useState({ loading: true, data: [], error: false });
    useEffect(() => {
        GetRequestTypes()
            .then(res => {
                setRequestType({ loading: false, data: res.data.data, error: false, });
            })
            .catch(error => {
                setRequestType({ loading: false, data: [], error: true, });
            });
    }, []);

    //- Update request type value
    useEffect(() => {
        if(requestType.data.length >= 1) {
            setRequestTypeValue(requestType.data[selectedTab === 1 ? 0 : 1])
        }
    }, [requestType])


    //- Update data value
    useEffect(() => {
        if(Object.keys(reqeustTypeValue).length >= 1) {
            setData({
                ...data,
                requestTypeId: reqeustTypeValue.id,
            });
        }
    }, [reqeustTypeValue])



    //- Handle request type click
    const handleRequestType = (value, id) => {
        setRequestTypeValue({ ...reqeustTypeValue, nameEn: value, id: id });
    };

    return (
        requestType.loading
        ?   <div className="card-loader input"></div>
        :   requestType.error
            ?   <p className="location-note">{phrases?.lblSomethingWrong?.[lang]}</p>
            :   requestType.data.map(item => {
                    return (
                        <div className="form-col half" key={item.id}>
                            <div className="request-select">
                                <div
                                    className={`request-select-head ${reqeustTypeValue?.nameEn?.toLowerCase() === item?.nameEn?.toLowerCase() ? "selected" : "" }`}
                                    onClick={() => handleRequestType( item?.nameEn?.toLowerCase(), item.id ) }
                                >
                                    <div className={`request-select-text ${item?.nameEn.toLowerCase()}`} >
                                        {
                                            item.nameEn.toLowerCase() === "impound" 
                                            ?  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" >
                                                    <g fill="none" fillRule="evenodd">
                                                        <g fill="#44C267">
                                                            <g>
                                                                <g>
                                                                    <g>
                                                                        <path
                                                                            d="M14.064 1.545L7.882 0h-.387L1.314 1.545c-.31.078-.541.387-.541.773V8.5c0 5.023 6.336 8.268 6.645 8.423.077.077.155.077.31.077.154 0 .231 0 .308-.077.31-.155 6.646-3.4 6.646-8.423V2.318c0-.386-.232-.695-.618-.773zm-.928 6.994c0 3.497-4.172 6.139-5.409 6.916-1.236-.7-5.409-3.342-5.409-6.916V2.944l5.41-1.399 5.408 1.4v5.594z"
                                                                            transform="translate(-1362 -552) translate(1340 201) translate(0 331) translate(22 20)"
                                                                        />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            :  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" >
                                                    <g fill="none" fillRule="evenodd">
                                                        <g
                                                            fillRule="nonzero"
                                                            stroke="#45C266"
                                                            strokeWidth="1.4"
                                                        >
                                                            <g>
                                                                <g>
                                                                    <g>
                                                                        <g>
                                                                            <path
                                                                                d="M33.946 25.4l-6.59-11.2c-.073-.123-.21-.2-.356-.2-.147 0-.283.077-.356.2l-6.59 11.2c-.072.125-.072.277.001.4.074.124.209.2.355.2h13.18c.146 0 .281-.076.355-.2.073-.123.073-.275 0-.4z"
                                                                                transform="translate(-1651 -303) translate(1334) translate(19 154) translate(30 110) translate(249 26)"
                                                                            />
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                        }

                                        <span> {lang === "en" ? item.nameEn : item.nameAr} </span>
                                    </div>
                                    <img className="request-select-img" src={CheckMark} alt="icon" />
                                </div>
                            </div>
                        </div>
                    );
                })
    )
}

export default RequestType
