import React, { useEffect, useRef, useContext } from 'react'
import ReactDOM from 'react-dom'
import CloseIocn from '../../../assets/icons/icon-close-white.svg';
import Tick from "../../../assets/icons/tick-white.svg";
import { langContext } from '../../../context/langContext';
import { PhrasesContext } from '../../../context/PhrasesContext';


function ResetPasswordModal({setLoginView, setShowResetSuccessModal}) {
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
  
    const modalRef = useRef(null)

    const hideModal = () => {
        setShowResetSuccessModal(false)
        setLoginView(true)
    }
    const stopProb = (e) => {
        e.stopPropagation()
    }

    useEffect(() => {
        modalRef.current.classList.add('active')
    }, [])


    return ReactDOM.createPortal(
        <div className="modal-container" onClick={hideModal}>
            <div className="modal-wrapper" ref={modalRef}>
                <div className="modal-data" onClick={stopProb} style={{paddingTop: '48px', paddingBottom: '48px'}}>
                    <div className="close-modal" onClick={hideModal}>
                        <img src={CloseIocn} alt="icon" />
                    </div>
                
                    <div className="modal-data-head">
                        <img src={Tick} alt="icon" />
                        <strong style={{maxWidth: '317px'}}>{phrases?.lblYourPasswordChangedSuccessfully?.[lang]}</strong>
                    </div>
                
                </div>
            </div>
        </div>,
        document.getElementById('modal_root')
    )
}

export default ResetPasswordModal
