import React, { useContext, useRef, useReducer } from "react";
import IconBrokenCar from "../../assets/icons/towing.svg";
import DownArrow from "../../assets/icons/icons-arrow-gray.svg";
import Tick from "../../assets/icons/tick.png";

import IconClose from "../../assets/icons/icon-close-white.svg";
import IconMapPin from "../../assets/icons/icons-map-pin.svg";
import shape from "../../assets/icons/shape.svg";
import IconPhone from "../../assets/icons/icons-phone.svg";
import VehicleIcon from "../../assets/icons/icons-broken-car.svg";

import { langContext } from "../../context/langContext";
import { PhrasesContext } from "../../context/PhrasesContext";
import $ from "jquery";
import { ListSubStatus } from "../../Utils/utils";
import {
  activeSubRequestContext,
} from "../../context/activeSubRequestContext";

function RequestSubData({ Card }) {
  const { lang } = useContext(langContext);
  const [phrases] = useContext(PhrasesContext);
    const headBtnRef = useRef(null);
    const { activeSubRequest } = useContext(activeSubRequestContext)

  // - Handle recovery body slide
  const recoveryBodyRef = useRef(null);
  const toggleReecoveryBody = e => {
    $(recoveryBodyRef.current).slideToggle();
    $(headBtnRef.current).toggleClass("active");
  };

  let serviceName;
  if (
    Card.statusId === 12 ||
    Card.statusId === 15 ||
    Card.statusId === 16 ||
    Card.statusId === 17
  ) {
    serviceName = phrases?.lblInProgress?.[lang];
  } else if (Card.statusId === 18) {
    serviceName = phrases?.lblServiceCompletedCC?.[lang];
  } else if (Card.statusId === 7) {
    serviceName = phrases?.lblCancelled?.[lang];
  } else {
    serviceName = lang === "en" ? Card.statusNameEn : Card.statusNameAr;
  }

  return (
    <li className={`recoveries-list-item ${Card.requestId == activeSubRequest ? 'containActive' : ''}`}>
      {
        Card.requestId == activeSubRequest
        ? <div className="tracked-wrap">
            <div className="tracked">
              <img src={IconMapPin} alt="icon" />
              <span>{phrases?.lblTrackedOnMap?.[lang]}</span>
            </div>
          </div>
        : null
      }
      
      <div
        className="recoveries-head"
        ref={headBtnRef}
        onClick={toggleReecoveryBody}
      >
        <div className="recoveries-head-text-wrap">
          <img src={IconBrokenCar} alt="icon" />
          <div className="recoveries-head-text">
            <strong>
              {lang === "en" ? Card.requestTypeNameEn : Card.requestTypeNameAr}
            </strong>
            {/* <p>{phrases?.lblTonsRecovery?.[lang]}</p> */}
          </div>
        </div>
        <div className="recoveries-head-status">
          <div
            className={`recoveries-head-status-text ${
              ListSubStatus[Card.statusId]
            }`}
          >
            {serviceName}
          </div>
          <div className="recoveries-head-status-arrow">
            <img src={DownArrow} alt="icon" />
          </div>
        </div>
      </div>
      <div className="recoveries-body" ref={recoveryBodyRef}>
        <div className="recoveries-process-wrap">
          <ul>
            <li className={ListSubStatus[Card.statusId]}>
              <div className="process-circle">
                <img src={Tick} alt="icon" />
              </div>
              <strong>{phrases?.lblInProgress?.[lang]}</strong>
            </li>

            <li>
              <div className={`process-circle ${ListSubStatus[Card.statusId]}`}>
                {Card.statusId === 18 && <img src={Tick} alt="icon" />}
                {Card.statusId === 7 && <img src={IconClose} alt="icon" />}
              </div>
              <strong className={ListSubStatus[Card.statusId]}>
                {Card.statusId === 7
                  ? phrases?.lblServiceCancelledCC?.[lang]
                  : phrases?.lblServiceCompletedCC?.[lang]}
              </strong>
            </li>
          </ul>
          {/* <div className="time-spent-wrap">
                        <div className="time-spent">
                            <span>Time Spent:</span>
                            <span>15m, 22s</span>
                        </div>
                    </div> */}
        </div>
        <div className="recoveries-body-list">
          <div className="recoveries-location-wrap">
            <ul className="request-data-list">
              <li className="full">
                <div className="request-data-list-img">
                  <img src={IconMapPin} alt="icon" />
                </div>
                <div className="request-data-list-text">
                  <strong>{phrases?.lblRequestLocation?.[lang]}</strong>
                  <p>{Card.pickUpAddress}</p>
                </div>
              </li>

              <li className="full">
                <div className="request-data-list-img">
                  <div className="request-data-list-img-icon"></div>
                </div>
                <div className="request-data-list-text">
                  <strong>{phrases?.lblTargetLocation?.[lang]}</strong>
                  <p>
                    {Card.dropOffAddress === null ? "-" : Card.dropOffAddress}
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div className="vehicle-data-wrap">
            <ul className="request-data-list">
              <li className="full">
                <div className="request-data-list-img">
                  <img src={VehicleIcon} alt="icon" />
                </div>
                <div className="request-data-list-text">
                  <strong>
                    {phrases?.lblVehiclesTowedInThisJobOrder?.[lang]}
                  </strong>
                  <div className="vehicle-meta-wrap">
                    <div className="vehicle-meta-item">
                      <strong>{phrases?.lblVinNumber?.[lang]}</strong>
                      <span>{Card.vin === null ? "-" : Card.vin}</span>
                    </div>

                    <div className="vehicle-meta-item">
                      <strong>{phrases?.lblPlateNumber?.[lang]}</strong>
                      <span>
                        {Card.plateCode === null ? "-" : Card.plateCode}
                      </span>
                    </div>

                    <div className="vehicle-meta-item">
                      <strong>{phrases?.lblMake?.[lang]}</strong>
                      <span>{Card.make === null ? "-" : Card.make}</span>
                    </div>

                    <div className="vehicle-meta-item">
                      <strong>{phrases?.lblModel?.[lang]}</strong>
                      <span>{Card.model === null ? "-" : Card.model}</span>
                    </div>

                    <div className="vehicle-meta-item">
                      <strong>{phrases?.lblColor?.[lang]}</strong>
                      <span>{Card.color === null ? "-" : Card.color}</span>
                    </div>
                  </div>
                </div>
              </li>

              <li className="auto-width noMargin">
                <div className="request-data-list-img">
                  <img src={shape} alt="icon" />
                </div>
                <div className="request-data-list-text">
                  <strong>{phrases?.lblDriverName?.[lang]}</strong>
                  <p>{Card.driverName === null ? "-" : Card.driverName}</p>
                </div>
              </li>

              <li className="auto-width noMargin">
                <div className="request-data-list-img">
                  <img src={IconPhone} alt="icon" />
                </div>
                <div className="request-data-list-text">
                  <strong>{phrases?.lblDriverPhone?.[lang]}</strong>
                  <p>
                    {Card.driverPhoneNumber === null
                      ? "-"
                      : Card.driverPhoneNumber}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </li>
  );
}

export default RequestSubData;
