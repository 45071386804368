import React, { useState, createContext } from 'react';

export const CCExternalIdContext = createContext()

export const CCExternalIdProvider = props => {
    const [ccItemId, setCcItemId] = useState('');
    return (
        <CCExternalIdContext.Provider value={[ccItemId, setCcItemId]}>
            {props.children}
        </CCExternalIdContext.Provider>
    );
}