import React, { useState, createContext, useEffect } from 'react';
import moment from "moment";

export const RequestListContext = createContext()

export const RequestListPorvider = props => {
    const [report, setReport] = useState({loading: true, data: [], error: false, totalCount: "", subRequestsCount: ""})
    useEffect(() => {
        console.log("REPORTS ===>", report)
    }, [report])

    const [filter, setFilter] = useState({
        requestId: "",
        vehiclePlateNo: "",
        vehicleVin: "",
        locations: [],
        pageIndex: 1,
        from: moment(moment().subtract(1, "days")).startOf("day").format(),
        to: moment(moment().subtract(1, "days")).endOf("day").format(),
    })

    
    useEffect(() => {
        console.log("FILTER DATA IS =====>", filter)
        
    }, [filter])

    return (
        <RequestListContext.Provider value={[report, setReport, filter, setFilter]}>
            {props.children}
        </RequestListContext.Provider>
    );
}