import React from 'react';



export const modalContext = React.createContext();
export const modalInitialState = {
    showNotification: false,
    showCancel: false,
    showUserAdd: false,
    showPolicemanAdd: false,
    showUserEdit: false,
    showPolicemanEdit: false,
    showPolicemanDelete: false,
    showPasswordSuccess: false,
    policeManAddedSucces: false,
    policeManUpdatedSucces: false,
    userAddedSucces: false,
    userUpdatedSucces: false,
    updateProfileSuccess: false,
    showDashboardHasNoData: false,
    showShareSend: false,
    showCreateReportRequest: false,
    showDeleteReport: false,
    showReportShareSend: false,
    showCompareModal: false
}


export const modalReducer = (state = modalInitialState, action) => {
    switch(action) {
        case 'showNotification':
            return {
                ...state,
                showNotification: true
            }
        case 'showCancel':
            return {
                ...state,
                showCancel: true
            }
        case 'showUserAdd':
            return {
                ...state,
                showUserAdd: true
            }
        case 'showPolicemanAdd':
            return {
                ...state,
                showPolicemanAdd: true
            }
        case 'showUserEdit':
            return {
                ...state,
                showUserEdit: true
            }
        case 'showPolicemanEdit':
            return {
                ...state,
                showPolicemanEdit: true
            }
        case 'showPolicemanDelete':
            return {
                ...state,
                showPolicemanDelete: true
            }
        case 'showPasswordSuccess':
            return {
                ...state,
                showPasswordSuccess: true,
            }
        case 'showPoliceManAddedSucces':
            return {
                ...state,
                policeManAddedSucces: true,
            }
        case 'showPoliceManUpdatedSucces':
            return {
                ...state,
                policeManUpdatedSucces: true,
            }
        case 'showUserAddedSucces':
            return {
                ...state,
                userAddedSucces: true,
            }
        case 'showUserUpdatedSucces':
            return {
                ...state,
                userUpdatedSucces: true,
            }
        case 'showUpdateProfileSuccess':
            return {
                ...state,
                updateProfileSuccess: true
            }
        case 'showShareSend':
            return {
                ...state,
                showShareSend: true
            }
        case 'showCreateReportRequest':
            return {
                ...state,
                showCreateReportRequest: true
            }
        case 'showDeleteReport':
            return {
                ...state,
                showDeleteReport: true
            }
        case 'showReportShareSend':
            return {
                ...state,
                showReportShareSend: true
            }
        case 'showCompareModal':
            return {
                ...state,
                showCompareModal: true
            }
            
        case 'hideModal':
            return {
                ...state = false
            }
        
        default:
            return state
    }
}