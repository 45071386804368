import React, { useContext, useState } from "react";
import { PhrasesContext } from "../../context/PhrasesContext";
import { langContext } from "../../context/langContext";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
import { structureTypeChartData } from "../../Pages/DashboardPage/helpers";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

const DISPLAY = true;
const BORDER = true;
const CHART_AREA = false;
const TICKS = false;

function Canvas({ chartData = [], isBig }) {
  const {
    chartLabels: labels,
    data: { offsetRequests, impoundRequests } = {},
  } = structureTypeChartData(chartData);
  const { lang } = useContext(langContext);
  const [phrases] = useContext(PhrasesContext);
  const [portalView, setPortalView] = useState("offset");
  let ctx = null;
  const useCanvas = callback => {
    const canvasRef = React.useRef(null);

    React.useEffect(() => {
      const canvas = canvasRef.current;
      ctx = canvas.getContext("2d");
      callback([canvas, ctx]);
    }, [callback]);

    return canvasRef;
  };

  const Canvas = () => {
    const canvasRef = useCanvas(ctx => {
      new Chart(ctx, {
        type: "bar",
        data: {
          labels,
          datasets: [
            {
              label:
                portalView === "impound"
                  ? phrases?.lblImpound?.[lang]
                  : phrases?.lblOffset?.[lang],
              data: portalView === "impound" ? impoundRequests : offsetRequests,
              backgroundColor: "#6fbcd4",
              borderWidth: 1,
              borderRadius: 13.5,
              barThickness: 27,
              borderSkipped: false,
            },
          ],
        },
        options: {
          responsive: true,
          aspectRatio: isBig ? 2.5 : 5,
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              grid: { display: false },
              display: DISPLAY,
              drawTicks: TICKS,
              drawBorder: BORDER,
              drawOnChartArea: CHART_AREA,
            },
            y: {
              ticks: {
                stepSize: 1,
              },
              beginAtZero: true,
            },
          },
        },
      });
    });

    return (
      <div style={{ width: "100%" }}>
        <canvas ref={canvasRef} />
      </div>
    );
  };

  return (
    <div className="card remove-shadow EN_IMP">
      <div className="chart-header AR_IMP hide-on-print-item" id="hide-on-print">
        <span>{phrases?.lblServicesChart?.[lang]}</span>

        <div className="site-tabs">
          <ul>
            <li
              className={`${
                portalView === "impound" ? "active" : "not-active"
              } user`}
              onClick={() => {
                setPortalView("impound");
              }}
            >
              {phrases?.lblImpound?.[lang]}
            </li>
            <li
              className={`${
                portalView === "offset" ? "active" : "not-active"
              } policeman`}
              onClick={() => {
                setPortalView("offset");
              }}
            >
              {phrases?.lblOffset?.[lang]}
            </li>
          </ul>
        </div>
      </div>

      <Canvas />
    </div>
  );
}
export default React.memo(Canvas);
