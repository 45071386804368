import React, {useContext} from 'react'
import calendarIcon from "../../../../assets/icons/icons-calendar.svg";

import { langContext } from '../../../../context/langContext'
import { PhrasesContext } from '../../../../context/PhrasesContext'
import moment from "moment";

function ReportsCompareHeadData({reportDataFirst, reportDataSecond}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)

    return (
        <>
            <div className="body">
                <div className="header compare-header" style={{minHeight: "150px"}}>
                    <div className="compare-header-left">
                        <div className="header-left">
                            <div className="status-div">
                                <div className="small-Oval">
                                    <div className="Oval-in">{reportDataFirst.mainRequestsCount}</div>
                                </div>
                                <div className="request-labels">
                                    <span>{reportDataFirst.report.reportName}</span>
                                    <label className="total">
                                        {phrases?.lblTotalRequests?.[lang]}
                                    </label>
                                </div>

                            </div>
                        </div>
                        <div className="header-right">
                            <div className="selected-date-wrap">
                                <div className="white-icon-wrap">
                                    <img src={calendarIcon} alt="calendar" width="16" />
                                </div>
                                <p>{moment(reportDataFirst.report.reportFilter.from.replace('+04', '+00')).format('M/DD/YYYY')} - {moment(reportDataFirst.report.reportFilter.to).format('M/DD/YYYY')}</p>
                            </div>

                            {
                                reportDataFirst.report.reportFilter.locations !== null
                                ?   <div className="selected-locations-wrap">
                                        {
                                            reportDataFirst.report.reportFilter.locations.map(item => {
                                                return (
                                                    <div key={item.address} className="selected-location-item">
                                                        <span>{item.address.substring(0 , 8)}.. + {item.radius}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                :   null
                            }
                        </div>
                    
                    </div>
                    
                    <div className="compare-header-right">
                        <div className="header-left">
                            <div className="status-div">
                                <div className="small-Oval">
                                    <div className="Oval-in">{reportDataSecond.mainRequestsCount}</div>
                                </div>
                                <div className="request-labels">
                                    <span>{reportDataSecond.report.reportName}</span>
                                    <label className="total">
                                        {phrases?.lblTotalRequests?.[lang]}
                                    </label>
                                </div>

                            </div>
                        </div>
                        <div className="header-right">
                            <div className="selected-date-wrap">
                                <div className="white-icon-wrap">
                                    <img src={calendarIcon} alt="calendar" width="16" />
                                </div>
                                <p>{moment(reportDataSecond.report.reportFilter.from.replace('+04', '+00')).format('M/DD/YYYY')} - {moment(reportDataSecond.report.reportFilter.to).format('M/DD/YYYY')}</p>
                            </div>

                            {
                                reportDataSecond.report.reportFilter.locations !== null
                                ?   <div className="selected-locations-wrap">
                                        {
                                            reportDataSecond.report.reportFilter.locations.map(item => {
                                                return (
                                                    <div key={item.address} className="selected-location-item">
                                                        <span>{item.address.substring(0 , 8)}.. + {item.radius}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                :   null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportsCompareHeadData
