import React, {useState, useContext, useEffect} from 'react'
import { useHistory } from "react-router-dom";

import Files from "../../../../assets/icons/files.svg";
import Arrow from "../../../../assets/icons/icons-arrow.svg";
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';
import ReportActionsListIndex from './ReportActionsList.js/ReportActionsListIndex';

function ReportDetailsHead({reportData, setReportData, itemId, portalView}) {
    const history = useHistory();

    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)



    const backToReportList = () => {
        history.push('/report')
    }

    return (
        <>
            <div className="head-level-1">
                <div className="head-back-wrap">
                    <div className="head-back" onClick={backToReportList}>
                        <img src={Arrow} alt="icon" />
                    </div>
                    <div className="head-title">
                        <img src={Files} alt="icon" />
                        <p>
                            <span>{phrases?.lblShowingRequests?.[lang]}</span>
                            <span>
                                ({reportData.mainRequestsCount} {phrases?.lblRequestsLoc?.[lang]} {phrases?.lblAnd?.[lang]} {reportData.totalCount} {phrases?.lblServices?.[lang]} {phrases?.lblFoundReport?.[lang]})
                            </span>
                            
                        </p>
                    </div> 
                </div>
                <ReportActionsListIndex reportData={reportData} itemId={itemId} portalView={portalView} />
            </div>
        </>
    )
}

export default ReportDetailsHead
