import React from 'react'
import RadiusMapWrap from './RadiusMap/RadiusMapWrap'
import ReportTable from './ReportTable/ReportTable'

function RequestReportBody({data, setData, coulmns, setCoulmns}) {
    return (
        <div className="request-report-body">
            <div className="request-report-map">
                <RadiusMapWrap data={data} setData={setData} />
            </div>
            <div className="request-report-table" id="request-table">

                <ReportTable data={data} setData={setData}  coulmns={coulmns} setCoulmns={setCoulmns} />
            </div>
        </div>
    )
}

export default RequestReportBody
