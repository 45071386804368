import React, { useEffect, useContext, useState } from 'react'
import DownloadBtn from './DownloadBtn';
import CreateReport from './CreateReport';
import ShareBtn from './ShareBtn';
import PrintBtn from './PrintBtn';
import useOnclickOutside from "react-cool-onclickoutside";
import DropDownArrow from "../../../../assets/icons/down-arrow-white.png";
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';


function ActionsList({coulmns, data}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)


    const [actionsListView, setActionsListView] = useState(false)

    const handleActionsDropdown = () => {
        setActionsListView(prevState => !prevState)
    }
    const actionsListRef = useOnclickOutside(() => {
        setActionsListView(false)
    });
    useEffect(() => {
        if(actionsListView) {
            document.getElementById('dropdown-list').style.display = 'block'
        } else {
            document.getElementById('dropdown-list').style.display = 'none'
        }
    }, [actionsListView])

    return (
        <div className="head-actions">
            <div className="mobile-key site-btn" onClick={handleActionsDropdown}>
                <span>{phrases?.lblActions?.[lang]}</span>
                <img src={DropDownArrow} alt="icon" />
            </div>
            <div className="actions-list" id="dropdown-list" ref={actionsListRef}>
                <ul>
                    
                    <CreateReport  />
                    <DownloadBtn coulmns={coulmns} data={data} />
                    <PrintBtn columns={coulmns} data={data}  />
                    <ShareBtn data={data}  />
                    
                    
                </ul>
            </div>
            
        </div>
    )
}

export default ActionsList
