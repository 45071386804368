import React, { useEffect, useReducer, useRef, useContext } from "react";
import _get from "lodash/get";
import * as moment from "moment";
import { useHistory } from "react-router-dom";

import RequestsTypeChart from "../../Components/RequestsTypeChart";
import RequestsStatusChart from "../../Components/RequestsStatusChart";
import Loader from "../../Components/Loader";
import ModalContainer from "../../Modals/ModalContainer";
import ModalInfoWrapper from "../../Modals/ModalInfoWrapper";
import RequestsStatisticsBar from "../../Components/RequestsStatisticsBar";

import { PhrasesContext } from "../../context/PhrasesContext";
import { langContext } from "../../context/langContext";
import { getRequestsbyStatus, getRequestsbyType } from "../../api/dashboard";
import calendarModalIcon from "../../assets/icons/calendarModalIcon.svg";
import dashboardModalIcon from "../../assets/icons/icons-dashboard.svg";
import { PickedLocationContext } from "../../context/pickedLocationContext";
import StatMap from "../../Components/StatMap";

function DashboardPage() {
  const { push } = useHistory();
  const [pickedLocation, setPickedLocation] = useContext(PickedLocationContext);
  const [
    {
      dateFilter,
      requestsStatusData,
      requestsTypeData,
      loading,
      error,
      showModal,
    },
    dispatch,
  ] = useReducer((base, next) => ({ ...base, ...next }), {
    dateFilter: [moment().subtract(1, "days").startOf('day'), moment().subtract(1, "days").endOf('day')],
    requestsStatusData: null,
    loading: false,
    error: null,
    showModal: true,
  });
  const isInitialRequest = useRef(true);
  const { lang } = useContext(langContext);
  const [phrases] = useContext(PhrasesContext);

  useEffect(() => {
    const filter = {
      from: moment(dateFilter[0]).startOf("day").format(),
      to: moment(dateFilter[dateFilter.length - 1])
        .endOf("day")
        .format(),
    };

    (async function getStatisticsData() {
      dispatch({ loading: true, showModal: true });
      setPickedLocation({
        ...pickedLocation,
        dateRangeFormationDashBoard: {
          ...filter,
          from: new Date(filter.from).toLocaleDateString(),
          to: new Date(filter.to).toLocaleDateString(),
        },
      });

      try {
        const [requestsByStatusRes, requestsByTypeRes] = await Promise.all([
          getRequestsbyStatus(filter),
          getRequestsbyType(filter),
        ]);
        const requestsByStatus = _get(
          requestsByStatusRes,
          "data.data.requestsbyStatusList",
          null
        );
        const requestsByType = _get(
          requestsByTypeRes,
          "data.data.requestsbyTypeList",
          null
        );

        dispatch({
          loading: false,
          requestsStatusData: requestsByStatus,
          requestsTypeData: requestsByType,
        });

        isInitialRequest.current = false;
      } catch (err) {
        dispatch({ loading: false, error: err });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter]);

  if (loading)
    return (
      <div className="dashboard-loader">
        <Loader />
      </div>
    );

  if (error)
    return (
      <div className="dashboard-error">
        <h1>{phrases?.lblSomethingWrong?.[lang]}</h1>
      </div>
    );

  if (
    isInitialRequest.current &&
    (!requestsStatusData || !requestsTypeData) &&
    showModal
  ) {
    return (
      <ModalContainer closeAction={() => dispatch({ showModal: false })}>
        <ModalInfoWrapper
          title={phrases?.lblNoDashboardDataToDisplay?.[lang]}
          description={phrases?.lblStartAddingRequestsToSeeStatistics?.[lang]}
          icon={dashboardModalIcon}
          closeAction={() => dispatch({ showModal: false })}
        >
          <button
            type="submit"
            className="site-btn full"
            onClick={() => push("/")}
          >
            {phrases?.btnAddANewRequest?.[lang]}
          </button>
        </ModalInfoWrapper>
      </ModalContainer>
    );
  }

  if (
    !isInitialRequest.current &&
    (!requestsStatusData || !requestsTypeData) &&
    showModal
  ) {
    return (
      <ModalContainer closeAction={() => dispatch({ showModal: false })}>
        <ModalInfoWrapper
          title={phrases?.lblNoDashboardDataToDisplay?.[lang]}
          description={`${phrases?.lblFrom?.[lang]} ${moment(
            dateFilter[0]
          ).format("ll")} ~ ${moment(dateFilter[dateFilter.length - 1]).format(
            "ll"
          )}`}
          icon={calendarModalIcon}
          closeAction={() => dispatch({ showModal: false })}
        >
          <button
            type="submit"
            className="site-btn full"
            onClick={() => dispatch({ showModal: false })}
          >
            {phrases?.lblChangeFiltrationData?.[lang]}
          </button>
        </ModalInfoWrapper>
      </ModalContainer>
    );
  }

  return (
    <div className="data-container">
      <div className="data-wrapper">
        <div className="body">
          <RequestsStatisticsBar
            statistics={requestsStatusData}
            filterValue={dateFilter}
            setFilterValue={dispatch}
          />

          <div className="chart-cards">
            <RequestsStatusChart chartData={requestsStatusData} />
            <RequestsTypeChart chartData={requestsTypeData} />
          </div>

          <StatMap filterValue={dateFilter} phrases={phrases} lang={lang} />
          
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
