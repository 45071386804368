import React, { useState } from "react";

const ActiveCarContext = React.createContext([]);
const ActiveOnMapProvider = ({ children }) => {
  const [activeOnMapId, setActiveOnMapId] = useState(null);

  const changeActiveId = newValue => {
    setActiveOnMapId(newValue);
  };

  return (
    <ActiveCarContext.Provider value={[activeOnMapId, changeActiveId]}>
      {children}
    </ActiveCarContext.Provider>
  );
};

export { ActiveCarContext, ActiveOnMapProvider };
