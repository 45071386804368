import React, { useContext, useEffect, useState } from 'react'
import UserIconWhite from "../../../assets/icons/user-icon-white.svg";
import editIcon from '../../../assets/icons/icons-edit.svg';
import ProfileInformationView from './ProfileInformationView';
import ProfileInformationEdit from './ProfileInformationEdit';
import { PhrasesContext } from '../../../context/PhrasesContext';
import { langContext } from '../../../context/langContext';
import { modalContext } from '../../../context/modalContext';
import ModalContainer from '../../../Modals/ModalContainer';
import DataSuccessModal from '../../../Modals/DataSuccessModal';
import { getProfileData } from '../../../api/profile';
import { profileContext } from '../../../context/profileContext';

function ProfileInformation() {
    const [phrases] = useContext(PhrasesContext);
    const { lang } = useContext(langContext);
    const [isProfileEdit, setProfileEditStatus] = useState(false)
    const{profileContextData} = useContext(profileContext)
    const { modalState } = useContext(modalContext);
    const [profileData, setProfileData] = useState({loading: true, data: {}, error: false})
    
    //- get user data

    useEffect(() => {
        if(Object.keys(profileContextData).length > 0) {
            setProfileData({
                loading: false,
                data: profileContextData,
                error: false
            })
        }
    }, [profileContextData])

    

    const handleProfileEdit = () => {
        setProfileEditStatus(true)
    }
    return (
        <>
            {
                profileData.loading
                ?   <div className="profile-information-contain">
                        <div className="profile-information-wrap">
                            <div className="profile-information-data">
                                <div className="card-loader data profile-card" style={{height: '522px'}}></div>
                            </div>
                        </div>
                    </div>
                :   profileData.error
                    ?   'OPS!'
                    :   <>
                            <div className="profile-information-contain">
                                <div className="profile-information-wrap">
                                    <div className="profile-information-data">
                                        <div className="profile-card">
                                            <div className="profile-card-head">
                                                <div className="user-title-wrap">
                                                    <div className="user-icon">
                                                        <img src={UserIconWhite} alt="icon" />
                                                    </div>
                                                    <strong>{phrases?.lblMyPersonalInformation?.[lang]}</strong>
                                                </div>
                                                {
                                                    !isProfileEdit &&  profileData.data.isAdmin
                                                    &&  <div className="profile-edit" onClick={handleProfileEdit}>
                                                            <img src={editIcon} alt="icon"/>
                                                        </div>
                                                }
                                                
                                                
                                            </div>
                                            {
                                                isProfileEdit
                                                ?   <ProfileInformationEdit data={profileData.data} setData={setProfileData}  setProfileEditStatus={setProfileEditStatus} />
                                                :   <ProfileInformationView data={profileData.data} />
                                            }
                                                
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            {
                                modalState.updateProfileSuccess &&
                                    <ModalContainer>
                                        <DataSuccessModal msg={phrases?.lblProfileUpdatedSuccessfully?.[lang]} />
                                    </ModalContainer>
                            }
                        </>
            }
            
            
        </>
    )
}

export default ProfileInformation
