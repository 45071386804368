import React, {useState, useContext, useEffect} from 'react'
import { getReportDetails } from '../../../api/requests_reports';
import { langContext } from '../../../context/langContext';
import { modalContext } from '../../../context/modalContext';
import { PhrasesContext } from '../../../context/PhrasesContext';
import ModalContainer from '../../../Modals/ModalContainer';
import ReportDetailsChartIndex from './components/ReportDetailsChart/ReportDetailsChartIndex';
import ReportDetailsHead from './components/ReportDetailsHead';
import ReportDetailsHeadData from './components/ReportDetailsHeadData';
import ReportDetailsListIndex from './components/ReportDetailsList/ReportDetailsListIndex';
import ReportsDetailsCompareModal from './ReportsDetailsCompareModal';

function ReportDetailsIndex({itemId}) {
    const {modalState} = useContext(modalContext)
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)

    //- toggle between tables
    const [portalView, setPortalView] = useState('table')
    const handleProtalView = (view) => {
        setPortalView(view)
    }

    //- report details data
    const [reportData, setReportData] = useState({
        canceledMainRequestCount: "",
        completedMainRequestCount: "",
        mainRequestsCount: "",
        subRequestsCount: "",
        totalCount: "",
        report: {},
        requests: [],
        loading: true,
        error: false,
        pageIndex: 1,
    })

    useEffect(() => {
        getReportDetails(itemId, 0)
            .then(response => {
                setReportData({
                    canceledMainRequestCount: response.data.data.canceledMainRequestCount,
                    completedMainRequestCount: response.data.data.completedMainRequestCount,
                    mainRequestsCount: response.data.data.mainRequestsCount,
                    subRequestsCount: response.data.data.subRequestsCount,
                    totalCount: response.data.data.totalCount,
                    report: response.data.data.report,
                    requests: response.data.data.requests,
                    loading: false,
                    error: false,
                    pageIndex: 1,
                })
            })
            .catch(error => {
                console.log(error)
                setReportData({
                    canceledMainRequestCount: "",
                    completedMainRequestCount: "",
                    mainRequestsCount: "",
                    subRequestsCount: "",
                    totalCount: "",
                    report: {},
                    requests: [],
                    loading: false,
                    error: true,
                    pageIndex: 1,
                })
            })
            
    }, [lang])


    //- log the report details
    useEffect(() => {
        console.log("Report Details Data is ==>", reportData)
    }, [reportData])

    
    return (
        <div className="request-report-data">
            <div className="request-report-head">
                {
                    reportData.loading
                    ?   <div className="card-loader data" style={{ width: "100%", height: "60px", marginBottom: "16px" }} ></div>
                    :   <ReportDetailsHead reportData={reportData} setReportData={setReportData} itemId={itemId} portalView={portalView} />

                }
            </div>
            <div id="downloaded-view">
                <div className="request-report-body">
                    {
                        reportData.loading
                        ?   <div className="card-loader data" style={{ width: "100%", height: "180px", marginBottom: "16px" }} ></div>
                        :   <ReportDetailsHeadData reportData={reportData} />
                    }
                </div>

                
                <div className="request-report-body">
                    {
                        reportData.loading
                        ?   <div className="card-loader data" style={{ width: "100%", height: "50px", marginBottom: "16px" }} ></div>
                        :   <div className="report-tabs-head">
                                <div className="site-tabs hide-on-print-item" id="hide-on-print-two">
                                    <ul>
                                        <li 
                                            className={`${portalView === 'table' ? 'active' : 'not-active'} user`} 
                                            onClick={() => {handleProtalView('table')}} 
                                        >
                                            {phrases?.lblListView?.[lang]}
                                        </li>
                                        <li 
                                            className={`${portalView === 'chart' ? 'active' : 'not-active'} policeman`} 
                                            onClick={() => {handleProtalView('chart')}} 
                                        >
                                            {phrases?.lblChartsView?.[lang]}
                                        </li>
                                    </ul>
                                </div>
                            </div>


                    }
                    
                    <div className="report-tabs-body">
                        
                        {
                            portalView === 'table'
                            ?   <ReportDetailsListIndex reportData={reportData} setReportData={setReportData} itemId={itemId} />
                            :   <ReportDetailsChartIndex reportData={reportData} itemId={itemId} />
                        }
                        
                        
                    </div>
                </div>
            </div>
            

            {
                modalState.showCompareModal &&
                    <ModalContainer>
                        <ReportsDetailsCompareModal itemId={itemId} />
                    </ModalContainer>
            }
        
        </div>
    )
}

export default ReportDetailsIndex
