import axiosInstance from "../axios/axiosInstance";

export const getReports = (reportFilters) => axiosInstance.post(`api/Report/GetRequests`, reportFilters);



export const downloadExcel = (reportFilters) => axiosInstance.post(`api/Report/RequestsExcel`, reportFilters  , {
    responseType: 'blob',
  } );
export const downloadCsv = (reportFilters) => axiosInstance.post(`api/Report/RequestsCSV`, reportFilters,  {
    responseType: 'blob',
  } );
export const downloadPdf = (reportFilters) => axiosInstance.post(`api/Report/RequestsPdf`,reportFilters ,  {
    responseType: 'blob',
    },);



export const getGoogleSuggestions = (searchText) => axiosInstance.get(`api/Request/getGoogleMapsApiSuggestions/${searchText}`)

export const shareWithEmail = (shareFilters) => axiosInstance.post(`api/Request/shareWithEmail`, shareFilters)
export const shareWithEmailBase64 = (shareFilters) => axiosInstance.post(`api/Report/shareWithEmailBase64File`, shareFilters)

export const printPDFFile = (filter) => axiosInstance.post(`api/Report/printPDF`, filter);

export const createReport = (reportData) => axiosInstance.post(`api/Report/saveReport`, reportData);
export const getReportList = (reportData) => axiosInstance.post(`api/Report/getReports`, reportData);
export const getReportDetails = (id, pageIndex) => axiosInstance.get(`api/Report/getReportById/${id}/${pageIndex}`);

export const deleteReport = (reportData) => axiosInstance.post('api/Report/deleteReports', reportData);



export const chartsStatus = (id) => axiosInstance.get(`api/Report/RequestsbyStatus/${id}`);
export const chartsType = (id) => axiosInstance.get(`api/Report/RequestsbyType/${id}`);
export const mapHeatPoints = (id) => axiosInstance.get(`api/Report/RequestsbyPoints/${id}`);
