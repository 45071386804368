import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";

import $ from 'jquery'
import FullNameInput from '../../../shell/FullNameInput';
import MilitaryNumberInput from '../../../shell/MilitaryNumberInput';
import EmailInput from '../../../shell/EmailInput';
import CloseBlack from "../../../assets/icons/icons-close.svg";
import closeRed from "../../../assets/icons/close-red.svg";

import PhoneHandleWrap from '../../../shell/PhoneHandleWrap/PhoneHandleWrap';
import { userContext } from '../../../context/userContext';
import { langContext } from '../../../context/langContext';
import { PhrasesContext } from '../../../context/PhrasesContext';
import { modalContext } from '../../../context/modalContext';
import axios from 'axios'
import { decryptToken } from '../../../Utils/utils';
import { profileContext } from '../../../context/profileContext';
import { UpdateProfile } from '../../../api/profile';

//- Validation
function validateForm(relatedForm) {
    var amountInput = $(relatedForm).find('.requiredData');
    var filledInputs = [];
    amountInput.each(function() {
        filledInputs.push(this);
    });
    
    
    var noValFilter = filledInputs.filter(function (item) {
        return $(item).val() === '';
    })

    $(noValFilter).each(function () {
        $(this).closest('.form-col').addClass('invalid-col');
        $(this).addClass('invalid-input');
        $(this).closest('.form-col').find('.error-msg').show()
    });


    if(noValFilter.length === 0) {
        return true
    } 

}
function ProfileInformationEdit({data, setData, setProfileEditStatus, setDataChanged}) {
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const history = useHistory();
    const {setProfileStatus, profileStatus} = useContext(profileContext)
    const {userContextData, userContextDispatch} = useContext(userContext)
    const [isSumbitError, setIsSubmitError] = useState(false)
    const [apiValidation, setApiValidation] = useState("")
    const {setModalState} = useContext(modalContext)

    const [isSubmitted, setIsSubmitted] = useState(false)
    const [personalInformation, setPersonalInformation] = useState({
        id: data.id,
        name: data.name,
        phone: data.phoneNum,
        email: data.email,
        militaryNumber: data.militaryNumber
    })

    // - back to information
    const backToInfo = () => {
        setProfileEditStatus(false)
    }

    
    // custom error handling
    const closeErrorSubmit = () => {
        setIsSubmitError(false)
    }

    useEffect(() => {
        console.log('Perosnal Information ===>', personalInformation)
    }, [personalInformation])


    //- handle data submit
    const handleFormSubmit = e => {
        let elem = $(e.target);
        let emailValid = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        let isValidateToSubmit = true;

        if(
            personalInformation.name.length < 3 || personalInformation.name.length > 300
            || personalInformation.militaryNumber.length < 2 
            || personalInformation.phone.length < 10  
            || !personalInformation.email.match(emailValid)
        ) {
            isValidateToSubmit = false;
        }

        if (!validateForm(elem)) {
            isValidateToSubmit = false;
        }

        if(isValidateToSubmit) {
            console.log("Validate Status ===> SUCCESS")
            setIsSubmitted(true)
            let sendingObj = {
                name: personalInformation.name,
                phone: personalInformation.phone,
                email: personalInformation.email,
                militaryNumber: +personalInformation.militaryNumber
            }
            UpdateProfile(sendingObj)
            .then(response => {
                setIsSubmitted(false)
                setProfileEditStatus(false)
                setModalState('showUpdateProfileSuccess')
                setProfileStatus(!profileStatus)
                

            })
            .catch(error => {
                console.log(error)
                setIsSubmitted(false)
                setIsSubmitError(true)
                setApiValidation(error?.response?.data?.key || 'lblSomethingWrong')

            })

                
        }

        e.preventDefault();
    }

    return (
        <>
            {
                isSumbitError
                ?   <div className="note error">
                        <div className="note-text">
                            <img src={closeRed} alt="" />
                            <span>
                                {
                                    phrases[apiValidation]
                                    ?   phrases[apiValidation][lang]
                                    :   phrases[`lblSomethingWrong`][lang]
                                }
                            </span>
                        </div>
                        <img src={CloseBlack} className="closeNote" onClick={closeErrorSubmit} />
                    </div>
                :   null
            }
            <div className="profile-card-body">
                <form className="request-form" id="update-profile" onSubmit={handleFormSubmit}>
                     
                    
                    
                    <div className="form-row">
                        <div className="form-col">
                            <strong>{phrases?.lblName?.[lang]}</strong>
                            <FullNameInput 
                                data={personalInformation} 
                                setData={setPersonalInformation} 
                                name="name"
                                placeHolder={phrases?.lblEnterFullName?.[lang]}
                                setIsSubmitError={setIsSubmitError}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col">
                            <strong>{phrases?.lblEmailAddress?.[lang]}</strong>
                            <EmailInput 
                                data={personalInformation} 
                                setData={setPersonalInformation} 
                                name="email"
                                placeHolder={phrases?.lblEnterYourEmail?.[lang]}
                                setIsSubmitError={setIsSubmitError}
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-col">
                            <strong>{phrases?.lblMilitaryNumber?.[lang]}</strong>
                            <MilitaryNumberInput 
                                data={personalInformation} 
                                setData={setPersonalInformation} 
                                name="militaryNumber" 
                                placeHolder={phrases?.lblEnterMilitaryNumber?.[lang]}
                                setIsSubmitError={setIsSubmitError}

                            />
                        </div>
                    </div>


                    <div className="form-row">
                        <div className="form-col">
                            <strong>{phrases?.lblPhone?.[lang]}</strong>
                            <PhoneHandleWrap
                                data={personalInformation}
                                setData={setPersonalInformation}
                                name="phone"
                                placeHolder={phrases?.lblEnterYourPhoneNumber?.[lang]}
                                setIsSubmitError={setIsSubmitError}
                            />
                        </div>
                    </div> 


                    

                    <div className="request-form-action">
                        <div className="form-aciton">
                            <button form="update-profile" type="submit" className="site-btn" disabled={`${isSubmitted  ? "disabled" : ""}`}>
                                <span>{phrases?.lblUpdate?.[lang]}</span>
                                {
                                    isSubmitted
                                    ?   <div className="spinner"></div>
                                    :   null
                                }
                                
                            </button>
                            <p onClick={backToInfo} className={`${isSubmitted ? "disabled" : ""}`}>{phrases?.btnCloseAndGoBackToPersonalInfo?.[lang]}</p>
                        </div>
                    </div>
                    
                </form>
            </div>
        </>
    )
}

export default ProfileInformationEdit


