import React, {useEffect, useState} from 'react'
import Search from "../assets/icons/search.svg";
import Close from "../assets/icons/icons-close.svg";
import { SearchPoliceMan , SearchUser , GetAllUsers , GetPoliceMan } from '../api/profile';
import { getReportList } from '../api/requests_reports';

function SearchInput(props) {
    const [isValidToSubmit, setValidToSubmit] = useState(false)
    const [stopDoubleClick, setDoubleClick] = useState(false)
    const [searchText, setSearchText] = useState("");
    const {setData, clearSearch, setClearSearch, placeHolder, userType} = props;
    
 
    //- handle input change
    const handleInputChange = e => {
        setSearchText(e.target.value);
        setDoubleClick(false)
    };


    //- handle remove text
    const removeText = () => {
        setSearchText("");
        if(searchText !== '' && isValidToSubmit) {
            setValidToSubmit(false)
            setDoubleClick(true)
            removeSearchData();
        }
    };


    //- handle on ENTER click
    const handleKeyPress = e => {
        if (e.key === "Enter" && searchText !== '' && !stopDoubleClick) {
            setValidToSubmit(true)
            setDoubleClick(true)
            setClearSearch(false)
            getSearchData()
        }
    }


    //- handle on remove key up
    const handleKeyUp = e => {
        if (e.target.value === "" && isValidToSubmit) {
            setValidToSubmit(false)
            removeSearchData();
        }
    };


    //- get the data you searched for

    const getSearchData = () => {
        console.log({ searchText: searchText })
        setData(prevState => ({
            ...prevState,
            loading: true
        }))
        
        if(userType === 'user') {
            SearchUser(searchText)
                .then(response => {
                    setData({
                        loading: false,
                        data: response.data.data,
                        error: false,
                        length: Object.keys(response.data.data).length
                    })
                })
                .catch(error => {
                    console.log(error)
                    setData({
                        loading: false,
                        data: [],
                        error: true,
                        length: 0
                    })
                })
        }

        if(userType === 'police') {
            SearchPoliceMan(searchText)
                .then(response => {
                    setData({
                        loading: false,
                        data: response.data.data,
                        error: false,
                        length: Object.keys(response.data.data).length
                    })
                })
                .catch(error => {
                    console.log(error)
                    setData({
                        loading: false,
                        data: [],
                        error: true,
                        length: 0
                    })
                })
        }

        if(userType === 'report') {
            const submittedData = {
                reportName: searchText,
                pageIndex: 0
            }
            getReportList(submittedData)
                .then(response => {
                    const reportDataArray = [];
                    if(response.data.data.reports.length) {
                        response.data.data.reports.map(item => reportDataArray.push({...item, selected: false}))
                    }
                    setData({
                        loading: false,
                        data: reportDataArray,
                        totalCount: response.data.data.totalCount,
                        error: false
                    })
                })
                .catch(error => {
                    setData({
                        loading: false,
                        data: [],
                        totalCount: "",
                        error: true
                    })
                })
        }
        
            
    }


    //- get all data
    const removeSearchData = () => {
        setData(prevState => ({
            ...prevState,
            loading: true
        }))

        if(userType === 'user') {
            GetAllUsers()
                .then(response => {
                    setData({
                        loading: false,
                        data: response.data.data,
                        error: false,
                        length: response.data.data.length
                    })
                })
                .catch(error => {
                    console.log(error)
                    setData({
                        loading: false,
                        data: [],
                        error: true,
                        length: 0
                    })
                    
                })
        }


        if(userType === 'police') {
            GetPoliceMan()
                .then(response => {
                    setData({
                        loading: false,
                        data: response.data.data,
                        error: false,
                        length: response.data.data.length
                    })
                })
                .catch(error => {
                    console.log(error)
                    setData({
                        loading: false,
                        data: [],
                        error: true,
                        length: 0
                    })
                    
                })
        }

        if(userType === 'report') {
            const submittedData = {
                reportName: "",
                pageIndex: 0
            }
            getReportList(submittedData)
                .then(response => {
                    const reportDataArray = [];
                    if(response.data.data.reports.length) {
                        response.data.data.reports.map(item => reportDataArray.push({...item, selected: false}))
                    }
                    setData({
                        loading: false,
                        data: reportDataArray,
                        totalCount: response.data.data.totalCount,
                        error: false
                    })
                })
                .catch(error => {
                    setData({
                        loading: false,
                        data: [],
                        totalCount: "",
                        error: true
                    })
                })
        }
           
    }

    //- handle clear search
    useEffect(() => {
        if(clearSearch) {
            removeText()
        }
    }, [clearSearch])


    return (
        <div className="input-wrap hasSearchIcon">
            <img className="input-icon" src={Search} />
            <input
                className="site-input"
                type="text"
                placeholder={placeHolder}
                value={searchText}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                onKeyUp={handleKeyUp}
            />
            {
                searchText !== "" 
                ?   <img className="remove-text" src={Close} onClick={removeText} />
                :   null
            }
        </div>
    )
}

export default SearchInput
