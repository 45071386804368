import React, { useContext, useState, useEffect } from 'react'
import $ from "jquery";
import { langContext } from '../context/langContext';
import { PhrasesContext } from '../context/PhrasesContext';

function MilitaryNumberInput({data, setData, name, placeHolder, setIsSubmitError, setMilitaryNumber}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    const [validateMsg, setValidateMsg] = useState("")
    useEffect(() => {
        if (data[name].toString().length === 0) {
            setValidateMsg(phrases?.lblMilitaryNumberIsRequired?.[lang])
        }
        if (data[name].toString().length < 2 && data[name].toString().length !== 0) {
            setValidateMsg(phrases?.lblMilitaryNumberMinMax?.[lang])
        }
    }, [phrases, lang])


    // handle value change
    const handleValueChange = e => {
        var numbers = /^[0-9]+$/;
        if (!e.target.value.match(numbers) && e.target.value !== '') {
            return;
        }
        if (e.target.value.length > 5) {
            return;
        }
        setData({ ...data, [name]: e.target.value });
        if(setMilitaryNumber) {
            setMilitaryNumber(e.target.value)
        }
        $(e.target).removeClass("invalid-input");
        $(e.target).closest(".form-col").removeClass("invalid-col");
        setIsSubmitError(false)
    };

    // handle focus out
    const hanldeFocusOut = e => {
        // Military Number Custom Validation
        if (data[name].toString().length === 0) {
            $(`.${name}`).closest(".form-col").addClass("invalid-col");
            $(`.${name}`).addClass("invalid-input");

            setValidateMsg(phrases?.lblMilitaryNumberIsRequired?.[lang])

        } else if (data[name].toString().length < 2) {
            $(`.${name}`).closest(".form-col").addClass("invalid-col");
            $(`.${name}`).addClass("invalid-input");
            
            setValidateMsg(phrases?.lblMilitaryNumberMinMax?.[lang])
            
        } else {
            console.log("===> Military Number Validate <===");
        }
    }

    // handle key down
    const hanldeMilitartKeydown = e => {
        if (e.which === 38 || e.which === 40) {
            e.preventDefault();
        }
    };

    // handle mouse wheel
    const handleWheel = e => {
        e.target.blur();
    };


    // handle focus out
    return (
        <>
            <div className="input-wrap">
                <input 
                    className={`site-input requiredData ${name}`} 
                    type="text" 
                    placeholder={placeHolder}
                    name={name}
                    value={data[name]}
                    onChange={handleValueChange}
                    onKeyDown={hanldeMilitartKeydown}
                    onWheel={handleWheel}
                    onBlur={hanldeFocusOut}
                    autoComplete="off"
                    inputMode="numeric"
                    pattern="\d*"

                />
            </div>
            <p className="error-msg">{validateMsg}</p>
        </>
    )
}

export default MilitaryNumberInput
