import React, { useEffect, useContext, useState } from 'react'
import { useHistory } from "react-router-dom";
import useOnclickOutside from "react-cool-onclickoutside";
import DropDownArrow from "../../../../assets/icons/down-arrow-white.png";

import Files from "../../../../assets/icons/files.svg";
import Arrow from "../../../../assets/icons/icons-arrow.svg";
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';
import ReportDownloadShell from '../../ReportsShell/ReportDownloadShell';
import ReportPrintShell from '../../ReportsShell/ReportPrintShell';

function ReportsCompareHead({reportDataFirst, reportDataSecond, chartView, setChartView}) {
    const history = useHistory();

    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)

    const [actionsListView, setActionsListView] = useState(false)

    const handleActionsDropdown = () => {
        setActionsListView(prevState => !prevState)
    }
    const actionsListRef = useOnclickOutside(() => {
        setActionsListView(false)
    });
    useEffect(() => {
        if(actionsListView) {
            document.getElementById('dropdown-list').style.display = 'block'
        } else {
            document.getElementById('dropdown-list').style.display = 'none'
        }
    }, [actionsListView])


    const backToReportList = () => {
        history.push('/report')
    }

    const handleChartView = (viewType) => {
        setChartView(viewType)
    }


    return (
        <>
            <div className="head-level-1">
                <div className="head-back-wrap">
                    <div className="head-back" onClick={backToReportList}>
                        <img src={Arrow} alt="icon" />
                    </div>
                    <div className="head-title">
                        <img src={Files} alt="icon" />
                        <p>
                            <span>
                                {phrases?.lblComparing?.[lang]} 
                                <strong>{reportDataFirst.report.reportName}</strong>
                                {phrases?.lblWith?.[lang]}
                                <strong>{reportDataSecond.report.reportName}</strong>
                            </span>
                        </p>
                    </div> 
                    
                </div>
                <div className="head-actions">
                    <div className="mobile-key site-btn" onClick={handleActionsDropdown}>
                        <span>{phrases?.lblActions?.[lang]}</span>
                        <img src={DropDownArrow} alt="icon" />
                    </div>
                    <div className="actions-list" id="dropdown-list" ref={actionsListRef}>
                        <ul>
                            {
                                chartView === 'merged'
                                ?   <li className="site-btn white seperate" onClick={() => handleChartView('separated')}>
                                        <img src={Files} alt="icon" />
                                        <span>{phrases?.lblSeparatedView?.[lang]}</span>
                                    </li>
                                :   <li className="site-btn white seperate" onClick={() => handleChartView('merged')}>
                                        <img src={Files} alt="icon" />
                                        <span>{phrases?.lblMergedView?.[lang]}</span>
                                    </li>
                            }
                            

                            

                            <ReportDownloadShell reportData={reportDataFirst} />
                            <ReportPrintShell reportData={reportDataFirst} />
                            
                        </ul>
                    </div>
                    
                </div>
                
            </div>
        </>
    )
}

export default ReportsCompareHead
