import React, { useContext, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import IconButton from "@material-ui/core/IconButton";

import CalendarPicker from "../CalendarPicker";

import { langContext } from "../../context/langContext";
import { PhrasesContext } from "../../context/PhrasesContext";
import { structureStatisticsBarData } from "../../Pages/DashboardPage/helpers";

import calendarIcon from "../../assets/icons/icons-calendar.svg";
import { PickedLocationContext } from "../../context/pickedLocationContext";

const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

const RequestsStatisticsBar = ({
  statistics = [],
  filterValue,
  setFilterValue,
}) => {
  const [view, setView] = useState(false);
  const barData = structureStatisticsBarData(statistics);
  const [pickedLocation, setPickedLocation] = useContext(PickedLocationContext);
  const { from, to } = pickedLocation.dateRangeFormationDashBoard;

  const ref = useOnclickOutside(() => {
    setView(false);
  });
  let today = new Date();
  let yesterday = new Date();

  yesterday.setDate(today.getDate() - 1);

  const { lang } = useContext(langContext);
  const [phrases] = useContext(PhrasesContext);

  const { totalAmount, completedAmount, canceledAmount } = barData;
  const completePer = (completedAmount / totalAmount) * 100;
  const canceltePer = (canceledAmount / totalAmount) * 100;
  const completeWidth = (167 * completePer) / 100;
  const cancellWidth = (167 * canceltePer) / 100;

  return (
    <div className="header">
      <div className="header-left">
        <div className="status-div">
          <div className="Oval">
            <div className="Oval-in">{totalAmount}</div>
          </div>
          <div className="request-labels">
            <span>{phrases?.lblTotalRequests?.[lang]}</span>
            <label className="total">
              {phrases?.lblTotalDailyRequests?.[lang]}
            </label>
            <label className="total">{phrases?.lblAcrossDubai?.[lang]}</label>
          </div>
        </div>
        <div className="status-div">
          <div className="small-Oval">
            <div className="Oval-in">{completedAmount}</div>
          </div>

          <div className="request-labels">
            <span>{phrases?.lblCompletedRequests?.[lang]}</span>

            {isNaN(completePer) ? null : (
              <>
                <div className="rectangle">
                  <div
                    className="complete-rectangle"
                    style={{ width: completeWidth }}
                  />
                </div>

                <label>
                  {Math.round(completePer)}{" "}
                  {phrases?.lblRequestsPercentage?.[lang]}
                </label>
              </>
            )}
          </div>
        </div>
        <div className="status-div cancel">
          <div className="small-Oval cancel">
            <div className="Oval-in">{canceledAmount}</div>
          </div>

          <div className="request-labels">
            <span>{phrases?.lblCancelledRequests?.[lang]}</span>

            {isNaN(canceltePer) ? null : (
              <>
                <div className="rectangle">
                  <div
                    className="cancel-rectangle"
                    style={{ width: cancellWidth }}
                  />
                </div>

                <label>
                  {Math.round(canceltePer)}{" "}
                  {phrases?.lblRequestsPercentage?.[lang]}
                </label>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="header-right">
        <div className="date-rectangle">
          <span className="date-text">
            {pickedLocation.range
            ? `${from} ~ ${to}`
            : yesterday.toLocaleDateString(
                lang == "ar" ? "ar-EG" : "en",
                options
              )}
          </span>
          <div className="circle-BG">
            <IconButton
              style={{ outline: "0" }}
              onClick={() => {
                setView((currentView) => !currentView);
              }}
            >
              <img src={calendarIcon} alt="calendar" />
            </IconButton>
          </div>
        </div>
        <div
          style={{ display: view ? "flex" : "none" }}
          className="calendar-container"
          ref={ref}
        >
          <CalendarPicker
            setDataValue={(e) => setFilterValue({ dateFilter: e })}
            datavalue={filterValue}
            closePicker={() => setView(false)}
            isReports={false}
          />
        </div>
      </div>
    </div>
  );
};

export default RequestsStatisticsBar;
