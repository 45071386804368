import React, { useEffect, useState, useContext } from 'react'
import reportIconGray from "../../../../../assets/icons/report-btn-icon-gray.svg";
import { langContext } from '../../../../../context/langContext';
import { PhrasesContext } from '../../../../../context/PhrasesContext';
import { modalContext } from '../../../../../context/modalContext';
import compareFilesIcon from '../../../../../assets/icons/share-files.png';

export default function ReportCompareBtn() {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    const {setModalState} = useContext(modalContext)

    const [isResponsve, setResponsive] = useState(false)

    useEffect(() => {
        const dynamicResponsive = (media) => {
            if(media.matches) {
                setResponsive(true)
            }
        }
        let activeMedia = window.matchMedia("(max-width: 1281px)");
        dynamicResponsive(activeMedia)

    }, [])

    
    const showCompare = () => {
        setModalState('showCompareModal')
    }

    return (
        <li className={`site-btn ${isResponsve ? 'white' : ''}`} onClick={showCompare}>
            <img src={isResponsve ? reportIconGray : compareFilesIcon} alt="icon" />
            <span>{phrases?.lblCompare?.[lang]}</span>
        </li>
    )

}
