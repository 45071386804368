import React, { useContext } from "react";
import ArrowGray from "../../assets/icons/icons-arrow-gray.svg";
import ArrowAr from "../../assets/icons/icons-arrow-left.svg";
import { itemIdContext } from "../../context/itemIdContext";
import { ListOnMapContext } from "../../context/listOnMap";
import { requestContext } from "../../context/reqeustContext";
import { langContext } from "../../context/langContext";
import { PhrasesContext } from "../../context/PhrasesContext";
import { PinContext } from "../../context/pinContext";
import { ListMainStatus } from "../../Utils/utils";
import { PickedLocationContext } from "../../context/pickedLocationContext";

function ReqeustItem({ item }) {
  const { setItemId } = useContext(itemIdContext);
  const [list, , updateRequestsList] = useContext(ListOnMapContext);
  const { lang } = useContext(langContext);
  const [phrases] = useContext(PhrasesContext);
  const [showPin, changePinStatus] = useContext(PinContext);
  const [location, changeLocation] = useContext(PickedLocationContext);

  const { setRequestStatus } = useContext(requestContext);
  const showDetailedRequest = id => {
    setItemId(id);
    setRequestStatus("showDetailed");
    changePinStatus(false);
    let filteredArray = list.filter(
      listItem => listItem?.requestId == item?.requestId
    );
    updateRequestsList(filteredArray);
    changeLocation({
      longitude: item.pickUpLongtitude,
      latitude: item.pickUpLatitude,
      title: item.pickUpTitle,
      address: item.pickUpAddress,
    });
  };

  return (
    <div
      className={`request-card-item ${ListMainStatus[item.statusId]}`}
      onClick={() => {
        showDetailedRequest(item.requestId);
      }}
    >
      <div className="request-card-head">
        <div className="request-card-head-left">
          <div className="request-card-id">
            <span>{phrases?.lblRequestID?.[lang]}</span>
            <strong>{item.ccExternalId}</strong>
          </div>
          <div className="request-card-status">
            <strong>
              {lang === "en" ? item.statusNameEn : item.statusNameAr}
            </strong>
          </div>
        </div>
        <div className="request-card-head-right">
          <div className="request-card-time">
            <svg className="icon" width="16" height="16">
              <g fill="none" fillRule="evenodd" opacity=".6">
                <g fill="#222B45" fillRule="nonzero">
                  <g>
                    <g>
                      <g>
                        <path
                          d="M8 0C3.564 0 0 3.564 0 8s3.564 8 8 8 8-3.564 8-8-3.564-8-8-8zm0 14.545c-3.636 0-6.545-2.909-6.545-6.545S4.364 1.455 8 1.455 14.545 4.364 14.545 8 11.636 14.545 8 14.545zm2.69-4.872c.292.29.292.727 0 1.018-.145.145-.29.218-.508.218-.218 0-.364-.073-.51-.218L7.492 8.509c-.146-.145-.218-.29-.218-.509V3.636c0-.436.29-.727.727-.727.436 0 .727.291.727.727V7.71l1.964 1.964z"
                          transform="translate(-1739 -233) translate(1374 214) translate(1) translate(364 19)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <strong>
              {new Date(item.creationTime).toLocaleString(
                `${lang === "en" ? "en-EG" : "ar-EG"}`,
                {
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )}
            </strong>
          </div>
        </div>
      </div>
      <div className="request-card-body">
        <div className="request-card-location">
          <strong>{phrases?.lblRequestLocation?.[lang]}</strong>
          <p>{item.pickUpAddress}</p>
        </div>
        <div className="request-card-action">
          <img src={lang == "en" ? ArrowGray : ArrowAr} alt="ArrowGray" />
        </div>
      </div>
    </div>
  );
}

export default ReqeustItem;
