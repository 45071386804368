import React, { useState } from 'react';

const LoginContext = React.createContext([() => {}]);

const LoginProvider = ({ children }) => {
	const [userValidates, setUserValidates] = useState(
		{
			isAuth:false,
			isPasswordForgotten:false,
			isResetPassword: false,		
			isValidUser: localStorage.getItem('isValidUser') ? true : false
		}
	);

	const [userData , setUserData] = useState({})


	const updateUserValidates = (validates) => {
		setUserValidates(validates);
	};

	const updateUserData = (userData)=>{
		setUserData(userData)
	}

	return (
		<LoginContext.Provider value={[userValidates, updateUserValidates , userData, updateUserData]}>
			{children}
		</LoginContext.Provider>
	);
};

export { LoginProvider, LoginContext };
