import React, { useContext, useEffect, useRef, useState } from 'react'
import downArrow from "../../../../../../assets/icons/down-arrow-green.svg";
import useOnclickOutside from "react-cool-onclickoutside";
import { langContext } from '../../../../../../context/langContext';
import { PhrasesContext } from '../../../../../../context/PhrasesContext';
import AdvancedSearchListItem from '../../../../../MyRequestReport/components/AdvancedSearch/AdvancedSearchListItem';


function ReportsCompareChartsStatusFilter({setDataToShow, totalData, cancelledData, completedData, labelsString}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)

    const [showDropDown, setShowDropDown] = useState(false)
    const textRef = useRef(null)
    //- handle dropdown
    const hanldeDropdown = () => {
        setShowDropDown(prevState => !prevState)
    }

    //- hanlde outside click
    const dropDownKey = useOnclickOutside(() => {
        setShowDropDown(false)
    }, { ignoreClass: "ignore-this" });


    //- advanced search list
    const [advancedSearchList, setAdvancedSearchList] = useState([
        {id: 1, name: "lblTotalRequests", selected: true},
        {id: 2, name: "lblCompletedRequests", selected: false},
        {id: 3, name: "lblCancelledRequests", selected: false},
    ])

    useEffect(() => {
        advancedSearchList.map(item => {
            if(item.id === 1 && item.selected) {
                setDataToShow({labels: labelsString, datasets: totalData})
            }
            if(item.id === 2 && item.selected) {
                setDataToShow({labels: labelsString, datasets: completedData})
            }
            if(item.id === 3 && item.selected) {
                setDataToShow({labels: labelsString, datasets: cancelledData})
            }
        })
    }, [advancedSearchList])


    return (
        <div className="advanced-filter-contain hide-on-print-item" id="hide-on-print">
            <div className="advanced-filter-wrap">
                <div className="advanced-filter-select">
                    <div className="input-wrap">
                        <div className="request-select">
                            <div className={`request-select-head ignore-this`} onClick={hanldeDropdown} ref={dropDownKey}>
                                <div className="request-select-text">
                                    <span ref={textRef}>{phrases?.lblTotalRequests?.[lang]}</span>
                                </div>
                                <img src={downArrow} width="11" alt="icon" />
                            </div>
                            {
                                showDropDown
                                ?   <div className="request-select-body ignore-this" >
                                        <ul>
                                            {
                                                advancedSearchList.map(item => {
                                                    return (
                                                        <AdvancedSearchListItem 
                                                            key={item.id} 
                                                            item={item} 
                                                            setShowDropDown={setShowDropDown}
                                                            advancedSearchList={advancedSearchList}
                                                            setAdvancedSearchList={setAdvancedSearchList}
                                                            textRef={textRef}
                                                        />
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                :   null
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReportsCompareChartsStatusFilter
