import React from 'react';



export const activeSubRequestContext = React.createContext();
export const activeSubRequestInitialState = ''


export const activeSubRequestReducer = (state = activeSubRequestInitialState, action) => {
    return state = action
}