import React, { useState, useContext } from 'react'
import CheckMark from "../../../../assets/icons/icons-check-mark.svg";
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';

function RoleSelect({data, setData}) {
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
  
    const [isAdmin, setAdminStatus] = useState(data.isAdmin)
    const handleSelect = (role) => {
        setAdminStatus(role)
        setData({...data, isAdmin: role})
    }
    return (
        <>
            <div className="form-col half">
                <div className="request-select">
                    <div className={`request-select-head ${!isAdmin && 'selected'}`} onClick={() => handleSelect(false)}>
                        <div className={`request-select-text`}>
                            <span>{phrases?.lblUser?.[lang]}</span>
                        </div>
                        <img className="request-select-img" src={CheckMark} alt="icon" />
                    </div>
                </div>
            </div>

            <div className="form-col half">
                <div className="request-select">
                    <div className={`request-select-head ${isAdmin && 'selected'}`} onClick={() => handleSelect(true)}>
                        <div className={`request-select-text`}>
                            <span>{phrases?.lblAdmin?.[lang]}</span>
                        </div>
                        <img className="request-select-img" src={CheckMark} alt="icon" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default RoleSelect
