import React from 'react'


export const langContext = React.createContext();
export const langInitalState = localStorage.getItem('lang') || 'en';



export const langReducer = (state = langInitalState, action) => {
    switch(action) {
        case 'en':
            return 'en'
        case 'ar':
            return 'ar'
        default:
            return state
    }
}


