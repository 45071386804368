import React from "react";
export const serverDownContext = React.createContext();
export const serverDownState = false;


export const serverDownReducer = (state = serverDownState, action) => {
    switch(action) {
        case 'serverIsDown':
            return true
        default:
            return state;
    }
}

