import React, { useContext } from 'react'
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';

function AdvancedSearchListItem({item, setShowDropDown, textRef, advancedSearchList, setAdvancedSearchList}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)

    const hanldeListClick = relatedData => {
        textRef.current.innerHTML = phrases[relatedData.name][lang]
        setAdvancedSearchList(advancedSearchList.map(item => item.id === relatedData.id ? {...item, selected: true} : {...item, selected: false}))
        setShowDropDown(false)
    }

    return (
        <>
            <li onClick={() => {hanldeListClick(item)}}>
                <span>{phrases[item.name][lang]}</span>
            </li>
            
        </>
    )
}

export default AdvancedSearchListItem
