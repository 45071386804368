import React, {
  useContext,
  useEffect,
  useState,
  useReducer,
  useMemo,
  useRef,
} from "react";
import { useReactToPrint } from "react-to-print";

import _get from "lodash/get";
import ReactTable from "react-table";
import moment from "moment";
import calendarIcon from "../../assets/icons/icons-calendar.svg";
import useOnclickOutside from "react-cool-onclickoutside";
import SearchIcon from "../../assets/icons/search.svg";
import DownLoadIcon from "../../assets/icons/download-icon.png";
import PdfIcon from "../../assets/icons/PDF.svg";
import XlsIcon from "../../assets/icons/XLS.svg";
import CsvIcon from "../../assets/icons/CSV.svg";

import printIcon from "../../assets/icons/printer-icon.png";
import Files from "../../assets/icons/files.svg";
import InfiniteScroll from "react-infinite-scroll-component";

import Close from "../../assets/icons/icons-close.svg";

import Loader from "../../Components/Loader";

import { downloadExcel, getReports } from "../../api/requests_reports";
import { PhrasesContext } from "../../context/PhrasesContext";
import { langContext } from "../../context/langContext";

import "react-table/react-table.css";
import "./RequestBoardPage.scss";
import IconButton from "@material-ui/core/IconButton";
import CalendarPicker from "../../Components/CalendarPicker";
import SearchComponent from "../../Components/SearchComponent";
import { PickedLocationContext } from "../../context/pickedLocationContext";
import SearchInput from "../../shell/SearchInput";
import {
  handleDownloadCsv,
  handleDownloadPdf,
  handleDownloadExcel,
} from "./helpers";
import DataTable from "../../Components/DataTable/DataTable";

function ReportsTableHeader({
  lang,
  phrases,
  reports,
  filter,
  handleReset,
  handlePrint,
}) {
  const [isDropdownOn, setDropDownStatus] = useState(false);
  const showReportsDropdown = () => {
    setDropDownStatus(prevState => !prevState);
  };
  const dropDownRef = useOnclickOutside(() => {
    setDropDownStatus(false);
  });

  return (
    <div className="reports-table-head">
      <div className="reports-table-head-title">
        <div className="reports-table-head-title-icon">
          <img src={Files} alt="icon" />
          <strong>{phrases?.lblShowingRequests?.[lang]}</strong>
        </div>
        <p>({`${reports.length} ${phrases?.lblRequestsFound?.[lang]}`})</p>
      </div>
      <div className="reports-table-head-actions">
        <div
          className="reports-table-head-actions-item site-btn reset"
          onClick={handleReset}
        >
          {/* <img src={DownLoadIcon} alt="icon" /> */}
          <span>{phrases?.btnResetData?.[lang]}</span>
        </div>
        <div
          className="reports-table-head-actions-item site-btn white"
          onClick={showReportsDropdown}
        >
          <img src={DownLoadIcon} alt="icon" />
          <span>{phrases?.btnDownload?.[lang]}</span>
          {isDropdownOn ? (
            <div className="reports-dropdown" ref={dropDownRef}>
              <ul>
                <li onClick={() => handleDownloadExcel(filter)}>
                  <img src={XlsIcon} alt="icon" />
                  <span>Excel</span>
                </li>
                <li onClick={() => handleDownloadPdf(filter)}>
                  <img src={PdfIcon} alt="icon" />
                  <span>PDF</span>
                </li>
                <li onClick={() => handleDownloadCsv(filter)}>
                  <img src={CsvIcon} alt="icon" />
                  <span>CSV</span>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
        <div
          className="reports-table-head-actions-item site-btn white"
          onClick={handlePrint}
        >
          <img src={printIcon} alt="icon" />
          <span>{phrases?.btnPrint?.[lang]}</span>
        </div>
      </div>
    </div>
  );
}

const ReportsFilters = ({
  lang,
  phrases,
  setFireSearch,
  fireSearch,
  initialDateValue = [new Date(), new Date()],
  propagateDateFilterValue = _x => _x,
}) => {
  const [searchText, setSearchText] = useState("");
  const [viewCalendar, setViewCalendar] = useState(false);
  const [pickedLocation, setPickedLocation] = useContext(PickedLocationContext);

  const ref = useOnclickOutside(() => {
    setViewCalendar(false);
  });

  const hanldeSearchText = e => {
    setPickedLocation({ ...pickedLocation, reportLocation: e.target.value });
    setSearchText(e.target.value);
  };

  const clearSearchText = () => {
    setPickedLocation({ ...pickedLocation, reportLocation: "" });

    setSearchText("");
    setFireSearch(!fireSearch);
  };

  const handleSubmitSearch = e => {
    if (e.keyCode === 13) {
      setFireSearch(!fireSearch);
      setViewCalendar(false);

      return false;
    }
  };

  return (
    <div className="portal-table-head heightAuto">
      <div className="report-head-wrap">
        <div className="reports-search-wrap">
          <strong>{phrases?.lblLocation?.[lang]}</strong>
          <div className="input-wrap hasSearchIcon">
            <img className="input-icon" src={SearchIcon} />
            <input
              className="site-input"
              onKeyDown={handleSubmitSearch}
              type="text"
              placeholder={phrases?.lblSearchToAddLocations?.[lang]}
              value={pickedLocation.reportLocation}
              onChange={hanldeSearchText}
            />
            {searchText !== "" ? (
              <img
                className="remove-text"
                src={Close}
                onClick={clearSearchText}
              />
            ) : null}
          </div>
        </div>

        <div className="reports-date-wrap">
          <strong>{phrases?.lblDate?.[lang]}</strong>
          <div className="input-wrap">
            <div className="site-input">
              <span>
                {pickedLocation.range
                  ? `${pickedLocation.dateRangeFormationReports.from}~${pickedLocation.dateRangeFormationReports.to}`
                  : phrases?.lblYesterday?.[lang]}
              </span>
              <div className="circle-BG">
                <IconButton
                  style={{ outline: "0" }}
                  onClick={() => setViewCalendar(prev => !prev)}
                >
                  <img src={calendarIcon} alt="calendar" />
                </IconButton>
              </div>
            </div>

            <div style={{ display: viewCalendar ? "flex" : "none" }} ref={ref}>
              <CalendarPicker
                setDataValue={e => {
                  propagateDateFilterValue(e);
                }}
                datavalue={initialDateValue}
                closePicker={() => setViewCalendar(false)}
                isReports
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function RequestBoardPage() {
  const [phrases] = useContext(PhrasesContext);
  const { lang } = useContext(langContext);
  const [pickedLocation, setPickedLocation] = useContext(PickedLocationContext);
  const [fireSearch, setFireSearch] = useState(false);
  const tableRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });
  const [
    { datavalue, reports, loading, error, page, totalReports, loadingFooter },
    dispatch,
  ] = useReducer((base, next) => ({ ...base, ...next }), {
    datavalue: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    reports: [],
    loading: false,
    loadingFooter: false,
    error: null,
    page: 0,
    totalReports: 0,
  });

  const filter = useMemo(
    () => ({
      from: moment(datavalue[0]).startOf("day").format(),
      to: moment(datavalue[datavalue.length - 1])
        .endOf("day")
        .format(),
      pageIndex: page,
      pickUpAddress: pickedLocation.reportLocation,
    }),
    [datavalue, page, pickedLocation.reportLocation]
  );

  const handleReset = () => {
    let today = new Date();
    let yesterday = new Date();
    dispatch({ loading: true });
    setPickedLocation({ pickedLocation, reportLocation: "", range: null });
    getReports({
      ...filter,
      from: moment(yesterday.setDate(today.getDate() - 1))
        .startOf("day")
        .format(),
      to: moment(yesterday.setDate(today.getDate() - 1)).format(),
    }).then(res => {
      dispatch({
        reports: _get(res, "data.data.requests", []),
        datavalue: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        loading: false,
        error: null,
        totalReports: _get(res, "data.data.totalCount", 0),
        page: 0,
      });
    });
  };

  useEffect(() => {
    (async () => {
      dispatch({ loading: true });

      try {
        const reportsResponse = await getReports({ ...filter, pageIndex: 0 });

        setPickedLocation({
          ...pickedLocation,
          dateRangeFormationReports: {
            from: new Date(filter.from).toLocaleDateString(),
            to: new Date(filter.to).toLocaleDateString(),
          },
        });

        dispatch({
          reports: _get(reportsResponse, "data.data.requests", []),
          loading: false,
          error: null,
          totalReports: _get(reportsResponse, "data.data.totalCount", 0),
          page: 0,
        });
      } catch (err) {
        dispatch({ loading: false, error: err });
      }
    })();
  }, [datavalue, fireSearch]);

  if (loading)
    return (
      <div className="dashboard-loader">
        <Loader />
      </div>
    );

  if (error)
    return (
      <div className="dashboard-error">
        <h1>{phrases?.lblSomethingWrong?.[lang]}</h1>
      </div>
    );

  const columns = [
    {
      label: phrases?.lblRequestID?.[lang],
      id: "requestId",
      style: {
        minWidth: "200px",
        maxWidth: "200px",
      },
    },
    {
      label: phrases?.lblRequestDate?.[lang],
      id: "creationTime",
      style: {
        minWidth: "200px",
        maxWidth: "200px",
      },
    },
    {
      label: phrases?.lblRequestType?.[lang],
      id: lang == "en" ? "requestTypeNameEn" : "requestTypeNameAr",
      style: {
        minWidth: "200px",
        maxWidth: "200px",
      },
    },
    {
      label: phrases?.lblPickupLocationCCPAR?.[lang],
      id: "pickUpAddress",
      style: {
        minWidth: "320px",
        maxWidth: "400px",
      },
    },
    {
      label: phrases?.lblPoliceman?.[lang],
      id: "ccPolicemanName",
      style: {
        minWidth: "229px",
        minWidth: "229px",
      },
    },
  ];

  return (
    <div className={`data-container profile reports-container`}>
      <div className="data-wrapper">
        <div className="portal-contain">
          <div className="portal-wrap">
            <div className="portal-body">
              <div className="portal-table-contain">
                <div className="portal-table-wrap">
                  <ReportsTableHeader
                    lang={lang}
                    phrases={phrases}
                    reports={reports}
                    filter={filter}
                    handleReset={handleReset}
                    handlePrint={handlePrint}
                  />

                  <ReportsFilters
                    lang={lang}
                    phrases={phrases}
                    dispatch={dispatch}
                    setFireSearch={setFireSearch}
                    fireSearch={fireSearch}
                    initialDateValue={datavalue}
                    propagateDateFilterValue={dateFilter =>
                      dispatch({ datavalue: dateFilter })
                    }
                  />

                  <div ref={tableRef} id="section-to-print">
                    <InfiniteScroll
                      dataLength={reports.length}
                      next={async () => {
                        try {
                          dispatch({ loadingFooter: true });

                          const reportsResponse = await getReports({
                            ...filter,
                            pageIndex: page + 1,
                          });

                          dispatch({
                            reports: reports.concat(
                              _get(reportsResponse, "data.data.requests", [])
                            ),
                            loadingFooter: false,
                            page: page + 1,
                          });
                        } catch (err) {
                          dispatch({
                            error: err,
                            loadingFooter: false,
                          });
                        }
                      }}
                      hasMore={totalReports > reports.length ? true : false}
                    >
                      <DataTable
                        columns={columns}
                        data={reports}
                        loading={reports.length < totalReports && loadingFooter}
                        error={error}
                      />
                    </InfiniteScroll>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestBoardPage;
