import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from "react-router-dom";

import { modalContext } from '../../../../context/modalContext';
import PortalUserViewData from './PortalUserViewData';
import userIconAdd from "../../../../assets/icons/user-create-white.svg";
import dobuleUserIcon from "../../../../assets/icons/double-user-icon-white.svg";

import { getAllUsers } from '../../../../api/login';
import { userListContext } from '../../../../context/userListContext';
import SearchInput from '../../../../shell/SearchInput';
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';

import ModalContainer from '../../../../Modals/ModalContainer';
import DataSuccessModal from '../../../../Modals/DataSuccessModal';
import { userContext } from '../../../../context/userContext';

function PortalUserView() {
    const history = useHistory();
    const {userContextDispatch} = useContext(userContext)
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    const {userStatus} = useContext(userListContext)
    const { setModalState, modalState } = useContext(modalContext) 
    const [clearSearch, setClearSearch] = useState(false);
    const [usersList, setUsersList] = useState({ loading: true, data: [], error: false, length: 0 })
    const [isUsersListEmpty, setUserListEmpty] = useState(false)

    const showCreateUser = () => {
        setModalState('showUserAdd')
    }

    // get all users
    useEffect(() => {
        setUsersList(prevState => ({
            ...prevState,
            loading: true
        }))
        getAllUsers()
            .then(response => {
                console.log('Users List ==> ', response.data.data)
                setUsersList({
                    loading: false,
                    data: response.data.data,
                    error: false,
                    length: response.data.data.length
                })
                setUserListEmpty(response.data.data.length === 0 ? true : false)
            })
            .catch(error => {
                console.log('Users List Error ==> ', error)
                setUsersList({
                    loading: false,
                    data: [],
                    error: true,
                    length: 0
                })
              
            })
    }, [userStatus])
    


    //- hanlde clear search
    const hanldeClearSearch = () => {
        setClearSearch(true)
    }


    return (
        <>
            {
                isUsersListEmpty &&
                    <div className="general-card-contain">
                        <div className="general-card-wrap">
                            <div className="general-card">
                                <div className="general-card-icon">
                                    <img src={dobuleUserIcon} alt="icon" />
                                </div>
                                <div className="general-card-body">
                                    <div className="site-btn" onClick={showCreateUser}>
                                        <img src={userIconAdd} alt="icon" />
                                        <span>{phrases?.lblCreateNewUser?.[lang]}</span>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
            }
            {
                !isUsersListEmpty &&
                    <div className="portal-table-contain">
                        <div className="portal-table-wrap">


                            <div className="portal-table-head">
                                <div className="portal-table-head-title">
                                    <div className="portal-title">
                                        {
                                            usersList.loading
                                            ?   <div className="card-loader data" style={{ width: '100%', height: '33px', marginBottom: '0px' }}></div>
                                            :   usersList.error
                                                ?   <p>Opps! something went wrong.</p>
                                                :   <>
                                                        <strong>{phrases?.lblAllUsers?.[lang]}</strong>
                                                        <span>
                                                            {`(${usersList.length} ${phrases?.lblUser?.[lang]})`}
                                                        </span>
                                                    </>
                                        }
                                    </div>
                                    <div className="portal-search">
                                        <SearchInput 
                                            setData={setUsersList}
                                            userType='user'
                                            clearSearch={clearSearch}
                                            setClearSearch={setClearSearch}
                                            placeHolder={phrases?.lblSearchUsers?.[lang]} 
                               
                                        />
                                    </div>
                                </div>
                                <div className="portal-table-head-action">
                                    {
                                        usersList.loading
                                        ?   <div className="card-loader data" style={{ width: '185px', height: '50px', marginBottom: '0px' }}></div>
                                        :   usersList.error
                                            ?   <p>Opps! something went wrong.</p>
                                            :   <>
                                                    <div className="site-btn" onClick={showCreateUser}>
                                                        <img src={userIconAdd} alt="icon" />
                                                        <span>{phrases?.lblCreateNewUser?.[lang]}</span>
                                                    </div>
                                                </>
                                    }
                                    
                                </div>
                            </div>


                            <div className="portal-table-body">
                                <div className="portal-table">
                                    {
                                        usersList.loading
                                        ?   <>
                                                <div className="card-loader data" style={{ width: '100%', height: '60px' }}></div>
                                                <div className="card-loader data" style={{ width: '100%', height: '60px' }}></div>
                                                <div className="card-loader data" style={{ width: '100%', height: '60px' }}></div>
                                                <div className="card-loader data" style={{ width: '100%', height: '60px' }}></div>
                                            </>
                                        :   usersList.error
                                            ?   <p>Opps! something went wrong.</p>
                                            :   usersList.length === 0
                                                ?   <>
                                                        <div className="no-active-request sub table" style={{marginBottom: '96px'}}>
                                                            <div className="no-active-request-icon">
                                                                <img src={dobuleUserIcon} alt="icon" />
                                                            </div>
                                                            <div className="no-active-request-body">
                                                                <strong>{phrases?.lblNoMatchingUsers?.[lang]}</strong>
                                                                <p>{phrases?.lblThereWereNoMatchingUsers?.[lang]}</p>
                                                                <p className="clear-search-text" onClick={hanldeClearSearch}>{phrases?.lblClearSearchResultsAndDisplayAllUsers?.[lang]}</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                :   
                                                    <>
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th className="nameRow">{phrases?.lblName?.[lang]}</th>
                                                                        <th className="userNameRow">{phrases?.lblUsername?.[lang]}</th>
                                                                        <th className="emailRow">{phrases?.lblEmailAddress?.[lang]}</th>
                                                                        <th className="militaryRow">{phrases?.lblMilitaryNumber?.[lang]}</th>
                                                                        <th className="phoneRow">{phrases?.lblPhoneNo?.[lang]}</th>
                                                                        <th className="roleRow">{phrases?.lblRole?.[lang]}</th>
                                                                        <th className="statusRow">{phrases?.lblStatus?.[lang]}</th>
                                                                        <th className="actionsRow">{phrases?.lblAction?.[lang]}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        usersList.data.map((item) => {return (
                                                                            <PortalUserViewData data={item} key={item.id} />
                                                                        )})
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
            }

            {
                modalState.userAddedSucces &&
                    <ModalContainer>
                        <DataSuccessModal msg={phrases?.lblUserAddedSuccessfully?.[lang]} />
                    </ModalContainer>
            }

            {
                modalState.userUpdatedSucces &&
                    <ModalContainer>
                        <DataSuccessModal msg={phrases?.lblUserUpdatedSuccessfully?.[lang]} />
                    </ModalContainer>
            }
            
        </>
    )
}

export default PortalUserView
