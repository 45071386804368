import React, { useState , useEffect} from 'react';

const PickedLocationContext = React.createContext([() => {}]);

const PickedLocationProvider = ({ children }) => {
	const [location, setLocation] = useState(
		{
			longitude:"",	
			latitude:""	,
			title:"",
			address:"",
		}
	);

	const changeLocation = (newLocation) => {
		setLocation(newLocation);
	};
	return (
		<PickedLocationContext.Provider value={[location, changeLocation]}>
			{children}
		</PickedLocationContext.Provider>
	);
};

export { PickedLocationProvider, PickedLocationContext };
