import React, {useCallback, useContext, useState} from 'react'
import printJS from 'print-js';
import _get from 'lodash/get';

import ButtonSpinner from '../../../ButtonSpinner/ButtonSpinner';

import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';
import printIcon from "../../../../assets/icons/printer-icon.png";
import { printPDFFile } from '../../../../api/requests_reports';
import { RequestListContext } from '../../../../context/RequestListContext';

function PrintBtn({ columns = [], data = {} }) {
    const [report, setReport, filter, setFilter] = useContext(RequestListContext)
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    const [printing, setPrinting] = useState(false);
    const [, setError] = useState(null);
    const mappedColumns = columns
        .filter(({ mainColumn, selected }) => mainColumn || selected)
        .map(({ relatedColumnName, name }) => ({
            name: relatedColumnName,
            title: name
        }));

    const handlePrint = useCallback(async () => {
        if(report.data.length === 0) {
            return
        }
        setPrinting(true);
        const subittedData = {
            requestId: +data.requestId,
            vehiclePlateNo: data.vehiclePlateNo,
            vehicleVin: data.vehicleVin,
            from: data.from,
            to: data.to,
            pageIndex: data.pageIndex,
            locations: data.locations
        }
        try {
            const fileBase64 = _get(await printPDFFile({...subittedData, columns: mappedColumns }), 'data.data', '');

            printJS({ printable: fileBase64, type: 'pdf', base64: true });
        } catch (e) {
            setError(e);
        } finally {
            setPrinting(false);
        }
    }, [columns, data]);
    
    return (
        <li className={`site-btn white print-btn ${report.data.length === 0 ? 'disabled' : ''}`} onClick={handlePrint}>
            {printing ? (<ButtonSpinner />) : (
                <>
                    <img src={printIcon} alt="icon" />
                    <span>{phrases?.btnPrint?.[lang]}</span>
                </>
            )}
        </li>
    )
}

export default PrintBtn
