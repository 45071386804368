import React, { useContext, useEffect, useState } from 'react'
import PhoneHandleCountryCode from './PhoneHandleCountryCode';
import { langContext } from '../../context/langContext';
import { PhrasesContext } from '../../context/PhrasesContext';
import $ from 'jquery'

function PhoneHandleWrap({data, setData, name, placeHolder, setIsSubmitError, disabled, dataChanged}) {    
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    const [countryCode, setCountryCode] = useState("")
    const [phone, setPhone] = useState("")    
    const [completePhone, setCompletePhone] = useState(countryCode + phone)
    const [validateMsg, setValidateMsg] = useState("")
    
    //- load data
    useEffect(() => {
        setCountryCode(data[name].length !== 0 ? data[name].substring(0, 3) : '050')
        setPhone(data[name].length !== 0 ? data[name].substring(3, 20) : '')
    }, [dataChanged])
    
    useEffect(() => {
        if (data[name].toString().length === 0) {
            setValidateMsg(phrases?.lblPhoneRequiredCC?.[lang])
        }
        if (data[name].toString().length < 7 && data[name].toString().length !== 0) {
            setValidateMsg(phrases?.lblInvalidPhoneNumberCC?.[lang])
        }
    },  [phrases, lang])

    //- handle value change
    const hanldeValueChange = e => {
        var numbers = /^[0-9]+$/;
        if (!e.target.value.match(numbers) && e.target.value !== '') {
            return;
        }
        if (e.target.value.length > 7) {
            return;
        }
        setPhone(e.target.value)
        $(e.target).removeClass('invalid-input')
        $(e.target).closest('.form-col').removeClass('invalid-col')
        setIsSubmitError(false)

    }

    //- handle focus out
    const hanldeFocusOut = e => {
        if (e.target.value.length === 0) {
            $(`.${name}`).closest('.form-col').addClass('invalid-col');
            $(`.${name}`).addClass('invalid-input');
            setValidateMsg(phrases?.lblPhoneRequiredCC?.[lang])

        } else if (e.target.value.length < 7) {
            $(`.${name}`).closest('.form-col').addClass('invalid-col');
            $(`.${name}`).addClass('invalid-input');
            setValidateMsg(phrases?.lblInvalidPhoneNumberCC?.[lang])

        } else {
            console.log("===> Phone Validate <===");
        }    
    }

    //- handle mouse wheel
    const handleWheel = (e) => {
        e.target.blur();
    }

    //- concat full phone
    useEffect(() => {
        setCompletePhone(countryCode + phone)
    }, [phone, countryCode])


    //- get the full phone
    useEffect(() => {
        setData(prevState => ({
            ...prevState,
            [name]: completePhone
        }))
    }, [completePhone])



    return (
        <div className="phone-number-wrap">

            <PhoneHandleCountryCode setData={setCountryCode} cCode={countryCode} disabled={disabled} />
            
            <div className="phone-number">
                <div className="input-wrap">
                <input 
                    className={`site-input requiredData ${name} ${disabled ? 'disabled' : ''}`} 
                    type="text" 
                    placeholder={placeHolder}
                    name="name"
                    value={phone}
                    onChange={hanldeValueChange}
                    onWheel={handleWheel}
                    onBlur={hanldeFocusOut}
                    disabled={disabled}
                    inputMode="numeric"
                    pattern="\d*"


                />
                </div>
                <p className="error-msg">{validateMsg}</p>
            </div>
        </div>
    )
}

export default PhoneHandleWrap
