import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import { requestContext } from '../../context/reqeustContext'
import IconEdit from "../../assets/icons/icons-edit.svg";
import IconTrash from "../../assets/icons/icons-trash.svg";
import RequestMainData from './RequestMainData';
import RequestSubData from './RequestSubData';
import { GetRequestDetails } from "../../api/requests";
import { itemIdContext } from "../../context/itemIdContext";
import { modalContext } from "../../context/modalContext";
import { langContext } from "../../context/langContext";
import { PhrasesContext } from "../../context/PhrasesContext";
import { PinContext } from '../../context/pinContext'
import { notificationContext } from '../../context/addNotificationContext';
import { addSubRequestContext } from '../../context/addSubRequestContext'
import { PickedLocationContext } from '../../context/pickedLocationContext';
import { ListMainStatus } from '../../Utils/utils';
import { userContext } from '../../context/userContext';
import { CCExternalIdContext } from '../../context/ccExternalID';
import { addCarDetailsContext } from '../../context/carDetailsContext';

function RequestSingle({ }) {
    const [ccItemId, setCcItemId] = useContext(CCExternalIdContext)

    const history = useHistory();
    const { userContextDispatch } = useContext(userContext)
    const { modalState, setModalState } = useContext(modalContext);
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const [, changePinStatus] = useContext(PinContext)
    const { itemId, setItemId } = useContext(itemIdContext);
    const [card, setCard] = useState({ loading: true, data: {}, error: false });
    const [isSubCompleted, setIsSubCompleted] = useState(false);

    const { notification } = useContext(notificationContext);
    const { subRequest } = useContext(addSubRequestContext);
    const { carDetails } = useContext(addCarDetailsContext)
    const [location, changeLocation] = useContext(PickedLocationContext);

    const { requestStatus, setRequestStatus } = useContext(requestContext);
    let isCompleted = false;
    const showEditReqeust = id => {
        setItemId(id);
        setRequestStatus("showEditRequest");
        changeLocation({
            longitude: card.data.mainRequest.pickUpLongtitude,
            latitude: card.data.mainRequest.pickUpLatitude,
            title: card.data.mainRequest.pickUpTitle,
            address: card.data.mainRequest.pickUpAddress,
        })
        if (isCompleted) {
            changePinStatus(false)
        } else {
            changePinStatus(true)
        }

    };



    useEffect(() => {
        if (!card.loading) {
            if (card.data.subRequests.length >= 1) {
                card.data.subRequests.find(item => {
                    if (item.statusId === 18) {
                        isCompleted = true

                    }

                })
            }


        }

    }, [card])



    useEffect(() => {
        if (!card.loading) {
            if (card.data.subRequests.length >= 1) {
                card.data.subRequests.forEach((v, i, array) => {
                    if (v.requestId == carDetails.requestId && card.data.mainRequest.requestId == v.mainRequestId) {
                        v.vin = carDetails.VIN_No
                        v.plateCode = carDetails.PlateCode;
                        v.make = carDetails.Make;
                        v.model = carDetails.Model;
                        v.color = carDetails.Color;

                    }
                })
                setCard({ ...card });
            }


        }

    }, [carDetails])






    const showCancelModal = (exID) => {
        setCcItemId(exID)
        setModalState('showCancel')
    }


    useEffect(() => {
        if (!card.loading) {
            if (notification?.MainRequestId) {
                if (requestStatus.showDetailed) {
                    if (card.data.mainRequest.requestId == notification.MainRequestId) {
                        card.data.mainRequest.statusId = notification.MainRequestStatusId;
                        card.data.mainRequest.statusNameEn = notification.MainRequestStatusEn;
                        card.data.mainRequest.statusNameAr = notification.MainRequestStatusAr;
                        const index = card.data.subRequests.findIndex(r => r.requestId == notification.RequestId);
                        if (index != -1) {
                            card.data.subRequests[index].statusId = notification.StatusId;
                            card.data.subRequests[index].statusNameEn = notification.Status;
                            card.data.subRequests[index].statusNameAr = notification.Status;
                        }
                        setCard({ ...card });
                    }
                }
            }
        }


    }, [notification])




    useEffect(() => {

        GetRequestDetails(itemId)
            .then(response => {
                console.log("card ==>:", response.data)
                setCard({
                    loading: false,
                    data: response.data.data,
                    error: false,
                })
            })
            .catch(error => {
                console.log('ERROR ==> ', error.response)
                setCard({
                    loading: false,
                    data: [],
                    error: true
                })

            })
    }, [itemId, subRequest])
    const hasCompletedRequest = !card.loading && card && card.data.subRequests.length > 0 && card.data.subRequests.some(item => item.statusId === 18)


    return (
        <>
            <div className="request-head request-list-head single">
                {
                    card.loading
                        ? <div className="card-loader data" style={{ width: '100%', height: '100px', marginBottom: '0px' }}></div>
                        : card.error
                            ? <div className="request-title noMargin">
                                <strong>Opps!</strong>
                            </div>
                            : <div className="request-head-single">
                                <div className="request-head-single-data">
                                    <ul className="reqeust-id-wrap">
                                        <li className="selected-id">{phrases?.lblRequestID?.[lang]} {card.data.mainRequest.ccExternalId}</li>
                                        {
                                            card.data.mainRequest.requestTypeNameEn !== null
                                                ? <li className="selected-status">
                                                    {
                                                        lang === 'en'
                                                            ? card.data.mainRequest.requestTypeNameEn
                                                            : card.data.mainRequest.requestTypeNameAr
                                                    }
                                                </li>
                                                : null
                                        }
                                    </ul>
                                    <div className="request-title noMargin">
                                        <strong>{phrases?.lblRequestDetailsCC?.[lang]}</strong>
                                        <span className={ListMainStatus[card.data.mainRequest.statusId]}>
                                            {
                                                lang === 'en'
                                                    ? card.data.mainRequest.statusNameEn
                                                    : card.data.mainRequest.statusNameAr
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="request-head-single-action">
                                    <ul>
                                        {card.data.mainRequest.statusId == 14 ? null : (
                                            <li onClick={() => {

                                                showEditReqeust(card.data.mainRequest.requestId)

                                            }}>
                                                <img src={IconEdit} alt="icon" />
                                            </li>
                                        )}




                                        {hasCompletedRequest ?
                                            null :
                                            (<li onClick={() => { showCancelModal(card.data.mainRequest.ccExternalId) }}>
                                                <img src={IconTrash} alt="icon" />
                                            </li>)}


                                    </ul>
                                </div>

                            </div>
                }
            </div>
            <div className="request-body request-list-body">
                {
                    card.loading
                        ? <div className="card-loader image"></div>
                        : card.error
                            ? <strong>Opps!</strong>
                            : <>
                                <RequestMainData mainData={card.data.mainRequest} />
                                {
                                    card.data.subRequests.length >= 1 &&
                                    <div className="request-data-contain">
                                        <div className="request-data-wrap">
                                            <div className="request-data-head">
                                                <strong>{phrases?.lblRecoveriesAssignedOnThisRequest?.[lang]}</strong>
                                            </div>
                                            <div className="request-data-body">
                                                <ul className="recoveries-list">
                                                    {
                                                        card.data.subRequests.map(item => {
                                                            return (
                                                                <RequestSubData key={item.requestId} Card={item} />
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </>
                }
            </div>
        </>
    )
}

export default RequestSingle;
