import { useContext } from 'react'
import { useHistory } from 'react-router';
import { langContext } from '../context/langContext';
import { serverDownContext } from '../context/serverDownContext';
import { userContext} from '../context/userContext';
import { decryptToken } from '../Utils/utils';
import axiosInstance from './axiosInstance'



const WithAxios = ({ children }) => {
    const { lang } = useContext(langContext);

    const {userContextData , userContextDispatch} = useContext(userContext)
    const {setServerDown} = useContext(serverDownContext)
    const history = useHistory();

    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${decryptToken(userContextData.userData.token)}`;
    axiosInstance.defaults.headers.common['language'] = lang;

    
    axiosInstance.interceptors.response.use(
		(res) => {
			return res
		},
		(error) => {
			return responseErrorInterceptor(error);
		}
	);

    const responseErrorInterceptor =(error)=>{
        if (error.response) {
            
            if (error.response?.status === 401) {
                console.log("UNAUTHHHHH");
                userContextDispatch('logout')
                localStorage.removeItem("user");
                history.push('/')
            }
            else if (error.response?.status === 400){
                console.log("Handled Error::" ,error.response?.status );
                return Promise.reject(error);
            }
            else {
                setServerDown('serverIsDown')
            }
      

    } else {
        setServerDown('serverIsDown')
    }
    return Promise.reject(error);
}

    return children
}

export default WithAxios