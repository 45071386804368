import React, { useEffect, useRef, useState } from 'react'
import IconMinus from "../../../assets/icons/icons-minus.svg";
import IconAdd from '../../../assets/icons/add.svg';

function InjuredCounter({data, setData, isEdit}) {
    const minusBtn = useRef(null)
    const [injuredNumber, setInjuredNumber] = useState(data.numberOfVehicles);
    const handleInjuredMinus = () => {
        if(injuredNumber <= 1 || isEdit) {
            return
        }
        setInjuredNumber(state => state - 1)
    }

    const handleInjuredAdd = () => {
        if(injuredNumber >= 200) {
            return
        }
        setInjuredNumber(state => state + 1)
    }

    useEffect(() => {
        if(injuredNumber <= 1 || isEdit) {
            minusBtn.current.classList.add('disabled')
        } else {
            minusBtn.current.classList.remove('disabled')
        }

        setData({...data, numberOfVehicles: injuredNumber})

    }, [injuredNumber])




    return (
        <div className="injured-wrap">
            
            <div className="injured-action-item minus" ref={minusBtn} onClick={handleInjuredMinus}>
                <img src={IconMinus} alt="icon" />
            </div>
            <div className="site-input">
                {injuredNumber}
            </div>
            <div className="injured-action-item add" onClick={handleInjuredAdd}>
                <img src={IconAdd} alt="icon" />
            </div>
        </div>
    )
}

export default InjuredCounter
