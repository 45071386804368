import React from 'react'
import RequestsStatusChart from '../../../../RequestsStatusChart'

function ReportDetailsChartStatus({chartData}) {
    return (
        <>
            <RequestsStatusChart chartData={chartData} />
        </>
    )
}

export default ReportDetailsChartStatus
