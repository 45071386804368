import React, { useState, useEffect, useContext } from 'react'
import CloseBlack from "../../../assets/icons/icons-close.svg";
import closeRed from "../../../assets/icons/close-red.svg";
import {PhrasesContext} from '../../../context/PhrasesContext';
import {langContext} from '../../../context/langContext';

import $ from 'jquery'
import { forgetPassword } from '../../../api/login';
import UsernameInput from '../../../shell/UsernameInput';
 

//- Validation
function validateForm(relatedForm) {
    var amountInput = $(relatedForm).find('.requiredData');
    var filledInputs = [];
    amountInput.each(function() {
        filledInputs.push(this);
    });
    
    
    var noValFilter = filledInputs.filter(function (item) {
        return $(item).val() === '';
    })

    $(noValFilter).each(function () {
        $(this).closest('.form-col').addClass('invalid-col');
        $(this).addClass('invalid-input');
        $(this).closest('.form-col').find('.error-msg').show()
    });


    if(noValFilter.length === 0) {
        return true
    } 

}


function ForgetPassword({setLoginView, setForgetPasswordViews, setLoginUserData}) {
    const [apiValidation, setApiValidation] = useState("")
    const [isSumbitError, setIsSubmitError] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [loginData, setLoginData] = useState({
        userName: "",
    })
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);

  

    //- load data once changed
    useEffect(() => {
        console.log("loginData ===>", loginData)
    }, [loginData])

    //- handle submit
    const hanldeSubmit = e => {
        let elem = $(e.target);
        let isValidateToSubmit = true;

        if (!validateForm(elem)) {
            isValidateToSubmit = false;
        }

        if (loginData.userName.length < 3 || loginData.userName.length > 30)  {
            isValidateToSubmit = false;
        }


        if(isValidateToSubmit) {
            setIsSubmitted(true)
            forgetPassword(loginData.userName)
                .then(response => {
                    console.log(response.data)
                    setIsSubmitted(false)
                    setLoginUserData({
                        name: loginData.userName,
                        mob1: response.data.data.mob1,
                        mob2: response.data.data.mob2
                    })
                    setForgetPasswordViews({
                        isForgetPassword: false,
                        isForgetPasswordOTP: true,
                        isForgetPasswordResend: false
                    })
                })
                .catch(error => {
                    console.log(error)
                    setIsSubmitted(false)
                    setIsSubmitError(true)
                    setApiValidation(error?.response?.data?.key || 'lblSomethingWrong')
                })
        }

        e.preventDefault();
    }

    
    // custom error handling
    const closeErrorSubmit = () => {
        setIsSubmitError(false)
    }
    
    const handleLoginView = () => {
        setLoginView(true)
    }
    return (
        <>
            <div className="login-card-head">
                <strong className="title">{phrases?.lblForgotPasswordCC?.[lang]}</strong>
                <strong>{phrases?.lblEnterUsernameToReceiveOTP?.[lang]}</strong>

            </div>
            {
                isSumbitError
                ?   <div className="note error" style={{marginTop: '32px'}}>
                        <div className="note-text">
                            <img src={closeRed} alt="" />
                            <span>
                                {
                                    phrases[apiValidation]
                                    ?   phrases[apiValidation][lang]
                                    :   phrases[`lblSomethingWrong`][lang]
                                }
                            </span>
                        </div>
                        <img src={CloseBlack} className="closeNote" onClick={closeErrorSubmit} />
                    </div>
                :   null
            }
            <div className="login-card-body">
                <div className="login-form-wrapper">
                    <form className="request-form" id="login-create" onSubmit={hanldeSubmit}>
                        <div className="form-row">
                            <div className="form-col">
                               <strong>{phrases?.lblUsername?.[lang]}</strong>
                                <UsernameInput 
                                    data={loginData} 
                                    setData={setLoginData} 
                                    name="userName"
                                    placeHolder={phrases?.lblEnterYourUsername?.[lang]}
                                    hasIcon={true}
                                    setIsSubmitError={setIsSubmitError}
                                />
                            </div>
                        </div>
                        
                        

                        <div className="request-form-action">
                            <div className="form-aciton">
                                <button form="login-create" type="submit" className="site-btn full" disabled={`${isSubmitted  ? "disabled" : ""}`}>
                                    <span>{phrases?.btnContinueCC?.[lang]}</span>
                                    {
                                        isSubmitted
                                        ?   <div className="spinner"></div>
                                        :   null
                                    }
                                    
                                </button>
                            </div>
                        </div>

                        <div className="form-row" style={{marginTop: '20px'}}>
                            <div className="form-col invalid-col back-wrap">
                                <p className="success-msg login-back-btn" onClick={handleLoginView}>{phrases?.lblBacktoLogin?.[lang]}</p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ForgetPassword
