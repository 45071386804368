import React, { useContext, useEffect, useState } from "react";
import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
} from "chart.js";
import { langContext } from "../../../../../../context/langContext";
import { PhrasesContext } from "../../../../../../context/PhrasesContext";
import { structureStatusChartData } from "../../../../../../Pages/DashboardPage/helpers";
import ReportsCompareChartsStatusFilter from "./ReportsCompareChartsStatusFilter";

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
);

function Canvas({ firstChartData= [], secondChartData= [] }) {
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    
    // charts data collecting in objects
    const [labelsString, setLabelsString] = useState([])
    const [labelsStringSecond, setLabelsStringSecond] = useState([])
    const [finalLabel, setFinalLabel] = useState([])
    const [completedData, setCompletedData] = useState([])
    const [cancelledData, setCancelledData] = useState([])
    const [totalData, setTotalData] = useState([])
    const [dataToShow, setDataToShow] = useState({labels: [], datasets: []})
    const [maxNum, setMaxNum] = useState(0)

    
    const convertDataToLocale = (orignalData) => {
        let { chartLabels: labels, data } = structureStatusChartData(orignalData);
        return {
            labels: labels,
            data: data
        }
    }

    //- get and organize the data at the first time

    useEffect(() => {
        
        setLabelsString(convertDataToLocale(firstChartData).labels)
        setLabelsStringSecond(convertDataToLocale(secondChartData).labels)

        //- set completed data
        setCompletedData([
            {
                data: convertDataToLocale(firstChartData).data.completedRequests,
                borderColor: "#00c0e1",
                fill: true,
                label: phrases?.lblCompletedRequests?.[lang],
                cubicInterpolationMode: "monotone",
                tension: 0.5,
                backgroundColor: "rgba(0, 192, 225, 0.3)",
            },
            {
                data: convertDataToLocale(secondChartData).data.completedRequests,
                borderColor: "#63d588",
                fill: true,
                label: phrases?.lblCompletedRequests?.[lang],
                cubicInterpolationMode: "monotone",
                tension: 0.5,
                backgroundColor: "rgba(99, 213, 136, 0.3)",
            },
        ])        


        //- set cancelled data
        setCancelledData([
            {
                data: convertDataToLocale(firstChartData).data.cancelledRequests,
                borderColor: "#00c0e1",
                cubicInterpolationMode: "monotone",
                label: phrases?.lblCancelledRequests?.[lang],
                fill: true,
                backgroundColor: "rgba(0, 192, 225, 0.3)",
                tension: 0.5,
            },
            {
                data: convertDataToLocale(secondChartData).data.cancelledRequests,
                borderColor: "#63d588",
                cubicInterpolationMode: "monotone",
                label: phrases?.lblCancelledRequests?.[lang],
                fill: true,
                backgroundColor: "rgba(99, 213, 136, 0.3)",
                tension: 0.5,
            },
        ]) 


        //- set total data
        setTotalData([
            {
                data: convertDataToLocale(firstChartData).data.totalRequests,
                borderColor: "#00c0e1",
                cubicInterpolationMode: "monotone",
                fill: true,
                label: phrases?.lblTotalRequests?.[lang],
                tension: 0.5,
                backgroundColor: "rgba(0, 192, 225, 0.3)",
            },
            {
                data: convertDataToLocale(secondChartData).data.totalRequests,
                borderColor: "#63d588",
                cubicInterpolationMode: "monotone",
                fill: true,
                label: phrases?.lblTotalRequests?.[lang],
                tension: 0.5,
                backgroundColor: "rgba(99, 213, 136, 0.3)",            
            },
        ])  

    }, [])


    useEffect(() => {
        // console.log("labelsString" , labelsString)

        // console.log("labelsStringSecond" , labelsStringSecond)

        function timePairs(labelsString) {
            const result = []
            for (let i = 0; i < labelsString.length; i++) {
                result.push([labelsString[i]])
            }
            return result
        }

        const result = timePairs(labelsString).map((pair, index) => {
            return [ ...pair, labelsStringSecond[index]].join(' | ')
        });

        setFinalLabel(result)
    }, [labelsString, labelsStringSecond])

    //- set data show for the first time only
    useEffect(() => {
        if(labelsString.length >= 1 && completedData.length >= 1 && cancelledData.length >= 1 && totalData.length >= 1) {
            setDataToShow({labels: finalLabel, datasets: totalData})
        }
        

    }, [finalLabel, completedData, cancelledData, totalData])


    //- set max
    useEffect(() => {
        if(dataToShow.datasets.length >= 1) {
            let bothData = dataToShow.datasets[0].data.concat(dataToShow.datasets[1].data);
            setMaxNum(Math.max(...bothData))

            console.log("dataToShow", dataToShow)
        }
    }, [dataToShow])

    //- get canvas ref
    let ctx = null;
    const useCanvas = callback => {
        const canvasRef = React.useRef(null);

        React.useEffect(() => {
            const canvas = canvasRef.current;
            ctx = canvas.getContext("2d");
            callback([canvas, ctx]);
        }, [callback]);

        return canvasRef;
    };


    //- draw the canvas
    const Canvas = () => {
        const canvasRef = useCanvas(ctx => {
            
            new Chart(ctx, 
                {
                    type: "line",
                    data: dataToShow,
                    options: {
                    responsive: true,
                    aspectRatio: 5,
                    plugins: {
                        title: {
                            display: false,
                        },
                        legend: {
                            display: false,
                        },
                    },
                    interaction: {
                        intersect: false,
                    },
                    label: {
                        display: false,
                    },
                    scales: {
                        x: {
                            grid: { display: false },
                            title: {
                                display: true,
                                text: phrases?.lblRequestTime?.[lang],
                                color: "#2ea1bb",
                                foontSize: "14px",
                                textAlign: "start",
                                fontFamily: "Roboto",
                                fontWight: "500",
                            },
                        },
                        y: {
                            display: true,
                            ticks: {
                                stepSize: 1,
                            },
                            title: {
                                display: true,
                                text: phrases?.lblRequestsNumber?.[lang],
                                color: "#2ea1bb",
                                foontSize: "14px",
                                textAlign: "start",
                                fontFamily: "Roboto",
                                fontWight: "500",
                            },
                            suggestedMax: maxNum,
                        },
                        
                    },
                },
            });
        });


        return (
            <div style={{ width: "100%", height: "100%" }}>
                <canvas ref={canvasRef} />
            </div>
        );
    };


    return (
        <div className="total-card compare-chart-card remove-shadow EN_IMP">
            <div className="compare-chart-status-head AR_IMP">
                <ReportsCompareChartsStatusFilter setDataToShow={setDataToShow} totalData={totalData} cancelledData={cancelledData} completedData={completedData} labelsString={labelsString} />
            </div>
            <Canvas />
        </div>
    );
}
export default React.memo(Canvas);
