import React, {useContext} from 'react'
import shareICON from "../../../../../assets/icons/share-btn-icon.png";
import { langContext } from '../../../../../context/langContext';
import { PhrasesContext } from '../../../../../context/PhrasesContext';
import { useHistory } from "react-router-dom";
import { RequestListContext } from '../../../../../context/RequestListContext';
import uuid from 'react-uuid'

function ReportCloneBtn({reportData}) {
    const [report, setReport, filter, setFilter] = useContext(RequestListContext)
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    const history = useHistory();
    
    
    const handleReportClone = () => {
        let locationsWithId = [];
        if(reportData.report.reportFilter.locations.length) {
            reportData.report.reportFilter.locations.map(item => {
                locationsWithId.push({...item, id: uuid()})
            })
        }
        setFilter({
            ...reportData.report.reportFilter,
            from: reportData.report.reportFilter.from.replace('+04', '+00'),
            locations: locationsWithId,
            requestId: "",
            pageIndex: filter.pageIndex
        })


        setReport({loading: true, data: [], error: false, totalCount: "", subRequestsCount: ""})

        history.push('/requestboard')
        
    }


    return (
        <li className={`site-btn white`} onClick={handleReportClone} >
            <img src={shareICON} alt="icon" />
            <span>{phrases?.lblClone?.[lang]}</span>
        </li>
    )
}

export default ReportCloneBtn
