import React, { useContext, useEffect, useState, useRef } from "react";
import {
    DirectionsRenderer,
    OverlayView,
    Polyline

} from "@react-google-maps/api";

import { usePubNub } from "pubnub-react";
import { notificationContext } from '../../context/addNotificationContext'
import { addSubRequestContext } from '../../context/addSubRequestContext';
import { requestContext } from "../../context/reqeustContext";
import { itemIdContext } from '../../context/itemIdContext';
import { PinContext } from "../../context/pinContext";
import { GetRequestDetails } from "../../api/requests";
import { ListOnMapContext } from "../../context/listOnMap";
import { activeSubRequestContext } from "../../context/activeSubRequestContext";
import { addCarDetailsContext } from "../../context/carDetailsContext";
import { lineString, point } from '@turf/helpers';
import nearestPointOnLine from '@turf/nearest-point-on-line';



const CustomMarker = (props => {

    const [activeRequests, updataActiveRequests] = useState([]);
    const pubnub = usePubNub();
    const [channels] = useState(["CommandCenter_"]);
    useEffect(() => {
        pubnub.addListener({ message: updateCarsLocation, status: handleReconnect });
        pubnub.subscribe({ channels });
    }, [pubnub, channels]);
    const { setNotification } = useContext(notificationContext);
    const { requestStatus, setRequestStatus } = useContext(requestContext);
    const { setItemId, itemId } = useContext(itemIdContext);
    const [showPin, changePinStatus] = useContext(PinContext);
    const { setSubRequest } = useContext(addSubRequestContext);
    const { addCarDetails } = useContext(addCarDetailsContext);
    const [carLocation, setCarLocation] = useState(null);
    const [CurrentMainRequestId, setCurrentMainRequestId] = useState(null);
    const [, getAllRequests,] = useContext(ListOnMapContext);
    const { activeSubRequest, setActiveSubRequest } = useContext(activeSubRequestContext);
    const [carStatus, setCarStatus] = useState(null);
    const [carDriver, setCarDriver] = useState(null);

    let isLocationOnEdge = props.google?.maps.geometry.poly.isLocationOnEdge;
    let geocoder;
    if (props.google) {
        geocoder = new props.google.maps.Geocoder();
    }


    let AllMainRequests = [];


    const onIconDblClick = (e, marker) => {
        if (marker.clicked == true) {
            marker.clicked = false;
            const newActiveRequests = [...activeRequests];
            updataActiveRequests(newActiveRequests);
            setItemId(null);
            setRequestStatus("hideAllRequest");
            changePinStatus(false);
            getAllRequests();
            setActiveSubRequest(null)
        } else if (marker.mainRequest) {
            marker.clicked = true;
            let otherRequests = activeRequests.filter(a => a.RequestId != marker.RequestId);
            otherRequests.forEach((v) => {
                v.clicked = false;
            });
            const newActiveRequests = [...activeRequests];
            updataActiveRequests(newActiveRequests);
            setItemId(marker.mainRequest.requestId);
            setRequestStatus("showDetailed");
            changePinStatus(false);
            let filteredArray = props.AllFiltredRequestsList.filter(item => item?.requestId == marker.mainRequest.requestId)
            props.updateRequestsList(filteredArray)
            const bounds = new window.google.maps.LatLngBounds();
            bounds.extend(
                new window.google.maps.LatLng(
                    marker.Latitude,
                    marker.Longtitude
                )
            );
            bounds.extend(
                new window.google.maps.LatLng(
                    marker.mainRequest.pickUpLatitude,
                    marker.mainRequest.pickUpLongtitude
                )
            );

            props.map.fitBounds(bounds, 100);
            props.map.setZoom(props.map.zoom - 2)
            setActiveSubRequest(marker.RequestId);
        }
    };


    const getIndexOfNearestPoint = (path, hoveredPoint) => {
        let line = lineString(path);
        let coordinates = [hoveredPoint.lng(), hoveredPoint.lat()];
        let pt = point(coordinates);
        let pointRequired = nearestPointOnLine(line, pt);
        let nearst = new props.google.maps.LatLng(pointRequired.geometry.coordinates[1], pointRequired.geometry.coordinates[0]);
        return nearst;
    }



    useEffect(() => {
        if (carLocation) {

            const mainRequest = props.requestsList?.find(c => c.requestId == carLocation.MainRequestId);


            const found = activeRequests.some(r => r.RequestId === carLocation.RequestId);


            if (found) {
                let index = activeRequests.findIndex(r => r.RequestId === carLocation.RequestId);

                if (activeRequests[index].poly) {

                    let plylineLength = props.google?.maps.geometry.spherical.computeLength(activeRequests[index].poly.getPath());


                    if (plylineLength <= 50 && !activeRequests[index].ismoving) {
                        activeRequests[index].Latitude = activeRequests[index].mainRequest?.pickUpLatitude;
                        activeRequests[index].Longtitude = activeRequests[index].mainRequest?.pickUpLongtitude;
                        activeRequests[index].path = [];
                        updataActiveRequests([...activeRequests]);
                    }

                    let currentMainRequestLocation = new props.google.maps.LatLng(activeRequests[index].mainRequest?.pickUpLatitude, activeRequests[index].mainRequest?.pickUpLongtitude);

                    let destinationRequired = new props.google.maps.LatLng(carLocation.Latitude, carLocation.Longtitude);


                    const distanceOfRequiredLocationFromMainRequest = props.google?.maps.geometry.spherical.computeDistanceBetween(
                        destinationRequired,
                        currentMainRequestLocation
                    );




                    if (distanceOfRequiredLocationFromMainRequest <= 50 && plylineLength <= 50 && !activeRequests[index].ismoving) {
                        activeRequests[index].Latitude = activeRequests[index].mainRequest?.pickUpLatitude;
                        activeRequests[index].Longtitude = activeRequests[index].mainRequest?.pickUpLongtitude;
                        activeRequests[index].path = [];
                        updataActiveRequests([...activeRequests]);
                        return;
                    }
                }

                if (activeRequests[index].prevLatitude === carLocation.Latitude && activeRequests[index].prevLongtitude === carLocation.Longtitude) {
                    return;
                }


                if (new Date(activeRequests[index].CreationTime) > new Date(carLocation.CreationTime)) {
                    return;
                }


                activeRequests[index].prevLatitude = carLocation.Latitude;
                activeRequests[index].prevLongtitude = carLocation.Longtitude;




                if (!activeRequests[index].Latitude) {
                    activeRequests[index].queuedRequests = [];
                    activeRequests[index].timeOuts = [];
                    activeRequests[index] = { ...activeRequests[index], ...carLocation }

                    activeRequests[index].duration = 2000;
                    activeRequests[index].interval = 20;
                    activeRequests[index].ignoredNumbers = 0;


                    let allOrirgin = new props.google.maps.LatLng(activeRequests[index].Latitude, activeRequests[index].Longtitude);
                    let allDestination = new props.google.maps.LatLng(activeRequests[index].mainRequest?.pickUpLatitude, activeRequests[index].mainRequest?.pickUpLongtitude);
                    props.directionService.route(
                        {
                            origin: allOrirgin,
                            destination: allDestination,
                            travelMode: props.google.maps.TravelMode.DRIVING
                        },
                        (response) => {
                            if (response?.status === props.google.maps.DirectionsStatus.OK) {
                                activeRequests[index].response = response;
                                activeRequests[index].route = [];
                                activeRequests[index].path = [];
                                for (var i = 0; i < response.routes[0].legs[0].steps.length; i++) {
                                    activeRequests[index].path = activeRequests[index].path.concat(response.routes[0].legs[0].steps[i].path);
                                    activeRequests[index].route = activeRequests[index].route.concat(response.routes[0].legs[0].steps[i].path);
                                }
                                activeRequests[index].queuedRequests = [];
                                activeRequests[index].timeOuts = [];
                                updataActiveRequests([...activeRequests]);
                            } else {
                                console.error(`error fetching directions ${response}`);
                            }
                        }
                    );

                    updataActiveRequests([...activeRequests]);
                    return;
                }










                activeRequests[index].mainRequest = mainRequest;

                let mainRequestfirst = mainRequest;

                let newLocation = carLocation;


                if (activeRequests[index].ismoving) {
                    activeRequests[index].queuedRequests.push(newLocation);
                    return;
                }






                let origin = new props.google.maps.LatLng(activeRequests[index].Latitude, activeRequests[index].Longtitude);


                let destination = new props.google.maps.LatLng(newLocation.Latitude, newLocation.Longtitude);


                let polyline = new props.google.maps.Polyline({
                    path: activeRequests[index].path,
                    visible: false
                })

                if (!isLocationOnEdge(destination, polyline, 10e-5) && activeRequests[index].ignoredNumbers < 5) {


                    activeRequests[index].duration = 1000;
                    activeRequests[index].interval = 10;
                    activeRequests[index].ignoredNumbers++;
                    if (activeRequests[index].queuedRequests.length > 0) {
                        let newLocation = activeRequests[index].queuedRequests.shift();
                        activeRequests[index].prevLatitude = null;
                        activeRequests[index].prevLongtitude = null;
                        activeRequests[index].ismoving = false;
                        setCarLocation(newLocation);
                        return;
                    }
                    activeRequests[index].ismoving = false;
                    return;
                }

                if (activeRequests[index].ignoredNumbers > 0) {
                    activeRequests[index].ignoredNumbers = 0;
                    activeRequests[index].Latitude = newLocation.Latitude;
                    activeRequests[index].Longtitude = newLocation.Longtitude;
                    updataActiveRequests([...activeRequests]);
                    let originLat = activeRequests[index].Latitude;
                    let originLng = activeRequests[index].Longtitude;
                    let allOrirgin = new props.google.maps.LatLng(originLat, originLng);
                    let allDestination = new props.google.maps.LatLng(activeRequests[index].mainRequest.pickUpLatitude, activeRequests[index].mainRequest.pickUpLongtitude);

                    let directionIndex = index;

                    props.directionService.route(
                        {
                            origin: allOrirgin,
                            destination: allDestination,
                            travelMode: props.google.maps.TravelMode.DRIVING
                        },
                        (response) => {
                            if (response?.status === props.google.maps.DirectionsStatus.OK) {
                                activeRequests[directionIndex].route = [];
                                activeRequests[directionIndex].response = response;
                                activeRequests[directionIndex].path = [];
                                for (var i = 0; i < response.routes[0].legs[0].steps.length; i++) {
                                    activeRequests[directionIndex].path = activeRequests[directionIndex].path.concat(response.routes[0].legs[0].steps[i].path);
                                    activeRequests[directionIndex].route = activeRequests[directionIndex].route.concat(response.routes[0].legs[0].steps[i].path);
                                }
                                updataActiveRequests([...activeRequests]);
                            } else {
                                console.error(`error fetching directions ${response}`);
                            }
                        }
                    );
                    return;
                }
                activeRequests[index].ignoredNumbers = 0;

                let newMainRequest = mainRequestfirst;

                let newIndex = index;


                activeRequests[newIndex].ismoving = true;



                let allPathPoints = activeRequests[newIndex].poly.getPath()
                    .getArray()
                    .map(latLng => {
                        return [latLng.lng(), latLng.lat()];
                    });

                let nearstPoint = getIndexOfNearestPoint(allPathPoints, destination);


                props.directionService.route(
                    {
                        origin: origin,
                        destination: nearstPoint,
                        travelMode: props.google.maps.TravelMode.DRIVING
                    },
                    (response) => {
                        if (response?.status === props.google.maps.DirectionsStatus.OK) {
                            let moveDistance = 0;
                            let intervalMainRequest = newMainRequest;
                            let carRoute = [];
                            for (var i = 0; i < response.routes[0].legs[0].steps.length; i++) {
                                carRoute = carRoute.concat(response.routes[0].legs[0].steps[i].path);
                            }
                            moveCar(newIndex, intervalMainRequest, moveDistance, carRoute, destination);

                        } else {
                            console.error(`error fetching directions ${response}`);
                        }
                    }
                );
            }
            else {
                carLocation.mainRequest = mainRequest;
                carLocation.prevLatitude = carLocation.Latitude;
                carLocation.prevLongtitude = carLocation.Longtitude;
                carLocation.duration = 2000;
                carLocation.interval = 20;
                carLocation.ignoredNumbers = 0;
                if (mainRequest) {
                    AllMainRequests.push(mainRequest.requestId);
                }

                if (mainRequest) {
                    let allOrirgin = new props.google.maps.LatLng(carLocation.Latitude, carLocation.Longtitude);
                    let allDestination = new props.google.maps.LatLng(carLocation.mainRequest?.pickUpLatitude, carLocation.mainRequest?.pickUpLongtitude);
                    props.directionService.route(
                        {
                            origin: allOrirgin,
                            destination: allDestination,
                            travelMode: props.google.maps.TravelMode.DRIVING
                        },
                        (response) => {
                            if (response?.status === props.google.maps.DirectionsStatus.OK) {
                                carLocation.response = response;
                                carLocation.route = [];
                                carLocation.path = [];
                                for (var i = 0; i < response.routes[0].legs[0].steps.length; i++) {
                                    carLocation.path = carLocation.path.concat(response.routes[0].legs[0].steps[i].path);
                                    carLocation.route = carLocation.route.concat(response.routes[0].legs[0].steps[i].path);
                                }

                                carLocation.queuedRequests = [];
                                carLocation.timeOuts = [];
                                updataActiveRequests(req => [...req, carLocation]);

                            } else {
                                console.error(`error fetching directions ${response}`);
                            }
                        }
                    );
                }
            }

        }
    }, [carLocation]);


    useEffect(() => {

        setCurrentMainRequestId(itemId);

    }, [itemId])


    const onLoad = (polyline, marker) => {
        let index = activeRequests.findIndex(r => r.RequestId === marker.RequestId);
        activeRequests[index].poly = polyline;
        updataActiveRequests([...activeRequests])
    };

    const moveCar = (newIndex, intervalMainRequest, moveDistance, carRoute, destination) => {
        let originLat = activeRequests[newIndex].Latitude;
        let originLng = activeRequests[newIndex].Longtitude;
        let innerOrigin = new props.google.maps.LatLng(originLat, originLng);
        let nextPointLat = carRoute[moveDistance].lat();
        let nextPointLng = carRoute[moveDistance].lng();
        let newOrirgin = new props.google.maps.LatLng(nextPointLat, nextPointLng);
        let innerAngle = props.google.maps.geometry.spherical.computeHeading(
            innerOrigin,
            newOrirgin
        );

        if (moveDistance === 0) {
            activeRequests[newIndex].angle = activeRequests[newIndex].angle;
        }
        else {
            activeRequests[newIndex].angle = innerAngle - 90;
        }
        updataActiveRequests([...activeRequests]);


        let deltalat = (nextPointLat - originLat) / 100;
        let deltalng = (nextPointLng - originLng) / 100;

        let timeOutIndex = newIndex;

        for (let i = 0; i < activeRequests[newIndex].duration;) {
            let timeOut = setTimeout(
                () => {
                    activeRequests[timeOutIndex].timeOuts.push(timeOut);
                    if (activeRequests[timeOutIndex]) {
                        let lat = activeRequests[timeOutIndex].Latitude;
                        let lng = activeRequests[timeOutIndex].Longtitude;
                        lat += deltalat;
                        lng += deltalng;
                        activeRequests[timeOutIndex].Latitude = lat;
                        activeRequests[timeOutIndex].Longtitude = lng;
                        updataActiveRequests([...activeRequests]);
                        if (i == activeRequests[timeOutIndex].duration) {
                            moveDistance++;
                            if (moveDistance == carRoute.length) {
                                activeRequests[timeOutIndex].ismoving = false;
                                activeRequests[timeOutIndex].duration = 2000;
                                activeRequests[timeOutIndex].interval = 20;
                                updataActiveRequests([...activeRequests]);
                                if (activeRequests[timeOutIndex].queuedRequests.length > 0) {
                                    let newLocation = activeRequests[timeOutIndex].queuedRequests.shift();
                                    activeRequests[timeOutIndex].prevLatitude = null;
                                    activeRequests[timeOutIndex].prevLongtitude = null;
                                    setCarLocation(newLocation);
                                }
                            }
                            if (moveDistance < carRoute.length) {

                                let currentPaths = activeRequests[timeOutIndex].poly.getPath()
                                    .getArray()
                                    .map(latLng => {
                                        return { lat: latLng.lat(), lng: latLng.lng() };
                                    });

                                currentPaths.splice(0, 1);

                                activeRequests[timeOutIndex].path = currentPaths;

                                updataActiveRequests([...activeRequests]);
                                moveCar(timeOutIndex, intervalMainRequest, moveDistance, carRoute, destination);
                            }
                        }

                    }
                }, i

            );
            i += activeRequests[newIndex].interval;
        }



    }



    useEffect(() => {
        if (carStatus) {
            const mainRequest = props.requestsList?.find(c => c.requestId == carStatus.MainRequestId);

            carStatus.mainRequest = mainRequest;


            setNotification(carStatus);



            const found = activeRequests.some(r => r.RequestId === carStatus.RequestId);

            if (found) {
                const index = activeRequests.findIndex(r => r.RequestId === carStatus.RequestId);

                if (carStatus.Status === "Cancelled" || carStatus.Status == "ServiceCompleted" || carStatus.status == "Pending") {
                    activeRequests.splice(index, 1);
                    setSubRequest(null);
                }
                else {
                    activeRequests[index] = { ...activeRequests[index], ...carStatus };
                }

                updataActiveRequests([...activeRequests]);
                return;
            }

            else {
                if (mainRequest) {
                    AllMainRequests.push(mainRequest.requestId);
                }
                updataActiveRequests(req => [...req, carStatus]);
            }
        }

    }, [carStatus]);



    useEffect(() => {
        if (carDriver) {

            setSubRequest(carDriver);


            const mainRequest = props.requestsList.find(c => c.requestId == carDriver.MainRequestId);

            carDriver.mainRequest = mainRequest;




            const found = activeRequests.some(r => r.RequestId === carDriver.RequestId);

            if (found) {
                const index = activeRequests.findIndex(r => r.RequestId === carDriver.RequestId);
                activeRequests[index] = { ...activeRequests[index], ...carDriver };
                updataActiveRequests([...activeRequests]);

            }

            else {
                if (mainRequest) {
                    AllMainRequests.push(mainRequest.requestId);
                }
                updataActiveRequests(req => [...req, carDriver]);
            }
        }

    }, [carDriver]);



    const handleReconnect = (e) => {
        if (e.category === "PNNetworkUpCategory") {
            this.pubnub.reconnect();
            if (AllMainRequests.length > 0) {
                for (var i = 0; i < AllMainRequests.length; i++) {
                    GetRequestDetails(AllMainRequests[i])
                        .then(response => {
                            for (var i = 0; i < response.data.data.subRequests.length; i++) {
                                let subrequest = response.data.data.subRequests[i];
                                if (subrequest.statusNameEn === "Cancelled" || subrequest.statusNameEn == "Service Completed" || subrequest.statusNameEn == "Pending") {
                                    let index = activeRequests.findIndex(r => r.RequestId === subrequest.requestId);
                                    if (index > -1) {
                                        activeRequests.splice(index, 1);
                                    }
                                }
                            }
                            setNotification(response?.data?.data)
                        })
                        .catch(error => {
                            console.log('ERROR ==> ', error.response)
                        })
                }
            }
        }

    }






    const updateCarsLocation = (e) => {

        if (e.message.CCActiveRequestLocation) {

            const RequestLocationNew = e.message.CCActiveRequestLocation;
            setCarLocation(RequestLocationNew);

        }

        else if (e.message.CCActiveRequestStatus) {
            const RequestStatusNew = e.message.CCActiveRequestStatus;

            setCarStatus(RequestStatusNew);

        }


        else if (e.message.CCActiveRequestDriver) {
            const RequestDriverNew = e.message.CCActiveRequestDriver;

            setCarDriver(RequestDriverNew);

        }


        else if (e.message.CCAddingSubRequests) {
            const request = e.message.CCAddingSubRequests;
            setSubRequest({ ...request });
        }

        else if (e.message.VehicleDetail) {
            const vehicleDetail = e.message.vehicleDetail;
            addCarDetails({ ...vehicleDetail });
        }


    };

    return (
        <>

            {


                activeRequests?.map((marker, i) => (

                    <>
                        {((marker?.Latitude && marker.mainRequest.requestId == CurrentMainRequestId) || (marker?.Latitude && CurrentMainRequestId == null)) &&
                            <>
                                <OverlayView
                                    key={marker?.Latitude}
                                    position={{
                                        lat: marker?.Latitude,
                                        lng: marker?.Longtitude,
                                    }}
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                    getPixelPositionOffset={(width, height) => ({
                                        x: -(width / 2),
                                        y: -(height / 2)
                                    })}
                                >
                                    <img
                                        src="/tow-vehicle.png"
                                        style={{ transform: `rotate(${marker.angle ?? 90}deg)` }}
                                        onClick={e => onIconDblClick(e, marker)}
                                        ref={ref =>
                                            ref && props.google.maps.OverlayView.preventMapHitsFrom(ref)
                                        }
                                    />



                                </OverlayView>




                                <Polyline
                                    path={marker?.path}
                                    geodesic={true}
                                    onLoad={(p) => { onLoad(p, marker) }}
                                    options={{
                                        strokeColor: "#2ebb55",
                                        clickable: false,
                                        draggable: false,
                                        editable: false,
                                        visible: marker?.clicked ?? false,
                                        radius: 30000,
                                        paths: marker.path,
                                        zIndex: 1
                                    }}
                                />

                            </>
                        }

                    </>
                ))

            }
        </>
    )
})






export default CustomMarker;