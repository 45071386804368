import React, {useContext} from 'react'
import calendarIcon from "../../../../assets/icons/icons-calendar.svg";

import { langContext } from '../../../../context/langContext'
import { PhrasesContext } from '../../../../context/PhrasesContext'
import moment from "moment";

function ReportDetailsHeadData({reportData}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    
    return (
        <>
            <div className="body">
                <div className="header" style={{minHeight: "150px"}}>
                    <div className="header-left">
                        {
                            reportData.loading
                            ?   <>
                                </>
                            :   <>
                                    <div className="status-div">
                                        <div className="Oval">
                                            <div className="Oval-in">{reportData.mainRequestsCount}</div>
                                        </div>
                                        <div className="request-labels">
                                            <span>{phrases?.lblTotalRequests?.[lang]}</span>
                                            <label className="total">
                                                {phrases?.lblTotalDailyRequests?.[lang]}
                                            </label>
                                            <label className="total">{phrases?.lblAcrossDubai?.[lang]}</label>
                                        </div>
                                    </div>
                                    <div className="status-div">
                                        <div className="small-Oval">
                                            <div className="Oval-in">{reportData.completedMainRequestCount}</div>
                                        </div>

                                        <div className="request-labels">
                                            <span>{phrases?.lblCompletedRequests?.[lang]}</span>

                                            <div className="rectangle">
                                                <div className="complete-rectangle" style={{ width: (reportData.completedMainRequestCount / reportData.mainRequestsCount) * 100 + '%' }} />
                                            </div>
                                            <label>
                                                {Math.round((reportData.completedMainRequestCount / reportData.mainRequestsCount) * 100)}
                                                {phrases?.lblRequestsPercentage?.[lang]}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="status-div cancel">
                                        <div className="small-Oval cancel">
                                            <div className="Oval-in">{reportData.canceledMainRequestCount}</div>
                                        </div>

                                        <div className="request-labels">
                                            <span>{phrases?.lblCancelledRequests?.[lang]}</span>

                                            <div className="rectangle">
                                                <div className="cancel-rectangle" style={{ width: (reportData.canceledMainRequestCount / reportData.mainRequestsCount) * 100 + '%' }}  />
                                            </div>

                                            <label>
                                                {Math.round((reportData.canceledMainRequestCount / reportData.mainRequestsCount) * 100)}
                                                {phrases?.lblRequestsPercentage?.[lang]}
                                            </label>
                                        </div>
                                    </div>
                            
                                </>
                        }
                        
                    </div>
                    <div className="header-right">
                        {
                            reportData.loading
                            ?   <>
                                </>
                            :   <>
                                    <div className="selected-date-wrap">
                                        <div className="white-icon-wrap">
                                            <img src={calendarIcon} alt="calendar" width="16" />
                                        </div>
                                        <p>{moment(reportData.report.reportFilter.from.replace('+04', '+00')).format('M/DD/YYYY')} - {moment(reportData.report.reportFilter.to).format('M/DD/YYYY')}</p>
                                    </div>

                                    {
                                        reportData.report.reportFilter.locations !== null
                                        ?   <div className="selected-locations-wrap">
                                                {
                                                    reportData.report.reportFilter.locations.map(item => {
                                                        return (
                                                            <div key={item.address} className="selected-location-item">
                                                                <span>{item.address.substring(0 , 8)}.. + {item.radius}</span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        :   null
                                    }
                                </>
                        }
                        
                        
                        
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportDetailsHeadData
