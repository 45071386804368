
import React, {useState, useEffect, useContext} from 'react'
import $ from "jquery";
import { langContext } from '../context/langContext';
import { PhrasesContext } from '../context/PhrasesContext';

function EmailInput({data, setData, name, placeHolder, setIsSubmitError}) {
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);

    const [validate, setValidate] = useState('')
    useEffect(() => {
        var emailValid = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (data[name].toString().length === 0) {
            setValidate(phrases?.lblEmailAddressIsRequired?.[lang])
        }
        if (!data[name].toString().match(emailValid) && data[name].toString().length !== 0) {
            setValidate(phrases?.lblEmailValidation?.[lang])
        }
    },  [phrases, lang])

    // handle value change
    const handleValueChange = e => {
        var startWithSpace = /^\s/;
        var arabic = /[\u0621-\u064A]+/;
        if (e.target.value.match(startWithSpace) || e.target.value.match(arabic)) {
            return;
        }
        setData({ ...data, [name]: e.target.value });
        $(e.target).removeClass("invalid-input");
        $(e.target).closest(".form-col").removeClass("invalid-col");
        setIsSubmitError(false)
    };

    // handle input foucs
    const hanldeFocusOut = e => {
        var emailValid = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if(data[name].length === 0) {
            $(`.${name}`).closest(".form-col").addClass("invalid-col");
            $(`.${name}`).addClass("invalid-input");
            setValidate(phrases?.lblEmailAddressIsRequired?.[lang])
        } else if (!data[name].toString().match(emailValid)) {
            $(`.${name}`).closest(".form-col").addClass("invalid-col");
            $(`.${name}`).addClass("invalid-input");

            setValidate(phrases?.lblEmailValidation?.[lang])
        } else {
            console.log("===> Email Validate <===");
        }
        

    }


    return (
        <>
            <div className="input-wrap">
                <input 
                    className={`site-input requiredData ${name}`} 
                    type="text" 
                    placeholder={placeHolder}
                    value={data[name]}
                    name={name}
                    onChange={handleValueChange}
                    onBlur={hanldeFocusOut}
                />
            </div>
            <p className="error-msg">{validate}</p>
        </>
    )
}

export default EmailInput
