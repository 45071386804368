import React , {useState} from 'react'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Circle, Marker, } from "@react-google-maps/api";

function MapLocationCircle({data, setData, singleItem, setZoom, setCenterPoint}) {
    const circleOptions = {
        strokeColor: "rgba(68, 194, 103, 0.5)",
        strokeOpacity: 1,
        strokeWeight: 3,
        fillColor: "rgba(46, 187, 85, 0.25)",
        fillOpacity: 1,
    };
    const [rad, setRad] = useState(singleItem.radius)
    //- hanlde change value
    const onSliderChange = value => {
        setRad(value)
    };

    //- update radius value
    const onAfterChange = value => {
        setData({
            ...data,
            locations: data.locations.map(item => item.id === singleItem.id ? {...item, radius: value} : item)
        })
    };

    //- hanlde circle click
    const hanldeCircleClick = id => {
        setData({
            ...data,
            locations: data.locations.map(item => item.id === singleItem.id ? {...item, selected: true} : {...item, selected: false})
        })
        setCenterPoint(singleItem.center);
        setZoom(13);
    }

    return (
        <>
            <Circle options={circleOptions} center={singleItem.center} radius={rad} onClick={()=>hanldeCircleClick(singleItem.id)}  ></Circle>
            <Marker 
                position={singleItem.center} 
                icon={{
                    url: `/greenPin.svg`,
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(25, 25),
                    scaledSize: new window.google.maps.Size(50, 50),
                }}
            />
            {
                singleItem.selected
                ?   <div className="circle-slider">
                        <Slider  
                            vertical={true}
                            value={rad} 
                            onChange={onSliderChange} 
                            onAfterChange={onAfterChange} 
                            min={500} 
                            max={15000}
                            step={500}
                            trackStyle={{ 
                                backgroundColor: '#00c55b', 
                                width: 10, 
                                border: '2px solid #FFF' 
                            }}
                            railStyle={{
                                backgroundColor: '#fff' , 
                                width:10 , 
                                boxShadow:"0 0 3px 2px rgb(0 0 0 / 7%)"
                            }}
                            handleStyle={{
                                borderColor: '#FFF',
                                borderWidth:"4px",
                                height: 28,
                                width: 28,
                                marginLeft: -9,
                                marginTop: -9,
                                backgroundColor: '#00c55b'}}  
                            />
                    </div>
                :   null
            }
            
        </>
    )
}

export default MapLocationCircle
