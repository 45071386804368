import React, {useContext} from 'react'
import deleteReportIcon from '../../../../../assets/icons/deleteReportIcon.png';
import { langContext } from '../../../../../context/langContext';
import { modalContext } from '../../../../../context/modalContext';
import { PhrasesContext } from '../../../../../context/PhrasesContext';
import ModalContainer from '../../../../../Modals/ModalContainer';
import ReportDetailsDeleteModal from '../../ReportDetailsDeleteModal';


function ReportDeleteBtn({itemId}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    const {modalState, setModalState} = useContext(modalContext)

    const handleDeleteReport = () => {
        setModalState('showDeleteReport')
    }

    
    return (
        <>
            <div className="site-btn red" onClick={handleDeleteReport}>
                <img src={deleteReportIcon} alt="icon" />
                <span>{phrases?.btnDelete?.[lang]}</span>
            </div>
            {
                modalState.showDeleteReport
                ?   <ModalContainer>
                        <ReportDetailsDeleteModal itemId={itemId} />
                    </ModalContainer>
                :   null
            }
        </>
    )

}

export default ReportDeleteBtn
