import React, {useContext} from 'react'
import { langContext } from '../../../../../../context/langContext'
import { PhrasesContext } from '../../../../../../context/PhrasesContext'
import PlainMap from '../../../../../PlainMap'

function ReportsCompareChartsMap({filterValueFirst, filterValueSecond, reportDataFirst, reportDataSecond}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    return (
        <>
            <div className="map-stat-card map-status-compare remove-shadow">
                <div className="double-map-wrapper">
                    <div className="double-map-item">
                        <strong>{reportDataFirst.report.reportName}</strong>
                        <div className="double-map-item-map">
                            <PlainMap filterValue={filterValueFirst} isReport />
                        </div>
                    </div>
                    <div className="double-map-item">
                        <strong>{reportDataSecond.report.reportName}</strong>
                        <div className="double-map-item-map">
                            <PlainMap filterValue={filterValueSecond} isReport />
                        </div>
                    </div>
                </div>
                <div className="map-status-bar-contain">
                    <div className="map-status-bar-wrap">
                        <div className="map-status-bar-title">
                            <span>{phrases?.lblRequestsAroundDubai?.[lang]}</span>
                        </div>
                        <div className="map-status-bar-progress"></div>
                        <div className="map-status-bar-text">
                            <span>{phrases?.lblLow?.[lang]}</span>
                            <span>{phrases?.lblMid?.[lang]}</span>
                            <span>{phrases?.lblHigh?.[lang]}</span>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default ReportsCompareChartsMap
