import React, { useState } from 'react'
import Login from './Login'
import LoginOTP from './LoginOTP'

function LoginWrap({setLoginView}) {
    const [loginUserData, setLoginUserData] = useState({
        name: "",
        mob1: "",
        mob2: ""
    })
    const [isLoginSuccess, setLoginSuccess] = useState(false)
    return (
        <div className="login-card">
            {
                isLoginSuccess
                ?   <LoginOTP loginUserData={loginUserData} setLoginSuccess={setLoginSuccess} />
                :   <Login setLoginView={setLoginView} setLoginSuccess={setLoginSuccess} setLoginUserData={setLoginUserData} />
            }
        </div>
    )
}

export default LoginWrap
