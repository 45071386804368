import React from 'react';



export const notificationContext = React.createContext();
export const notificationInitialState = {

}


export const notificationReducer = (state = notificationInitialState, action) => {
    return state = action
}