import React from 'react'
import ReportsCompareChartsStatusIndex from './ReportsCompareChartsStatus/ReportsCompareChartsStatusIndex'
import ReportsCompareChartsTypeIndex from './ReportsCompareChartsType/ReportsCompareChartsTypeIndex'
import ReportsCompareChartsMapIndex from './ReprotsCompareChartsMap/ReportsCompareChartsMapIndex'

function ReportsCompareChartsIndex({chartView, firstId, secondId, reportDataFirst, reportDataSecond}) {
    
    return (
        <div className="body">
            <div className="chart-cards">
                <ReportsCompareChartsStatusIndex chartView={chartView}  firstId={firstId} secondId={secondId} />
                <ReportsCompareChartsTypeIndex chartView={chartView} firstId={firstId} secondId={secondId} reportDataFirst={reportDataFirst} reportDataSecond={reportDataSecond}  />
                <ReportsCompareChartsMapIndex firstId={firstId} secondId={secondId} reportDataFirst={reportDataFirst} reportDataSecond={reportDataSecond}  />
            </div>
        </div>
    )
}

export default ReportsCompareChartsIndex
