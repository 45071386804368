import React, { useContext } from 'react'
import { langContext } from '../../context/langContext';
import { PhrasesContext } from '../../context/PhrasesContext';

function NotificationCard({ data }) {
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    

    const statusNames = {
        0: 'updated',
        19: 'updated',
        12: 'initiated',
        14: 'completed',
        7: 'cancelled',
        13: 'arrived'
    }
    


    const convertSecondsToDate = (seconds) => {
        seconds = Number(seconds);
        var mm = Math.floor((seconds % 31536000) / 2628000);
        var d = Math.floor(seconds / (3600*24));
        var h = Math.floor(seconds % (3600*24) / 3600);
        var m = Math.floor(seconds % 3600 / 60);
        var s = Math.floor(seconds % 3600 % 60);

        var MonthDisplay = mm > 0 ? mm + (mm === 1? `${lang === 'en' ? ' month' : ' شهر'}` : `${lang === 'en' ? ' months' : ' شهر'}`) : "";
        var DayDisplay = d > 0 ? d + (d == 1 ? `${lang === 'en' ? ' day' : ' يوم'}` : `${lang === 'en' ? ' days' : ' يوم'}`) : "";
        var HourDisplay = h > 0 ? h + (h == 1 ? `${lang === 'en' ? ' hour' : ' ساعة'}` : `${lang === 'en' ? ' hours' : ' ساعة'}`) : "";
        var MinuteDisplay = m > 0 ? m + (m == 1 ? `${lang === 'en' ? ' minute' : ' دقيقة'}` : `${lang === 'en' ? ' minutes' : ' دقيقة'}`) : "";
        var SecondsDisplay = s > 0 ? s + (s == 1 ? `${lang === 'en' ? ' second' : ' ثانية'}` : `${lang === 'en' ? ' seconds' : ' ثانية'}`) : "";
        
        
        if(MonthDisplay !== '') {
            return MonthDisplay
        } 

        if(DayDisplay !== '') {
            return DayDisplay
        }

        if(HourDisplay !== '') {
            return HourDisplay
        }
        
        if(MinuteDisplay !== '') {
            return MinuteDisplay
        }

        if(SecondsDisplay !== '') {
            return SecondsDisplay
        }

        
        
    }

    return (
        <div className={`notification-item ${statusNames[data.statusId]} seen`}>
            <div className="notification-item-data">
                <div className="notification-item-id">
                    <span>{phrases?.lblRequestID?.[lang]}</span>
                    <strong>{data.objId}</strong>
                </div>
                <div className="notification-item-status">
                    <span>{ `${lang === 'en' ? data.textEn : data.textAr}`}</span>
                </div>
            </div>
            <div className="notification-item-date">
                <span>
                    {
                        lang === 'en'
                        ?   <>
                                {convertSecondsToDate(data.createdAt)}
                                {` ago`}
                            </>
                        :   <>
                                {`منذ `}
                                {convertSecondsToDate(data.createdAt)}
                            </>
                    }
                    
                    
                </span>
            </div>
        </div>
    )
}

export default NotificationCard
