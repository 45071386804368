import React , {useContext, useEffect, useState} from 'react'
import At from "../assets/icons/mask.svg";
import {PhrasesContext} from '../context/PhrasesContext'
import { langContext } from "../context/langContext";

import $ from "jquery";

function FullNameInput({data, setData, name, placeHolder, hasIcon, setIsSubmitError, disabled}) {
    
    // handle value change
    const handleValueChange = e => {
        var startWithSpace = /^\s/;
        var letters = /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa\a-zA-Z\s]*$/;
        var letterAndSpace = /^[a-zA-Z\s]*$/;
        if (e.target.value.match(startWithSpace)) {
            return;
        }
        if(!e.target.value.match(letters)) { 
            return; 
        }
        if(e.target.value.length > 300) {
            return;
        }

        setData({ ...data, [name]: e.target.value });
        $(e.target).removeClass("invalid-input");
        $(e.target).closest(".form-col").removeClass("invalid-col");
        setIsSubmitError(false)
    };

    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const [validate, setValidate] = useState('')
    useEffect(() => {
        if (data[name].toString().length === 0) {
            setValidate(phrases?.lblFullNameIsRequired?.[lang])
        }
        if (data[name].toString().length < 3 && data[name].toString().length !== 0) {
            setValidate(phrases?.lblFullNameShouldBeAtLeast?.[lang])
        }
        if (data[name].toString().length > 300) {
            setValidate(phrases?.lblNameNotExceed?.[lang])
        }
    },  [phrases, lang])


    // handle input foucs
    const hanldeFocusOut = e => {

        if (data[name].toString().length === 0) {
            $(`.${name}`).closest(".form-col").addClass("invalid-col");
            $(`.${name}`).addClass("invalid-input");
            setValidate(phrases?.lblFullNameIsRequired?.[lang])



        } else if (data[name].toString().length < 3) {
            $(`.${name}`).closest(".form-col").addClass("invalid-col");
            $(`.${name}`).addClass("invalid-input");
            setValidate(phrases?.lblFullNameShouldBeAtLeast?.[lang])

        } else if (data[name].toString().length > 300) {
            $(`.${name}`).closest(".form-col").addClass("invalid-col");
            $(`.${name}`).addClass("invalid-input");
            setValidate(phrases?.lblNameNotExceed?.[lang])
        } else {
            console.log("===> Full Name Validate <===");
        }
    }


    return (
        <>
            <div className={`input-wrap ${hasIcon && 'has-input-icon'}`}>
                {
                    hasIcon && <img className="input-icon" src={At} alt="icon" />
                }
                <input 
                    className={`site-input requiredData ${name} ${disabled ? 'disabled' : ''}`} 
                    type="text" 
                    placeholder={placeHolder}
                    value={data[name]}
                    name={name}
                    onChange={handleValueChange}
                    onBlur={hanldeFocusOut}
                    disabled={disabled}
                />
            </div>
            <p className="error-msg">{validate}</p>
        </>
    )
}

export default FullNameInput
