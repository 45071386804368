import React, {useContext,  useState} from 'react'
import { getReportDetails } from '../../../../../api/requests_reports'
import { langContext } from '../../../../../context/langContext'
import { PhrasesContext } from '../../../../../context/PhrasesContext'
import CustomIfiniteScroll from '../../../../MyRequestReport/components/ReportTable/CustomIfiniteScroll'

function ReportDetailsTable({matchingColumns, reportData, setReportData, itemId}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    //- load more data
    const [loading, setLoading] = useState(false)
    
    const loadMoreData = () => {
        

        setLoading(true)
        //- API handle
        getReportDetails(itemId, reportData.pageIndex)
            .then(response => {
                
                setReportData(prevState => ({
                    ...prevState,
                    requests: prevState.requests.concat(response.data.data.requests),
                    pageIndex: prevState.pageIndex + 1
                }))

                setLoading(false)

            })
            .catch(error => {
                console.log("Request Report Error ===>", error)
                setLoading(false)
            })
        
    }
    return (
        <>
            {
                reportData.loading
                ?   <>
                        <div className="card-loader data" style={{ width: "100%", height: "33px", marginBottom: "16px" }} ></div>
                        <div className="card-loader data" style={{ width: "100%", height: "33px", marginBottom: "16px" }} ></div>
                        <div className="card-loader data" style={{ width: "100%", height: "33px", marginBottom: "16px" }} ></div>
                        <div className="card-loader data" style={{ width: "100%", height: "33px", marginBottom: "16px" }} ></div>
                        <div className="card-loader data" style={{ width: "100%", height: "33px", marginBottom: "16px" }} ></div>
                    </>
                :   <>
                        <div className="portal-table report-list-table">
                            <CustomIfiniteScroll dataLength={reportData.requests.length} next={loadMoreData}  hasMore={reportData.totalCount > reportData.requests.length ? true : false}> 
                                <table className="policeman-table">
                                    <thead>
                                        <tr>
                                            {
                                                matchingColumns.map(item => {
                                                        return (
                                                            <th key={item.id} className={`militaryRow ${item.relatedColumnName}`}>{item.name}</th>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            reportData.requests.map((item) => {
                                                return (
                                                    <ReportDetailsListItemTd key={item['Job Order ID']} item={item} matchingColumns={matchingColumns} />
                                                )
                                            })
                                        }
                                        
                                    </tbody>
                                </table>
                                {
                                    loading
                                    ?   <>
                                            <div className="card-loader data" style={{ width: "100%", height: "33px", marginBottom: "16px" }} ></div>
                                            <div className="card-loader data" style={{ width: "100%", height: "33px", marginBottom: "16px" }} ></div>
                                        </>
                                    :   null
                                }
                            </CustomIfiniteScroll>
                        </div>
                    </>
            }
        </>
    )
}
function ReportDetailsListItemTd({item}) {
    let tableData = [];
    for (var key in item) {
        if (item.hasOwnProperty(key)) {
            tableData.push(item[key])
        }
    }
    return (
        <tr>
            {
                tableData.map((x, index) => {
                    return (
                        <td key={index}><span>{x || '-'}</span></td>
                    )
                })
            }
        </tr>
    )
}


export default ReportDetailsTable
