import React, {useContext, useState} from 'react'
import dobuleUserIcon from "../../../../assets/icons/double-user-icon-white.svg";

import { getReports } from '../../../../api/requests_reports';
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';
import ReportColumnsDrodown from './ReportColumnsDrodown';
import CustomIfiniteScroll from './CustomIfiniteScroll';
import moment from "moment";
import { RequestListContext } from '../../../../context/RequestListContext';

function ReportTable({data, setData,  coulmns, setCoulmns}) {
    const [report, setReport, filter, setFilter] = useContext(RequestListContext)
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)

   
    
    //- load more data
    const [loading, setLoading] = useState(false)
    const loadMoreData = () => {
        const subittedData = {
            requestId: +filter.requestId,
            vehiclePlateNo: filter.vehiclePlateNo,
            vehicleVin: filter.vehicleVin,
            from: filter.from,
            to: filter.to,
            pageIndex: filter.pageIndex,
            locations: filter.locations
        }

        setLoading(true)
        //- API handle
        getReports(subittedData)
            .then(response => {
                setReport(prevState => ({
                    ...prevState,
                    data: prevState.data.concat(response.data.data.requests)
                }))

                setFilter(prevState => ({
                    ...filter,
                    pageIndex: prevState.pageIndex + 1
                }))
                setLoading(false)

            })
            .catch(error => {
                console.log("Request Report Error ===>", error)
                setLoading(false)
            })
        
    }


    return (
        <>
            {
                report.loading
                ?   <>
                        <div className="card-loader data" style={{ width: "100%", height: "33px", marginBottom: "16px" }} ></div>
                        <div className="card-loader data" style={{ width: "100%", height: "33px", marginBottom: "16px" }} ></div>
                    </>
                :   report.data.length === 0
                    ?   <>
                            <div className="no-active-request sub table" style={{marginBottom: '96px'}}>
                                <div className="no-active-request-icon">
                                    <img src={dobuleUserIcon} alt="icon" />
                                </div>
                                <div className="no-active-request-body">
                                    <strong>{phrases?.lblNoDataFound?.[lang]}</strong>
                                </div>
                            </div>
                        </>
                    :   <>
                            <ReportColumnsDrodown dynamicList={coulmns} setDynamicList={setCoulmns} />
                            
                            <CustomIfiniteScroll dataLength={report.data.length} next={loadMoreData}  hasMore={report.totalCount > report.data.length ? true : false}>
                                <div className="portal-table">
                                    <table>
                                        <thead>
                                            <tr>
                                                {
                                                    coulmns.map(item => {
                                                        return (
                                                            item.selected
                                                            ?   <th key={item.id} className={`militaryRow ${item.relatedColumnName}`}>{item.name}</th>
                                                            :   null
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                            {
                                                report.data.map(item => {
                                                    return (
                                                        coulmns.length
                                                        ?   <ReportTableData key={item.requestId} item={item} dynamicList={coulmns} lang={lang} />
                                                        :   null
                                                        
                                                    )
                                                })
                                            }
                                            
                                        </tbody>
                                    </table>
                                    {
                                        loading
                                        ?   <>
                                                <div className="card-loader data" style={{ width: "100%", height: "33px", marginBottom: "16px" }} ></div>
                                                <div className="card-loader data" style={{ width: "100%", height: "33px", marginBottom: "16px" }} ></div>
                                            </>
                                        :   null
                                    }
                                </div>
                            </CustomIfiniteScroll>

                        </>

            }
            
            
            
        </>
    )
}


function ReportTableData({item, dynamicList, lang}) {
    return (
        <>
            <tr>
                {
                    dynamicList[0].selected 
                    ?   <td className={dynamicList[0].relatedColumnName}>{item.ccExternalId}</td>
                    :   null
                }
                {
                    dynamicList[1].selected 
                    ?   <td className={dynamicList[1].relatedColumnName}>{item.jobId || '-'}</td>
                    :   null
                }
                {
                    dynamicList[2].selected 
                    ?   <td>{moment(item.creationTime).format('M/DD/YYYY')}</td>
                    :   null
                }
                {
                    dynamicList[3].selected 
                    ?   <td><span>{item.pickUpAddress}</span></td>
                    :   null
                }
                {
                    dynamicList[4].selected 
                    ?   <td>
                            {
                                lang === 'en'
                                ?   item.statusNameEn
                                :   item.statusNameAr
                            }
                        </td>
                    :   null
                }
                {
                    dynamicList[5].selected 
                    ?   <td>
                            {
                                lang === 'en'
                                ?   item.requestTypeNameEn
                                :   item.requestTypeNameAr
                            }
                        </td>
                    :   null
                }
                {
                    dynamicList[6].selected 
                    ?   <td><span>{item.dropOffAddress || '-'}</span></td>
                    :   null
                }
                {
                    dynamicList[7].selected 
                    ?   <td>{item.driverName || '-'}</td>
                    :   null
                }
                {
                    dynamicList[8].selected 
                    ?   <td>{item.vehicleVin || '-'}</td>
                    :   null
                }
                {
                    dynamicList[9].selected 
                    ?   <td>{item.vehiclePlateNo || '-'}</td>
                    :   null
                }
                {
                    dynamicList[10].selected 
                    ?   <td>{item.vehicleMake || '-'}</td>
                    :   null
                }
                {
                    dynamicList[11].selected 
                    ?   <td>{item.vehicleModel || '-'}</td>
                    :   null
                }
                {
                    dynamicList[12].selected 
                    ?   <td>{item.vehicleColor || '-'}</td>
                    :   null
                }
                

                
            </tr>
        </>
    )
}

export default ReportTable
