import React, {useContext, useEffect, useState} from 'react'
import { modalContext } from '../../../context/modalContext'
import ReportICON from "../../../assets/icons/report-btn-icon-white.svg";
import { useHistory } from 'react-router';

import CloseIocn from '../../../assets/icons/icon-close-white.svg';
import CloseBlack from "../../../assets/icons/icons-close.svg";
import closeRed from "../../../assets/icons/close-red.svg";
import tickGreen from "../../../assets/icons/tick-green-small.svg";
import $ from "jquery";
import { langContext } from '../../../context/langContext';
import { PhrasesContext } from '../../../context/PhrasesContext';
import { createReport } from '../../../api/requests_reports';

function CreateReportRequestModa({filter, coulmns, setCoulmns}) {
    const history = useHistory();

    const {lang} = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);

    const {setModalState} = useContext(modalContext)
    const hideModal = () => {
        setModalState('hideModal')
    }
    const stopProb = (e) => {
        e.stopPropagation()
    }

    //- validations and submitting
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [isSumbitError, setIsSubmitError] = useState(false)
    const [isValidSubmit, setIsValidSubmit] = useState(false)
    const [apiValidation, setApiValidation] = useState('')

    //- Submitted form object
    const [reportForm, setReportForm] = useState("")

    //- handle message value change
    const hanldeChange = e => {
        var startWithSpace = /^\s/;
        var rgex = /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z\ 0-9+]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_ 0-9+]*$/;
        if (!e.target.value.match(rgex) && e.target.value !== '' || e.target.value.match(startWithSpace)) {
            return;
        }
        setReportForm(e.target.value)
        $(e.target).removeClass("invalid-input");
        $(e.target).closest(".form-col").removeClass("invalid-col");
    }
    

    //- log form data
    useEffect(() => {
        console.log("Sending Report ===>", reportForm)
    }, [reportForm])
    
    //- Hanlde form submit
    const handleSubmit = e => {
        let isValidateToSubmit = true;
        if(reportForm.length === 0) {
            $(`.report-input`).closest(".form-col").addClass("invalid-col");
            $(`.report-input`).addClass("invalid-input");
            isValidateToSubmit = false

        }


        if(isValidateToSubmit) {
            setIsSubmitted(true)
            let selectedCoulmns = [];
            coulmns.map(item => item.selected ? selectedCoulmns.push({name: item.relatedColumnName, title: item.name}) : item)
            const submittedData = {
                reportName: reportForm,
                reportFilter: {...filter, pageIndex: 0, requestId: +filter.requestId},
                columns: selectedCoulmns,
            }
            console.log('submittedData', submittedData)
            createReport(submittedData)
                .then(resp => {
                    setIsSubmitted(false)
                    console.log(resp.data)
                    setIsValidSubmit(true)
                    setReportForm("")
                    
                    // setModalState('hideModal')
                    // history.push(`report`)

                })
                .catch(error => {
                    console.log('ERROR =>', error)
                    setIsSubmitted(false)
                    setIsSubmitError(true)
                    setApiValidation(error?.response?.data?.key || 'lblSomethingWrong')
                })
        }


        e.preventDefault()
    }
    
    
    // custom error handling
    const closeErrorSubmit = () => {
        setIsSubmitError(false)
    }
    const closeValidSubmit = () => {
        setIsValidSubmit(false)
    }
    return (
        <div className="modal-data protal-modal share-modal" onClick={stopProb}>
            <div className="close-modal" onClick={hideModal}>
                <img src={CloseIocn} alt="icon" />
            </div>

            <div className="profile-card">
                <div className="profile-card-head">
                    <div className="user-title-wrap">
                        <div className="user-icon">
                            <img src={ReportICON} alt="icon" style={{width: '24px'}} />
                        </div>
                        <strong>{phrases?.lblCustomizeReport?.[lang]}</strong>
                    </div>
                </div>
                {
                    isValidSubmit
                    ?   <div className="note success">
                            <div className="note-text">
                                <img src={tickGreen} alt="" style={{marginTop: '5px'}} />
                                <span>
                                    {phrases?.lblReportCreatedSuccessfully?.[lang]}
                                </span>
                            </div>
                            <img src={CloseBlack} className="closeNote" onClick={closeValidSubmit} />
                        </div>
                    :   null
                }
                {
                    isSumbitError
                    ?   <div className="note error">
                            <div className="note-text">
                                <img src={closeRed} alt="" />
                                <span>
                                    {
                                        phrases[apiValidation]
                                        ?   phrases[apiValidation][lang]
                                        :   phrases[`lblSomethingWrong`][lang]
                                    }
                                </span>
                            </div>
                            <img src={CloseBlack} className="closeNote" onClick={closeErrorSubmit} />
                        </div>
                    :   null
                }
                <div className={`profile-card-body`}>
                    <form className="share-form request-form" onSubmit={handleSubmit}>
                        <div className="share-form-content">
                            <div className="form-row">
                                <div className="form-col">
                                    <strong>{phrases?.lblReportName?.[lang]}</strong>
                                    <div className="input-wrap">
                                       
                                        <input 
                                            className="site-input report-input" 
                                            type="text" 
                                            placeholder={phrases?.lblEnterReportName?.[lang]}
                                            value={reportForm}
                                            onChange={hanldeChange}
                                        />
                                    </div>
                                    <p className="error-msg">{phrases?.lblReportNameMissing?.[lang]}</p>
                                </div>
                            </div>

                            
                        </div>
                        <div className="share-form-content report-content mt-32">
                            <strong>{phrases?.lblSelectReportColumns?.[lang]}</strong>
                            <div className="columns-list-contain">
                                <span>{phrases?.lblReportMainData?.[lang]}</span>
                                <div className="columns-list-wrap">
                                    {
                                        coulmns.map(item => {
                                            return (
                                                item.mainColumn
                                                ?   <div key={item.id} className="columns-list-item half-opacity">
                                                        <label className="custom-checkbox-label">
                                                            <span className="custom-checkbox-text">{item.name}</span>
                                                            <input type="checkbox" disabled onChange={(e) => {e.preventDefault()}} defaultChecked="checked" />
                                                            <span className="custom-checkbox-checkmark"></span>
                                                        </label>
                                                    </div>
                                                :   null
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="columns-list-contain mt-32">
                                <span>{phrases?.lblOtherData?.[lang]}</span>
                                <div className="columns-list-wrap">
                                    {
                                        coulmns.map(item => {
                                            return (
                                                !item.mainColumn
                                                ?   <DynamicListItem 
                                                        key={item.id} 
                                                        item={item}
                                                        dynamicList={coulmns}
                                                        setDynamicList={setCoulmns}
                                                    />
                                                :   null
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        

                        <div className="request-form-action">
                            <div className="form-aciton">
                                <button  type="submit" className="site-btn" disabled={`${isSubmitted  ? "disabled" : ""}`}>
                                    <span>{phrases?.btnCreateReport?.[lang]}</span>
                                    {
                                        isSubmitted
                                        ?   <div className="spinner"></div>
                                        :   null
                                    }
                                    
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


//- dynamic list item  functional component
function DynamicListItem({item, dynamicList, setDynamicList}) {

    const handleChange = (e) => {
        let checked = e.target.checked;
        if(checked) {
            setDynamicList(dynamicList.map(x => x.id === item.id ? {...x, selected: true} : {...x}))
        } else {
            setDynamicList(dynamicList.map(x => x.id === item.id ? {...x, selected: false} : {...x}))
        }
    }

    return (
        <>
            <div className="columns-list-item">
                <label className="custom-checkbox-label">
                    <span className="custom-checkbox-text">{item.name}</span>
                    <input type="checkbox" onChange={handleChange} defaultChecked={item.selected} />
                    <span className="custom-checkbox-checkmark"></span>
                </label>
            </div>
        </>
    )
}


export default CreateReportRequestModa
