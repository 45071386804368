import React from 'react'
import ProfileInformation from '../Components/Profile/ProfileInformation/ProfileInformation'
import ProfileSideBar from '../Components/Profile/ProfileSideBar/ProfileSideBar'

function UserProfile() {
    
    return (
        <>
            <div className="data-container profile">
                <div className="data-wrapper">
                    <ProfileSideBar />
                    <ProfileInformation />
                </div>
            </div>
        </>
    )
}

export default UserProfile

