import axiosInstance from "../axios/axiosInstance";

export const getRequestsbyStatus = filter =>
  axiosInstance.post(`api/Dashboard/RequestsbyStatus`, filter);

export const getRequestsbyType = filter =>
  axiosInstance.post(`api/Dashboard/RequestsbyType`, filter);

export const getRequestsHeatMap = filter =>
  axiosInstance.post(`api/Dashboard/RequestsbyPoints`, { ...filter });
