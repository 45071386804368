import React, { useContext } from 'react'
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';

function AdvancedSearchInput({item, data, setData}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    
    const hanldeValueChange = e => {
        var numbers = /^[0-9]+$/;
        if (!e.target.value.match(numbers) && e.target.value !== '') {
            return;
        }
        if(e.target.name === 'requestId') {
            setData({...data, requestId: e.target.value, vehiclePlateNo: ""})
        } 
        if(e.target.name === 'vehiclePlateNo') {
            setData({...data, requestId: "", vehiclePlateNo: e.target.value})
        } 
        
    }

    // handle key down
    const hanldeMilitartKeydown = e => {
        if (e.which === 38 || e.which === 40) {
            e.preventDefault();
        }
    };


    return (
        <div className={`input-wrap`}>
            <input 
                className={`site-input`} 
                type="text"
                name={item.submittedValue}
                value={data[item.submittedValue]}
                onChange={hanldeValueChange}
                onKeyDown={hanldeMilitartKeydown}
                placeholder={phrases[item.placeHolder][lang]}
                inputMode="numeric"
                pattern="\d*"

            />
        </div>
    )
}

export default AdvancedSearchInput
