


export const normalize = (arr = [], key = "id") => {
    return arr.reduce((acc, currentValue) => {
        acc[currentValue[key]] = currentValue;
        return acc;
    }, {});
};

export const phoneFormat = num => {
    let arr = num.split("");
    let splittedArray = arr.splice(0, 3);

    return `(${splittedArray.join("")}) ${arr.join("")}`;
};



export const ListMainStatus = {
    12: 'pending',
    13: 'onProgess',
    14: 'completed',
    7: 'cancelled'
}

export const ListSubStatus = {
    12: 'onProgess',
    15: 'onProgess',
    16: 'onProgess',
    17: 'onProgess',
    18: 'completed',
    7: 'cancelled'
}


export const encryptToken = (token)=>{
    let encryptedToken = token.replace('W' , '*_*_*_')
    return encryptedToken

}

export const decryptToken = (token)=>{
    let decryptedToken = token.replace('*_*_*_' ,'W')
    return decryptedToken

}


export const DubaiDialCodes = [
    {id: 1, value: '050'},
    {id: 2, value: '051'},
    {id: 3, value: '052'},
    {id: 4, value: '054'},
    {id: 5, value: '055'},
    {id: 6, value: '056'},
    {id: 7, value: '058'}
]


export const mobileFormat =(mobile)=>{
    let firstThree = mobile.slice(0,3);
    let lastThree = mobile.slice(mobile.length - 3, mobile.length);
    let fullNum = `(${firstThree}) * * * * ${lastThree}`

    return fullNum

}

