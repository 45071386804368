import React, { useContext, useEffect, useState } from "react";
import { langContext } from "../../context/langContext";
import { PhrasesContext } from "../../context/PhrasesContext";
import { requestContext } from "../../context/reqeustContext";
import { itemIdContext } from "../../context/itemIdContext";
import { PinContext } from "../../context/pinContext";
import { PickedLocationContext } from "../../context/pickedLocationContext";

import { EditNewRequest, GetRequestDetails } from "../../api/requests";
import InjuredCounter from "../RequestAdd/InjuredCounter";
import RequestType from "../RequestAdd/RequestType";
import FormIcon from "../../assets/icons/form-icon-head.svg";
import mapPin from "../../assets/icons/icons-map-pin.svg";
import closeRed from "../../assets/icons/close-red.svg";
import CloseBlack from "../../assets/icons/icons-close.svg";
import tickGreen from "../../assets/icons/tick-green-small.svg";

import $ from "jquery";
import AutoCompleteInputs from "../RequestAdd/AutoCompleteInputs";



//- dynamic valdations
//- Validation
function validateForm(relatedForm) {
    var amountInput = $(relatedForm).find(".requiredData");
    var filledInputs = [];
    amountInput.each(function () {
        filledInputs.push(this);
    });

    var noValFilter = filledInputs.filter(function (item) {
        return $(item).val() === "";
    });

    $(noValFilter).each(function () {
        $(this).closest(".form-col").addClass("invalid-col");
        $(this).addClass("invalid-input");
        $(this).closest(".form-col").find(".error-msg").show();
    });

    if (noValFilter.length === 0) {
        return true;
    }
}
function RequestEditIndex() {
    const { lang } = useContext(langContext);
    const [phrases] = useContext(PhrasesContext);
    const { setRequestStatus } = useContext(requestContext);
    const { itemId } = useContext(itemIdContext);
    const [showPin, changePinStatus] = useContext(PinContext);
    const [isMainDataLoading, setMainDataLoading] = useState(true)


    


    //- Request state
    const [newRequestData, setNewRequestData] = useState({
        pickUpLocation: {
            longitude: 0,
            latitude: 0,
            title: "",
            address: "",
        },
        policemanName: "",
        policemanPhone: "",
        numberOfVehicles: 1,
        requestTypeId: 1,
        militaryNumber: "",
        policemanId: 0,
        userId: 0,
        requestId: itemId,
    });
    
    //- Log data once changed
    useEffect(() => {
        console.log("Request Data ===> ", newRequestData)        
    }, [newRequestData])

    //- Request location update
    const [location] = useContext(PickedLocationContext);
    useEffect(() => {
        setNewRequestData({ ...newRequestData, pickUpLocation: location });
    }, [location]);



   //- Get military number data 
   useEffect(() => {
        GetRequestDetails(itemId)
            .then(response => {
                let itemData = response.data.data.mainRequest;
                setNewRequestData({
                    pickUpLocation: {
                        longitude: itemData.pickUpLongtitude,
                        latitude: itemData.pickUpLatitude,
                        title: itemData.pickUpTitle,
                        address: itemData.pickUpAddress,
                    },
                    requestId: itemId,
                    policemanName: itemData.ccPolicemanName,
                    policemanPhone: itemData.ccPolicemanPhone,
                    numberOfVehicles: +itemData.ccNumberOfVehicles,
                    requestTypeId: itemData.requestTypeId,
                    militaryNumber: itemData.militaryNumber,
                    policemanId: 0,
                    userId: 0,
                });
                setMainDataLoading(false)
            })
            .catch(error => {
                setMainDataLoading(false)
                console.log("ERROR ==> ", error.response);
            })

    }, [])
    

    


    //- handle submit preloading
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSumbitError, setIsSubmitError] = useState(false);
    const [apiValidation, setApiValidation] = useState('');
    const [isSumbitValid, setIsSubmitValid] = useState(false);
    const [apiMsg, setApiMsg] = useState('');
    const [isApiMsgError, setApiMsgError] = useState(false)
    const closeErrorSubmit = () => {
        setIsSubmitError(false);
    };


    //- show request list
    const showRequestList = () => {
        if (!isSubmitted) {
            setRequestStatus("showDetailed");
        }
    };


    //- handle form submit
    const hanldeSubmit = e => {
        let elem = $(e.target);
        let isValidateToSubmit = true;

        if(
            newRequestData.pickUpLocation.address === "" ||  newRequestData.policemanName.length < 3 ||
            newRequestData.policemanPhone.length < 10 ||
            newRequestData.militaryNumber.length < 2
        ) {
            isValidateToSubmit = false
        }

        if (!validateForm(elem)) {
            isValidateToSubmit = false;
        }

        if(isValidateToSubmit) {
            console.log("Validate Status ===> SUCCESS")


            setIsSubmitted(true)
            let handleNewReuqestData = {
                requestId: itemId,
                pickUpLocation: newRequestData.pickUpLocation,
                policemanName: newRequestData.policemanName,
                policemanPhone: newRequestData.policemanPhone,
                numberOfVehicles: newRequestData.numberOfVehicles,
                requestTypeId: newRequestData.requestTypeId,
                militaryNumber: +newRequestData.militaryNumber,
                policemanId: newRequestData.policemanId,
                userId: newRequestData.userId
        
            };

            
            EditNewRequest(handleNewReuqestData)
                .then(response => {
                    if(response.data.message.length >= 1) {
                        setApiMsg(response.data.message)
                        setApiMsgError(true)
                        setIsSubmitted(false);
                    } else {
                        setIsSubmitted(false);
                        setIsSubmitValid(true);
                        changePinStatus(false);
                        setTimeout(() => {
                            setRequestStatus("showDetailed");
                        }, 1500)
                    }
                    
                })
                .catch(error => {
                    console.log(error)
                    setIsSubmitted(false)
                    setIsSubmitError(true)
                    setApiValidation(error?.response?.data?.key || 'lblSomethingWrong')

                })
        }

        e.preventDefault();
    }


    
    return (
        <>
            <div className="request-head">
                <div className="request-title noMargin">
                    <strong>{phrases?.lblEditRequest?.[lang]}</strong>
                </div>
            </div>
            

            {
                isApiMsgError
                ?    <div className="note error">
                        <div className="note-text">
                            <img src={closeRed} alt="" />
                            <span>{apiMsg}</span>
                        </div>
                        <img className="closeNote" onClick={closeErrorSubmit} src={CloseBlack} />
                    </div>
                :   null
            }

            {
                isSumbitError 
                ?   <div className="note error">
                        <div className="note-text">
                            <img src={closeRed} alt="" />
                            <span> 
                                {
                                    phrases[apiValidation]
                                    ?   phrases[apiValidation][lang]
                                    :   phrases[`lblSomethingWrong`][lang]
                                }
                            </span>
                        </div>
                        <img className="closeNote" onClick={closeErrorSubmit} src={CloseBlack} />
                    </div>
                :   null
            }

            {
                isSumbitValid 
                ?  <div className="note success">
                        <div className="note-text">
                            <img src={tickGreen} alt="" />
                            <span>{phrases?.lblDataUpdatedSuccessfully?.[lang]}</span>
                        </div>
                        <img className="closeNote" onClick={closeErrorSubmit} src={CloseBlack} />
                    </div>
                :   null
            }

            {
                isMainDataLoading
                ?   <div className="request-body">
                        <div className="card-loader data" style={{ width: '100%', height: '100px', marginTop: '8px' }}></div>
                        <div className="card-loader data" style={{ width: '100%', height: '100px', marginTop: '8px' }}></div>
                    </div>
                :   <div className="request-body">
                        <div className="request-form-contain">
                            <div className="request-form-wrap">
                                <div className="request-form-head">
                                    <div className="request-form-title">
                                        <img src={FormIcon} alt="icon" />
                                        <strong>{phrases?.lblEditRequestMainData?.[lang]}</strong>
                                    </div>
                                </div>
                                <div className="request-form-body">
                                    <form className="request-form" id="request-add-form" onSubmit={hanldeSubmit}>
                                        <div className="form-row">
                                            <div className="form-col">
                                                <strong>{phrases?.lblRequestType?.[lang]}</strong>
                                            </div>
                                            <RequestType data={newRequestData} setData={setNewRequestData} selectedTab={newRequestData.requestTypeId} />
                                        </div>
                                        
                                        <div className="form-row">
                                            <div className="form-col">
                                                <strong>
                                                    {phrases?.lblNumberofRecoveriesNeeded?.[lang]}

                                                    <span>{phrases?.lblOptional?.[lang]}</span>
                                                </strong>
                                                <InjuredCounter
                                                    data={newRequestData}
                                                    setData={setNewRequestData}
                                                    
                                                />
                                            </div>
                                        </div>
                                        
                                        <AutoCompleteInputs 
                                            data={newRequestData} 
                                            setData={setNewRequestData} 
                                            setIsSubmitError={setIsSubmitError}
                                            isEdit={true}
                                            isDataLoad={isMainDataLoading}
                                        />
                                        
                                        
                                        <div className="form-row">
                                            <div className="form-col">
                                                <strong>{phrases?.lblAccidentLocation?.[lang]}</strong>
                                                <div className="request-location">
                                                    <div className="request-location-item noMargin">
                                                        <img src={mapPin} alt="icon" />
                                                        <p className="location-txt">{newRequestData.pickUpLocation.address}</p>
                                                    </div>
                                                    
                                                </div>
                                                <p className="error-msg">
                                                    {phrases?.lblInvalidAddress?.[lang]}
                                                </p>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="request-form-action">
                            <div className="form-aciton">
                                <button
                                    form="request-add-form"
                                    type="submit"
                                    className="site-btn"
                                    disabled={`${isSubmitted ? "disabled" : ""}`}
                                >
                                    <span>{phrases?.btnUpdate?.[lang]}</span>
                                    {isSubmitted ? <div className="spinner"></div> : null}
                                </button>
                                <p onClick={showRequestList} className={`${isSubmitted ? "disabled" : ""}`}>
                                    {phrases?.lblCloseAndGoBackToRequestDetails?.[lang]}
                                </p>
                            </div>
                        </div>
                    </div>
            }
            
            
        </>
    )
}

export default RequestEditIndex

