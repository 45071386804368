import React, { useState } from 'react'
import ForgetPassword from './ForgetPassword'
import ForgetPasswordOTP from './ForgetPasswordOTP'
import ForgetPasswordResend from './ForgetPasswordResend'
import ResetPasswordModal from './ResetPasswordModal'

function ForgetPasswordWrap({setLoginView}) {
    const [loginUserData, setLoginUserData] = useState({
        name: "",
        mob1: "",
        mob2: ""
    })
    const [forgetPasswordViews, setForgetPasswordViews] = useState({
        isForgetPassword: true,
        isForgetPasswordOTP: false,
        isForgetPasswordResend: false,
    })
    const [showResetSuccessModal, setShowResetSuccessModal] = useState(false)

    return (
        <div className="login-card">
            {
                forgetPasswordViews.isForgetPassword && 
                    <ForgetPassword 
                        setLoginView={setLoginView} 
                        setForgetPasswordViews={setForgetPasswordViews} 
                        setLoginUserData={setLoginUserData} 
                    />
            }

            {
                forgetPasswordViews.isForgetPasswordOTP && 
                    <ForgetPasswordOTP loginUserData={loginUserData} setForgetPasswordViews={setForgetPasswordViews}  />
            }

            {
                forgetPasswordViews.isForgetPasswordResend && 
                    <ForgetPasswordResend setShowResetSuccessModal={setShowResetSuccessModal} />
            }

            {
                showResetSuccessModal && 
                    <ResetPasswordModal setLoginView={setLoginView}  setShowResetSuccessModal={setShowResetSuccessModal}  />
            }

            
        </div>
    )
}

export default ForgetPasswordWrap
