// import React, { useState } from 'react';



// const RequestItemIdContext = React.createContext([() => {}]);

// const RequestItemIdProvider = ({children}) => {
//     const [itemId, setItemId] = useState('')
//     const changeItemId = (value) => {
//         setItemId(value)
//     }

//     return (
//         <RequestItemIdContext.Provider value={[itemId, changeItemId]}>
//             {children}
//         </RequestItemIdContext.Provider>
//     )
// }

// export { RequestItemIdProvider };


import React from 'react';



export const itemIdContext = React.createContext();
export const itemIdInitialState = null


export const itemIdReducer = (state = itemIdInitialState, action) => {
    return state = action
}