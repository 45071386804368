import React, { useContext, useRef, useState } from 'react'
import downArrow from "../../../../assets/icons/down-arrow-green.svg";
import useOnclickOutside from "react-cool-onclickoutside";
import AdvancedSearchListItem from './AdvancedSearchListItem';
import AdvancedSearchInput from './AdvancedSearchInput';
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';

function AdvancedSearchWrap({data, setData}) {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)

    const [showDropDown, setShowDropDown] = useState(false)
    const textRef = useRef(null)
    //- handle dropdown
    const hanldeDropdown = () => {
        setShowDropDown(prevState => !prevState)
    }

    //- hanlde outside click
    const dropDownKey = useOnclickOutside(() => {
        setShowDropDown(false)
    }, { ignoreClass: "ignore-this" });


    //- advanced search list
    const [advancedSearchList, setAdvancedSearchList] = useState([
        {id: 1, name: "lblRequestID", submittedValue: "requestId", placeHolder: "lblEnterRequestID", selected: true},
        {id: 2, name: "lblPlateNumber", submittedValue: "vehiclePlateNo", placeHolder: "lblEnterPlateNumber", selected: false},
    ])



    return (
        <div className="advanced-filter-contain">
            <strong>{phrases?.lblSearchBy?.[lang]}</strong>
            <div className="advanced-filter-wrap">
                <div className="advanced-filter-select">
                    <div className="input-wrap">
                        <div className="request-select">
                            <div className={`request-select-head ignore-this`} onClick={hanldeDropdown} ref={dropDownKey}>
                                <div className="request-select-text">
                                    <span ref={textRef}>{phrases?.lblRequestID?.[lang]}</span>
                                </div>
                                <img src={downArrow} width="11" alt="icon" />
                            </div>
                            {
                                showDropDown
                                ?   <div className="request-select-body ignore-this" >
                                        <ul>
                                            {
                                                advancedSearchList.map(item => {
                                                    return (
                                                        <AdvancedSearchListItem 
                                                            key={item.id} 
                                                            item={item} 
                                                            setShowDropDown={setShowDropDown}
                                                            advancedSearchList={advancedSearchList}
                                                            setAdvancedSearchList={setAdvancedSearchList}
                                                            textRef={textRef} 
                                                        />
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                :   null
                            }
                            
                        </div>
                    </div>
                </div>
                <div className="advanced-filter-input">
                    {
                        advancedSearchList.map(item => {
                            return (
                                item.selected
                                ?   <AdvancedSearchInput 
                                        key={item.id} 
                                        item={item} 
                                        data={data}
                                        setData={setData}
                                    />
                                :   null
                                
                            )
                        })
                    }
                    
                </div>
            </div>
        </div>
    )
}

export default AdvancedSearchWrap
