import React, { useEffect, useState } from "react";
import Search from "../../assets/icons/search.svg";
import Close from "../../assets/icons/icons-close.svg";
import { SearchForRequest, GetMainRequests } from "../../api/requests";


function SearchRequest({ setRequestList, searchType, placeHold, resetClicked }) {
  const [isValidToSubmit, setValidToSubmit] = useState(false)
  const [searchText, setSearchText] = useState("");
  const handleInputChange = e => {
    setSearchText(e.target.value);
  };

  const loadDataInit = () => {
    setValidToSubmit(false)
    setRequestList({
      length: 0,
      loading: true,
      data: [],
      error: false,
    });

    if(searchType === 'requestList') {
      GetMainRequests()
      .then(response => {
        setRequestList({
          length: response.data.data.length,
          loading: false,
          data: response.data.data,
          error: false,
        });
      })
      .catch(error => {
        console.log("ERROR ==> ", error.response);
        setRequestList({
          length: 0,
          loading: false,
          data: [],
          error: true,
        });
      });
    }    
  }

  const handleKeyUp = e => {
    if (e.target.value === "" && isValidToSubmit) {
      loadDataInit()
    }
  };

  const removeText = () => {
    if(isValidToSubmit) {
      loadDataInit()  
    }
    setSearchText("");
  };

  useEffect(() => {
    removeText()
  }, [resetClicked])
  const handleKeyPress = e => {
    if (e.key === "Enter" && searchText !== '') {
      setValidToSubmit(true)
      setRequestList({
        length: 0,
        loading: true,
        data: [],
        error: false,
      });

      if(searchType === 'requestList') {
        SearchForRequest({ searchText: searchText })
        .then(response => {
          setRequestList({
            length: response.data.data.length,
            loading: false,
            data: response.data.data,
            error: false,
          });
        })
        .catch(error => {
          console.log("ERROR ==> ", error.response);
          setRequestList({
            length: 0,
            loading: false,
            data: [],
            error: true,
          });
        });
      }
      
    }
  };
  
  return (
    <div className="input-wrap hasSearchIcon">
      <img className="input-icon" src={Search} />
      <input
        className="site-input"
        type="text"
        value=""
        placeholder={placeHold}
        name="searchRequest"
        value={searchText}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        onKeyUp={handleKeyUp}
      />
      {searchText !== "" ? (
        <img className="remove-text" src={Close} onClick={removeText} />
      ) : (
        ""
      )}
    </div>
  );
}

export default SearchRequest;
