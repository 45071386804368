import React from 'react'
import RequestReportPageWrapper from '../Components/MyRequestReport/RequestReportPageWrapper'

function NewRequestBoardPage() {
    return (
        <div className="data-container profile">
            <div className="data-wrapper">
                <RequestReportPageWrapper />
            </div>
        </div>
    )
}

export default NewRequestBoardPage
