import React from 'react';



export const addCarDetailsContext = React.createContext();
export const addCarDetailsInitialState = {

}


export const addCarDetailsReducer = (state = addCarDetailsInitialState, action) => {
    return state = action
}