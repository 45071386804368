import React, {useContext, useState} from 'react'
import { getReports } from '../../../../api/requests_reports';
import DownArrow from "../../../../assets/icons/icons-arrow-gray.svg";
import { langContext } from '../../../../context/langContext';
import { PhrasesContext } from '../../../../context/PhrasesContext';
import { RequestListContext } from '../../../../context/RequestListContext';
import DateSearch from './DateSearch';
import LocationSearch from './LocationSearch';

function FilterWrap({data, setData, showAdvancedSearch, setShowAdvancedSearch}) {
    const [report, setReport, filter, setFilter] = useContext(RequestListContext)

    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext)
    const [submitLoading, setSubmitLoading] = useState(false)
    const hanldeShowAdvancedSearch = () => {
        setShowAdvancedSearch(prevState => !prevState)
    }

    const hanldeSearch = () => {

        setSubmitLoading(true)

        const subittedData = {
            requestId: +data.requestId,
            vehiclePlateNo: data.vehiclePlateNo,
            vehicleVin: data.vehicleVin,
            from: data.from,
            to: data.to,
            pageIndex: 0,
            locations: data.locations
        }

        //- API handle
        getReports(subittedData)
            .then(response => {
                setReport({
                    ...report,
                    data: response.data.data.requests,
                    totalCount: response.data.data.totalCount,
                    subRequestsCount: response.data.data.subRequestsCount,
                    mainRequestsCount: response.data.data.mainRequestsCount,
                })
                setData({
                    ...data,
                    pageIndex: 1
                })
                setSubmitLoading(false)
                let tableLocation = document.getElementById("request-table").offsetTop;
                window.scrollTo({ top: tableLocation, behavior: 'smooth'});

            })
            .catch(error => {
                console.log("Request Report Error ===>", error)
                setSubmitLoading(false)
            })
    }
    return (
        <div className="head-filter-wrap">
            <div className="head-filter-location">
                <div className="filter-location">
                    <strong>{phrases?.lblLocation?.[lang]}</strong>
                    <LocationSearch data={data} setData={setData} />
                </div>
            </div>
            <div className="head-filter-date">
                <div className="filter-date">
                    <strong>{phrases?.lblDate?.[lang]}</strong>
                    <div className="input-wrap">
                        <DateSearch data={data} setData={setData} />
                    </div>
                    
                </div>
                <div className="filter-advanced">
                    <div className={`filter-btn site-btn ${submitLoading ? 'disabled' : ''}`} onClick={hanldeSearch}>
                        <span>{phrases?.btnSearchButton?.[lang]}</span>
                        {
                            submitLoading ? <div className="spinner"></div> : null
                        }
                    </div>
                    
                    
                    <div className={`filter-advanced-key ${showAdvancedSearch ? 'active' : ''}`} onClick={hanldeShowAdvancedSearch}>
                        <img src={DownArrow} alt="icon" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterWrap
