import React, {useState, useContext, useEffect} from 'react'
import { getReportList } from '../../../api/requests_reports';
import { langContext } from '../../../context/langContext';
import { PhrasesContext } from '../../../context/PhrasesContext';
import ReportListFixedFoot from './components/ReportListFixedFoot';
import ReportListHead from './components/ReportListHead';
import ReportListTable from './components/ReportListTable';

function ReportListingIndex() {
    const {lang} = useContext(langContext)
    const [phrases] = useContext(PhrasesContext);

    //- define report list
    const [reportList, setReportList] = useState({ loading: true, data: [], error: false, totalCount: "" });
    const [filter, setFilter] = useState({reportName: "", pageIndex: 1})

    //- fetch report list
    useEffect(() => {
        const submittedData = {
            reportName: filter.reportName,
            pageIndex: 0
        }
        getReportList(submittedData)
            .then(response => {
                const reportDataArray = [];
                if(response.data.data.reports.length) {
                    response.data.data.reports.map(item => reportDataArray.push({...item, selected: false}))
                }
                
                setReportList({
                    loading: false,
                    data: reportDataArray,
                    totalCount: response.data.data.totalCount,
                    error: false
                })
            })
            .catch(error => {
                setReportList({
                    loading: false,
                    data: [],
                    totalCount: "",
                    error: true
                })
                console.log("Error ==>", error)
            })
    }, [])


    //- call report list once updated
    useEffect(() => {
        console.log("Report Listing ===>", reportList)
    }, [reportList])

    return (
        <div className="portal-table-wrap" style={{paddingBottom: '132px'}}>
            <div className="portal-table-head">
                <ReportListHead report={reportList} setReport={setReportList} />
            </div>
            
            <div className="portal-table-body">
                <ReportListTable report={reportList} setReport={setReportList} filter={filter} setFilter={setFilter} />
                <ReportListFixedFoot report={reportList} setReport={setReportList} />
            </div>
            
    
        </div>
    )
}

export default ReportListingIndex
